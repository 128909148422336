import React, { } from 'react';
import {
  Button,
  Grid,
  Typography,
  Dialog,
  CircularProgress,
} from "@mui/material";
import { AddFillSVG, CloseRoundedSVG, DeleteSVG, UploadSVG, WarningSVG } from '../../assets/icons';
import { useTheme } from '@emotion/react';

const DialogAction = (props) => {
  const { open, onClose, header, icon, title, messages, deleting, icons, hideButtons, cancel, confirm, footer, children } = props;
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      style={{
        width:'100%',
      }}
      PaperProps={{
        style:{
          overflow:'visible',
          borderRadius:16,
          width:'100%'
        }
      }}
    >
      <Grid
        container
        style={{
          position:'relative',
          padding:32,
          // paddingTop:16,
          width:'100%',
          flexDirection:'column',
          alignItems:'center'
        }}
      >
        <Grid
          style={{
            position:'absolute',
            top:-25,
            display:'flex',
            gap:8,
            alignItems:'center',
            background:'#fff',
            padding:'4px 16px 20px 16px',
            borderRadius:8
          }}
        >
          {icon}
          <Typography
            style={{
              textTransform:'uppercase',
              fontSize:'.9rem',
              fontWeight:700,
              color: theme.palette.primary.main
            }}
          >
            {header}
          </Typography>
        </Grid>
        <Typography
          style={{
            fontSize:'1.3rem',
            marginBottom:8
          }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            marginBottom:8
          }}
        >
          {messages?.one}
        </Typography>
        <Typography

        >
          {messages?.two}
        </Typography>

        <div style={{marginTop:8, width:'100%'}}>
          {children}
        </div>
          

        {
          hideButtons
            ? 
              null
            :
              <Grid
                container
                style={{
                  marginTop:24,
                  justifyContent:'space-around'
                }}
              >
                <Button
                  color="mid"
                  variant="outlined"
                  startIcon={icons ? (icons.cancel ? icons.cancel : <CloseRoundedSVG width={12} color1={theme.palette.mid.main} />) : null}
                  onClick={cancel ? cancel : onClose}
                  style={{
                    minWidth:120
                  }}
                >
                  {messages.cancel ? messages.cancel : 'Nevermind'}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={icons ? (deleting ? <CircularProgress size={16} thickness={8} style={{color:'#fff'}} /> : icons.confirm ? icons.confirm : <DeleteSVG width={12} color1="#fff" />) : null}
                  onClick={confirm}
                  style={{
                    minWidth:120
                  }}
                >
                  {deleting ? (messages.inAction ? messages.inAction : 'Deleting') : (messages.confirm ? messages.confirm : 'Delete')}
                </Button>
              </Grid>
        }
      </Grid>
      {footer}
    </Dialog>
  )
}

export default DialogAction