import React, { useEffect, useState } from 'react'
import { DjangoComponents } from '../../templates/DjangoStyles';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { CircularProgress, Grid, IconButton, LinearProgress, Typography } from '@mui/material';
import { TagButton } from '../ui/Tags';
import { useGetFilteredCampaigns } from '../../hooks/mutations';
import { OpenInNewSVG } from '../../assets/icons';
import { subscriberStatusEnum } from '../../lib/Data';

const ListOverview = (props) => {
  const { listData } = props;
  // const [list, setList] = useState({});
  const [reloadCampaigns, setReloadCampaigns] = useState(false);
  const [filterBy, setFilterBy] = useState({
    page: 1,
    page_size: 10,
    mailing_lists: [listData.id]
  });
  const campaigns = useGetFilteredCampaigns({reload:reloadCampaigns, filterBy:filterBy});
  // console.log('listData', listData);
  // console.log('campaigns', campaigns);

  return (
    <Grid container p={2}>
      <ReportHeader listData={listData} />
      <ListStatus listData={listData} />
      <Grid container spacing={2} mt={2}>
        <LinearProgressCard
          title="Open Rate"
          value={Math.round(listData.open_rate)}
          valueEnd="%"
          details={{
            "List average": `${Math.round(listData.mailing_list_open_rate)}%`,
            "Unique opens": listData.unique_opens_count,
            "Total opens": listData.total_opens_count,
          }}
        />
        <LinearProgressCard
          title="Click Rate"
          value={Math.round(listData.click_rate)}
          valueEnd="%"
          details={{
            "List average": `${Math.round(listData.mailing_list_click_rate)}%`,
            "Unique clicks": listData.unique_clicks_count,
            "Total clicks": listData.total_clicks_count,
          }}
        />
        {/* <CircleProgressCard
          title="Open Rate"
          value={Math.round(listData.open_rate)}
          valueEnd="%"
          details={{
            "List average": `${Math.round(listData.mailing_list_open_rate)}%`,
            "Unique opens": listData.unique_opens_count,
            "Total opens": listData.total_opens_count,
          }}
        />
        <CircleProgressCard
          title="Click Rate"
          value={Math.round(listData.click_rate)}
          valueEnd="%"
          details={{
            "List average": `${Math.round(listData.mailing_list_click_rate)}%`,
            "Unique clicks": listData.unique_clicks_count,
            "Total clicks": listData.total_clicks_count,
          }}
        /> */}
      </Grid>
      <ListCampaigns campaigns={campaigns} listData={listData} />
    </Grid>
  )
}

export default ListOverview

const ReportHeader = (props) => {
  const { listData } = props;

  return (
    <Grid
      container
      sx={{
        justifyContent:'space-between',
        mb:2
      }}
    >
      <Typography
        variant="h2"
        sx={{
          // fontSize:'2rem'
        }}
      >
        {listData.name}
      </Typography>
    </Grid>

  )
}

const ListTimeline = (props) => {
  const { listData } = props;

  return (
    <DjangoComponents margin card padding>
      <Grid
        container
        sx={{

        }}
      >
        <Grid
          container
          variant="center"
          sx={{
            height:300,
            my:2,
            border:'1px solid #ccc',
            borderRadius:2
          }}
        >
          TIMELINE GRAPH GOES HERE
        </Grid>
      </Grid>
    </DjangoComponents>
  )
}

const ListStatus = (props) => {
  const { listData } = props;
  const [currentStatus, setCurrentStatus] = useState({});
  const [hideUnselected, setHideUnselected] = useState(false);
  const [menuHover, setMenuHover] = useState(null);
  console.log('menuHover', menuHover);
  return (
    <DjangoComponents margin card padding>
      <Grid
        container
        onMouseEnter={() => {if (hideUnselected) {setHideUnselected(false)}}}
        onMouseLeave={() => {if (!hideUnselected && currentStatus !== Object.create({})) {setHideUnselected(true)} }}
        sx={{
          pb:1,
          borderBottom:'1px solid #ccc'
        }}
      >
        {subscriberStatusEnum.map(subscriber => (
          <Grid
            item
            sx={{
              aspectRatio:2,
              maxHeight:50,
              // width:menuWidth,
              // display:hideUnselected && currentStatus !== subscriber ? 'none' : 'flex',
              display:'flex',
              overflowY:'hidden',
              alignItems:'center',
              // justifyContent:'center',
              p:.5,
            }}
            ts={12}
            xs={6}
            sm={4}
            md={2}
          >
            <Grid
              onMouseEnter={() => setMenuHover(subscriber.status)}
              onMouseLeave={() => setMenuHover(null)}
              onClick={() => { if (currentStatus === subscriber) {setCurrentStatus({}); setHideUnselected(false);} else {setCurrentStatus(subscriber); setHideUnselected(true)}}}
              sx={{
                display:'flex',
                alignItems:'center',
                gap:.7,
                background:currentStatus.status === subscriber.status ? `${subscriber.color}35` : 'transparent',
                p:1,
                pr:2,
                borderRadius:4,
                outline: menuHover === subscriber.status || currentStatus.status === subscriber.status ? `1px solid ${subscriber.color}70` : 'none',
                cursor:'pointer'
              }}
            >
              <div
                style={{
                  width:20,
                  minWidth:20,
                  height:20,
                  background:subscriber.color,
                  opacity:currentStatus.status && subscriber.status !== currentStatus.status ? .6 : 1,
                  borderRadius:20,
                }}
              />
              <Typography
                sx={{
                  lineHeight:1.1,
                  // textAlign:'center',
                }}
              >
                {subscriber.label}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        variant="center"
        sx={{
          height:300,
          my:2,
          border:`1px solid ${currentStatus.color}`,
          color:currentStatus.color,
          background:`${currentStatus.color}45`,
          borderRadius:2
        }}
      >
        TIMELINE GRAPH GOES HERE
      </Grid>
      
    </DjangoComponents>
  )
}

const ListCampaigns = (props) => {
  const { campaigns, listData } = props;

  return (
    <DjangoComponents margin card padding>
      <Grid
        container
        sx={{
        }}
      >
        <Grid>
          <Typography
              sx={{
                fontSize:'1.3rem',
                fontWeight:600,
                lineHeight:1
              }}
            >
              Campaigns
            </Typography>
            <Typography
              sx={{
                lineHeight:1.4,
                fontWeight:500,
                color:'#777'
              }}
            >
              Campaigns that were sent to {listData.name}
            </Typography>
        </Grid>
        <Grid
          container
          sx={{
            mt:2
          }}
        >
          {
            campaigns?.results.map((campaign, index) => (
              <Grid
                container
                sx={{
                  background:index % 2 ? '#f7f7f7' : '#fdfdfd',
                  border:'1px solid #f7f7f7',
                  p:1,
                  justifyContent:'space-between',
                  alignItems:'center'
                }}
              >
                <Grid item xs>
                  <Typography>{campaign.name}</Typography>
                </Grid>
                <Grid item sx={{width:32}}>
                  <IconButton
                    href=""
                  >
                    <OpenInNewSVG width={16} />
                  </IconButton>
                </Grid>
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </DjangoComponents>
  )
}

const LinearProgressCard = (props) => {
  const { title, value, valueEnd, details } = props;
  const theme = useTheme();
  const [determinedValue, setDeterminedValue] = useState(0);
  
  useEffect(() => {
    if (value) {
      setDeterminedValue(value);
    }
  }, [value])  
  
  return (
    <Grid item xs={12} sm>
      <DjangoComponents card padding>
        <Grid
          container
          sx={{
            justifyContent:'space-between',
            alignItems:'flex-end`',
            mb:1
          }}
        >
          <Typography
              sx={{
                fontSize:'1.3rem',
                fontWeight:600,
                lineHeight:1
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize:'1.1rem',
                lineHeight:1
              }}
            >
              {value}{valueEnd}
            </Typography>
        </Grid>
        <LinearProgress
          variant="determinate"
          value={determinedValue}
          style={{
            width:'100%',
            // maxWidth:400,
            height:20,
            borderRadius:6
          }}
        />
        {
          details &&
            <Grid
              container
              sx={{
                mt:1
              }}
            >
              {Object.entries(details).map(([key, value]) => (
                <Grid key={key} container sx={{justifyContent:'space-between'}}>
                  <Typography
                    sx={{
                      fontSize:'.9rem',
                      color:'#333'
                    }}
                  >
                    {key}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize:'.9rem',
                      color:'#333'
                    }}
                  >
                    {value}
                  </Typography>
                  </Grid>
              ))}
            </Grid>
        }
          
      </DjangoComponents>
    </Grid>
  )
}

const CircleProgressCard = (props) => {
  const { title, value, valueEnd, details } = props;
  const theme = useTheme();
  const [determinedValue, setDeterminedValue] = useState(0);
  
  useEffect(() => {
    if (value) {
      setDeterminedValue(value);
    }
  }, [value])

  return (
    <Grid item xs={12} sm>
      <DjangoComponents card padding>
        <Grid
          container
          sx={{
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            mb:1
          }}
        >
          <Typography
            sx={{
              fontSize:'1.3rem',
              lineHeight:1
            }}
          >
            {title}
          </Typography>
          <Grid
            sx={{
              position:'relative',
              width:200,
              height:200,
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              mt:1
            }}
          >
            <CircularProgress
              variant="determinate"
              value={100}
              size={200}
              thickness={10}
              sx={{
                position:'absolute',
                top:0,
                left:0,
                '&&.MuiCircularProgress-root':{
                  color:theme.palette.primary.light
                }
              }}
            />
            <CircularProgress
              variant="determinate"
              color="secondary"
              value={determinedValue}
              size={200}
              thickness={10}
              sx={{
                position:'absolute',
                top:0,
                left:0,
                '&&.MuiCircularProgress-root':{
                  color:theme.palette.primary.dark
                }
              }}
            />
            <Typography
              sx={{
                fontSize:'1.6rem',
                fontWeight:600,
                lineHeight:1
              }}
            >
              {value}{valueEnd}
            </Typography>

          </Grid>
        </Grid>
        {
          details &&
            <Grid
              container
              sx={{
                mt:1
              }}
            >
              {Object.entries(details).map(([key, value]) => (
                <Grid key={key} container sx={{justifyContent:'space-between'}}>
                  <Typography
                    sx={{
                      fontSize:'.9rem',
                      color:'#333'
                    }}
                  >
                    {key}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize:'.9rem',
                      color:'#333'
                    }}
                  >
                    {value}
                  </Typography>
                  </Grid>
              ))}
            </Grid>
        }
          
      </DjangoComponents>
    </Grid>
  )
}