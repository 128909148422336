import React, { cloneElement, useEffect, useState } from 'react'
import { Grid, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Typography } from '@mui/material'
import { getContrastColor, getContrastTone } from '../../lib/Functions'
import { CloseFilledSVG, CloseOutlineSVG, ColorFillSVG, ColorOutlineSVG, DeleteSVG, EditSVG, MoreMenuSVG, MoreOptionsSVG } from '../../assets/icons';
import { deleteTag, putTag } from '../../hooks/mutations';
import { InputField } from './Fields';

// FOR ALL TAG COMPONENTS: COLORS MUST HAVE SIX CHARACTER HEX CODES — NO 3 CHARACTER HEX CODES
// + #FFFFFF    < GOOD
// - #FFF       < BAD

export const Tag = (props) => {
  const { tag, light, dark, removeTag, showOptions, setReload, contactId, reloadContact } = props;
  const [hover, setHover] = useState(false);
  const [tagData, setTagData] = useState(tag);
  const [optionType, setOptionType] = useState(null);

  const backgroundColor = getContrastTone(tag.color).background; // tag.color ? tag.color : '#ccc';
  const fontColor = getContrastTone(tag.color).text;
  const outlineColor = getContrastTone(tag.color).outline;

  // console.log('fontColor', ' ', tag.name, ': ', fontColor)

  // const fontColor = getContrastColor({
  //   background: tag.color,
  //   light: light ? light : '#ffffff',
  //   dark: dark ? dark : '#000000'
  // });

  const handleTagDataChange = (field, value) => {
      setTagData(prev => ({ ...prev, [field]: value}))  ;
  }

  const editTag = async () => {
      const tagObj = {
        tagId: tag.id,
        payload: tagData
      }
  
      const updatedTag = await putTag(tagObj);
      if (updatedTag.status === "success") {
        console.log('successful tag update');
        if (setReload) {
          setReload(true);
        }
        if (reloadContact) {
          reloadContact(contactId);
        }
      }
  }


  // MENU
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openOptions = Boolean(anchorEl);
  const handleOptionsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOptionsClose = () => {
    setAnchorEl(null);
    setHover(false);
    if ( tag !== tagData) {
      editTag();
    } else {
      console.log('no tag data has been changed')
    }
    setTimeout(() => {
      setOptionType(null);
    }, 250);
  };

  useEffect(() => {
    if (tag) {
      setTagData(tag);
    }
  }, [tag])


  return (
    <Grid
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        position:'relative',
        background: backgroundColor,
        padding:'4px 12px',
        borderRadius:16,
        overflow:'clip',
        outline: outlineColor
      }}
    >
      <Typography
        style={{
          color: fontColor,
          fontSize:'.8rem',
          lineHeight:1,
          fontWeight:500
        }}
      >
        {
          tag.name
            ?
              tag.name.replace('-', ' ')
            :
              <span style={{fontStyle:'italic'}}>Unnamed Tag</span>
        }
      </Typography>
      <Grid
        style={{
          position:'absolute',
          margin:'auto',
          top:0,
          bottom:0,
          right:hover ? 0 : -45,
          transition:'.2s',
          display:'flex',
          columnGap:4,
          padding:'0 4px',
          background:backgroundColor
        }}
      >
        {
          showOptions &&
            <MoreOptionsSVG
              onClick={handleOptionsClick}
              color1={`${fontColor}cc`}
              height={5}
              style={{height:20, paddingRight:removeTag ? 0 : 4}}
            />
        }
        {
          removeTag &&
            <CloseOutlineSVG
              onClick={() => removeTag(tag.id)}
              height={15}
              color1={`${fontColor}cc`}
              style={{height:20}}
            />
        }
      </Grid>
      <Menu
        id="tag-options-menu"
        anchorEl={anchorEl}
        open={openOptions}
        onClose={handleOptionsClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}          
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuList>
          {
            optionType === null &&
              <>
                <CustomMenuItem
                  label="Rename"
                  onClick={() => setOptionType('name')}
                  icon={<EditSVG />}
                />
                <CustomMenuItem
                  label="Color"
                  onClick={() => setOptionType('color')}
                  icon={<ColorOutlineSVG />}
                />
                <CustomMenuItem
                  label="Remove"
                  onClick={() => {}}
                  icon={<DeleteSVG />}
                />
              </>
          }
          {
            optionType === 'name' &&
              <Grid
                container
                style={{
                  padding:'0 8px'
                }}
              >
                <Typography
                  style={{
                    marginBottom:8
                  }}
                >
                  Rename {tag.name}
                </Typography>
                <InputField
                  id={optionType}
                  // placeholder='Field Name'
                  // label={fieldKey}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleOptionsClose()
                    }
                  }}
                  value={tagData[optionType]}
                  onChange={(e) => handleTagDataChange(optionType, e.target.value)}
                />
              </Grid>
          }
          {
            optionType === 'color' &&
              <Grid
                container
                style={{
                  padding:'0 8px'
                }}
              >
                <Typography
                  style={{
                    marginBottom:8
                  }}
                >
                  Change Color
                </Typography>
                <input
                  id={optionType}
                  type="color"
                  // placeholder='Field Name'
                  // label={fieldKey}
                  value={tagData[optionType]}
                  onChange={(e) => handleTagDataChange(optionType, e.target.value)}
                  style={{
                    width:'100%'
                  }}
                />
              </Grid>
          }
        </MenuList>
      </Menu>

    </Grid>
  )
}

export const TagSimple = (props) => {
  const { name, color={background: 'transparent', outline:'#ccc', text:'#aaa'}, thin } = props;
  const fontColor = getContrastColor(color.background);
  const outlineColor = getContrastTone(color.background).outline;

  return (
    <Grid
      style={{
        position:'relative',
        background: color.background,
        padding: thin ? '2px 12px' : '6px 12px 4px',
        borderRadius:16,
        overflow:'clip',
        border: color.border ? `1px solid ${color.border ? color.border : outlineColor}` : 'none',
        outline: !color.outline ? 'none' : `1px solid ${color.outline ? color.outline : outlineColor}`,
        display:'flex',
        alignItems:'center',
        whiteSpace:'nowrap',
        textOverflow:'ellipsis'
      }}
    >
      <Typography
        style={{
          color: color.text ? color.text : fontColor,
          fontSize:'.8rem',
          lineHeight:1,
          fontWeight:500,
          letterSpacing:1.05,
          whiteSpace:'nowrap',
          textOverflow:'ellipsis',
          cursor:'default'
        }}
      >
        {name}
      </Typography>

    </Grid>
  )
}

const CustomMenuItem = (props) => {
  const { icon, label, onClick } = props;

  return (
    <MenuItem
      onClick={onClick}
    >
      <ListItemIcon>
        {cloneElement(icon, { width:label === 'Rename' ? 17 : 15, color1:label === 'Remove' ? 'red' : '#777' } )}
      </ListItemIcon>
      <ListItemText
        style={{
          color:label === 'Remove' ? 'red' : '#777'
        }}
      >
        {label}
      </ListItemText>
    </MenuItem>
  )
}

export const TagButton = (props) => {
  const { bgColor, fontColor, label, outlined, outlinedColor, border, borderColor, onClick, iconStart, iconEnd, regular, noPointer } = props;
  const [hover, setHover] = useState(false);


  return (
    <Grid
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        position:'relative',
        display:'flex',
        alignItems:'center',
        gap:4,
        background: bgColor,
        padding:'4px 12px',
        borderRadius:16,
        overflow:'clip',
        border: border ? (`2px solid ${borderColor ? borderColor : fontColor}`) : null,
        outline: outlined ? (`2px solid ${outlinedColor ? outlinedColor : fontColor}`) : null,
        cursor: noPointer ? 'default' : 'pointer',
        transform:''
      }}
    >
      {
        iconStart &&
          cloneElement(iconStart, { width: 10, color1: fontColor } )
      }
      <Typography
        style={{
          color: fontColor,
          fontSize: regular ? '1rem' : '.8rem',
          lineHeight: regular ? '.9rem' : '.7rem',
          fontWeight:500
        }}
      >
        {label}
      </Typography>
      {
        iconEnd &&
          cloneElement(iconEnd, { width: 15, color1: fontColor } )
      }
    </Grid>
  )
}
