import React, { useEffect, useState } from 'react'
import { DjangoComponents } from '../../templates/DjangoStyles';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Box, Button, CircularProgress, Grid, IconButton, LinearProgress, Tab, Tabs, Typography } from '@mui/material';
import { TagButton } from '../ui/Tags';
import { useGetFilteredCampaigns } from '../../hooks/mutations';
import { OpenInNewSVG } from '../../assets/icons';
import { subscriberStatusEnum } from '../../lib/Data';
import { InputField } from '../ui/Fields';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      // style={{ width: '100%' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const ListSettings = (props) => {
  const { listData } = props;
  const [current, setCurrent] = React.useState(0);
  const [data, setData] = useState({
    list_settings: {},
    subscription_settings: {},
    campaign_defaults: {}
  });

  console.log('data', data);

  const handleChange = (event, newValue) => {
    setCurrent(newValue);
  };

  return (
    <Grid container p={2}>
      <SettingsHeader listData={listData} />
      <Box
        sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={current}
          onChange={handleChange}
          aria-label="List Settings"
          sx={{ pt:3, borderRight: 1, borderColor: 'divider', minWidth:250 }}
        >
          <Tab label="List Settings" sx={{alignItems:'flex-start'}}  {...a11yProps(0)} />
          <Tab label="Subscription Settings" sx={{alignItems:'flex-start'}} {...a11yProps(1)} />
          <Tab label="Campaign Defaults" sx={{alignItems:'flex-start'}} {...a11yProps(2)} />
        </Tabs>
        <TabPanel value={current} index={0}>
          <ListSettingsContent data={data} setData={setData} />
        </TabPanel>
        <TabPanel value={current} index={1}>
        <SubscriptionSettingsContent data={data} setData={setData} />
        </TabPanel>
        <TabPanel value={current} index={2}>
        <CampaignDefaultsContent data={data} setData={setData} />
        </TabPanel>      
      </Box>

    </Grid>
  )
}

export default ListSettings

const SettingsHeader = (props) => {
  const { listData } = props;

  return (
    <Grid
      container
      sx={{
        justifyContent:'space-between',
        mb:2
      }}
    >
      <Typography
        variant="h2"
        sx={{
          // fontSize:'2rem'
        }}
      >
        {listData.name}
      </Typography>
    </Grid>

  )
}

const ListSettingsContent = (props) => {
  const { data, setData } = props;

  const saveData = async () => {
    // Run save settings data api here
  }

  return (
    <Grid
      container
    >
      <InputField
        label="Name"
        value={data.list_settings.name}
        onChange={(e) => setData(prev => ({ ...prev, list_settings: { ...prev.list_settings, name: e.target.value } }) )}
        fullWidth
        margin
      />
      <InputField
        label="User visible name"
        value={data.list_settings.user_visible_name}
        onChange={(e) => setData(prev => ({ ...prev, list_settings: { ...prev.list_settings, user_visible_name: e.target.value } }) )}
        fullWidth
        margin
      />
      <InputField
        label="Opt-in website url"
        value={data.list_settings.opt_in_url}
        onChange={(e) => setData(prev => ({ ...prev, list_settings: { ...prev.list_settings, opt_in_url: e.target.value } }) )}
        fullWidth
        margin
      />
      <InputField
        label="Contact email address"
        value={data.list_settings.contact_email}
        onChange={(e) => setData(prev => ({ ...prev, list_settings: { ...prev.list_settings, contact_email: e.target.value } }) )}
        fullWidth
        margin
      />
      <Grid
        container
      >
        <Button
          onClick={saveData}
          variant="contained"
        >
          Save changes
        </Button>
      </Grid>
    </Grid>
  )
}

const SubscriptionSettingsContent = (props) => {
  const { data, setData } = props;

  const saveData = async () => {
    // Run save settings data api here
  }

  return (
    <Grid
      container
    >
      <InputField
        label="List manager"
        value={data.subscription_settings.list_manager}
        onChange={(e) => setData(prev => ({ ...prev, subscription_settings: { ...prev.subscription_settings, list_manager: e.target.value } }) )}
        fullWidth
        margin
      />
      <Grid
        container
      >
        <Button
          onClick={saveData}
          variant="contained"
        >
          Save changes
        </Button>
      </Grid>
    </Grid>
  )
}

const CampaignDefaultsContent = (props) => {
  const { data, setData } = props;

  const saveData = async () => {
    // Run save settings data api here
  }

  return (
    <Grid
      container
    >
      <InputField
        label="Default from name"
        value={data.campaign_defaults.default_from_name}
        onChange={(e) => setData(prev => ({ ...prev, campaign_defaults: { ...prev.campaign_defaults, default_from_name: e.target.value } }) )}
        fullWidth
        margin
      />
      <InputField
        label="Default from email address"
        value={data.campaign_defaults.default_from_email}
        onChange={(e) => setData(prev => ({ ...prev, campaign_defaults: { ...prev.campaign_defaults, default_from_email: e.target.value } }) )}
        fullWidth
        margin
      />
      <InputField
        label="Default subject"
        value={data.campaign_defaults.default_subject}
        onChange={(e) => setData(prev => ({ ...prev, campaign_defaults: { ...prev.campaign_defaults, default_subject: e.target.value } }) )}
        fullWidth
        margin
      />
      <Grid
        container
      >
        <Button
          onClick={saveData}
          variant="contained"
        >
          Save changes
        </Button>
      </Grid>
    </Grid>
  )
}