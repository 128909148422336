import React, { useState, useEffect } from 'react';
import { getGiphyStickerSearch } from '../../hooks/mutations';
import DialogAction from '../ui/DialogAction';
import { GiphyIconLogo, GiphyLogo } from '../../assets/logos';
import { Grid } from '@mui/material';
import { useTheme } from '@emotion/react';

// Define the initial text styles
const TEXT_STYLES = ['normal', 'italic', 'oblique'];
const TEXT_ALIGN = ['left', 'center', 'right', 'justify'];
const TEXT_DECORATION = ['none', 'underline', 'line-through', 'overline'];
const FONT_WEIGHTS = ['normal', 'bold', 'bolder', 'lighter'];

const ElementEditorSticker = ({ selectedElement, onTextStyleChange, onWrapStyleChange, onTextContentChange, onTextUrlChange }) => {
  const [searchTerms, setSearchTerms] = useState(null);
  const [gifs, setGifs] = useState(null);
  const [hover, setHover] = useState(null);
  const [url, setUrl] = useState(selectedElement.url || null);
  const theme = useTheme();

  const [width, setWidth] = useState(selectedElement?.style.width?.replace(/[^0-9]/g, '') || 100);
  const [widthFormat, setWidthFormat] = useState('%');
  const [height, setHeight] = useState(selectedElement?.style.height?.replace(/[^0-9]/g, '') || 'auto');
  const [heightFormat, setHeightFormat] = useState('px');

  // Whenever the selectedElement changes, update the local state
  useEffect(() => {
    if (selectedElement) {
      if (selectedElement.style) {
        setWidthFormat(selectedElement.style?.width?.replace(/[0-9]/g, ''));
        setWidth(selectedElement.style?.width?.replace(/[^0-9]/g, ''));
        setHeightFormat(selectedElement.style?.height?.replace(/[0-9]/g, ''));
        setHeight(selectedElement.style?.height?.replace(/[^0-9]/g, ''));
      };
    }
  }, [selectedElement]);

  // Update width when value changes
  useEffect(() => {
    if (!width) {
      setWidth('auto');
      const reducedStyle = JSON.parse(JSON.stringify(selectedElement));
      onTextStyleChange({...reducedStyle.style, width: `auto` });
      return;
    }
    if (width && width !== 'auto' && widthFormat) {
      if (onTextStyleChange) {
        const reducedStyle = JSON.parse(JSON.stringify(selectedElement));
        onTextStyleChange({...reducedStyle.style, width: `${width}${widthFormat}` });
      }
    }
  }, [width, widthFormat])

  // Update height when value changes
  useEffect(() => {
    if (!height) {
      setHeight('auto');
      const reducedStyle = JSON.parse(JSON.stringify(selectedElement));
      onTextStyleChange({...reducedStyle.style, height: `auto` });
      return;
    }
    if (height && height !== 'auto' && heightFormat) {
      if (onTextStyleChange) {
        const reducedStyle = JSON.parse(JSON.stringify(selectedElement));
        onTextStyleChange({...reducedStyle.style, height: `${height}${heightFormat}` });
      }
    }
  }, [height, heightFormat])


  const handleClick = async () => {
    const incomingGifs = await getGiphyStickerSearch(searchTerms);
    setGifs(incomingGifs);
  }

  const handleClose = () => {
    setUrl(null);
  }

  // Handle change for the wrapStyle fields
  const handleWrapStyleChange = (field, value) => {
    // Call the onWrapStyleChange function to update the element's wrapper style
    if (onWrapStyleChange) {
      const reducedWrapStyle = JSON.parse(JSON.stringify(selectedElement?.wrapStyle));
      onWrapStyleChange({...reducedWrapStyle, [field]: value });
    }
  };

  // Handle change for the style fields
  const handleStyleChange = (field, value) => {
    // Call the onTextStyleChange function to update the element's wrapper style
    if (onTextStyleChange) {
      const reducedStyle = JSON.parse(JSON.stringify(selectedElement?.style));
      onTextStyleChange({...reducedStyle, [field]: value });
    }
  };

  // Update url when value changes
  useEffect(() => {
    if (url) {
      if (onTextUrlChange) {
        onTextUrlChange(url);
      }
    }
  }, [url])

  return (
    <div className="EmailTemplate-text-editor">
      <h4>Edit Text</h4>
      <div className="EmailTemplate-columns EmailTemplate-editor-field">
        <label>Search Term</label>
        <input
          className="EmailTemplate-editor-field-input EmailTemplate-input-no-padding-block" 
          type="string"
          value={searchTerms}
          onChange={(e) => setSearchTerms(e.target.value)}
        />
      </div>

      <div className="EmailTemplate-editor-field">
        <button
          className="EmailTemplate-editor-field-input" 
          type="string"
          onClick={handleClick}
        >
          Search Stickers
        </button>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Object Fit</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <select className="EmailTemplate-editor-field-input" value={selectedElement.style.objectFit} onChange={(e) => handleStyleChange('objectFit', e.target.value)}>
            <option value="cover">Cover</option>
            <option value="contain">Contain</option>
            <option value="fill">Fill</option>
            <option value="scale-down">Scale-down</option>
          </select>
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Width</label>
        </div>
        <div className="EmailTemplate-editor-field-value-col-2">
          <input
            className="EmailTemplate-editor-field-input EmailTemplate-input-no-padding-block"
            type="number"
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            min="20"
            max="1000"
          />
        </div>
        <div className="EmailTemplate-editor-field-value-col-3">
          <div
            className={`EmailTemplate-editor-field-value-col-3 EmailTemplate-item ${widthFormat === "px" ? 'EmailTemplate-selected' : ''}`}
            onClick={() => setWidthFormat('px')}
          >
            px
          </div>
          <div
            className={`EmailTemplate-editor-field-value-col-3 EmailTemplate-item ${widthFormat === "%" ? 'EmailTemplate-selected' : ''}`}
            onClick={() => setWidthFormat('%')}
          >
            %
          </div>
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Height</label>
        </div>
        <div className="EmailTemplate-editor-field-value-col-2">
          <input
            className="EmailTemplate-editor-field-input EmailTemplate-input-no-padding-block"
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            min="20"
            max="1000"
          />
        </div>
        <div className="EmailTemplate-editor-field-value-col-3">
          <div
            className={`EmailTemplate-editor-field-value-col-3 EmailTemplate-item ${heightFormat === "px" ? 'EmailTemplate-selected' : ''}`}
            onClick={() => setHeightFormat('px')}
          >
            px
          </div>
          <div
            className={`EmailTemplate-editor-field-value-col-3 EmailTemplate-item ${heightFormat === "%" ? 'EmailTemplate-selected' : ''}`}
            onClick={() => setHeightFormat('%')}
          >
            %
          </div>
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Alignment</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <select className="EmailTemplate-editor-field-input" value={selectedElement.wrapStyle.textAlign || 'left'} onChange={(e) => handleWrapStyleChange('textAlign', e.target.value)}>
            <option key="left" value="left">Left</option>
            <option key="center" value="center">Center</option>
            <option key="right" value="right">Right</option>
          </select>
        </div>
      </div>



      <DialogAction
        open={gifs}
        onClose={() => setGifs(null)}
        header="SELECT STICKER"
        // title={<img src={process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? `/static/img/Poweredby_640px-White_HorizText.png` : `https://static.fillmarketing.com/static/static/img/Poweredby_640px-White_HorizText.png`} style={{width:200}} />}
        icon={<GiphyIconLogo width={16} color={theme.palette.primary.main} />}
        hideButtons
        messages={{
        }}
        footer={
          <Grid
            container
            sx={{
              background:'#000',
              borderRadius:'0 0 16px 16px',
              height:50,
              justifyContent:'center',
              alignItems:'center'
            }}
          >
            <img src={process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? `/static/img/PoweredBy_200_Horizontal_Light-Backgrounds_With_Logo.gif` : `https://static.fillmarketing.com/static/static/img/PoweredBy_200_Horizontal_Light-Backgrounds_With_Logo.gif`} style={{width:200, height:'auto'}} />
          </Grid>
          }  
      >
        <Grid
          container
          sx={{
            // minHeight:300
          }}
        >
          {gifs &&
            gifs.map((gif, index) => (
              <Grid item sx={{p:1}} xs={4}>
                <img
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(null)}
                  onClick={() => onTextUrlChange(gif.images.original.url)}
                  src={gif.images.fixed_width.url}
                  style={{
                    width:'100%',
                    aspectRatio:1,
                    objectFit:'cover',
                    borderRadius:16,
                    outline: hover === index ? `6px solid ${theme.palette.primary.main}` : 'none',
                    cursor:'pointer'
                  }}
                />
              </Grid>
            ))
          }
        </Grid>
      </DialogAction>

    </div>
  );
};

export default ElementEditorSticker;
