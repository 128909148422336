import React, { useState, useEffect } from 'react';

// Define the initial text styles
const TEXT_STYLES = ['normal', 'italic', 'oblique'];
const TEXT_ALIGN = ['left', 'center', 'right', 'justify'];
const TEXT_DECORATION = ['none', 'underline', 'line-through', 'overline'];
const FONT_WEIGHTS = ['normal', 'bold', 'bolder', 'lighter'];

const ElementEditorButton = ({ selectedElement, onTextStyleChange, onWrapStyleChange, onTextContentChange, onTextUrlChange }) => {
  // Combine all text-related styles into a single `style` object
  const [style, setStyle] = useState({
    fontFamily: selectedElement.style?.fontFamily || 'Arial',
    fontSize: selectedElement.style?.fontSize || '16px',
    fontWeight: selectedElement.style?.fontWeight || 'normal',
    color: selectedElement.style?.color || '#000000',
    backgroundColor: selectedElement.style?.backgroundColor || '#FFFFFF',
    textAlign: selectedElement.style?.textAlign || 'left',
    textDecoration: selectedElement.style?.textDecoration || 'none',
    fontStyle: selectedElement.style?.fontStyle || 'normal',
    width: selectedElement?.style.width,
    height: selectedElement?.style.height,
  });

  const [wrapStyle, setWrapStyle] = useState({
    textAlign: selectedElement.wrapStyle?.textAlign || 'left',
  })

  const [content, setContent] = useState(selectedElement.content || 'Click Me');
  const [url, setUrl] = useState(selectedElement.url || '');
  const [sizeFormat, setSizeFormat] = useState('px');
  const [fontSize, setFontSize] = useState(selectedElement.style.fontSize?.replace(/[^0-9]/g, '') || 16);
  const [width, setWidth] = useState(selectedElement?.style?.width?.replace(/[^0-9]/g, '') || 'auto');
  const [widthFormat, setWidthFormat] = useState('px');
  const [height, setHeight] = useState(selectedElement?.style?.height?.replace(/[^0-9]/g, '') || 'auto');
  const [heightFormat, setHeightFormat] = useState('px');

  // Whenever the selectedElement.style changes, update the local state
  useEffect(() => {
    if (selectedElement.style) {
      if (selectedElement.style.fontSize) {
        setSizeFormat(selectedElement.style.fontSize.replace(/[0-9]/g, ''));
        setFontSize(selectedElement.style.fontSize.replace(/[^0-9]/g, ''));
        setWidthFormat(selectedElement.style?.width?.replace(/[0-9]/g, ''));
        setWidth(selectedElement.style?.width?.replace(/[^0-9]/g, ''));
        setHeightFormat(selectedElement.style?.height?.replace(/[0-9]/g, ''));
        setHeight(selectedElement.style?.height?.replace(/[^0-9]/g, ''));
      };
    }
  }, [selectedElement]);

  // Update width when value changes
  useEffect(() => {
    if (!width) {
      setWidth('auto');
      const reducedStyle = JSON.parse(JSON.stringify(selectedElement.style));
      onTextStyleChange({...reducedStyle, width: `auto` });
      return;
    }
    if (width && width !== 'auto' && widthFormat) {
      setStyle(prev => ({ ...prev, width: `${width}${widthFormat}` }))
      if (onTextStyleChange) {
        const reducedStyle = JSON.parse(JSON.stringify(selectedElement.style));
        onTextStyleChange({...reducedStyle, width: `${width}${widthFormat}` });
      }
    }
  }, [width, widthFormat])

  // Update height when value changes
  useEffect(() => {
    if (!height) {
      setHeight('auto');
      const reducedStyle = JSON.parse(JSON.stringify(selectedElement));
      onTextStyleChange({...reducedStyle.style, height: `auto` });
      return;
    }
    if (height && height !== 'auto' && heightFormat) {
      setStyle(prev => ({ ...prev, height: `${height}${heightFormat}` }))
      if (onTextStyleChange) {
        const reducedStyle = JSON.parse(JSON.stringify(selectedElement.style));
        onTextStyleChange({...reducedStyle, height: `${height}${heightFormat}` });
      }
    }
  }, [height, heightFormat])


  // Update fontSize when value changes
  useEffect(() => {
    if (fontSize) {
      setStyle(prev => ({ ...prev, fontSize: `${fontSize}${sizeFormat}` }))
      if (onTextStyleChange) {
        const reducedStyle = JSON.parse(JSON.stringify(selectedElement.style));
        onTextStyleChange({...reducedStyle, fontSize: `${fontSize}${sizeFormat}` });
      }
    }
  }, [fontSize])

  // Update content when value changes
  useEffect(() => {
    if (content) {
      if (onTextContentChange) {
        onTextContentChange(content);
      }
    }
  }, [content])

  // Update url when value changes
  useEffect(() => {
    if (url) {
      if (onTextUrlChange) {
        let safeUrl = url;
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
          // If not, assume it's missing and add 'http://'
          safeUrl = 'http://' + url;
        }     
        onTextUrlChange(safeUrl);
      }
    }
  }, [url])


  // Handle change for the style fields
  const handleWrapStyleChange = (field, value) => {
    // const updatedStyle = { ...wrapStyle, [field]: value };
    // setWrapStyle(updatedStyle);

    // Call the onWrapStyleChange function to update the element's wrapper style
    if (onWrapStyleChange) {
      const reducedWrapStyle = JSON.parse(JSON.stringify(selectedElement?.wrapStyle));
      console.log('reducedstyle', reducedWrapStyle)
      onWrapStyleChange({...reducedWrapStyle, [field]: value });
    }
  };

  // Handle change for the style fields
  const handleChange = (field, value) => {
    const updatedStyle = { ...style, [field]: value };
    setStyle(updatedStyle);

    // Call the onTextStyleChange function to update the element's style
    if (onTextStyleChange) {
      const reducedStyle = JSON.parse(JSON.stringify(selectedElement.style));
      console.log('reducedstyle', reducedStyle)
      onTextStyleChange({...reducedStyle, [field]: value });
    }
  };


  return (
    <div className="EmailTemplate-text-editor">
      <h4>Edit Text</h4>
      <div className="EmailTemplate-columns EmailTemplate-editor-field">
        <label>Button Text</label>
        <input
          className="EmailTemplate-editor-field-input EmailTemplate-input-no-padding-block" 
          type="string"
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </div>

      <label>Link Url</label>
      <input
        className="EmailTemplate-editor-field-input EmailTemplate-input-no-padding-block" 
        type="string"
        value={url} 
        onChange={(e) => setUrl(e.target.value)}
      />
      <h4>Edit Style</h4>



      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Font Family</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <select className="EmailTemplate-editor-field-input" value={style.fontFamily} onChange={(e) => handleChange('fontFamily', e.target.value)}>
            <option value="Arial">Arial</option>
            <option value="Verdana">Verdana</option>
            <option value="Georgia">Georgia</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Courier New">Courier New</option>
          </select>
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Font Size</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <input
            className="EmailTemplate-editor-field-input EmailTemplate-input-no-padding-block"
            type="number"
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
            min="10"
            max="100"
          />
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Width</label>
        </div>
        <div className="EmailTemplate-editor-field-value-col-2">
          <input
            className="EmailTemplate-editor-field-input EmailTemplate-input-no-padding-block"
            type="number"
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            min="20"
            max="1000"
          />
        </div>
        <div className="EmailTemplate-editor-field-value-col-3">
          <div
            className={`editor-field-value-col-3 item ${widthFormat === "px" ? 'selected' : ''}`}
            onClick={() => setWidthFormat('px')}
          >
            px
          </div>
          <div
            className={`editor-field-value-col-3 item ${widthFormat === "%" ? 'selected' : ''}`}
            onClick={() => setWidthFormat('%')}
          >
            %
          </div>
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Height</label>
        </div>
        <div className="EmailTemplate-editor-field-value-col-2">
          <input
            className="EmailTemplate-editor-field-input EmailTemplate-input-no-padding-block"
            type="number"
            value={height}
            onChange={(e) => setHeight(e.target.value)}
            min="20"
            max="1000"
          />
        </div>
        <div className="EmailTemplate-editor-field-value-col-3">
          <div
            className={`EmailTemplate-editor-field-value-col-3 EmailTemplate-item ${heightFormat === "px" ? 'EmailTemplate-selected' : ''}`}
            onClick={() => setHeightFormat('px')}
          >
            px
          </div>
          <div
            className={`EmailTemplate-editor-field-value-col-3 EmailTemplate-item ${heightFormat === "%" ? 'EmailTemplate-selected' : ''}`}
            onClick={() => setHeightFormat('%')}
          >
            %
          </div>
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Font Weight</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <select className="EmailTemplate-editor-field-input" value={style.fontWeight} onChange={(e) => handleChange('fontWeight', e.target.value)}>
            {FONT_WEIGHTS.map((weight) => (
              <option key={weight} value={weight}>{weight}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Text Color</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <input
            className="EmailTemplate-editor-field-input"
            type="color"
            value={style.color}
            onChange={(e) => handleChange('color', e.target.value)}
          />
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Button Color</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <input
            className="EmailTemplate-editor-field-input"
            type="color"
            value={style.backgroundColor}
            onChange={(e) => handleChange('backgroundColor', e.target.value)}
            />
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Text Align</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <select className="EmailTemplate-editor-field-input" value={style.textAlign} onChange={(e) => handleChange('textAlign', e.target.value)}>
            {TEXT_ALIGN.map((align) => (
              <option key={align} value={align}>{align}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Text Decoration</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <select
            className="EmailTemplate-editor-field-input"
            value={style.textDecoration}
            onChange={(e) => handleChange('textDecoration', e.target.value)}
          >
            {TEXT_DECORATION.map((decoration) => (
              <option key={decoration} value={decoration}>{decoration}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Text Style</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <select className="EmailTemplate-editor-field-input" value={style.fontStyle} onChange={(e) => handleChange('fontStyle', e.target.value)}>
            {TEXT_STYLES.map((style) => (
              <option key={style} value={style}>{style}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Alignment</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <select className="EmailTemplate-editor-field-input" value={selectedElement.wrapStyle.textAlign || 'left'} onChange={(e) => handleWrapStyleChange('textAlign', e.target.value)}>
            <option key="left" value="left">Left</option>
            <option key="center" value="center">Center</option>
            <option key="right" value="right">Right</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default ElementEditorButton;
