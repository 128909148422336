import React, { useEffect, useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import Lists from '../components/lists/Lists'

const mockLists = [
  {"id": "3", "name": "Penguins", "subscribers_count": "2", "open_rate": "20.0", "click_rate": "20.0"},
  {"id": "2", "name": "All Contacts", "subscribers_count": "3", "open_rate": "0.0", "click_rate": "90.0"},
  {"id": "1", "name": "Newsletter", "subscribers_count": "2", "open_rate": "0.0", "click_rate": "90.0"}
]

const ListsPage = () => {
  const isLive = process.env.REACT_APP_CURRENT_ENV !== 'STAGING';
  const [lists, setLists] = useState(isLive ? null : mockLists);

  // Get the lists
  const scriptElements = document.querySelectorAll('.django-list-mailing-list');
  // console.log('scriptElements', scriptElements);

  useEffect(() => {
    if (scriptElements && !lists) {
      const scriptElementsArray = Array.from(scriptElements).map(x => JSON.parse(x.innerHTML));
      // console.log('scriptElementsArray', scriptElementsArray);

      setLists(scriptElementsArray)
    }
    
  }, [scriptElements])

  return (

    // <MainPageTemplate title="Welcome">
      lists &&
      <Lists incomingLists={lists} />
    // </MainPageTemplate>

    
  )
}

export default ListsPage