import React, { useEffect, useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import ListSettings from '../components/lists/ListSettings'

const mockList = { "id": "kae9aeDE", "name": "Penguins", "subscribed_count": "2", "unsubscribed_count|intcomma": "0", "open_rate": "20.0", "last_campaign_open_rate": "0.0", "click_rate": "20.0", "last_campaign_click_rate": "0.0" }


const ListSettingsPage = () => {
  const isLive = process.env.REACT_APP_CURRENT_ENV !== 'STAGING';
  const [listData, setList] = useState(isLive ? null : mockList);
  
  // Get the list data script element
  const listElement = document.getElementById('django-list-settings');

  useEffect(() => {
    if (listElement && !listData) {
      // Access the content of the script element
      const djangoListString = listElement?.textContent;
      // console.log('djangoListString', djangoListString);

      // Parse the JSON data
      const djangoList = JSON.parse(djangoListString);
      // console.log('djangoList', djangoList);
      
      // Set the contact id state from the JSON data
      setList(djangoList)
    }
  }, [listElement])

  return (

    // <MainPageTemplate title="Welcome">
    listData &&
      <ListSettings listData={listData} />
    // </MainPageTemplate>    
  )
}

export default ListSettingsPage