import React, { useEffect, useState } from 'react'
import { DjangoComponents } from '../../templates/DjangoStyles';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Box, Button, CircularProgress, Grid, IconButton, LinearProgress, Tab, Tabs, Typography } from '@mui/material';
import { TagButton } from '../ui/Tags';
import { useGetFilteredCampaigns } from '../../hooks/mutations';
import { OpenInNewSVG } from '../../assets/icons';
import { subscriberStatusEnum } from '../../lib/Data';
import { InputField } from '../ui/Fields';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      // style={{ width: '100%' }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const ListCreate = () => {
  const [current, setCurrent] = React.useState(0);

  const handleChange = (event, newValue) => {
    setCurrent(newValue);
  };

  return (
    <Grid container p={2}>
      <NewListHeader />
      <NewListFields />
    </Grid>
  )
}

export default ListCreate

const NewListHeader = () => {

  return (
    <Grid
      container
      sx={{
        justifyContent:'space-between',
        mb:2
      }}
    >
      <Typography
        variant="h2"
        sx={{
          // fontSize:'2rem'
        }}
      >
        Create a new list
      </Typography>
    </Grid>

  )
}

const NewListFields = () => {
  const [data, setData] = useState({
    name: '',
    user_visible_name: '',
    default_from_name: '',
    default_from_email: '',
    opt_in_url: ''
  });

  console.log('data', data);

  const saveData = async () => {
    // Run save settings data api here
  }

  return (
    <Grid
      container
    >
      <InputField
        label="Name"
        value={data.name}
        onChange={(e) => setData(prev => ({ ...prev, name: e.target.value }) )}
        fullWidth
        margin
      />
      <InputField
        label="User Visible Name"
        value={data.user_visible_name}
        onChange={(e) => setData(prev => ({ ...prev, user_visible_name: e.target.value }) )}
        fullWidth
        margin
      />
      <InputField
        label="Default from name"
        value={data.default_from_name}
        onChange={(e) => setData(prev => ({ ...prev, default_from_name: e.target.value }) )}
        fullWidth
        margin
      />
      <InputField
        label="Default from email address"
        value={data.default_from_email}
        onChange={(e) => setData(prev => ({ ...prev, default_from_email: e.target.value }) )}
        fullWidth
        margin
      />
      <InputField
        label="Opt-in website url"
        value={data.opt_in_url}
        onChange={(e) => setData(prev => ({ ...prev, opt_in_url: e.target.value }) )}
        fullWidth
        margin
      />
      <Grid
        container
      >
        <Button
          onClick={saveData}
          variant="contained"
        >
          Create List
        </Button>
      </Grid>
    </Grid>
  )
}