import React, { useEffect, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { CloseFilledSVG } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { createContact, useGetAllCustomFields, useGetAllLists } from '../../hooks/mutations';
import { InputField, SelectField } from '../ui/Fields';
import SnackbarWrapper  from '../ui/SnackbarWrapper';
import { contactStatusEnum } from '../../lib/Data';
import Infotip from '../ui/Infotip';
import { useApi } from '../../context/apiContext';
import { useTemp } from '../../context/tempContext';
import CustomFieldEditor from './CustomFieldEditor';
import SectionHeader from '../ui/SectionHeader';

const CreateContact = () => {
  const theme = useTheme();
  // const incomingCustomFields = useGetCustomFields();
  // const incomingContact = useGetContacts();
  const [reloadCustomFields, setReloadCustomFields] = useState();
  const customFields = useGetAllCustomFields(reloadCustomFields);
  const lists = useGetAllLists();
  const [newContact, setNewContact] = useState({
    name: '',
    primary_email: '',
    company: '',
    title: '',
    notes: '',
    tags: [],
    description: '',
    custom_fields: {},
    mailing_lists: [],
    status: 1
  });
  // const [isAddingField, setIsAddingField] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);
  const [snackMessage, setSnackMessage] = useState(null);
  const [expandedLists, setExpandedLists] = useState(true);
  const [expandedStandard, setExpandedStandard] = useState(true);
  // const [expandedFields, setExpandedFields] = useState(true);
  // const tempContext = useTemp();
  // const apiContext = useApi();
  // const contactProfiles = apiContext?.contextUserDataBag?.data?.contactProfiles;

  // const screenSize = useScreenSize();

  // console.log('tempContext', tempContext);
  // console.log('newContact', newContact);

  // Set custom field state to incoming data on load
  useEffect(() => {
    if (resetSearch) {
      setResetSearch(false);
    }
  }, [resetSearch]);

  // Reset reloadCustomFields if triggered
  useEffect(() => {
    if (reloadCustomFields) {
      setReloadCustomFields(false);
    }
  }, [reloadCustomFields]);

  const handleTextStandard = (value, name) => {
    // const copiedContact = { ...newContact };
    // const customFields = { ...copiedContact.custom_fields };
    // const updatedCustomFields = { ...customFields, [name]: value }
    setNewContact({
      ...newContact,
      [name]: value
    })
  }

  const handleSelect = (e) => {
    setNewContact({
      ...newContact,
      status: e.target.value
    })
  }

  const handleTextCustomField = (value, id) => {
    const copiedContact = { ...newContact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [id]: value }
    setNewContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
  }

  const handleImportProfileFields = (customFields) => {
    // const copiedContact = { ...newContact };
    // const existingFields = { ...copiedContact.custom_fields };
    let newFields = {};
    
    customFields.forEach(field => {
      newFields = { ...newFields, [field.id]: '' }
    });

    setNewContact(prev => ({
      ...prev,
      custom_fields: newFields
    }))
  }

  const handleImportAllFields = (customFields) => {
    const copiedContact = { ...newContact };
    const existingFields = { ...copiedContact.custom_fields };
    let newFields = { ...existingFields };
    
    customFields.forEach(field => {
      if (Object.keys(existingFields).includes(field.id)) {
        console.log('THAT FIELD ALREADY EXISTS');
      } else {
        newFields = { ...newFields, [field.id]: '' }
      }
    });

    setNewContact(prev => ({
      ...prev,
      custom_fields: newFields
    }))
  }

  const handleFieldImport = (field) => {
    const newItem = {[field.id]: ''}
    const copiedContact = { ...newContact };
    const customFields = { ...copiedContact.custom_fields };

    if (Object.keys(customFields).includes(field.id)) {
      console.log('THAT FIELD ALREADY EXISTS');
    } else {
      const updatedCustomFields = { ...customFields, ...newItem }
      setNewContact(prev => ({
        ...prev,
        custom_fields: updatedCustomFields
      }))
    }
  }

  const handleFieldRemove = (field) => {  
    const copiedContact = { ...newContact };
    const customFields = { ...copiedContact.custom_fields };
  
    // Create a copy of customFields without the specified key
    delete customFields[field.key];
  
    setNewContact((prev) => ({
      ...prev,
      custom_fields: customFields,
    }));
  };

  const handleListSelection = (list) => {
    if (list.name === 'All Contacts') return;

    const copiedContact = JSON.parse(JSON.stringify(newContact));
    const existingLists = [ ...copiedContact.mailing_lists ];
    const existingListsIds = existingLists.map(x => x.id);
    console.log('existingListsIds', existingListsIds);
    let newLists = {}

    if (existingListsIds.includes(list.id)) {
      console.log('list exists')
      newLists = existingLists.filter(x => x.id !== list.id);
    } else {
      console.log('list needs to be added')
      newLists = [ ...existingLists, {id: list.id} ]
    }

    setNewContact((prev) => ({
      ...prev,
      mailing_lists: newLists,
    }));

  }
  
  const onSubmit = async () => {
    const copiedNewContact = { ...newContact };

    // Remove empty primary email field so it does not create error when creating contact
    if (copiedNewContact.primary_email.length < 1) {
      delete copiedNewContact.primary_email;
    }

    const contactObj = {
      payload: {
        ...copiedNewContact
      }
    }
    const submittedContact = await createContact(contactObj);
    console.log('submittedContact', submittedContact)
    if (submittedContact.status == "success") {
      setSnackMessage({open: true, message: 'New contact created', severity:'success'});
      setNewContact({
        name: '',
        primary_email: '',
        tags: [],
        description: '',
        custom_fields: {},
        status: 1 // 0, null, 1
      });
      console.log(newContact);
    } else {
      setSnackMessage({open: true, message: submittedContact.error, severity:'error'})
    }
  }



  return (
    <Grid
      container
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        // height: '100vh',
      }}
    >
      <Grid
        sx={{
          // background:'#fff',
          borderRadius: 1,
          // padding: screenSize.sm ? '36px 24px' : '36px 72px',
          // boxShadow:'0 2px 10px #00000020',
          width: '100%', //screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          transition:'.4s',
          maxWidth:1600,
          pb:10
        }}
      >
        <Grid
          container
          style={{
            justifyContent:'space-between',
            alignItems:'center',
            marginBottom:16
          }}
        >
          <Typography
            sx={{
              fontSize:'1.8rem',
              fontWeight:600,
              color:'#ccc',
            }}
          >
            Add Contact
          </Typography>
          <Grid
            style={{
              display:'flex',
              gap:16
            }}
          >
            <Button
              // disabled={!newContact.name || !newContact.primary_email}
              variant="contained"
              onClick={onSubmit}
              >
              Create Contact
            </Button>
          </Grid>
        </Grid>

        <FieldsTableWrapper>
          {
            newContact
              ?
                <Grid
                  container
                  sx={{
                    px:2
                  }}
                >
                  <SectionHeader
                    title="STANDARD FIELDS"
                    id="section-header-standard-fields"
                    expanded={expandedStandard}
                    setExpanded={setExpandedStandard}
                    infotip={
                      <Infotip
                        question
                        title="Standard Fields"
                        tip="Get information about standard fields"
                        messages={{
                          one:`Standard fields exist on every contact. They cannont be removed, but they can be hidden so you don't see them.`
                        }}
                      />
                    }
                    // button={}
                    // subSummary={}
                    // actions={}
                  >
                        <FieldsTableRow
                          customField={{key: 'name', value: newContact.name}}
                          handleTextCustomField={handleTextStandard}
                        />
                        <FieldsTableRow
                          customField={{key: 'primary_email', value: newContact.primary_email}}
                          handleTextCustomField={handleTextStandard}
                        />
                        <FieldsTableRow
                          customField={{key: 'company', value: newContact.company}}
                          handleTextCustomField={handleTextStandard}
                        />
                        <FieldsTableRow
                          customField={{key: 'title', value: newContact.title}}
                          handleTextCustomField={handleTextStandard}
                        />
                        <FieldsTableRow
                          customField={{key: 'notes', value: newContact.notes}}
                          handleTextCustomField={handleTextStandard}
                        />
                            {/* may need to be moved */}
                        <SelectField
                          label="Status"
                          onChange={handleSelect}
                          value={newContact.status}
                          options={contactStatusEnum.map(status => ({ key: status.label, value: status.status }) )}
                          width="calc(100% - 50px)"
                          infotip={
                            <Infotip
                              info
                              title="Status"
                              tip="Status defaults to pending"
                              messages={{
                                one:`Status defaults to pending unless otherwise selected`,
                                two:
                                  <>
                                    <Table>
                                      <TableHead>
                                        <TableCell>
                                          Status
                                        </TableCell>
                                        <TableCell>
                                          Description
                                        </TableCell>
                                      </TableHead>
                                      <TableBody>
                                        {
                                          contactStatusEnum.map(status => (
                                            <TableRow key={status.id}>
                                              <TableCell sx={{display:'flex', alignItems:'center', gap:1}}>
                                                <div
                                                  style={{
                                                    width:14,
                                                    height:14,
                                                    borderRadius:14,
                                                    background:status.color
                                                  }}
                                                />
                                                {status.label}
                                              </TableCell>
                                              <TableCell>
                                                description will go here
                                              </TableCell>
                                            </TableRow>
                                          ))
                                        }
                                      </TableBody>
                                    </Table>
                                  </>
                              }}
                            />
                          }
                          margin
                        />

                  </SectionHeader>
                  
                  <SectionHeader
                    title="LISTS"
                    id="section-header-lists"
                    expanded={expandedLists}
                    setExpanded={setExpandedLists}
                    infotip={
                      <Infotip
                        question
                        title="Lists"
                        tip="Get information about lists"
                        messages={{
                          one:<>Every contact is added to the <span style={{fontWeight:600}}>All Contacts</span> list and cannot be removed. Contacts may be added and removed from all other lists when necessary.</>,
                          two:'You may select lists to add the contact to in the lists section below.'
                        }}
                      />
                    }
                    // button={}
                    // subSummary={}
                    // actions={}
                  >
                        <Grid
                          container
                          sx={{
                            justifyContent:'center'
                          }}
                        >
                          <FormControlLabel
                            label="All Contacts"
                            control={
                              <Checkbox
                                checked={true}
                                // onChange={() => handleListSelection(list)}
                              />
                            }
                          />
                          {
                            lists?.filter(x => x.name !== 'All Contacts')?.map(list => (
                              <FormControlLabel
                                label={list.name}
                                control={
                                  <Checkbox
                                    // checked={}
                                    onChange={() => handleListSelection(list)}
                                  />
                                }
                              />
                            ))
                          }
                        </Grid>

                  </SectionHeader>

                  <CustomFieldEditor
                    isNewContact
                    setSelectedContact={setNewContact}
                    customFields={customFields}
                    setReloadCustomFields={setReloadCustomFields}
                    incomingContact={newContact}
                    setSnackMessage={setSnackMessage}
                  />
                </Grid>
              :
                null
          }
        </FieldsTableWrapper>

        <Grid
          container
          variant="center"
        >
          <Button
            // disabled={!newContact.name || !newContact.primary_email}
            variant="contained"
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Grid>

      </Grid>
      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />

    </Grid>
  );
};

export default CreateContact;

const FieldsTableWrapper = (props) => {
  const { children } = props;
  return (
    <Grid
      container
      style={{
        overflowY:'auto',
        msOverflowY:'auto',
        // maxHeight:600,
        padding:'16px 0',
        paddingLeft:10
      }}
    >
      {children}
    </Grid>
  )
}

const FieldsTableRow = (props) => {
  const { customField, name, index, handleTextCustomField, handleFieldRemove, showDelete } = props;
  const theme = useTheme();

  return (
    <Grid
      id={`r-${customField.key}`}
      container
      style={{
      }}
    >
      <InputField
        id={customField.key}
        label={name ? name : customField.key.replace('_', ' ')}
        value={customField.value}
        onChange={(e) => handleTextCustomField(e.target.value, customField.key)}
        width="calc(100% - 50px)"
        // noAutoFocus
        margin
      />
      <Grid
        style={{
          width:50,
          margin:'auto',
          textAlign:'center'
        }}
      >
        {
          showDelete &&
            <IconButton
              onClick={() => handleFieldRemove(customField)}
              style={{
                width:35,
                height:35,
                margin:'0 0 24px 0'
              }}
            >
              <CloseFilledSVG width={25} color1="#ddd" />
            </IconButton>
        }
      </Grid>
    </Grid>
  )
}
