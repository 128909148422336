import React, { useEffect, useState, cloneElement } from 'react'
import SurveyBuilder1 from './form-1/SurveyBuilder1'
import SurveyDisplayStartCollect from './form-1/SurveyDisplayStartCollect';
import { Button, Dialog, Divider, Grid, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { deleteSurvey, putSurvey, useGetFilteredSurveys, useGetSurveys } from '../../hooks/mutations';
import { AddSVG, EditSVG, VisibleSVG, DeleteSVG, CheckSVG, RemoveFillSVG, SelectSVG, UsageSVG, ContactSVG } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { useTheme } from '@emotion/react';
import ReusableSearch from '../ui/ReusableSearch';
import { DataGrid } from '@mui/x-data-grid';
import { ButtonCustom } from '../ui/Buttons';
import { contactStatusEnum, surveyColumns } from '../../lib/Data';
import { Link } from 'react-router-dom';
import { mockData } from '../../lib/mockData';
import CustomTooltip from '../ui/CustomTooltip';
import DialogWarning from '../ui/DialogWarning';
import ReusableModal from '../ui/ReusableModal';
import Loading from '../ui/Loading';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { fill_mui_scrollbar } from '../../lib/Styles';
import { FillTable } from '../ui/FillTable';
import { TagButton } from '../ui/Tags';
import ReusableFilterSearch from '../ui/ReusableFilterSearch';
import ActionContent from '../ui/ActionContent';



const Surveys = () => {
  const [reloadSurveys, setReloadSurveys] = useState(false);
  const [filterBy, setFilterBy] = useState({
    page: 1,
    page_size: 10,
  });
  const [pages, setPages] = useState({page: 1, count:null, next:null, prev:null});
  const incomingSurveys = useGetFilteredSurveys({reload:reloadSurveys, filterBy:filterBy});
  const screenSize = useScreenSize();
  const [surveys, setSurveys] = useState(incomingSurveys);
  const [resetSearch, setResetSearch] = useState(true);
  const [selected, setSelected] = useState([])
  const [isSearching, setIsSearching] = useState(false);
  const theme = useTheme();
  const [snackMessage, setSnackMessage] = useState("");
  const displayColumns = [
    {
      field: 'name',
      label: 'Name',
      minWidth: 125,
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <a
          href={`/surveys/${params.row.id}/edit/`}
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          {params.row.name}
        </a>
      )
    },
    {
      field: 'status',
      label: 'Status',
      minWidth: 125,
      flex: 1,
      editable: false,
    },
    {
      field: 'update_date',
      label: 'Last Update',
      minWidth: 125,
      flex: 1,
      editable: false,
      renderCell: (params) => (new Date(params.row.update_date).toLocaleDateString())
    },
    {
      field: 'actions',
      label: '',
      width: 130,
      // flex:1,
      editable: false,
      renderCell: (params) => (<SurveyActions survey={params.row} setSnackMessage={setSnackMessage} setReloadSurveys={setReloadSurveys} />)
    }
  ];
  const dataColumns = [
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 125,
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <a
          href={`/surveys/${params.row.id}/edit/`}
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          {params.row.name}
        </a>
      )
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 125,
      flex: 1,
      editable: false,
    },
    {
      field: 'update_date',
      headerName: 'Last Update',
      minWidth: 125,
      flex: 1,
      editable: false,
      renderCell: (params) => (new Date(params.row.update_date).toLocaleDateString())
    },
    {
      field: 'actions',
      headerName: '',
      width: 130,
      // flex:1,
      editable: false,
      renderCell: (params) => (<SurveyActions survey={params.row} setSnackMessage={setSnackMessage} setReloadSurveys={setReloadSurveys} />)
    }
  ];

  // Reset 'reset' state to false after it is triggered
  useEffect(() => {
    if (reloadSurveys) {
      setReloadSurveys(false);
    }
  }, [reloadSurveys])

  // Set surveys state to incoming data on load
  useEffect(() => {
    if (incomingSurveys) {
      setSurveys(incomingSurveys.results);
      const next = incomingSurveys.next?.split('?page=')[1]?.split('&page')[0] || null;
      const prev = incomingSurveys.previous?.split('?page=')[1]?.split('&page')[0] || null;
      const id = next ? Number(next) - 1 : (prev ? Number(prev) + 1 : 1 );
      setPages({
        page: id,
        count: incomingSurveys.count,
        next: next,
        prev: prev
      })

      setResetSearch(false);
    }
  }, [incomingSurveys]);

  // Handle selecting multiple rows
  const handleRowSelection = (ids) => {
    const selectedRowsData = ids.map((id) => surveys.find((row) => row.id === id));
    setSelected(selectedRowsData);
  }


  return (
    <Grid
      container
      style={{
        // height: 'calc(100vh - 148px)', // Add or subtract 55px if the Footer Paginate comopnent is used
        justifyContent: 'center',
      }}
    >
      <Grid
        style={{
          // background:'#fff',
          borderRadius: 8,
          // padding: screenSize.sm ? '36px 24px' : '36px 72px',
          // boxShadow:'0 2px 10px #00000020',
          width: '100%', // screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          // transition:'.4s',
          // maxWidth:1600
        }}
      >

        {/* <HeaderRow />
        <TableWrapper>
          {
            surveys
              ?
                surveys.map((survey, index) => (
                  <TableRow
                    survey={survey}
                    index={index}
                    key={survey.id}
                    onDeleteClick={() => handleDeleteClick(survey.id)}
                  />
                ))
              :
                <Typography>No custom fields have been created yet.</Typography>
          }
        </TableWrapper> */}
        {
          !isSearching || surveys.length
            ?
            // <DataGrid
            //   getRowId={(row) => row.id}
            //   rows={surveys?.length ? surveys : []}
            //   columns={dataColumns}
            //   density="comfortable"
            //   disableVirtualization
            //   onRowSelectionModelChange={(ids) => {
            //     handleRowSelection(ids);
            //   }}
            
            //   initialState={{
            //     pagination: {
            //       paginationModel: {
            //         pageSize: 20,
            //       },
            //     },
            //   }}
            //   sx={{
            //     '&.MuiDataGrid-root': {
            //       borderBottom: 'none',
            //       '& .MuiDataGrid-cell:focus': {
            //         outline: 'none',
            //         borderBottom: `2px solid ${theme.palette.primary.main}`,
            //         background: `${theme.palette.primary.light}15`
            //       }
            //     },
            //     ...fill_mui_scrollbar
            //   }}
            //   // pageSizeOptions={[5]}
            //   // autoPageSize
            //   checkboxSelection
            //   disableRowSelectionOnClick
            // />
            <FillTable
              title="Survey List"
              rows={surveys?.length ? surveys : []}
              columns={displayColumns}
              // onRowClick={handleRowClick}
              setFilterBy={setFilterBy}
              pages={pages}
              selectedRows={selected}
              setSelectedRows={setSelected}
              handleRowSelection={handleRowSelection}
              actionCell={(row) => (
                <SurveyActions survey={row} setSnackMessage={setSnackMessage} setReloadSurveys={setReloadSurveys} />
              )}
              renderCell={(row, column) => (
                <Stack direction="row">
                  <RenderCell row={row} column={column} />
                </Stack>
              )}
              searchButton={
                <ReusableFilterSearch
                  useSearch
                  isOpen
                  placeholder="Search Surveys"
                  content={surveys} // incomingSurveys
                  setContent={setSurveys}
                  filterBy={filterBy}
                  setFilterBy={setFilterBy}
                  searchFields={['name']}
                />
              }
              toolbarButtons={[
                <CustomTooltip
                  title="Create Survey"
                >
                  <IconButton
                    href="/surveys/add"
                    text={screenSize.sm ? null : "New Survey"}
                  >
                    <AddSVG width={18} />
                  </IconButton>
                </CustomTooltip>
              ]}
              toolbarActions={
                <BulkActions
                  selected={selected}
                  setReloadSurveys={setReloadSurveys}
                />
              }
              sx={{
                ...fill_mui_scrollbar
              }}
            />
            :
            <Grid
              container
              style={{
                justifyContent: 'center',
              }}
            >
              <Typography>No Surveys Found.</Typography>
            </Grid>

        }

      </Grid>
      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open: false }))}
        notice={snackMessage}
      />
    </Grid>
  )
}

export default Surveys

const RenderCell = ({ row, column }) => {

  switch (column.field) {
    case 'name':
      return (
        <a
          href={`/surveys/${row.id}/edit/`}
          style={{
            textDecoration: 'none',
            color: 'inherit'
          }}
        >
          {row.name}
        </a>
      )
    case 'status':
      return (
        <div>

          <TagButton
            bgColor={contactStatusEnum.filter(x => x.status === row[column.field])[0].color}
            fontColor={contactStatusEnum.filter(x => x.status === row[column.field])[0].text}
            outlined
            outlinedColor={contactStatusEnum.filter(x => x.status === row[column.field])[0].color}
            label={contactStatusEnum.filter(x => x.status === row[column.field])[0].label}
            noPointer
          />
        </div>
      );
    case 'update_date':
      return (
        new Date(row.update_date).toLocaleDateString()
      )
    default:
      break;
  }
}

const SurveyActions = (props) => {
  const { survey, setSnackMessage, setReloadSurveys } = props;
  // const survey = params.row;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleted, setDeleted] = useState(false);
  

  const handleCloseConfirmDelete = () => {
    setOpenConfirmDelete(false);
  }

  const removeSurvey = async () => {
    setDeleting(true);
    const deletedSurvey = await deleteSurvey(survey.id)
    if (deletedSurvey.status === 'success') {
      setReloadSurveys(true); // <-- Reload the current surveys to show the deletion
      setOpenConfirmDelete(false);
      setDeleted(true);
      setDeleting(false);
      setSnackMessage({ open: true, message: 'Survey deleted', severity: 'success' })
    }
  }


  return (
    <Stack
      direction="row"
      spacing={.5}
    >
      <CustomTooltip
        title="Edit survey"
      >
        <IconButton
          href={`/surveys/${survey.id}/edit/`}
        >
          <EditSVG height={18} style={{ width: 20 }} />
        </IconButton>
      </CustomTooltip>

      <CustomTooltip
        title="Survey results"
      >
        <IconButton
          href={`/surveys/${survey.id}/detail/`} // This route and view doesn't exist yet
        >
          <UsageSVG height={18} style={{ width: 20 }} />
        </IconButton>
      </CustomTooltip>

      <CustomTooltip
        title="Delete survey"
      >
        <IconButton
          onClick={() => setOpenConfirmDelete(true)}
        >
          <DeleteSVG height={18} style={{ width: 20 }} />
        </IconButton>
      </CustomTooltip>

      <DialogWarning
        open={openConfirmDelete}
        onClose={handleCloseConfirmDelete}
        header="Delete Survey"
        title={`You are about to delete the ${survey.name} survey`}
        messages={{
          one: 'Are you sure you want to delete this survey? It will be permanently deleted.',
          confirm: 'Yes'
        }}
        deleting={deleting}
        cancel={handleCloseConfirmDelete}
        confirm={removeSurvey}
        icons
      />

    </Stack>

  )
}


const BulkActions = (props) => {
  const { selected, setReloadSurveys } = props;
  const [action, setAction] = useState(null);
  const theme = useTheme();
  const screenSize = useScreenSize();
 
  const handleCancel = () => {
    setAction(null);
  }

  return (
    <Grid
      container
      gap={1}
    >
      <ActionButton
        label="Update Status"
        icon={<SelectSVG height={40} />}
        color={theme.palette.primary.dark}
        setAction={() => setAction('status')}
      />
      <ActionButton
        label="Change Display"
        icon={<VisibleSVG height={40} color2="#FFF" />}
        color={theme.palette.primary.dark}
        setAction={() => setAction('display')}
      />
      <ActionButton
        label="Delete Surveys"
        icon={<DeleteSVG height={40} />}
        color={theme.palette.primary.dark}
        setAction={() => setAction('delete')}
      />

      <Dialog
        open={action}
        onClose={() => setAction(null)}
        scroll={screenSize.md ? 'body' : 'paper'}
      >
        {
          action === 'status' &&
          <BulkStatus
            selected={selected}
            setReloadSurveys={setReloadSurveys}
            handleCancel={handleCancel}
          />
        }
        {
          action === 'display' &&
          <BulkDisplay
            selected={selected}
            setReloadSurveys={setReloadSurveys}
            handleCancel={handleCancel}
          />
        }
        {
          action === 'delete' &&
          <BulkDelete
            selected={selected}
            setReloadSurveys={setReloadSurveys}
            handleCancel={handleCancel}
          />
        }
      </Dialog>

    </Grid>

  )
}

const BulkStatus = (props) => {
  const { selected, setReloadSurveys, handleCancel } = props;
  const [progress, setProgress] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const theme = useTheme();
 

  const updateSurveys = async () => {
    try {
      for (let index = 0; index < selected.length; index++) {
        const survey = selected[index];

        const loadingProgress = await new Promise((resolve, reject) => {
          const timeoutID = setTimeout(() => {
            const progressValue = (index + 1) / selected.length; // Progress indicator
            setProgress(progressValue * 100); // Update progress state
            addStatus(selectedStatus, survey)
            resolve(progressValue);
            clearTimeout(timeoutID);
          }, 750);
        });
      }
      if (setReloadSurveys) {
        setReloadSurveys(true);
      }

    }

    catch (error) {
      console.log()
    }
  };

  const addStatus = async (value, survey) => {

    const surveyObj = {
      surveyId: survey.id,
      payload: {
        ...survey,
        status: value
      }
    }

    // console.log('surveyObj', surveyObj);
    const updatedSurvey = await putSurvey(surveyObj);
    if (updatedSurvey.status === 'success') {
      console.log('successfully updated status of surveys', updatedSurvey.data)
    }

  }

  const handleStatusClick = (value) => {
    if (selectedStatus === value) {
      setSelectedStatus(null);
    } else {
      setSelectedStatus(value);
    }
  }


  return (
    <ActionContent
      title="Status"
      progress={progress}
      cancel={handleCancel}
      confirm={updateSurveys}
      confirmCTA="Update Status"
      completedMessage="Finished Updating Status"
    >
      <Grid
        container
        style={{
          justifyContent: 'center'
        }}
      >
        {
          [{ label: "Active", value: 1 }, { label: "Draft", value: 2 }, { label: "Paused", value: 3 }].map((status, index) => {
            const selectedValue = status.value === selectedStatus;
            return (
              <Grid
                item
                // onMouseEnter={() => setHover(`status-${status.id}`)}
                // onMouseLeave={() => setHover(null)}
                onClick={() => handleStatusClick(status.value)}
                style={{
                  background: selectedValue ? theme.palette.primary.main : `${theme.palette.primary.light}15`,
                  padding: '16px 8px',
                  margin: 4,
                  borderRadius: 8,
                  transition: 'border-radius .4s, padding .4s',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                xs
              >
                {
                  status.value
                    ?
                    <CheckSVG width={30} color1={selectedValue ? "#fff" : theme.palette.primary.light} />
                    :
                    <RemoveFillSVG width={30} color1={selectedValue ? "#fff" : theme.palette.primary.light} color2={selectedValue ? theme.palette.primary.main : "#fff"} />
                }
                <Typography
                  style={{
                    fontSize: '1.3rem',
                    fontWeight: 700,
                    color: selectedValue ? '#ffffff' : theme.palette.primary.light
                  }}
                >
                  {status.label}
                </Typography>
              </Grid>
            )
          })
        }
      </Grid>

    </ActionContent>
  )
}

const BulkDisplay = (props) => {
  const { selected, setReloadSurveys, handleCancel } = props;
  const [progress, setProgress] = useState(0);
  const [selectedDisplay, setSelectedDisplay] = useState(null);
  const theme = useTheme();
 

  const updateSurveys = async () => {
    try {
      for (let index = 0; index < selected.length; index++) {
        const survey = selected[index];

        const loadingProgress = await new Promise((resolve, reject) => {
          const timeoutID = setTimeout(() => {
            const progressValue = (index + 1) / selected.length; // Progress indicator
            setProgress(progressValue * 100); // Update progress state
            addDisplay(selectedDisplay, survey)
            resolve(progressValue);
            clearTimeout(timeoutID);
          }, 750);
        });
      }
      if (setReloadSurveys) {
        setReloadSurveys(true);
      }

    }

    catch (error) {
      console.log()
    }
  };

  const addDisplay = async (value, survey) => {

    const surveyObj = {
      surveyId: survey.id,
      payload: {
        ...survey,
        display_method: value,
      }
    }

    // console.log('surveyObj', surveyObj);
    const updatedSurvey = await putSurvey(surveyObj);
    if (updatedSurvey.status === 'success') {
      console.log('successfully updated display of surveys', updatedSurvey.data)
    }

  }

  const handleDisplayClick = (value) => {
    setSelectedDisplay(value);
  }

  return (
    <ActionContent
      title="Question Display Method"
      progress={progress}
      cancel={handleCancel}
      confirm={updateSurveys}
      confirmCTA="Update Display"
      completedMessage="Finished updating display method"
    >
      <Grid
        container
        style={{
          justifyContent: 'center'
        }}
      >
        {
          [{ label: "By Question", value: 1 }, { label: "By Category", value: 2 }, { label: "One Page", value: 3 }].map((display, index) => {
            const selectedValue = display.value === selectedDisplay;
            return (
              <Grid
                item
                // onMouseEnter={() => setHover(`display-${display.id}`)}
                // onMouseLeave={() => setHover(null)}
                onClick={() => handleDisplayClick(display.value)}
                style={{
                  background: selectedValue ? theme.palette.primary.main : `${theme.palette.primary.light}15`,
                  padding: '16px 8px',
                  margin: 4,
                  borderRadius: 8,
                  transition: 'border-radius .4s, padding .4s',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                xs
              >
                {
                  display.value
                    ?
                    <CheckSVG width={30} color1={selectedValue ? "#fff" : theme.palette.primary.light} />
                    :
                    <RemoveFillSVG width={30} color1={selectedValue ? "#fff" : theme.palette.primary.light} color2={selectedValue ? theme.palette.primary.main : "#fff"} />
                }
                <Typography
                  style={{
                    fontSize: '1.3rem',
                    fontWeight: 700,
                    color: selectedValue ? '#ffffff' : theme.palette.primary.light
                  }}
                >
                  {display.label}
                </Typography>
              </Grid>
            )
          })
        }
      </Grid>

    </ActionContent>
  )
}

const BulkDelete = (props) => {
  const { selected, setReloadSurveys, handleCancel } = props;
  const [progress, setProgress] = useState(0);
  const theme = useTheme();
 

  const updateSurveys = async () => {
    try {
      for (let index = 0; index < selected.length; index++) {
        const survey = selected[index];

        const loadingProgress = await new Promise((resolve, reject) => {
          const timeoutID = setTimeout(() => {
            const progressValue = (index + 1) / selected.length; // Progress indicator
            setProgress(progressValue * 100); // Update progress state
            removeSurvey(survey);
            resolve(progressValue);
            clearTimeout(timeoutID);
          }, 750);
        });
      }
      if (setReloadSurveys) {
        setReloadSurveys(true);
      }
      // if (action === 'status') {
      //   setReloadStatus(true);
      // }

    }

    catch (error) {
      console.log()
    }
  };

  const removeSurvey = async (survey) => {
    const deletedSurvey = await deleteSurvey(survey.id);
    if (deletedSurvey.status === 'success') {
      console.log('successfully deleted the survey(s)', deletedSurvey.data)
    }

  }

  return (
    <ActionContent
      title="Delete surveys"
      progress={progress}
      cancel={handleCancel}
      confirm={updateSurveys}
      confirmCTA="Delete Surveys"
      completedMessage="Finished Deleting Surveys"
    >
      <Grid
        container
        style={{
          justifyContent: 'center'
        }}
      >
        <Typography

        >
          The following surveys will be permanently deleted. Are you sure?
        </Typography>
        <Grid
          container
          style={{
            marginTop: 16,
            maxHeight: '70vh',
            overflowY: 'auto'
          }}
        >
          {
            selected.map(survey => (
              <Grid
                container
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Typography
                  >
                    {survey.name}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Typography
                  >
                    {new Date(survey.modified).toLocaleDateString()}
                  </Typography>
                </Grid>
                <Divider style={{ width: '100%', margin: '8px 0' }} />
              </Grid>
            ))
          }
        </Grid>
      </Grid>

    </ActionContent>
  )
}

const ActionButton = (props) => {
  const { label, icon, color, setAction } = props;

  return (
    <CustomTooltip
      title={label}
    >
      <IconButton
        onClick={setAction}
        style={{
          width:34,
          height:34
        }}
      >
        {
          cloneElement(icon, {color1: color, height:18})
        }
      </IconButton>
    </CustomTooltip>
  )
}

// const ActionContent = (props) => {
//   const { children, progress, title, cancel, confirm, confirmCTA, completedMessage} = props;

//   return (
//     <Grid container>
//       <Typography
//         style={{
//           fontSize: '1.3rem',
//           width: '100%',
//           textAlign: 'center'
//         }}
//       >
//         {title}
//       </Typography>

//       {
//         progress < 100 &&
//         <Grid container style={{ margin: '16px 0' }}>
//           {children}
//         </Grid>
//       }

//       {
//         progress < 100 && progress > 0
//           ?
//           <>
//             <LinearProgress variant="determinate" value={progress} style={{ width: '100%', height: 10, borderRadius: 20 }} />
//             <Typography
//               color="primary"
//               style={{
//                 textAlign: 'center',
//                 width: '100%',
//                 fontWeight: 600,
//               }}
//             >
//               {Math.floor(progress)}%
//             </Typography>
//           </>
//           :
//           progress > 0
//             ?
//             <Typography
//               color="primary"
//               style={{
//                 textAlign: 'center',
//                 width: '100%',
//                 fontWeight: 600,
//                 margin: '36px 0'
//               }}
//             >
//               {completedMessage}
//             </Typography>
//             : null

//       }
//       <Grid
//         container
//         style={{
//           justifyContent: 'space-between'
//         }}
//       >
//         <Divider style={{ width: '100%', margin: '16px 0 8px 0' }} />
//         <Button
//           onClick={cancel}
//         >
//           {`< Back`}
//         </Button>
//         <Button
//           onClick={confirm}
//         >
//           {confirmCTA}
//         </Button>

//       </Grid>
//     </Grid>

//   )
// }
