import React, { cloneElement, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  // TableRow,
  TableCell,
  Select,
  MenuItem,
  Typography,
  Collapse,
  ListItemIcon,
  Menu,
  Dialog,
  useScrollTrigger,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionActions,
  AccordionDetails,
} from "@mui/material";
import { useTheme } from "@mui/system";
import { AddFillSVG, AddSVG, BriefcaseSVG, CalendarSVG, CheckmarkSVG, ChevronSVG, CloseFilledSVG, CloseOutlineSVG, CloseRoundedSVG, CloseThinSVG, CompanySVG, ContactSVG, CopySVG, DeleteSVG, EditSVG, Email2SVG, EmailSVG, EventSVG, FilterSVG, NumberSVG, SearchSVG, TextSVG, UploadSVG, VisibleSVG, WarningSVG } from '../../assets/icons';
import { ButtonCustom } from '../ui/Buttons';
import ReusableSearch from '../ui/ReusableSearch';
import { useScreenSize } from '../../lib/Interface';
import { postContactToMailingList, createContact, createStorageFile, deleteContactFromMailingList, getCustomFieldFolder, putContact, putStorageFile, useGetContacts, useGetCustomFields, useGetTags } from '../../hooks/mutations';
import ImportCustomFields from '../custom_fields/ImportCustomFields';
import { InputField, InputFieldType } from '../ui/Fields';
import { useGetContact } from '../../hooks/mutations'
import { copyString, generateContactName } from '../../lib/Functions';
import ImportTags from '../tags/ImportTags';
import { Tag, TagButton } from '../ui/Tags';
import DialogWarning from '../ui/DialogWarning';
import { contactStatusEnum } from '../../lib/Data';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { useApi } from '../../context/apiContext';
import Infotip from '../ui/Infotip';
import SectionHeader from '../ui/SectionHeader';

function ElevationScroll(props) {
  const { children, window  } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const triggerOnScrollElement = document.getElementById('drawer-contents');
  const screenSize = useScreenSize();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
    target: triggerOnScrollElement  ? triggerOnScrollElement : undefined,
  });

  return cloneElement(children, {
    style:{
      position:'sticky',
      top:0,
      padding: screenSize.xs ? '0 8px 8px 8px' : '0 32px 8px 32px',
      paddingBottom:8,
      background:trigger ? '#fff' : 'transparent',
      zIndex:1000,
      boxShadow: trigger ? '0 4px 5px #00000030' : 'none',
      transition:'box-shadow .5s'
    }

  });
}


const CustomFieldEditor = (props) => {
  const { setSelectedContact, customFields, setReloadCustomFields, incomingContact, updateContacts, setSnackMessage, infotip, isNewContact } = props;
  // const [reload, setReload] = useState(false);
  // const receivedContact = useGetContact(1, reload);
  // const customFields = useGetCustomFields();
  // const tags = useGetTags();
  const screenSize = useScreenSize();
  const theme = useTheme();
  const apiContext = useApi();
  const contactProfiles = apiContext.contextUserDataBag?.data?.contactProfiles ?? [];
  const [contact, setContact] = useState(null);
  const [isAddingField, setIsAddingField] = useState(false);
  const [isAddingList, setIsAddingList] = useState(false);
  // const [isAddingTag, setIsAddingTag] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);
  const [editing, setEditing] = useState(null);
  const [editTitle, setEditTitle] = useState(null);
  const [fieldToRemove, setFieldToRemove] = useState(null);
  const [openWarning, setOpenWarning] = useState(false);
  const [expandedLists, setExpandedLists] = useState(true);
  const [expandedFields, setExpandedFields] = useState(true);
  const [expandedTags, setExpandedTags] = useState(true);
  const [isSaved, setIsSaved] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [profileFields, setProfileFields] = useState();
  const [profile, setProfile] = useState(null);

  // console.log('isSaved', isSaved);
  // console.log('loaded', loaded);
  // console.log('contacts', contacts);

  // console.log('contact', contact);
  // console.log('incomingContact', incomingContact);

  // Set contact from api response 'incomingContact' state
  useEffect(() => {
    if (incomingContact && !loaded) {
      setContact(incomingContact)
      setLoaded(true);
    }
  }, [incomingContact])

  // Open warning dialog when a field is selected to remove
  useEffect(() => {
    if (fieldToRemove) {
      setOpenWarning(true);
    }
  }, [fieldToRemove])

  useEffect(() => {
    if (contact && loaded) {
      let timeoutId;
      let savingId;
  
      clearTimeout(timeoutId);
      clearTimeout(savingId);
      setIsSaved(false);
      timeoutId = setTimeout(() => {
        onSubmit(contact);
        setUpdated(false);
      }, 500);
  
      savingId = setTimeout(() => {
        setIsSaving(true);
      }, 250);
  
      return () => {
        clearTimeout(timeoutId);
        clearTimeout(savingId);
      };
    } else {
      setLoaded(true);
    }

  }, [updated])

  // Handle updating root level contact fields and save updated contact
  const handleContactFieldChange = (field, value) => {
    const updatedContact = { ...contact, [field]: value };
    setContact(updatedContact);
  }  

  const handleIsAddingList = () => {
    if (isAddingList) {
      setIsAddingList(false)
    } else {
      setIsAddingList(true);
    }
  }

  const handleIsAddingField = () => {
    if (isAddingField) {
      setIsAddingField(false)
    } else {
      setIsAddingField(true);
      setExpandedFields(true);
    }
  }

  const handleCloseWarning = () => {
    setOpenWarning(false);
    setTimeout(() => {
      setFieldToRemove(null);
    }, 1500); 
  }

  const handleTextStandard = (value, name) => {
    setContact({
      ...contact,
      [name]: value
    })
    setUpdated(true);
  }

  const handleResetContact = () => {
    setContact(incomingContact);
  }

  const handleTextCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    setContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
    setProfileFields(updatedCustomFields);
    setUpdated(true);
  }

  const handleListCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const copiedFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...copiedFields, [name]: value }
    const currentCustomField = customFields.find(x => x.id == name);
    
    setProfileFields(updatedCustomFields);

    if (currentCustomField.enum_values.includes(value)) {
      setContact(prev => ({
        ...prev,
        custom_fields: updatedCustomFields
      }))
    } else {
      if (value) {
        // setReloadCustomFields(true);
        setContact(prev => ({
          ...prev,
          custom_fields: updatedCustomFields
        }))
      }
    }
    setUpdated(true);
  }

  const handleNumberCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: Number(value) }
    setContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
    setProfileFields(updatedCustomFields);
    setUpdated(true);
  }

  const handleDateCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: new Date(value).toLocaleDateString() }
    setContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
    setProfileFields(updatedCustomFields);
    setUpdated(true);
  }

  const handleBoolCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    setContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }))
    setProfileFields(updatedCustomFields);
    setUpdated(true);
  }

  const handleImageCustomField = async (value, name) => {
    console.log('value', value);
    const fileExists = contact.custom_fields[name];
    console.log('fileExists', fileExists);

    if (!value) {
      const copiedContact = { ...contact };
      const customFields = { ...copiedContact.custom_fields };
      const updatedCustomFields = { ...customFields, [name]: '' }
      setContact(prev => ({
        ...prev,
        custom_fields: updatedCustomFields
      }))
      setProfileFields(updatedCustomFields);
      setUpdated(true);
      return;
    }

    try {
      // Save file to storage
      const storageFolder = await getCustomFieldFolder(); // Change to 'upload folder' when Gaurav adds that api
      const storageFolderId = storageFolder.folderId;
      const fileName = value.file.name;
      const formData = new FormData();
      formData.append('file', value.file);
      formData.append('name', fileName);
      formData.append('folder', storageFolderId);

      const storageFileObj = {
        folderId: storageFolderId,
        storageFilePayload: formData
      }

      if (fileExists) {
        // Replace the file, keeping the same file id
        // This PUT api call happens in the ImageField component so that the component
        // knows whent the image has been updated and can modify the ui accordingly.
        return;

      } else {
        // Create a new file with a new file id
        const storedImage = await createStorageFile(storageFileObj);
        if (storedImage.status === "success") {
          console.log('storedImage', storedImage.data);
  
          // Add storage file id to contact image custom field 
          const copiedContact = { ...contact };
          const customFields = { ...copiedContact.custom_fields };
          const updatedCustomFields = { ...customFields, [name]: storedImage.data.id }
          setContact(prev => ({
            ...prev,
            custom_fields: updatedCustomFields
          }))
          setProfileFields(updatedCustomFields);
          setUpdated(true);
          
          // const updatedContact = { ...copiedContact, custom_fields: updatedCustomFields };
          // const savedContact = await onSubmit(updatedContact);
      }


      }

    }

    catch (error) {

    }
  }

  const handleFileCustomField = async (value, name) => {
    // console.log('value', value);
    const fileExists = contact.custom_fields[name];
    // console.log('fileExists', fileExists);

    try {
      // Save file to storage
      const storageFolder = await getCustomFieldFolder(); // Change to 'upload folder' when Gaurav adds that api
      const storageFolderId = storageFolder.folderId;
      const fileName = value.file.name;
      const formData = new FormData();
      formData.append('file', value.file);
      formData.append('name', fileName);
      formData.append('folder', storageFolderId);

      const storageFileObj = {
        folderId: storageFolderId,
        storageFilePayload: formData
      }

      if (fileExists) {
        // Replace the file, keeping the same file id
        // This PUT api call happens in the ImageField component so that the component
        // knows when the image has been updated and can modify the ui accordingly.
        return;

      } else {
        // Create a new file with a new file id
        const storedImage = await createStorageFile(storageFileObj);
        if (storedImage.status === "success") {
          // console.log('storedImage', storedImage.data);
  
          // Add storage file id to contact image custom field 
          const copiedContact = { ...contact };
          const customFields = { ...copiedContact.custom_fields };
          const updatedCustomFields = { ...customFields, [name]: storedImage.data.id }
          setContact(prev => ({
            ...prev,
            custom_fields: updatedCustomFields
          }))
          setProfileFields(updatedCustomFields);

          // const updatedContact = { ...copiedContact, custom_fields: updatedCustomFields };
          // const savedContact = await onSubmit(updatedContact);
      }


      }

    }

    catch (error) {

    }
  }

  const handleRichTextCustomField = async (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    const savedContact = await onSubmit({...copiedContact, custom_fields: updatedCustomFields});
    if (savedContact) {
      console.log('success', savedContact);
    }

  }

  const handleImportAllFields = (customFields) => {
    const copiedContact = { ...contact };
    const existingFields = { ...copiedContact.custom_fields };
    // console.log('existingFields', existingFields);
    // console.log('object keys existingFields', Object.keys(existingFields));
    let newFields = { ...existingFields };
    // console.log('newFields', newFields);
    // console.log('customFields', customFields);
    
    customFields.forEach(field => {
      if (Object.keys(existingFields).includes(String(field.id))) {
        console.log('THAT FIELD ALREADY EXISTS');
      } else {
        newFields = { ...newFields, [field.id]: '' }
      }
    });

    
    // console.log('NEW newFields', newFields);

    setContact(prev => ({
      ...prev,
      custom_fields: newFields
    }))
    setUpdated(true);
  }

  const handleProfileFields = (incomingProfile) => {
    if (profile?.id === incomingProfile.id) {
      console.log('already there')
      setProfile(null);
      return;
    }
    const newProfileFields = [ ...incomingProfile.order ];
    console.log('newProfileFields', newProfileFields)

    const copiedCustomFields = { ...contact.custom_fields };
    console.log('copiedCustomFields', copiedCustomFields)
    let updatedFields = {};

    const visibleProfileFields = newProfileFields.filter(x => x.visible);
    //   const filteredItems = copiedCustomFields.filter(item =>
    //     visibleProfileFields.some(x => x.id === item.id)
    //   );
    visibleProfileFields.forEach(field => {
      if (copiedCustomFields[field.id]) {
        updatedFields = { ...updatedFields, [field.id]: copiedCustomFields[field.id] }
      } else {
        updatedFields = { ...updatedFields, [field.id]: '' }
      }
    })

    setProfile(incomingProfile);
    setProfileFields(prev => ({ ...prev, custom_fields: updatedFields }));
  }

  const handleFieldImport = (field) => {
    const newItem = {[field.id]: ''}
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };

    if (Object.keys(customFields).includes(field.id)) {
      console.log('THAT FIELD ALREADY EXISTS');
    } else {
      const updatedCustomFields = { ...customFields, ...newItem }
      setContact(prev => ({
        ...prev,
        custom_fields: updatedCustomFields
      }))
      setProfileFields(updatedCustomFields);
      setUpdated(true);
    }
  }

  const confirmFieldRemove = (field) => {
    setFieldToRemove(field);
  }

  const handleFieldRemove = () => {  
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
  
    // Create a copy of customFields without the specified key
    delete customFields[fieldToRemove.key];
  
    setContact((prev) => ({
      ...prev,
      custom_fields: customFields,
    }));
    setProfileFields(customFields);
    setUpdated(true);
    setOpenWarning(false);
  };

  const handleFieldRemoveEmpty = (e) => {
    e.stopPropagation();
    if (profile) {

    }

    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const emptyCustomFields = Object.entries(customFields).filter(([key, value]) => !value.length );
    console.log('emptyCustomFIelds', emptyCustomFields);
    const emptyCustomFieldKeys = emptyCustomFields.map(([key, value]) => key);
    console.log('emptyCustomFieldKeys', emptyCustomFieldKeys);
    console.log('customFields', customFields);

    // Create a copy of customFields without the specified key
    emptyCustomFieldKeys.forEach(key => {
      delete customFields[key];
    })
    
    console.log('customFields', customFields);
  
    setContact((prev) => ({
      ...prev,
      custom_fields: customFields,
    }));
    setProfileFields(customFields);
    setUpdated(true);
  };

  const onSubmit = async (contact) => {
    if (isNewContact) {
      setSelectedContact(prev => ({
        ...prev,
        custom_fields: contact.custom_fields
      }))
      return;
    }
    // console.log('contact', contact);
    // console.log('incomingContact', incomingContact);
    // console.log('no changes have been made', JSON.stringify(contact) === JSON.stringify(incomingContact))

    // If no changes have been made to the contact, then abort the save
    if (JSON.stringify(contact) === JSON.stringify(incomingContact)) return;

    const existingTags = [ ...contact.tags ];
    const existingTagNames = existingTags.map(x => x.name);
  
    const contactObj = {
      contactId: contact.id,
      payload: {
        ...contact,
        tags: existingTagNames,
      }
    }
    const updatedContact = await putContact(contactObj);
    setIsSaving(false);
    if (updatedContact.status === 'success') {
      updateContactsList(updatedContact.data);
      // setSnackMessage({ open: true, message: 'Contact updated', severity: 'success' })
      // setIsSaved(true);
      // // console.log('successfully updated contact');
      // // if (setReload) {
      // //   setReload(true);
      // // }
      // if (updateContacts) {
      //   updateContacts(updatedContact.data);
      // }

    }
  }

  const updateContactsList = (updatedContactData) => {
    setSnackMessage({ open: true, message: 'Contact updated', severity: 'success' })
    setIsSaved(true);
    if (updateContacts) {
      updateContacts(updatedContactData);
    }
  }

  return (
    contact
      ?
        <Grid
          style={{
            position:'relative'
          //   background:'#fff',
          //   borderRadius: 8,
          //   padding: screenSize.sm ? '36px 24px' : '36px 72px',
          //   boxShadow:'0 2px 10px #00000020',
          //   width: screenSize.sm ? '90%' : (screenSize.md ? '80%' : '70%'),
          //   transition:'.4s',
          //   maxWidth:1600
          }}
        >
            {/* <Grid
              elevation={4}
              style={{
                position:'sticky',
                top:0,
                paddingBottom:16,
                background:'#fff',
                zIndex:1000
              }}
            >
          <ElevationScroll {...props}>
            <div >
              <Grid
                container
                style={{
                  justifyContent:'space-between',
                  paddingTop:8
                }}
              >
              {
                setSelectedContact
                  ?
                    <Grid
                      container
                      style={{
                        justifyContent:'space-between',
                        alignItems:'center'
                      }}
                    >
                      <Button
                        onClick={() => navigateContact('back')}
                        startIcon={<ChevronSVG height={12} color1={theme.palette.primary.main} />}
                        style={{
                          // position:'absolute',
                          // top:0,
                          // left:0,
                          // width:25,
                          minWidth:0,
                          // height:'100%',
                          display:'flex',
                          // padding:'20px 0',
                          zIndex:1001
                        }}
                      >
                        {screenSize.sm ? 'Previous' : 'Previous Contact'}
                      </Button>

                      <Button
                        variant="contained"
                        href={`/contacts/${contact.id}`}
                        startIcon={<ContactSVG width={13} color1="#fff" />}
                        sx={{
                          px:2,
                          py:.75,
                          lineHeight:1,
                          minHeight:0
                        }}
                      >
                        Edit
                      </Button>

                      <Button
                        onClick={() => navigateContact('next')}
                        endIcon={<ChevronSVG height={12} color1={theme.palette.primary.main} style={{transform:'scale(-1)'}} />}
                        style={{
                          // position:'absolute',
                          // top:0,
                          // right:0,
                          lineHeight:1,
                          // width:25,
                          minWidth:0,
                          // height:'100%',
                          display:'flex',
                          // padding:'20px 0',
                          zIndex:1001
                        }}
                      >
                        {screenSize.sm ? 'Next' : 'Next Contact'}
                        
                      </Button>
                    </Grid>
                  :
                    null
              }


                <Grid
                
                >
                  {
                    editTitle
                      ?
                        <TextField
                          id="name"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setEditTitle(false)
                            }
                            if (e.key === "Escape") {
                              setEditTitle(false);
                              handleContactFieldChange('name', incomingContact.name);
                            }
                          }}
                          value={contact.name}
                          onChange={(e) => handleContactFieldChange('name', e.target.value)}
                          onBlur={() => {
                            onSubmit(contact);
                            setEditTitle(false);
                          }}
                          variant='standard'
                          autoFocus
                          inputProps={{
                            style:{
                              padding:0,
                              background:'transparent'
                            }
                          }}
                          InputProps={{
                            disableUnderline:true,
                            readOnly: !editTitle,
                            sx:{
                              fontSize:'1.4rem',
                              fontWeight:500,
                              lineHeight:1,
                              transition: '.3s',
                              borderRadius:1,
                            }
                          }}
                          style={{
                            borderRadius:8,
                            width:'auto',
                            margin:0,
                            // boxShadow:readOnly ? null : '0 0 5px #00000030',
                            padding:0,
                          }}
                        />
                      :
                        <Grid display="flex" alignItems="center">
                          <Typography
                            onClick={() => setEditTitle(true)}
                            style={{
                              fontSize:'1.4rem',
                              fontWeight:500,
                              lineHeight:1,
                              color: contact.name ? 'inherit' : '#aaa',
                              fontStyle: contact.name ? 'inherit' : 'italic' 
                            }}
                          >
                            {contact.name ? contact.name : 'Unnamed Contact'}
                          </Typography>
                          <IconButton
                            onClick={() => setEditTitle(true)}
                            sx={{
                              ml:1,
                              p:.75
                            }}
                          >
                            <EditSVG width={14} color1={theme.palette.primary.dark} />
                          </IconButton>
                          {
                            !contact.name.length &&
                              <TagButton
                                bgColor={`${theme.palette.primary.main}20`}
                                fontColor={theme.palette.primary.main}
                                outlined
                                onClick={createNameFromFields}
                                // iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                                label="Generate Name"
                              />
      
                          }
                        </Grid>
                  }

                  <ContactFieldInput
                    icon={<Email2SVG width={16} />}
                    field="primary_email"
                    value={contact.primary_email}
                    defaultValue="No Email"
                    handleContactFieldChange={handleContactFieldChange}
                    onSubmit={() => onSubmit(contact)}
                    onEscapeKey={() => handleContactFieldChange('primary_email', incomingContact.primary_email)}
                  />
                  <ContactFieldInput
                    icon={<BriefcaseSVG width={16} />}
                    field="title"
                    value={contact.title}
                    defaultValue="No Title"
                    handleContactFieldChange={handleContactFieldChange}
                    onSubmit={() => onSubmit(contact)}
                    onEscapeKey={() => handleContactFieldChange('title', incomingContact.title)}
                  />
                  <ContactFieldInput
                    icon={<CompanySVG width={16} />}
                    field="company"
                    value={contact.company}
                    defaultValue="No Company"
                    handleContactFieldChange={handleContactFieldChange}
                    onSubmit={() => onSubmit(contact)}
                    onEscapeKey={() => handleContactFieldChange('company', incomingContact.company)}
                  />
                  <ContactFieldInput
                    icon={<CalendarSVG width={16} />}
                    field="created_on"
                    value={new Date(contact.created_on).toLocaleString('en-US', {month:'short', day:'2-digit', year:'numeric'})}
                    defaultValue="No date"
                    handleContactFieldChange={handleContactFieldChange}
                    onSubmit={() => onSubmit(contact)}
                    onEscapeKey={() => handleContactFieldChange('created_on', incomingContact.created_on)}
                    noEdit
                  />

                </Grid>


                <Grid
                  style={{
                    display:'flex',
                    gap:16,
                    alignItems:'flex-start'
                  }}
                >
                  <Button
                    disabled={isSaved || isSaving}
                    variant="contained"
                    onClick={() => onSubmit(contact)}
                  >
                    {
                      isSaved ? 'Saved'
                        : isSaving
                          ? 'Saving...'
                          : 'Save'
                    }
                  </Button>

                  {
                    // !isAddingTag &&
                    //   <>
                    //   </>
                  }
                </Grid>


                  {
                    // 1 - Pending
                    // 2 - Subscribed
                    // 3 - Unsubscribed
                    // 4 - Cleaned
                    // 5 - EmailBounced
                    // 6 - EmailReportedSpam

                    // STATUS  /  COPIED FROM TAGS -- NEEDS UPDATING
                    <Grid
                      container
                      alignItems="center"
                      sx={{
                        mt:.5
                      }}
                    >
                      <TagButton
                        bgColor={contactStatusEnum.filter(x => x.status === contact.status)[0]?.color}
                        fontColor={contactStatusEnum.filter(x => x.status === contact.status)[0]?.text}
                        outlined
                        outlinedColor={contactStatusEnum.filter(x => x.status === contact.status)[0]?.color}
                        onClick={handleAddButtonStatusClick}
                        // iconStart={<AddSVG width={12} color1="#fff" style={{ transform: isAddingButtonTag ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                        label={contactStatusEnum.filter(x => x.status === contact.status)[0]?.label}
                      />
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorElStatus}
                        open={isAddingButtonStatus}
                        onClose={handleAddButtonStatusClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}          
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                        style={{
                          marginTop:4
                        }}
                      >
                        {
                          contactStatusEnum.map(status => (
                            <MenuItem
                              onClick={(e) => handleTextStandard(e.target.value, 'status')}
                              value={status.status}
                            >
                              {status.label}
                            </MenuItem>

                          ))
                        }
                      </Menu>

                    </Grid>
                }

                {
                  // CUSTOM FIELD ADDING
                  <Grid
                    container
                    style={{
                      marginTop:8
                    }}
                  >
                  </Grid>
                }

              </Grid>


            </div>
          </ElevationScroll>
            </Grid> */}
          
            <SectionHeader
              title="CUSTOM FIELDS"
              id="section-header-custom-fields"
              expanded={expandedFields}
              setExpanded={setExpandedFields}
              scroll
              scrollOffset={100}
              infotip={ infotip ? infotip :
                <Infotip
                  question
                  title="Custom Fields"
                  tip="Get information about custom fields"
                  messages={{
                    one:<>Unlike standard fields, custom fields may be added and removed from a contact when necessary. Click <a href="/contacts/custom_fields/" target="_blank">here</a> to see a list of your existing custom fields or add a new one.</>,
                    two:'By default, contacts are created without any custom fields, unless added at this step. You may add custom fields to the contact in the section below or anytime after the contact is created.'
                  }}
                />
              }
              button={
                <TagButton
                  bgColor="#eeeeee"
                  fontColor="#777777"
                  // bgColor={`${theme.palette.primary.main}20`}
                  // fontColor={theme.palette.primary.main}
                  outlined
                  onClick={(e) => {
                    e.stopPropagation();
                    handleIsAddingField();
                  }}
                  iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition:'.5s'}} />}
                  label={ isAddingField ? 'Close' : (screenSize.sm ? 'Field' : "Add Field") }
                />
              }
              subSummary={
                contactProfiles &&
                <Grid
                  container
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    cursor:'default'
                  }}
                >
                  <Grid container variant="center" gap={2} mt={1}>
                    <Grid sx={{display:'flex', alignItems:'center', gap:1, mr:2}}>
                      <Typography>Profiles</Typography>
                      <Infotip
                        question
                        title="Contact Profiles"
                        tip="Add preset fields from a profile"
                        messages={{
                          one: "Contact profiles allow you to add a group of custom fields that have been assigned to that profile.",
                          two: 'You can assign fields to contact profiles in the Preferences menu under the Contact Profiles tab.'
                        }}
                      />
                    </Grid>
                    {
                      contactProfiles.map(profileItem => (
                        <Grid item>
                          <TagButton
                            label={profileItem.label}
                            // fontColor="#777"
                            // outlinedColor="#CCC"
                            regular
                            onClick={(e) => {
                              e.stopPropagation();
                              handleProfileFields(profileItem)
                            }}
                            // bgColor={profileItem.id === profile?.id
                            //   ? `${theme.palette.primary.main}99`
                            //   : `${theme.palette.primary.main}20`
                            // }
                            outlinedColor={profileItem.id === profile?.id
                              ? theme.palette.primary.main
                              : '#CCC' //theme.palette.primary.main
                            }
                            fontColor={profileItem.id === profile?.id
                              ? theme.palette.primary.main
                              : '#777'
                            }
                            outlined
                            active={profileItem.id === profile?.id}
      
                          />
                        </Grid>
                      ))
                    }
                  </Grid>

                  <Grid container variant="center" sx={{my:1, py:1, background:'#eee'}}>
                    <Typography>
                      Empty custom fields will be removed to reduce clutter when contact is saved. <span onClick={(e) => handleFieldRemoveEmpty(e)}>Remove empty fields now.</span>
                    </Typography>
                  </Grid>
                </Grid>
              }
              actions={
                <Grid
                  container
                  style={{
                    padding:8, 
                    borderRadius:8,
                    border: isAddingField ? `1px solid #ccc` : `1px solid #cccccc00`,
                    transition:'.5s'
                  }}
                >
                  <Collapse
                    in={isAddingField}
                    style={{
                      width:'100%',
                      marginTop:isAddingField ? 16 : 0,
                      transition:'.75s'
                    }}
                  >
                    <ImportCustomFields
                      setIsOpen={setIsAddingField}
                      existingCustomFields={Object.keys(contact.custom_fields)}
                      handleFieldImport={handleFieldImport}
                      handleImportAllFields={handleImportAllFields}
                      setEditing={setEditing}
                      handleOpen={setIsAddingField}
                    />
                  </Collapse>

                </Grid>
              }
            >
            {
              contact
                ?
                  profile
                    ?
                      <>
                        {
                          // Object.entries(profileFields.custom_fields).map(([key, value], index) => {
                            Object.entries(profile.order.filter(x => x.visible).reduce((acc, field) => ({
                                ...acc,
                                [field.id]: contact.custom_fields[field.id] || ''
                            }), {})
                          ).map(([key, value], index) => {
                              // const fieldIdArr = customFields.map(x => x.id);
                            const fieldId = customFields?.find(x => x.id == key)?.id;
                            if (fieldId) {
                              return (
                                <TableRow
                                  contactId={contact.id}
                                  customFields={customFields}
                                  customField={{ key: fieldId, value: value }}
                                  index={index}
                                  handleTextCustomField={handleTextCustomField}
                                  handleListCustomField={handleListCustomField}
                                  handleNumberCustomField={handleNumberCustomField}
                                  handleDateCustomField={handleDateCustomField}
                                  handleBoolCustomField={handleBoolCustomField}
                                  handleImageCustomField={handleImageCustomField}
                                  handleFileCustomField={handleFileCustomField}
                                  handleRichTextCustomField={handleRichTextCustomField}
                                  confirmFieldRemove={confirmFieldRemove}
                                  setReloadCustomFields={setReloadCustomFields}
                                  showDelete
                                  setEditing={setEditing}
                                  active={editing === key}
                                />
                              )
                            }
                          })
                        }
                      </>
                    :
                      <>

                        {
                            Object.entries(contact.custom_fields).map(([key, value], index) => {
                              // const fieldIdArr = customFields.map(x => x.id);
                              const fieldId = customFields?.filter(x => x.id == key)[0]?.id;
                              if (fieldId) {
                                return (
                                  <TableRow
                                    contactId={contact.id}
                                    customFields={customFields}
                                    customField={{ key: fieldId, value: value }}
                                    index={index}
                                    handleTextCustomField={handleTextCustomField}
                                    handleListCustomField={handleListCustomField}
                                    handleNumberCustomField={handleNumberCustomField}
                                    handleDateCustomField={handleDateCustomField}
                                    handleBoolCustomField={handleBoolCustomField}
                                    handleImageCustomField={handleImageCustomField}
                                    handleFileCustomField={handleFileCustomField}
                                    handleRichTextCustomField={handleRichTextCustomField}
                                    confirmFieldRemove={confirmFieldRemove}
                                    setReloadCustomFields={setReloadCustomFields}
                                    showDelete
                                    setEditing={setEditing}
                                    active={editing === key}
                                  />
                                )
                            }
                          })
                        }
                      </>
                :
                <Typography>No custom fields have been created yet.</Typography>
            }

              {
                // Object.keys(profileFields).length
                //   ?
                //     <>
                //       {/* <TableRow
                //         customFields={customFields}
                //         customField={{key: 'primary_email', value: contact.primary_email}}
                //         handleTextCustomField={handleTextStandard}
                //         setEditing={setEditing}
                //         active={editing === 'primary_email'}
                //       /> */}
                //       {
                //         Object.entries(profileFields).map(([key, value], index) => {
                //           // const fieldIdArr = customFields.map(x => x.id);
                //           const fieldId = customFields?.filter(x => x.id == key)[0]?.id;
                //           if (fieldId) {
                //             return (
                //               <TableRow
                //                 contactId={contact.id}
                //                 customFields={customFields}
                //                 customField={{key: fieldId, value: value}}
                //                 index={index}
                //                 handleTextCustomField={handleTextCustomField}
                //                 handleListCustomField={handleListCustomField}
                //                 handleNumberCustomField={handleNumberCustomField}
                //                 handleDateCustomField={handleDateCustomField}
                //                 handleBoolCustomField={handleBoolCustomField}
                //                 handleImageCustomField={handleImageCustomField}
                //                 handleFileCustomField={handleFileCustomField}
                //                 handleRichTextCustomField={handleRichTextCustomField}
                //                 confirmFieldRemove={confirmFieldRemove}
                //                 setReloadCustomFields={setReloadCustomFields}
                //                 showDelete
                //                 setEditing={setEditing}
                //                 active={editing === key}
                //               />
                //             )
                //           }
                //         })
                //       }
                //     </>
                //   :
                //     <Typography width="100%" textAlign="center">No custom fields have been created yet.</Typography>
              }
            </SectionHeader>

          {/* REMOVE CUSTOM FIELD DIALOG */}
          <DialogWarning
            open={openWarning}
            onClose={handleCloseWarning}
            header="Remove custom field"
            title={`You are about to remove the ${customFields?.filter(x => x.id === fieldToRemove?.key )[0]?.field_name} field`}
            messages={{
              one:'Are you sure you want to remove this field from your contact? It will be permanently removed once you submit changes to the contact.',
              confirm:'Yes'
            }}
            // deleting
            cancel={handleCloseWarning}
            confirm={handleFieldRemove}
            // icons
          />

        </Grid>
      :
        null
  )
}

export default CustomFieldEditor

const TableRow = (props) => {
  const { contactId, customField, index, handleTextCustomField, handleListCustomField, handleNumberCustomField, handleDateCustomField, handleBoolCustomField, handleImageCustomField, handleFileCustomField, handleRichTextCustomField, confirmFieldRemove, showDelete, setEditing, active, customFields, setReloadCustomFields } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        // width: showDelete ? 'calc(100% - 50px)' : '100%',
        alignItems:'center'
      }}
    >
      {
        index !== null && index !== undefined
          ?
            <EditableDisplay
              fieldKey={customField.key}
              fieldValue={customField.value}
              customField={customField}
              contactId={contactId}
              onTextChange={(e) => handleTextCustomField(e.target.value, customField.key)}
              onListChange={(e) => handleListCustomField(e, customField.key)}
              onNumberChange={(e) => handleNumberCustomField(e.target.value, customField.key)}
              onDateChange={(e) => handleDateCustomField(e, customField.key)}
              onBoolChange={(e) => handleBoolCustomField(e.target.checked, customField.key)}
              onImageChange={(e) => handleImageCustomField(e, customField.key)}
              onFileChange={(e) => handleFileCustomField(e, customField.key)}
              onRichTextChange={(e) => handleRichTextCustomField(e, customField.key)}
              active={active}
              setEditing={setEditing}
              showDelete={showDelete}
              onClickRemove={() => confirmFieldRemove(customField)}
              setReloadCustomFields={setReloadCustomFields}
              customFields={customFields}
            />
          :
            <>
              <Grid display="flex" alignItems="center">
                <Typography
                  style={{
                    marginLeft:16,
                    marginRight:4,
                    fontWeight:500,
                    color: theme.palette.primary.main,
                  }}
                >
                  {customField.key}
                </Typography>

                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    copyString(customField.value);
                  }}
                >
                  <CopySVG width={15} color1={theme.palette.primary.dark} color2={theme.palette.primary.dark} />
                </IconButton>
              </Grid>
              <InputField
                // placeholder='Field Name'
                // label={customField.key}
                value={customField.value}
                onChange={(e) => handleTextCustomField(e.target.value, customField.key)}
                // width="calc(100% - 50px)"
                margin
              />
            </>

      }
      {/* <Grid
        style={{
          width:50,
          margin:'auto',
          textAlign:'center'
        }}
      >
        {
          showDelete &&
            <IconButton
              onClick={() => confirmFieldRemove(customField)}
              style={{
                width:35,
                height:35,
                margin: '0 0 24px 0' // <-- This is needed to match the bottom margin of InputField to properly align vertically
              }}
            >
              <CloseFilledSVG width={25} color1="#ccc" />
            </IconButton>
        }
      </Grid> */}
      {
        // columns.map(item => (
        //   <TableColumn item={item}>
        //     {
        //       item.id === 'data_type' &&
        //         <Grid
        //           style={{
        //             width:50,
        //             display:'flex',
        //             alignItems:'center',
        //             justifyContent:'center',
        //             marginLeft:-10
        //           }}
        //         >
        //           {cloneElement(selectedIcons[customField.data_type], {color1:'#777'})}
        //         </Grid>
        //     }
        //     {
        //       item.id === 'actions' &&
        //         <VisibleSVG width={20} color1="#777" />
        //     }
        //     <Typography style={{ fontSize:'1rem'}}>{customField[item.id]}</Typography>
        //   </TableColumn>
        // ))
      }
    </Grid>
  )
}

const EditableDisplay = (props) => {
  const { contactId, fieldKey, fieldValue, active, onTextChange, onListChange, onNumberChange, onDateChange, onBoolChange, onImageChange, onFileChange, onRichTextChange, setEditing, showDelete, onClickRemove, customField, customFields, setReloadCustomFields } = props;
  const theme = useTheme();
  const [hover, setHover] = useState(false);

  const handleClick = () => {
    if (!active) {
      setEditing(fieldKey);
      setTimeout(() => {
        if (document.getElementById(fieldKey)) {
          document.getElementById(fieldKey).focus();
        }
      }, 0);
    }
  }

  const fieldName = customFields.filter(x => x.id === fieldKey)[0].field_name;
  const fieldType = customFields.filter(x => x.id === fieldKey)[0].data_type;
  const copyable = ['text', 'number', 'email', 'date', 'url', 'large_text_field', 'phone_number'].includes(fieldType);

  return (
    <Grid
      id={`r-${fieldKey}`}
      container
    >
      <Grid
        container
        tabIndex="0"
        justifyContent="space-between"
        onClick={handleClick}
        // onKeyDown={(e) => { // <-- This is handled automatically by the onFocus
        //   if (e.key === "Enter") {
        //     handleClick()
        //   }
        // }}
        onFocus={handleClick}
        style={{
          background:hover ? '#eee' : 'transparent'
        }}
      >
        <Grid display="flex" alignItems="center">
          <Typography
            style={{
              marginLeft:16,
              marginRight:4,
              fontWeight:500,
              color: theme.palette.primary.main,
            }}
          >
            {fieldName}
          </Typography>

          {
            copyable &&
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  copyString(fieldValue);
                }}
              >
                <CopySVG width={15} color1={theme.palette.primary.dark} color2={theme.palette.primary.dark} />
              </IconButton>
          }
          {
            // active
            //   ?
            //   <IconButton
            //     onClick={() => setEditing(null)}
            //   >
            //     <CloseRoundedSVG width={15} color1={theme.palette.primary.dark} />
            //   </IconButton>
            //   :
            //   <IconButton
            //   // onClick={() => {
            //   //   setEditing(fieldKey);
            //   //   setTimeout(() => {
            //   //     setFocus();
            //   //   }, 0);
            //   // }}
            //   >
            //     <EditSVG width={15} color1={theme.palette.primary.dark} />
            //   </IconButton>
          }
        </Grid>
        <Grid display="flex" alignItems="center">
          {
            showDelete &&
              <IconButton
                onClick={onClickRemove}
                style={{
                  width:35,
                  height:35,
                  // margin: '0 0 24px 0' // <-- This is needed to match the bottom margin of InputField to properly align vertically
                }}
              >
                <DeleteSVG width={12} color1={hover ? "#333" : "#aaa"} />
              </IconButton>
          }
        </Grid>


      </Grid>
      <Grid
        container
        style={{
          padding:'0 8px'
        }}
      >
        <InputFieldType
          id={fieldKey}
          contactId={contactId}
          customField={customField}
          customFields={customFields}
          value={fieldValue}
          onTextChange={onTextChange}
          onListChange={onListChange}
          onNumberChange={onNumberChange}
          onDateChange={onDateChange}
          onBoolChange={onBoolChange}
          onRichTextChange={onRichTextChange}
          onSelectChange={onTextChange}
          onFileChange={onFileChange}
          onImageChange={onImageChange}
          enableOnClick={handleClick}
          setReloadCustomFields={setReloadCustomFields}
          noAutoFocus
          margin
        />

        {/* <InputField
          id={fieldKey}
          // placeholder='Field Name'
          // label={fieldKey}
          value={fieldValue}
          onChange={onChange}
          // readOnly={!active}
          enableOnClick={handleClick}
          // width="calc(100% - 50px)"
          noAutoFocus
          margin
        /> */}
      </Grid>

      {
        // active
        //   ?
        //     <>
        //       <InputField
        //         // placeholder='Field Name'
        //         // label={fieldKey}
        //         value={fieldValue}
        //         onChange={onChange}
        //         // width="calc(100% - 50px)"
        //         margin
        //       />
        //     </>
        //   :
        //     <>
        //       <Typography>{fieldValue}</Typography>
        //     </>
      }
    </Grid>

  )
}