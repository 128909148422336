import React from 'react'
import { CircularProgress, Grid, Typography } from '@mui/material'
import { theme } from '../../theme';
import { useScreenSize } from '../../lib/Interface';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Loading from '../ui/Loading';

const CampaignStatus = (props) => {
  const { campaigns, status_1, status_2, status_3, status_4, status_5, status_6 } = props;
  const screenSize = useScreenSize();
  console.log('screensize', screenSize)

  return (
    // campaigns && status_1 && status_2 && status_3
    //   ?
        <Grid2
          container
          spacing={2}
          style={{
            justifyContent:'space-between',
          }}
        >
          {
            [status_1, status_2, status_3].map((statusCampaigns, index) => (
              statusCampaigns && campaigns
                ?
                  <Grid2
                    key={index}
                    sx={{
                      // padding: screenSize.sm ? '8px' : (screenSize.md ?'0 8px' : '0 24px'),
                      maxWidth: screenSize.sm ? 'none' : 350,
                    }}
                    xs={12}
                    sm={4}
                  >
                    <Grid
                      container
                      style={{
                        display:'flex',
                        alignItems:'center',
                        height: 130,
                        background: status_colors[`status_${index+1}`].color.light,
                        border: `4px solid ${status_colors[`status_${index+1}`].color.main}`,
                        borderRadius: 16,
                        overflow:'clip'
                      }}
                    >
                      <Grid
                        item
                        style={{
                          display: screenSize.sm ? 'none' : 'flex',
                          justifyContent:'center',
                          position:'relative'
                        }}
                        xs
                      >
                        <div style={{position:'absolute', top:'-47px'}}>
                          <CircularProgress
                            variant="determinate"
                            size={92}
                            thickness={10}
                            value={100}
                            style={{
                              color: status_colors[`status_${index+1}`].color.accent,
                              zIndex: 1
                            }}
                          />
                        </div>
                        <div style={{position:'absolute', top:'-47px'}}>
                          <CircularProgress
                            variant="determinate"
                            size={92}
                            thickness={10}
                            value={ (statusCampaigns.length / campaigns.length) * 100 || 1}
                            style={{
                              color: status_colors[`status_${index+1}`].color.dark,
                              zIndex: 10
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display:'flex',
                          flexDirection: 'column',
                          alignItems:'center'
                        }}
                        xs
                      >
                        <Typography
                          style={{
                            fontFamily: 'Poppins',
                            fontWeight: 800,
                            fontSize: '56px',
                            lineHeight: .8,
                            color: status_colors[`status_${index+1}`].color.dark,
                          }}
                        >
                          {statusCampaigns.length}
                        </Typography>
                        <Typography
                          style={{
                            fontSize: '24px',
                            fontWeight: 500,
                            textTransform:'uppercase',
                            color: status_colors[`status_${index+1}`].color.dark,
                          }}
                        >
                          {status_colors[`status_${index+1}`].label}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid2>
                :
                  <Grid2
                    key={index}
                    sx={{
                      // padding: screenSize.sm ? '8px' : (screenSize.md ?'0 8px' : '0 24px'),
                      maxWidth: screenSize.sm ? 'none' : 350,
                    }}
                    xs={12}
                    sm={4}
                  >
                    <Grid
                      container
                      style={{
                        display:'flex',
                        alignItems:'center',
                        height: 130,
                        background: status_colors[`status_${index+1}`].color.light,
                        border: `4px solid ${status_colors[`status_${index+1}`].color.main}`,
                        borderRadius: 16,
                        overflow:'clip'
                      }}
                    >
                      <Loading minHeight={0} color={status_colors[`status_${index+1}`].color.dark} />
                    </Grid>
                  </Grid2>
            ))
          }
        </Grid2>
      // :
      //   <Loading />
  )
}

export default CampaignStatus

const status_colors = {
  status_1: {
    label: 'Sent',
    status: 1,
    color: {
      light: theme.palette.status.campaign.sent.light,
      main: theme.palette.status.campaign.sent.main,
      dark: theme.palette.status.campaign.sent.dark,
      accent: theme.palette.status.campaign.sent.accent
    }
  },
  status_2: {
    label: 'Scheduled',
    status: 2,
    color: {
      light: theme.palette.status.campaign.scheduled.light,
      main: theme.palette.status.campaign.scheduled.main,
      dark: theme.palette.status.campaign.scheduled.dark,
      accent: theme.palette.status.campaign.scheduled.accent
    }
  },
  status_3: {
    label: 'Draft',
    status: 3,
    color: {
      light: theme.palette.status.campaign.draft.light,
      main: theme.palette.status.campaign.draft.main,
      dark: theme.palette.status.campaign.draft.dark,
      accent: theme.palette.status.campaign.draft.accent
    }
  },
  status_4: {
    label: 'Queued',
    status: 4,
    color: {
      light: theme.palette.status.campaign.queued.light,
      main: theme.palette.status.campaign.queued.main,
      dark: theme.palette.status.campaign.queued.dark,
      accent: theme.palette.status.campaign.queued.accent
    }
  },
  status_5: {
    label: 'Delivering',
    status: 5,
  },
  status_6: {
    label: 'Paused',
    status: 6,
  },
}