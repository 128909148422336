import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify'; // Import DOMPurify
import { CopySVG, DeleteSVG, GallerySVG, SpacerSVG } from '../../assets/icons';
import { GiphyLogo } from '../../assets/logos';
import ElementRTEditor from './ElementRTEditor';

// ElementRenderer Component
const ElementRenderer = ({ element, elementStyle, focused, onElementUpdate, onElementDelete, onElementCopy, preview }) => {
    const [elementText, setElementText] = useState(element?.content || '');

    const handleEditorChange = (newContent) => {
        setElementText(newContent); // Update elementText when editor content changes
    };

    const handleBlur = () => {
      if (element.type === 'Text') {
          onElementUpdate({ ...element, content: elementText });
      }
  };

    const handleDelete = (e) => {
      e.stopPropagation()
      onElementDelete(element);
    }

    const handleCopy = (e) => {
      e.stopPropagation()
      onElementCopy(element);
    }

    useEffect(() => {
      if (elementText) {

      }
    }, [elementText])

    // Render based on element type
  switch (element.type) {
    case 'Text':
      return (
        <ElementWrapper
          handleDelete={handleDelete}
          handleCopy={handleCopy}
          onBlur={handleBlur}
          preview={preview}
          wrapStyle={element.wrapStyle}
        >
          {/* <ElementRTEditor handleEditorChange={handleEditorChange} elementId={element?.uid} initialContent={element?.content || ''} focused={focused} preview={preview} /> */}
          <ElementRTEditor handleEditorChange={handleEditorChange} elementId={element?.uid} initialContent={element?.content || ''} focused={focused} preview={preview} />
        </ElementWrapper>
      );

    case 'Button':
      return (
        <ElementWrapper
          handleDelete={handleDelete}
          handleCopy={handleCopy}
          onBlur={handleBlur}
          preview={preview}
          wrapStyle={element.wrapStyle}
        >
          <a
            href={preview ? element.url : null}
            target="_blank"
          // className={`${!preview && 'element-button'}`}
          >
            <button
              className="fill-eb-button"
              style={element.style}
            >
              {element.content || 'Button'}
            </button>
          </a>
        </ElementWrapper>
      );

    case 'Image':
      return (
        <ElementWrapper
          handleDelete={handleDelete}
          handleCopy={handleCopy}
          onBlur={handleBlur}
          preview={preview}
          wrapStyle={element.wrapStyle}
        >
          {
            element.url
              ?
              <img
                className="fill-eb-image"
                alt={element.alt || 'email image'}
                src={element.url || '/fill-logo_500px.png'}
                style={{
                  width: '100%',
                  height: 200,
                  objectFit: 'contain',
                  ...element.style,
                  maxWidth: '100%'
                }}
              />
              :
              <GallerySVG height={150} width="100%" style={{ opacity: .5 }} />
          }
        </ElementWrapper>
      );

    case 'GIF':
      return (
        <ElementWrapper
          handleDelete={handleDelete}
          handleCopy={handleCopy}
          onBlur={handleBlur}
          preview={preview}
          wrapStyle={element.wrapStyle}
        >
          {
            element.url
              ?
              <img
                className="fill-eb-gif"
                alt={element.alt || 'GIF image'}
                src={element.url || <GiphyLogo width={75} />}
                style={{
                  width: '100%',
                  height: 200,
                  objectFit: 'contain',
                  ...element.style,
                  maxWidth: '100%'
                }}
              />
              :
                <div style={{textAlign:'center'}}>
                  <p style={{fontSize:56, fontWeight:600, lineHeight:'normal', margin:0, textAlign:'center'}}>GIF</p>
                  {/* <img src={process.env.REACT_APP_CURRENT_ENV === 'STAGING' ? `/static/img/PoweredBy_200_Horizontal_Light-Backgrounds_With_Logo.gif` : `https://static.fillmarketing.com/static/static/img/PoweredBy_200_Horizontal_Light-Backgrounds_With_Logo.gif`} style={{width:150}} /> */}
                  {/* <div style={{display:'flex', gap:8, width:'100%', justifyContent:'center'}}>
                    <p style={{margin:0}}>Powered by</p>
                    <GiphyLogo width={80} />
                  </div> */}
                </div>
          }
        </ElementWrapper>
      );

    case 'Sticker':
      return (
        <ElementWrapper
          handleDelete={handleDelete}
          handleCopy={handleCopy}
          onBlur={handleBlur}
          preview={preview}
          wrapStyle={element.wrapStyle}
        >
          {
            element.url
              ?
              <img
                className="fill-eb-sticker"
                alt={element.alt || 'Sticker image'}
                src={element.url || <GiphyLogo width={75} />}
                style={{
                  width: '100%',
                  height: 200,
                  objectFit: 'contain',
                  ...element.style,
                  maxWidth: '100%'
                }}
              />
              :
                <div>
                  <p style={{fontSize:56, fontWeight:600, lineHeight:'normal', margin:0, textAlign:'center'}}>Sticker</p>
                  {/* <div style={{display:'flex', gap:8, width:'100%', justifyContent:'center'}}>
                    <p style={{margin:0}}>Powered by</p>
                    <GiphyLogo width={80} />
                  </div> */}
                </div>
          }
        </ElementWrapper>
      );

    case 'Divider':
      return (
        <ElementWrapper
          handleDelete={handleDelete}
          handleCopy={handleCopy}
          onBlur={handleBlur}
          preview={preview}
          wrapStyle={element.wrapStyle}
        >
          <hr
            className="fill-eb-divider"
            style={element.style}
          >
          </hr>
        </ElementWrapper>
      );

    case 'Spacer':
      return (
        <ElementWrapper
          handleDelete={handleDelete}
          handleCopy={handleCopy}
          onBlur={handleBlur}
          preview={preview}
          wrapStyle={element.wrapStyle}
        >
          <div
            className="fill-eb-spacer"
            style={{ ...element.style, width: '100%' }}
          >
            <SpacerSVG height="100%" />
          </div>
        </ElementWrapper>
      );

    // // Video is currently being integrated but not ready yet
    // case 'Video':
    //     return (
    //         <ElementWrapper
    //           handleDelete={handleDelete}
    //           onBlur={handleBlur}
    //         >
    //           {
    //             element.url
    //               ?
    //                 <img
    //                   className="element-image"
    //                   alt={element.alt || 'email image'}
    //                   src={element.url || '/fill-logo_500px.png'}
    //                   style={{
    //                     width: '100%',
    //                     height: 200,
    //                     objectFit: 'contain',
    //                     ...element.style,
    //                     maxWidth:'100%'
    //                   }}
    //                 />
    //               :
    //                 <GallerySVG height={200} />
    //           }
    //         </ElementWrapper>
    //     );




    // Other cases...

    default:
      return <div>{element.type || 'Unknown element type'}</div>;
  }
};

export default ElementRenderer;

const ElementWrapper = (props) => {
  const { children, handleDelete, handleCopy, onBlur, wrapStyle, preview } = props;

  return (
    <div
        className="fill-eb-element-wrapper"
        style={wrapStyle}
        onBlur={onBlur}
    >
      {children}
      <div
        className={`${preview && 'EmailTemplate-remove'} EmailTemplate-element-wrapper EmailTemplate-actions EmailTemplate-gap-16`}
      >
        <div
          onClick={(e) => handleCopy(e)}
        >
          <CopySVG width={11} color1="#fff" color2="#ffffffcc" color3="#ffffffcc" />
        </div>
        <div
          onClick={(e) => handleDelete(e)}
        >
          <DeleteSVG width={9} color1="#fff" />
        </div>
      </div>
    </div>

  )
}