import { Button, Grid, LinearProgress, Typography } from '@mui/material';
import React from 'react'
import { DjangoComponents } from '../../templates/DjangoStyles';
import CsvImportList from '../csv-impot/CsvImportList';
import { TagSimple } from '../ui/Tags';
import { status, statusColors } from '../../lib/Data';


const CsvUploadQueued = (props) => {
  const { incomingUpload } = props;
  console.log('incomingUpload', incomingUpload);
  
  return (
    incomingUpload &&
    <DjangoComponents card padding inner>
      <Grid
        container
        variant="center"
        flexDirection="column"
        style={{
          paddingBottom:16
        }}
      >
          <Typography
            style={{
              fontSize:'1.3rem',
              fontWeight:500
            }}
          >
            Contact upload progress
          </Typography>
          <Typography

          >
            Started at {new Date(incomingUpload.created).toLocaleDateString('en-US')} {new Date(incomingUpload.created).toLocaleTimeString('en-US')}
          </Typography>

          <Grid container flexDirection="column" variant="center" style={{marginTop:16}}>
            <Typography>
              {incomingUpload.file_name.split('csv_uploads/')[1]}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={(incomingUpload.total_done / incomingUpload.total_rows) * 100}
              style={{
                width:'100%',
                maxWidth:400,
                height:10,
                borderRadius:20
              }}
            />
            <Typography
              color="primary"
              style={{
                textAlign:'center',
                width:'100%',
                fontWeight:600,
                marginBottom:16
              }}
            >
              {incomingUpload.total_done} / {incomingUpload.total_rows} contacts
            </Typography>
            <TagSimple
              name={status.imports[incomingUpload.status].toUpperCase()}
              color={{
                background: statusColors.imports[incomingUpload.status]
              }}
            />
            <Typography
              style={{
                display: incomingUpload.total_errored > 0 ? 'inherit' : 'none',
                color: statusColors.imports[incomingUpload.status]
              }}
            >
              {incomingUpload.total_errored} contacts had errors
            </Typography>
            {
              incomingUpload.total_errored > 0 &&
                <Button
                  variant="outlined"
                  href={incomingUpload.failed_rows_file}
                  sx={{
                    mt:2
                  }}
                >
                  Download Error File
                </Button>
            }

            <Typography
              style={{
                display: incomingUpload.total_errored > 0 ? 'inherit' : 'none',
                color: statusColors.imports[incomingUpload.status]
              }}
            >
              <a href={incomingUpload.failed_rows_file} download="FailedCSVResults.csv" target='_blank'>
                <Button>Download FailedCSVResults</Button>
              </a>
            </Typography>

          </Grid>
          
          {/* <CsvImportList imports={[incomingUpload]} column /> */}
      </Grid>
    </DjangoComponents>
  )
}

export default CsvUploadQueued