import { useEffect, useState } from "react";
// ===============================================================
// HOOKS
// ===============================================================
export const useVerticalLocation = (querySelectorAll) => {
  // Update the sticky nav menu based on scroll location
  const [mostVisibleId, setMostVisibleId] = useState(null);

  useEffect(() => {
    function handleScroll() {
      const divs = document.querySelectorAll(querySelectorAll); // Select all divs on the page
      const viewportHeight = window.innerHeight; // Get viewport height
      let mostVisibleArea = 0;
      let mostVisibleId = null;

      // Loop through all divs that have an id and find the one with the most visible area
      divs.forEach((div) => {
        if (div.id) {
          const { top, bottom, height } = div.getBoundingClientRect();
          const visibleArea = Math.max(0, Math.min(bottom, viewportHeight) - Math.max(top, 0)) * Math.min(height, viewportHeight) / height;
          if (visibleArea > mostVisibleArea) {
            mostVisibleArea = visibleArea;
            mostVisibleId = div.id ? div.id : 'no-id';
          }
        }
      });

      setMostVisibleId(mostVisibleId); // Update state with the ID of the most visible div
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return mostVisibleId;
}


// ===============================================================
// FUNCTIONS
// ===============================================================
export const hashScroll = (hashId, offset=0, block ) => {
  const offsetY = offset ? offset : 0;
  const element = document.getElementById(hashId);

  if (element) {
    window.scrollTo({
      behavior: 'smooth',
      top:
        element.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    })
  
    // if (srollTo) {
    //   const y = element.getBoundingClientRect().top + window.scrollY - offsetY;
    //   console.log('y', y)
    //   window.scrollTo({top: y, behavior: 'smooth'});
    // } else {
    //   element.scrollIntoView({behavior: "smooth", block: block ? block : "start", inline: "nearest"});
    // }
    
  }
}

export const convertUTCToLocalTime = (utcTimestamp, dateFormat = { year: 'numeric', month: 'long', day: 'numeric' }, timeFormat = { hour: 'numeric', minute: 'numeric', hour12: true }) => {
  // const utcDate = new Date(utcTimestamp);
  // const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
  // return localDate.toLocaleString();

    
    const utcDate = new Date(utcTimestamp);
    const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
    const localDateString = localDate.toLocaleDateString(undefined, dateFormat);
    const localTimeString = localDate.toLocaleTimeString(undefined, timeFormat);
    return `${localDateString} ${localTimeString}`;
  
}

// Gets a random string of integers
export const getRandomInts = (num) => {
  const randomIntArr = new Uint32Array(10);

  const randomValue = crypto.getRandomValues(randomIntArr);
  // console.log('randomValue', randomValue)

  const randomInt = randomIntArr[0].toString();
  // console.log('randomInt', randomInt)

  const longInt = `${randomIntArr[0]}${randomIntArr[1]}${randomIntArr[2]}`;

  const desiredInt = longInt.slice(0, num);

  return desiredInt
}

// Gets a random string of letters and numbers
export const getRandomString = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  // Use crypto.getRandomValues for better randomness
  const randomValues = new Uint32Array(length);

  // Fill the array with random values
  crypto.getRandomValues(randomValues);

  // Build the random string
  for (let i = 0; i < length; i++) {
    const randomIndex = randomValues[i] % characters.length; // Ensure index is within bounds
    result += characters[randomIndex];
  }

  return result;
};

// Transform selected fields and the custom fields array
export const transformFields = (arr, customFields) => {
  const keys = new Set();
  // console.log('keys', keys)
  // console.log('arr', arr)
  // console.log('customFields', customFields)

  const standardKeys = [
    'primary_email',
  ]

  arr.forEach(obj => {

    // console.log('obj.custom_fields', obj.custom_fields)
    // Add listed standard keys to keys array
    standardKeys.forEach(key => {
      if (obj[key] && !keys.has(key)) {
        keys.add(key);
      }
    })

    // Add custom fields to keys array
    if(obj.custom_fields) {
      if (Object.keys(obj.custom_fields).length !== 0) {
        // console.log('obj.custom_fields', obj.custom_fields)
        Object.keys(obj.custom_fields).forEach(field => {
          // console.log('field', field);
          // if(!keys.has(field.field_name)) {
            const currentField = customFields?.filter(x => x.id == field)[0];
            // console.log('currentField', currentField)

            // Prevent saving any field types that will not display correctly in the display grid
            if (currentField?.data_type && currentField.data_type != 'large_text_field') {
              if (currentField.field_name) {
                keys.add(currentField.field_name);
              } else {
                console.log('This key was removed', currentField.id);
              }
            }
          // }
        });
      }
    }
    
  });

  const uniqueKeys = [ ...new Set(keys) ];
  // console.log('uniqueKeys', uniqueKeys)

  return [...uniqueKeys].map(key => {
    let label = key.replace(/\_/g, ' ')
                  .replace(/-/g, ' ')
                  .split(' ')
                  .map(word => word[0]?.toUpperCase() + word.slice(1))
                  .join(' ');    
    return {
      label,
      field: key,
      id: customFields?.filter(x => x.field_name == key)[0] ? customFields.filter(x => x.field_name == key)[0].id : null,
      type: customFields?.filter(x => x.field_name == key)[0] ? customFields.filter(x => x.field_name == key)[0].data_type : null,
      columnType: standardKeys.includes(key) ? 'standard' : 'custom'
    };
  }).sort((a, b) => {
    if(a.label < b.label) return -1;
    if(a.label > b.label) return 1;
    return 0;
  });
}

// Copy a text string to the clipboard
export const copyString = (text) => {
  try {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(text)
        .then(() => console.log('Text copied to clipboard:', text))
        .catch((error) => console.error('Failed to copy text to clipboard:', error));
      return {status: 'success', text: text}
    } else {
      console.error('Clipboard API not available.');
    }
  }

  catch (error) {
    return {status: 'error', error: error}
  }

}

export const getContrastColor = (background) => {
  // const { background } = obj;
  // Remove # if present
  const hexColor = background.replace('#', '');

  // Convert hex to RGB
  var r = parseInt(hexColor.substr(0, 2), 16);
  var g = parseInt(hexColor.substr(2, 2), 16);
  var b = parseInt(hexColor.substr(4, 2), 16);

  // Calculate relative luminance
  var luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

  // Use a contrast threshold of 0.5
  return luminance > 0.7 ? 'black' : 'white'; // <-- Return this instead of "newColor" for black or white

  // Adjust brightness for better readability
  var adjustValue = luminance > 0.5 ? -0.2 : 0.2;
  var adjustedColor = [
      Math.min(255, Math.max(0, Math.round(r + adjustValue * r))),
      Math.min(255, Math.max(0, Math.round(g + adjustValue * g))),
      Math.min(255, Math.max(0, Math.round(b + adjustValue * b)))
  ];

  // Convert adjusted RGB back to hex
  var adjustedHexColor = '#' + adjustedColor.map(component => component.toString(16).padStart(2, '0')).join('');

  return luminance > 0.5 ? adjustedHexColor : 'white';
}

export const getContrastTone = (color) => {
  // const { color } = obj;
  // Remove # if present
  const hexColor = color.replace('#', '');

  // Convert hex to RGB
  var r = parseInt(hexColor.substr(0, 2), 16);
  var g = parseInt(hexColor.substr(2, 2), 16);
  var b = parseInt(hexColor.substr(4, 2), 16);

  // Calculate relative luminance
  var luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

  // Use a contrast threshold of 0.5
  var isLightColor = luminance > 0.75;

  const adjustDarker = () => {
    // Adjust brightness for better readability
    const adjustValue = 100;
    const adjustedColor = [
      Math.min(255, Math.max(0, Math.round(r - adjustValue))),
      Math.min(255, Math.max(0, Math.round(g - adjustValue))),
      Math.min(255, Math.max(0, Math.round(b - adjustValue)))
    ];

    // Convert adjusted RGB back to hex
    const adjustedHexColor = '#' + adjustedColor.map(component => component.toString(16).padStart(2, '0')).join('');
    return adjustedHexColor;
  }

  if (isLightColor) {
    return {
      background: color,
      text: adjustDarker(),
      outline: null //`1px solid ${adjustDarker()}`
    };
  } else {
    return {
      background: tranparentToOpaqueHexCode(`${color}25`, '#ffffff'),
      text: color, //'#ffffff', //color
      outline: null
    };
  }

}

export const tranparentToOpaqueHexCode = (hexWithOpacity, backgroundHex) => {
    // Parse the foreground color and alpha
    var foreground = parseInt(hexWithOpacity.substring(1, 7), 16);
    var alpha = parseInt(hexWithOpacity.substring(7, 9), 16) / 255;

    // Parse the background color
    var background = parseInt(backgroundHex.substring(1), 16);

    // Calculate the displayed color
    var displayedR = Math.round((1 - alpha) * ((background >> 16) & 255) + alpha * ((foreground >> 16) & 255));
    var displayedG = Math.round((1 - alpha) * ((background >> 8) & 255) + alpha * ((foreground >> 8) & 255));
    var displayedB = Math.round((1 - alpha) * (background & 255) + alpha * (foreground & 255));

    // Convert the displayed color back to a hex string
    var displayedHex = '#' + ((1 << 24) + (displayedR << 16) + (displayedG << 8) + displayedB).toString(16).slice(1);

    return displayedHex;
}

export const translateFieldType = (type) => {
  switch (type) {
    case 'text': return 'text';
    case 'number': return 'number';
    case 'email': return 'email';
    case 'date': return 'date';
    case 'enum': return 'select';
    case 'list': return 'list';
    case 'bool': return 'checkbox';
    case 'image_file': return 'image';
    case 'file': return 'file';
    case 'url': return 'url';
    case 'large_text_field': return 'rich text';
    case 'phone_number': return 'phone';
    case 'required': return 'required'; // Not a field type but used to show the primary email and name fields when selecting columns
    case 'status': return 'status'; // Not a field type but used to show the contact status when selecting columns
    case 'tags': return 'tags'; // Not a field type but used to show the contact tags when selecting columns

    default:
      break;
  }
}

export const timeDifference = (previous) => {

  var msPerSecond = 1000;
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerWeek = msPerDay * 7;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var now = new Date();

  // This statement will be used if the date being compared is in the past
  if (now > previous) {
    var elapsed = now - previous;

    if (elapsed < msPerMinute) {
      return ' ';   
    }
    
    else if (elapsed < msPerMinute) {
      return Math.round(elapsed/1000) + `${Math.round(elapsed/1000) == 1 ? ' second ago' : ' seconds ago'}`;
    }
  
    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + `${Math.round(elapsed/msPerMinute) == 1 ? ' minute ago' : ' minutes ago'}`;
    }
  
    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + `${Math.round(elapsed/msPerHour) == 1 ? ' hour ago' : ' hours ago'}`;
    }
  
    else if (elapsed < msPerMonth) {
        return ' ' + Math.round(elapsed/msPerDay) + `${Math.round(elapsed/msPerDay) == 1 ? ' day ago' : ' days ago'}`;
    }
  
    else if (elapsed < msPerYear) {
        return ' ' + Math.round(elapsed/msPerMonth) + `${Math.round(elapsed/msPerMonth) == 1 ? ' month ago' : ' months ago'}`;
    }
  
    else {
        return ' ' + Math.round(elapsed/msPerYear ) + `${Math.round(elapsed/msPerYear) == 1 ? ' year ago' : ' years ago'}`;
    }

  }

  // This statement will be used if the date being compared is in the future
  if (previous > now) {
    var elapsed = previous - now;

    if (elapsed < msPerMinute) {
      return ' ';   
    }
    
    else if (elapsed < msPerMinute) {
      return Math.round(elapsed/1000) + `${Math.round(elapsed/1000) == 1 ? ' second away' : ' seconds away'}`;
    }
  
    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + `${Math.round(elapsed/msPerMinute) == 1 ? ' minute away' : ' minutes away'}`;;   
    }
  
    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + `${Math.round(elapsed/msPerHour) == 1 ? ' hour away' : ' hours away'}`;   
    }
  
    else if (elapsed < msPerMonth) {
        return ' ' + Math.round(elapsed/msPerDay) + `${Math.round(elapsed/msPerDay) == 1 ? ' day away' : ' days away'}`;
    }
  
    else if (elapsed < msPerYear) {
        return ' ' + Math.round(elapsed/msPerMonth) + `${Math.round(elapsed/msPerMonth) == 1 ? ' month away' : ' months away'}`;
    }
  
    else {
        return ' ' + Math.round(elapsed/msPerYear ) + `${Math.round(elapsed/msPerYear) == 1 ? ' year away' : ' years away'}`;
    }


  }


}

export const timeUntilDue = (dueDate, measurement) => {

  var msPerSecond = 1000;
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerWeek = msPerDay * 7;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var now = new Date();

  if (now > dueDate) {
    return 0;
  }

  var elapsed = dueDate - now;
  if (measurement === 'seconds') {
    return Math.round(elapsed/msPerSecond);
  }

  if (measurement === 'minutes') {
    return Math.round(elapsed/msPerMinute);
  }
  
  if (measurement === 'hours') {
    return Math.round(elapsed/msPerHour);
  }
  
  if (measurement === 'days') {
    return Math.round(elapsed/msPerDay);
  }
  
  if (measurement === 'weeks') {
    return Math.round(elapsed/msPerWeek);
  }

  if (measurement === 'months') {
    return Math.round(elapsed/msPerMonth);
  }

  if (measurement === 'years') {
    return Math.round(elapsed/msPerYear);
  }

}

export const formatApiResponse = (response) => {
  const uniqueValuesMap = {};
  
  // Iterate over the data object
  for (const key in response.data) {
    const value = response.data[key];
    
    // Check if the value is already in the uniqueValuesMap
    if (uniqueValuesMap[value]) {
      // Add the key to the existing value's list of keys
      uniqueValuesMap[value].push(key);
    } else {
      // Initialize a new list of keys for this value
      uniqueValuesMap[value] = [key];
    }
  }
  
  // Create an array of formatted strings for each unique value
  const formattedValues = Object.entries(uniqueValuesMap).map(([value, keys]) => {
    const keysList = keys.join(', ');
    return `${value} (${keysList})`;
  });
  
  return formattedValues;
}

export const generateContactName = (fields, name, customFields, replaceExisting) => {
  if (name && !replaceExisting) {
    return name;
  }

  const contactNameFieldMappings = {
      fullName: ['name', 'full', 'full name', 'full_name', 'fullname', 'namefull', 'name_full'],
      firstName: ['first', 'first name', 'first_name', 'firstname', 'fname', 'f name', 'f_name', 'given_name', 'givenname', 'forename', 'fore_name'],
      middleName: ['middle', 'middle_name', 'middle name', 'middlename', 'mname', 'm name', 'm_name', 'second_name', 'secondname', 'additionalname', 'additional_name'],
      lastName: ['last', 'last_name', 'last name', 'lastname', 'lname', 'l_name', 'family name', 'family_name', 'familyname', 'surname', 'sur_name'],
      suffix: ['sfx', 'suffix', 'name_suffix', 'namesuffix', 'suffix_name', 'suffix name', 'suffixname', 'nameending', 'name_ending']
  };
  let fieldIds = {fullName: '', firstName: '', middleName: '', lastName: '', suffix: ''};

  // Find ids of custom fields that match contactNameFieldMappings
  customFields.forEach(item => {
      const fieldName = item.field_name.trim().toLowerCase();
      if (contactNameFieldMappings.fullName.includes(fieldName)) {
        fieldIds.fullName = item.id;
      }
      if (contactNameFieldMappings.firstName.includes(fieldName)) {
        fieldIds.firstName = item.id;
      }
      if (contactNameFieldMappings.middleName.includes(fieldName)) {
        fieldIds.middleName = item.id;
      }
      if (contactNameFieldMappings.lastName.includes(fieldName)) {
        fieldIds.lastName = item.id;
      }
      if (contactNameFieldMappings.suffix.includes(fieldName)) {
        fieldIds.suffix = item.id;
      }
  });

  // Translate to the actual name values
  const fieldValues = {
    fullName: fields[fieldIds.fullName] || '',
    firstName: fields[fieldIds.firstName] || '',
    middleName: fields[fieldIds.middleName] || '',
    lastName: fields[fieldIds.lastName] || '',
    suffix: fields[fieldIds.suffix] || ''
  };

  // Generate a full name from existing fields
  const generatedNames = [
    fieldValues.firstName.trim(),
    fieldValues.middleName.trim(),
    fieldValues.lastName.trim(),
    fieldValues.suffix.trim()
  ].filter(Boolean).join(' ');

  return fieldValues.fullName ? fieldValues.fullName.trim() : generatedNames;
};

export const roundToNearest = (number, decimalPlaces) => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(number * factor) / factor;
}