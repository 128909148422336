import React, { useState, useEffect } from 'react';
import { CameraSVG, CloseFilledSVG, Column1SVG, Column2SVG, Column3SVG, FlipSVG } from '../../assets/icons';
import { Button, Collapse, Dialog, Divider, Grid, IconButton, Tab, Tabs, Typography } from '@mui/material';
import FileDropZone from '../ui/FileDropZone';
import { createStorageFile, deleteStorageFile, putTenantDataBag, putStorageFile, useGetTenantDataBag, useGetPublicFolder, getAllPublicStorageFileUploads } from '../../hooks/mutations';
import { ImageField } from '../ui/Fields';
import { useApi } from '../../context/apiContext';
import { useTheme } from '@emotion/react';
import DialogUpload from '../ui/DialogUpload';
import ColorPicker from '../ui/ColorPicker';

// Define the initial text styles
const TEXT_STYLES = ['normal', 'italic', 'oblique'];
const TEXT_ALIGN = ['left', 'center', 'right', 'justify'];
const TEXT_DECORATION = ['none', 'underline', 'line-through', 'overline'];
const FONT_WEIGHTS = ['normal', 'bold', 'bolder', 'lighter'];

const BodyEditor = ({ onBodyStyleChange, body, onRootStyleChange, root }) => {

  // Handle change for the body style fields
  const handleBodyStyleChange = (field, value) => {
    onBodyStyleChange({[field]: value});
  };

  // Handle change for the root style fields
  const handleRootStyleChange = (field, value) => {
    onRootStyleChange({[field]: value});
  };

  return (
    <div className="EmailTemplate-text-editor">

      <p>Select an element to edit</p>

      <h4>Edit Page</h4>
      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Background</label>
        </div>
        <div className="EmailTemplate-editor-field-value-col-2">
          <ColorPicker
            value={root?.style?.backgroundColor || '#ffffff'}
            onChange={ (color) => handleRootStyleChange('backgroundColor', color.hex) }
          />
        </div>
        <div className="EmailTemplate-editor-field-value-col-3">
          <button
            className="EmailTemplate EmailTemplate-invisible"
            onClick={() => handleRootStyleChange('backgroundColor', 'transparent')}
          >
            <CloseFilledSVG width={16} />
          </button>
        </div>
      </div>

      <h4>Edit Body</h4>
      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Background</label>
        </div>
        <div className="EmailTemplate-editor-field-value-col-2">
          <ColorPicker
            value={body?.style?.backgroundColor || '#ffffff'}
            onChange={ (color) => handleBodyStyleChange('backgroundColor', color.hex) }
          />
        </div>
        <div className="EmailTemplate-editor-field-value-col-3">
          <button
            className="EmailTemplate EmailTemplate-invisible"
            onClick={() => handleBodyStyleChange('backgroundColor', 'transparent')}
          >
            <CloseFilledSVG width={16} />
          </button>
        </div>
      </div>

      {/* <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Width (px)</label>
        </div>
        <div className="EmailTemplate-editor-field-value-col-2">
          <input
            className="EmailTemplate-editor-field-input input-no-padding-block"
            type="number"
            value={body?.style?.maxWidth || 600}
            onChange={(e) => handleBodyStyleChange('maxWidth', Number(e.target.value))}
            min={0}
            max={1000}
          />
        </div>
        <div className="EmailTemplate-editor-field-value-col-3">
          <button
            className="EmailTemplate-invisible"
            onClick={() => handleBodyStyleChange('maxWidth', Number(600))}
          >
            <CloseFilledSVG width={16} />
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default BodyEditor;
