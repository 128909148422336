import {
  Grid,
  Typography,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionActions,
  AccordionDetails,
} from "@mui/material";
import { useEffect } from "react";
import { hashScroll } from "../../lib/Functions";

const SectionHeader = (props) => {
  const { children, actions, title, id, subSummary, button, expanded, setExpanded, infotip, scroll, scrollOffset=0 } = props;

  return (
    <Accordion
      id={id ? id : title}
      disableGutters
      expanded={expanded}
      onChange={() => {
        setExpanded(prev => !prev);
        if (!expanded && scroll) {
          setTimeout(() => {
            hashScroll((id ? id : title), scrollOffset);
          }, 500);
        }
      }}
      sx={{
        width:'100%',
        marginBottom:2,
        '&&.MuiPaper-root': {
          boxShadow:`0 0 5px #00000030`,
          background:'#FFFFFF77',
          borderRadius:2
        }
      }}
    >
      {/* <Divider style={{marginTop:16, width:'100%'}} /> */}
      <AccordionSummary
        sx={{
        }}
      >
        <Grid
          container
          style={{
            position:'relative',
            padding:'8px 0',
            justifyContent:'center',
          }}
        >
          <Typography
            sx={{
              fontSize:'.85rem',
              // padding:'2px 12px',
              // borderRadius:20,
              fontWeight:600,
              display:'flex',
              alignItems:'center',
              gap:1
              // background:'#ddd'
            }}
          >
            {title}
            {infotip}
          </Typography>
          <div
            style={{
              position:'absolute',
              right:0
            }}
          >
            {button}
          </div>
        <Grid container>
          <Divider style={{marginTop: expanded ? 16 : 0, width: '100%', opacity: expanded ? 1 : 0, transition:'.2s'}} />
        </Grid>
        {expanded && subSummary}
        </Grid>

      </AccordionSummary>
      <AccordionActions disableSpacing disableGutters sx={{padding:0}}>
        {actions}
      </AccordionActions>
      <AccordionDetails disableSpacing disableGutters sx={{pt:0}}>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}

export default SectionHeader