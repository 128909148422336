import { createContext, useContext, useEffect, useState } from "react";

const tempContext = createContext();

export const useTemp = () => {
  return useContext(tempContext);
}

export const TempProvider = ({children}) => {
  // TEMP STATE
  const [isNavCollapsed, setIsNavCollapsed] = useState(false);
  const [isPreferencesOpened, setIsPreferencesOpened] = useState(false);
  const [preferenceSetting, setPreferenceSetting] = useState(false);
  const [tempNodes, setTempNodes] = useState(null);
  const [tempEdges, setTempEdges] = useState(null);
  const [tempSurvey, setTempSurvey] = useState(null);

  // GET COLOR MODE FROM LOCAL STORAGE ON PAGE LOAD / REFRESH / NEW TAB / ETC
  useEffect(() => {
    getIsNavCollapsedItem();
  }, [])

  // GET COLOR MODE FROM LOCAL STORAGE
  const getIsNavCollapsedItem = async () => {
    // Set custom fields state
    try {
      const navCollapsedValue = localStorage.getItem('nav-collapsed');
      if (navCollapsedValue) {
        setIsNavCollapsed(JSON.parse(navCollapsedValue));
      }

    } catch (error) {
        // This code runs if there were any errors.
        console.log('Error setting nav collapsed state:', error);
    }

  }


  return (
    <>
      <tempContext.Provider
        value={{
          isNavCollapsed,
          setIsNavCollapsed,
          isPreferencesOpened,
          setIsPreferencesOpened,
          preferenceSetting,
          setPreferenceSetting,
          tempNodes,
          setTempNodes,
          tempEdges,
          setTempEdges,
          tempSurvey,
          setTempSurvey
        }}
      >
        {children}
      </tempContext.Provider>
    </>
  )
}