import React, { cloneElement, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Collapse,
  useScrollTrigger,
  // Accordion,
  // AccordionSummary,
  // AccordionDetails,
  LinearProgress,
  CircularProgress,
  styled,
  Button,
  Tabs,
  Tab,
  Divider,
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle,
} from "@mui/material";
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { useTheme } from "@mui/system";
import { AddRoundedSVG, AddSVG, ChevronSVG, CloseFilledSVG, CloseRoundedSVG, CopySVG, DeleteSVG, EditSVG, EmailSentSVG, OpenInNewSVG, SubscribeListSVG, UnsubscribeListSVG, UpCaretSVG } from '../../assets/icons';
import { ButtonCustom } from '../ui/Buttons';
import ReusableSearch from '../ui/ReusableSearch';
import { useScreenSize } from '../../lib/Interface';
import { createStorageFile, getCustomFieldFolder, getFilteredCampaigns, putContact, useGetCampaigns, useGetContactActivity, useGetFilteredCampaigns, useGetSurveys } from '../../hooks/mutations';
import ImportCustomFields from '../custom_fields/ImportCustomFields';
import { InputField, InputFieldType } from '../ui/Fields';
import { useGetContact } from '../../hooks/mutations'
import { copyString, generateContactName } from '../../lib/Functions';
import ImportTags from '../tags/ImportTags';
import { Tag, TagButton } from '../ui/Tags';
import DialogWarning from '../ui/DialogWarning';
import { activitiesData, contactStatusEnum, status } from '../../lib/Data';
import { useParams } from 'react-router-dom';
import { DjangoComponents } from '../../templates/DjangoStyles';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { useApi } from '../../context/apiContext';
import ReusableModalTooltip from '../ui/ReusableModalTooltip'

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const triggerOnScrollElement = document.getElementById('drawer-contents');
  const screenSize = useScreenSize();
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
    target: triggerOnScrollElement ? triggerOnScrollElement : undefined,
  });

  return cloneElement(children, {
    style: {
      position: 'sticky',
      top: 0,
      padding: screenSize.xs ? '0 8px 12px 8px' : '0 32px 12px 32px',
      paddingBottom: 16,
      background: trigger ? '#fff' : 'transparent',
      zIndex: 1000,
      boxShadow: trigger ? '0 4px 5px #00000030' : 'none',
      transition: 'box-shadow .5s'
    }

  });
}

// ACCORDION CUSTOM STYLED COMPONENTS
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px 0 1px 0 solid ${theme.palette.divider}`,
  // '&:not(:last-child)': {
  //   borderBottom: 0,
  // },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<UpCaretSVG width={20} color1="#ccc" style={{transform:'rotate(180deg)'}} />} 
    {...props}
  />
))(({ theme }) => ({
  borderBottom: '1px solid rgba(0, 0, 0, .125)',
  // backgroundColor:
  //   theme.palette.mode === 'dark'
  //     ? 'rgba(255, 255, 255, .05)'
  //     : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(-90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',

}));


// TAB CUSTOM STYLED COMPONENTS
const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        width:'100%',
        height:'100%',
        overflowY:'auto'
      }}
      {...other}
    >
      {value === index && (
        children
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const ContactDetailsLayout1 = (props) => {
  const { customFields, setReloadCustomFields, incomingContact, updateContacts, reloadContact, setReload } = props;
  // const [reload, setReload] = useState(false);
  // const receivedContact = useGetContact(1, reload);
  // const customFields = useGetCustomFields();
  // const tags = useGetTags();
  const apiContext = useApi();
  const userDataBagContext = apiContext.contextUserDataBag;
  const screenSize = useScreenSize();
  const theme = useTheme();
  const [contact, setContact] = useState(null);
  const [isAddingField, setIsAddingField] = useState(false);
  // const [isAddingTag, setIsAddingTag] = useState(false);
  const [resetSearch, setResetSearch] = useState(true);
  const [editing, setEditing] = useState(null);
  const [editTitle, setEditTitle] = useState(null);
  const [fieldToRemove, setFieldToRemove] = useState(null);
  const [openWarning, setOpenWarning] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [snackMessage, setSnackMessage] = useState(null);
  const [openSideRight, setOpenSideRight] = useState(true);
  const [openSideLeft, setOpenSideLeft] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [isSaved, setIsSaved] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const currentEnv = process.env.REACT_APP_CURRENT_ENV;
  const { reactContactId } = useParams();
  const [contactId, setContactId] = useState(currentEnv === "STAGING" ? (reactContactId ? reactContactId : 5) : null);
  const [initialContact, setInitialContact] = useState(null);

  // Get campaigns, and surveys
  // const campaigns = getFilteredCampaigns({ pageId:1, filterBy:{mailing_lists: } });
  // const surveys = useGetSurveys();
  const activities = useGetContactActivity(incomingContact.id)?.activities.sort((a, b) => {
    if(new Date(a.date) < new Date(b.date)) return 1;
    if(new Date(a.date) > new Date(b.date)) return -1;
    return 0;
  });

  // console.log('contact', contact);
  console.log('activities', activities?.map(x => x.date))
  // console.log('customFields', customFields);

  useEffect(() => {
    if (contact) {
      let timeoutId;
      let savingId;
  
      clearTimeout(timeoutId);
      clearTimeout(savingId);
      setIsSaved(false);
  
      timeoutId = setTimeout(() => {
        if (JSON.stringify(contact) !== JSON.stringify(initialContact)) {
          onSubmit(contact);
        }
      }, 1000);
  
      savingId = setTimeout(() => {
        setIsSaving(true);
      }, 500);
  
      return () => {
        clearTimeout(timeoutId);
        clearTimeout(savingId);
      };
    }
  }, [contact]);



  // Set contact from api response 'incomingContact' state
  useEffect(() => {
    if (incomingContact) {
      setContact(incomingContact);
      setInitialContact(JSON.parse(JSON.stringify(incomingContact))); // Store the initial contact data
    }
  }, [incomingContact]);

  // Open warning dialog when a field is selected to remove
  useEffect(() => {
    if (fieldToRemove) {
      setOpenWarning(true);
    }
  }, [fieldToRemove]);

  useEffect(() => {
    const fetchCampaignLists = async () => {
      if (contact?.mailing_lists.length) {
        const campaignLists = await retrieveMailingLists2();
        console.log('campaign lists', campaignLists);
        setCampaigns(campaignLists);
      }
    };

    fetchCampaignLists();

  }, [contact?.mailing_lists])

  const retrieveMailingLists = async () => {
    let campaignLists = [];
    for (const list of contact.mailing_lists) {
      console.log('list', list.id);
      const newList = await getFilteredCampaigns({ pageId: 1, filterBy: { mailing_lists: list.id } });
      campaignLists.push(newList);
    }
    console.log('campaignLists', campaignLists);
    return campaignLists;
  }

  const retrieveMailingLists2 = async () => {
    const campaignListIds = contact.mailing_lists.map(x => x.id);
    console.log('campaing list ids', campaignListIds);
    const newList = await getFilteredCampaigns({ pageId: 1, filterBy: { mailing_lists: campaignListIds } });
    console.log('newList', newList);
    return newList.data.results;
  }

  // // Set contact from api response 'intialContact' state
  // useEffect(() => {
  //   // Use if passing the whole contact in
  //   if (receivedContact) {
  //     setContact(receivedContact)
  //   }

  // }, [receivedContact])

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const onSubmit = async (contact) => {
    const { tags = [], id, ...rest } = contact;
    const existingTagNames = tags.map(x => x?.name);
  
    const contactObj = {
      contactId: id,
      payload: {
        ...rest,
        tags: existingTagNames,
      }
    };
  
    // Clone the original contact object for comparison
    const originalContact = JSON.parse(JSON.stringify(contact.custom_fields));
    console.log('originalContact', originalContact);
    try {
      const updatedContact = await putContact(contactObj);
      console.log('updatedContact', updatedContact);
      if (updatedContact.status === 'success') {
        const updatedTagNames = updatedContact.data.tags.map(x => x?.name);
        // Check if there is a change in any property
        const contactChanged = JSON.stringify(originalContact) !== JSON.stringify(updatedContact.data);
        if (contactChanged) {
          console.log('Successfully updated contact');
          setSnackMessage({open: true, message: 'Successfully updated contact', severity: 'success'});
        }
        if (updateContacts) {
          updateContacts(updatedContact.data);
        }
        setInitialContact(JSON.parse(JSON.stringify(updatedContact.data))); // Update the initial contact state
      } else if (updatedContact.status === 'error') {
        console.error('Error updating contact');
        setSnackMessage({open: true, message: updatedContact.error, severity: 'error'});
      }
    } catch (error) {
      console.error('Error during update:', error);
      setSnackMessage({open: true, message: 'An unexpected error occurred', severity: 'error'});
    }
  };
  
  return (
    contact
      ?
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',
          minHeight: 'calc(100vh - 60px)',
        }}
      >
        <Grid
          container
          direction="row"
          // alignItems="center"
          spacing={2}
          // columns={14}
          padding="16px"
        >
          <Grid
            item
            style={{
              width: screenSize.xs
                ? '100%'
                : screenSize.md
                  ? 200
                  : 250
            }}
          >
            {/* Unit 1: Profile */}
            <DjangoComponents card padding>
              <Grid
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                style={{
                  height: 'calc(100vh - (92px + 2.5rem))',
                  position: 'relative'
                }}
              > {/* Unit 1-1: Bio */}
                <Grid
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                      borderRadius: '50%',
                      width: '50%',
                      aspectRatio: '1/1',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '1rem',
                      marginBottom: '1rem'
                    }}
                  >
                    <Typography variant="h3">
                      {nameInitial(contact.name)}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Typography variant="h6">
                      {contact.name}
                    </Typography>
                  </Grid>
                  {
                    // Business name not found in contact object
                    // <Grid>
                    //   <Typography>Business Name</Typography>
                    // </Grid>
                  }
                  <Grid>
                    <Typography
                      style={{
                        wordBreak:'break-word'
                      }}
                    >
                      {contact.primary_email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  display="flex"
                  flexDirection="column"
                  gap={4}
                > {/* Unit 1-2: Stats */}
                  <Grid 
                    style={{
                      // position: 'absolute',
                      // bottom: '20px',
                      // left: '50%',
                      // transform: 'translateX(-50%)',
                      // width: '90%',
                      // maxHeight: 'auto',
                    }}
                  >
                    <Typography
                      style={{
                        marginBottom: '0.25rem',
                        fontWeight: '500'
                      }}
                    >
                      Click-Through Rate
                    </Typography>
                    <Grid 
                      position="relative"
                      style={{
                        marginBottom: '1.25rem'
                      }}
                    >
                      <Typography
                        style={{
                          position: 'absolute',
                          top: '50%',
                          transform: "translateY(-50%)",
                          left: '0.5rem',
                          zIndex: '2',
                          fontWeight: 700
                        }}
                      >
                        {`${contact.click_rate}%`}
                      </Typography>
                      <LinearProgress sx={{
                        backgroundColor: `${theme.palette.light.light}`,
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: `${theme.palette.light.dark}`
                        }
                      }} style={{
                        height: '35px',
                        borderRadius: '10px',
                      }}
                        variant="determinate" value={contact.click_rate} /> {/* contact.click_rate */}
                    </Grid>
                    <Typography
                      style={{
                        marginBottom: '0.25rem',
                        fontWeight: '500'
                      }}
                    >
                      Open Rate
                    </Typography>
                    <Grid 
                      position="relative"
                      style={{
                        marginBottom: '1.25rem'
                      }}
                    >
                      <Typography
                        style={{
                          marginBottom: 1,
                          position: 'absolute',
                          top: '50%',
                          transform: "translateY(-50%)",
                          left: '0.5rem',
                          zIndex: '2',
                          fontWeight: 700
                        }}
                      >
                        {`${contact.open_rate}%`} {/*contact.open_rate*/}
                      </Typography>
                      <LinearProgress 
                        sx={{
                          backgroundColor: `${theme.palette.light.light}`,
                        '& .MuiLinearProgress-bar': {
                          backgroundColor: `${theme.palette.light.dark}`
                        }
                      }} style={{
                        height: '35px',
                        // marginBottom: '180px',
                        borderRadius: '10px',
                      }}
                        variant="determinate" value={contact.open_rate} /> {/*contact.open_rate*/}

                    </Grid>
                    <Typography 
                      style={{
                      // position: 'fixed',
                      // left: '0',
                      // top: '130px',
                        fontWeight: '500',
                        marginBottom: '0.25rem'
                      }}
                    >
                      Quality Score
                    </Typography>
                    <Box sx={{ position: 'relative' }}>
                      <CircularProgress 
                        variant="determinate" 
                        value={100} 
                        size={160}
                        thickness={10} 
                        style={{
                          position: 'relative',
                          left: 'calc(50% - 80px)',
                          // bottom: '0',
                          // transform: 'translateX(-50%)',
                          // zIndex: '1',
                          color: `${theme.palette.primary.accent}`,
                          accent: theme.palette.primary.main
                        }} 
                      />
                      <CircularProgress 
                        variant="determinate" 
                        value={contact.quality_score} 
                        size={160} 
                        thickness={10} 
                        style={{
                          position: 'absolute',
                          top:0,
                          left: 'calc(50% - 80px)',
                          // bottom: '0',
                          // transform: 'translateX(-50%)',
                          zIndex: '2',
                          color: `${theme.palette.primary.dark}`,
                          accent: theme.palette.primary.dark
                        }} /> {/* contact.quality_score */}
                      <Typography
                        variant="caption"
                        component="div"
                        color="black"
                        fontWeight="bold"
                        fontSize="larger"
                        style={{
                          position: 'absolute',
                          left: '50%',
                          bottom: '50%',
                          transform: 'translateX(-50%) translateY(50%)',
                          zIndex: '4',
                        }}
                      >{`${contact.quality_score}%`}</Typography> {/* contact.quality_score */}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </DjangoComponents>
          </Grid>

          <Grid
            item
            style={{
              width: screenSize.xs
                ? '100%'
                : 
                screenSize.md
                  ? 'calc(100% - 500px)'
                  : 'calc(100% - 650px)'
            }}
            xs={12}
            sm
          >
              {/* <Grid>
                <DjangoComponents card padding>
                  <Grid
                    overflowY="scroll"
                    style={{
                      height: 'calc((100vh - (92px + 2.5rem)) / 2 - 1.75rem)'
                    }}
                  >
                    <Typography variant='h6'>Activity</Typography>
                    <Typography>Activity Endpoints</Typography>
                  </Grid>
                </DjangoComponents>
              </Grid> */}
              <DjangoComponents card>
                <Grid 
                  overflowY="auto"
                  style={{
                    height: 'calc(100vh - (92px))',
                    padding:'1.25rem 0 3.0rem 0'
                  }}
                >
                  <Grid
                    sx={{
                      borderBottom:1,
                      borderColor:'divider',
                      width:'calc(100% - 2.5rem)',
                      margin: '0 1.25rem'
                    }}
                  >
                    <Tabs value={tabValue} onChange={handleChange} aria-label="Contact Tabs">
                      <Tab label="Activity" {...a11yProps(0)} />
                      <Tab label="Fields" {...a11yProps(1)} />
                      <Tab label="Notes" {...a11yProps(2)} />
                    </Tabs>
                  </Grid>
                  <CustomTabPanel value={tabValue} index={0}>
                    <ActivityTab
                      activities={activities}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={tabValue} index={1} style={{height:'100%'}}>
                    <FieldsTab
                      contact={contact}
                      setContact={setContact}
                      customFields={customFields}
                      setReloadCustomFields={setReloadCustomFields}
                      userDataBagContext={userDataBagContext}
                      onSubmit={onSubmit}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={tabValue} index={2}>
                    <NotesTab
                      contact={contact}
                      setContact={setContact}
                      onSubmit={onSubmit}
                    />
                  </CustomTabPanel>


                </Grid>
              </DjangoComponents>
              {/* <Grid>
                <DjangoComponents card padding>
                  <Grid
                    style={{
                      height: 'calc((100vh - (92px + 2.5rem)) / 2 - 1.75rem)'
                    }}
                  >
                    <Typography variant='h6'>Fields</Typography>
                    {
                      // CUSTOM FIELD ADDING
                      <Grid
                        container
                        style={{
                          marginTop: 8
                        }}
                      >
                        <TagButton
                          bgColor={`${theme.palette.primary.main}20`}
                          fontColor={theme.palette.primary.main}
                          outlined
                          onClick={handleIsAddingField}
                          iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition: '.5s' }} />}
                          label={isAddingField ? 'Close' : (screenSize.sm ? 'Field' : "Add Field")}
                        />
                      </Grid>
                    }
                    <Collapse
                      in={isAddingField}
                      style={{
                        width: '100%',
                        marginTop: isAddingField ? 16 : 0,
                        transition: '.75s',
                        overflow: 'scroll',
                      }}
                    >
                      <ImportCustomFields
                        setIsOpen={setIsAddingField}
                        existingCustomFields={Object.keys(contact.custom_fields)}
                        handleFieldImport={handleFieldImport}
                        handleImportAllFields={handleImportAllFields}
                        setEditing={setEditing}
                        handleOpen={setIsAddingField}
                      />
                    </Collapse>
                    <TableWrapper>
                      {
                        contact
                          ?
                          <>

                            {
                              Object.entries(contact.custom_fields).map(([key, value], index) => {
                                // const fieldIdArr = customFields.map(x => x.id);
                                const fieldId = customFields?.filter(x => x.id == key)[0]?.id;
                                if (fieldId) {
                                  return (
                                    <TableRow
                                      contactId={contact.id}
                                      customFields={customFields}
                                      customField={{ key: fieldId, value: value }}
                                      index={index}
                                      handleTextCustomField={handleTextCustomField}
                                      handleListCustomField={handleListCustomField}
                                      handleNumberCustomField={handleNumberCustomField}
                                      handleDateCustomField={handleDateCustomField}
                                      handleBoolCustomField={handleBoolCustomField}
                                      handleImageCustomField={handleImageCustomField}
                                      handleFileCustomField={handleFileCustomField}
                                      handleRichTextCustomField={handleRichTextCustomField}
                                      confirmFieldRemove={confirmFieldRemove}
                                      setReloadCustomFields={setReloadCustomFields}
                                      showDelete
                                      setEditing={setEditing}
                                      active={editing === key}
                                    />
                                  )
                                }
                              })
                            }
                          </>
                          :
                          <Typography>No custom fields have been created yet.</Typography>
                      }
                    </TableWrapper>

                  </Grid>
                </DjangoComponents>
              </Grid> */}
          </Grid>

          {
            openSideRight
              ?
                <Grid
                  item
                  style={{
                    width: screenSize.md
                      ? '100%'
                      : screenSize.lg
                        ? 300
                        : 400
                  }}
                  // md={4}
                >
                  <DjangoComponents card padding>
                    <div
                      onClick={() => setOpenSideRight(false)}
                      style={{
                        position:'absolute',
                        top:'.5rem',
                        right:'.5rem',
                        zIndex:100
                      }}
                    >
                      <UpCaretSVG width={20} height={20} style={{transform: openSideRight ? 'rotate(90deg)' : 'rotate(-90deg)', transition:'.4s'}} />
                    </div>
                  {/* Unit 4: Lists */}
                    <Grid 
                      flexDirection="column"
                      overflowY="scroll"
                      style={{
                        height: '100%' // 'calc(100vh - (92px + 2.5rem))',
                      }}
                    >
                        <Grid
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <MailingListAccordion
                            title="Mailing Lists"
                            map={contact.mailing_lists}
                          />
                          <CampaignAccordion
                            title="Campaigns"
                            map={campaigns}
                          />
                          {/* <Accordion
                            style={{
                              borderLeft: 'none',
                            }}
                          >
                            <AccordionSummary 
                              expandIcon={<UpCaretSVG width={20} color1="#ccc" style={{transform:'rotate(180deg)'}} />} 
                              id="panel-header" 
                              aria-controls="panel-content"
                              style={{
                                borderLeft: 'none',
                              }}>
                              <Typography style={{ fontWeight: 700 }}>Mailing Lists</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {
                                contact.mailing_lists.map(list => (
                                  <Grid
                                    container
                                    style={{
                                      justifyContent:'space-between',
                                      alignItems:'center'
                                    }}
                                  >
                                    <Typography>{list.name}</Typography>
                                    <Button
                                      href={`/lists/${list.id}`}
                                    >
                                      View
                                    </Button>
                                  </Grid>
                                ))
                              }
                            </AccordionDetails>
                          </Accordion>
                          <Accordion>
                            <AccordionSummary expandIcon={<UpCaretSVG width={20} color1="#ccc" style={{transform:'rotate(180deg)'}} />} id="panel-header" aria-controls="panel-content">
                              <Typography style={{ fontWeight: 700 }}>Campaigns</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {
                                [{name:'Test campaign 1'}, {name:'Test campaign 2'}].map(campaign => (
                                  <Grid
                                    container
                                    style={{
                                      justifyContent:'space-between',
                                      alignItems:'center'
                                    }}
                                  >
                                    <Typography>{campaign.name}</Typography>
                                    <Button
                                      href={`/campaigns/${campaign.id}`}
                                    >
                                      View
                                    </Button>
                                  </Grid>
                                ))
                              }
                            </AccordionDetails>
                          </Accordion>
                          <Accordion>
                            <AccordionSummary expandIcon={<UpCaretSVG width={20} color1="#ccc" style={{transform:'rotate(180deg)'}} />} id="panel-header" aria-controls="panel-content">
                              <Typography style={{ fontWeight: 700 }}>Surveys</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              {
                                [{name:'Test survey 1'}, {name:'Test survey 2'}].map(survey => (
                                  <Grid
                                    container
                                    style={{
                                      justifyContent:'space-between',
                                      alignItems:'center'
                                    }}
                                  >
                                    <Typography>{survey.name}</Typography>
                                    <Button
                                      href={`/surveys/${survey.id}`}
                                    >
                                      View
                                    </Button>
                                  </Grid>
                                ))
                              }
                            </AccordionDetails>
                          </Accordion> */}
                          
                        </Grid>

                    </Grid>
                  </DjangoComponents>
                </Grid>
              :
                <Grid
                  item
                  style={{
                    height:'100%',
                    width: screenSize.xs
                      ? '100%'
                      : 'auto'
                  }}
                  // md={4}
                >
                  <DjangoComponents card padding>
                    <div
                      onClick={() => setOpenSideRight(true)}
                      style={{
                        position:'absolute',
                        top:'.5rem',
                        right:'.5rem',
                        zIndex:100
                      }}
                    >
                      <UpCaretSVG width={20} height={20} style={{transform: openSideRight ? 'rotate(90deg)' : 'rotate(-90deg)', transition:'.4s'}} />
                    </div>
                  </DjangoComponents>
                </Grid>

          }

          <SnackbarWrapper
            onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
            notice={snackMessage}
          />

        </Grid>

      </div>
    : null
  )
}

export default ContactDetailsLayout1

const nameInitial = (name) => {
  const nameArray = name.split(' ');
  const firstInitial = nameArray[0].charAt(0);
  return firstInitial.toUpperCase();
}

const getChartData = (data) => {
  return [
    { value: data },
    { value: 10 - data }
  ]
}

const qualityScorePercent = (score) => {
  return (score / 10 * 100) + '%';
}

const TableWrapper = (props) => {
  const { children } = props;
  const screenSize = useScreenSize();
  return (
    <Grid
      container
      style={{
        overflowY: 'auto',
        msOverflowY: 'auto',
        padding: 0 // screenSize.xs ? '16px 8px' : '16px 32px',
        // transition:'.5s'
      }}
    >
      {children}
    </Grid>
  )
}

const TableRow = (props) => {
  const { contactId, customField, index, handleTextCustomField, handleListCustomField, handleNumberCustomField, handleDateCustomField, handleBoolCustomField, handleImageCustomField, handleFileCustomField, handleRichTextCustomField, confirmFieldRemove, showDelete, setEditing, active, customFields, setReloadCustomFields } = props;
  const theme = useTheme();

  return (
    <Grid
      container
      style={{
        // width: showDelete ? 'calc(100% - 50px)' : '100%',
        alignItems: 'center'
      }}
    >
      {
        index !== null && index !== undefined
          ?
          <EditableDisplay
            fieldKey={customField.key}
            fieldValue={customField.value}
            customField={customField}
            contactId={contactId}
            onTextChange={(e) => handleTextCustomField(e.target.value, customField.key)}
            onListChange={(e) => handleListCustomField(e, customField.key)}
            onNumberChange={(e) => handleNumberCustomField(e.target.value, customField.key)}
            onDateChange={(e) => handleDateCustomField(e, customField.key)}
            onBoolChange={(e) => handleBoolCustomField(e.target.checked, customField.key)}
            onImageChange={(e) => handleImageCustomField(e, customField.key)}
            onFileChange={(e) => handleFileCustomField(e, customField.key)}
            onRichTextChange={(e) => handleRichTextCustomField(e, customField.key)}
            active={active}
            setEditing={setEditing}
            showDelete={showDelete}
            onClickRemove={() => confirmFieldRemove(customField)}
            setReloadCustomFields={setReloadCustomFields}
            customFields={customFields}
          />
          :
          <>
            <Grid display="flex" alignItems="center">
              <Typography
                style={{
                  marginLeft: 16,
                  marginRight: 4,
                  fontWeight: 500,
                  color: theme.palette.primary.main,
                }}
              >
                {customField.key}
              </Typography>

              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  copyString(customField.value);
                }}
              >
                <CopySVG width={15} color1={theme.palette.primary.dark} color2={theme.palette.primary.dark} />
              </IconButton>
            </Grid>
            <InputField
              // placeholder='Field Name'
              // label={customField.key}
              value={customField.value}
              onChange={(e) => handleTextCustomField(e.target.value, customField.key)}
              // width="calc(100% - 50px)"
              margin
            />
          </>

      }
      {/* <Grid
        style={{
          width:50,
          margin:'auto',
          textAlign:'center'
        }}
      >
        {
          showDelete &&
            <IconButton
              onClick={() => confirmFieldRemove(customField)}
              style={{
                width:35,
                height:35,
                margin: '0 0 24px 0' // <-- This is needed to match the bottom margin of InputField to properly align vertically
              }}
            >
              <CloseFilledSVG width={25} color1="#ccc" />
            </IconButton>
        }
      </Grid> */}
      {
        // columns.map(item => (
        //   <TableColumn item={item}>
        //     {
        //       item.id === 'data_type' &&
        //         <Grid
        //           style={{
        //             width:50,
        //             display:'flex',
        //             alignItems:'center',
        //             justifyContent:'center',
        //             marginLeft:-10
        //           }}
        //         >
        //           {cloneElement(selectedIcons[customField.data_type], {color1:'#777'})}
        //         </Grid>
        //     }
        //     {
        //       item.id === 'actions' &&
        //         <VisibleSVG width={20} color1="#777" />
        //     }
        //     <Typography style={{ fontSize:'1rem'}}>{customField[item.id]}</Typography>
        //   </TableColumn>
        // ))
      }
    </Grid>
  )
}

const EditableDisplay = (props) => {
  const { contactId, fieldKey, fieldValue, active, onTextChange, onListChange, onNumberChange, onDateChange, onBoolChange, onImageChange, onFileChange, onRichTextChange, setEditing, showDelete, onClickRemove, customField, customFields, setReloadCustomFields } = props;
  const theme = useTheme();
  const [hover, setHover] = useState(false);

  const handleClick = () => {
    if (!active) {
      setEditing(fieldKey);
      setTimeout(() => {
        if (document.getElementById(fieldKey)) {
          document.getElementById(fieldKey).focus();
        }
      }, 0);
    }
  }

  const fieldName = customFields.filter(x => x.id === fieldKey)[0].field_name;
  const fieldType = customFields.filter(x => x.id === fieldKey)[0].data_type;
  const copyable = ['text', 'number', 'email', 'date', 'url', 'large_text_field', 'phone_number'].includes(fieldType);

  return (
    <Grid
      id={`r-${fieldKey}`}
      container
    >
      <Grid
        container
        tabIndex="0"
        justifyContent="space-between"
        onClick={handleClick}
        // onKeyDown={(e) => { // <-- This is handled automatically by the onFocus
        //   if (e.key === "Enter") {
        //     handleClick()
        //   }
        // }}
        onFocus={handleClick}
        style={{
          background: hover ? '#eee' : 'transparent'
        }}
      >
        <Grid display="flex" alignItems="center">
          <Typography
            style={{
              marginLeft: 16,
              marginRight: 4,
              fontWeight: 500,
              color: theme.palette.primary.main,
            }}
          >
            {fieldName}
          </Typography>

          {
            // For dev testing only
            // <Typography>{fieldKey}</Typography>
          }

          {
            copyable &&
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  copyString(fieldValue);
                }}
              >
                <CopySVG width={15} color1={theme.palette.primary.dark} color2={theme.palette.primary.dark} />
              </IconButton>
          }
          {
            // active
            //   ?
            //   <IconButton
            //     onClick={() => setEditing(null)}
            //   >
            //     <CloseRoundedSVG width={15} color1={theme.palette.primary.dark} />
            //   </IconButton>
            //   :
            //   <IconButton
            //   // onClick={() => {
            //   //   setEditing(fieldKey);
            //   //   setTimeout(() => {
            //   //     setFocus();
            //   //   }, 0);
            //   // }}
            //   >
            //     <EditSVG width={15} color1={theme.palette.primary.dark} />
            //   </IconButton>
          }
        </Grid>
        <Grid display="flex" alignItems="center">
          {
            showDelete &&
            <IconButton
              onClick={onClickRemove}
              style={{
                width: 35,
                height: 35,
                // margin: '0 0 24px 0' // <-- This is needed to match the bottom margin of InputField to properly align vertically
              }}
            >
              <DeleteSVG width={12} color1={hover ? "#333" : "#aaa"} />
            </IconButton>
          }
        </Grid>


      </Grid>
      <Grid
        container
        style={{
          padding: '0 8px'
        }}
      >
        <InputFieldType
          id={fieldKey}
          contactId={contactId}
          customField={customField}
          customFields={customFields}
          value={fieldValue}
          onTextChange={onTextChange}
          onListChange={onListChange}
          onNumberChange={onNumberChange}
          onDateChange={onDateChange}
          onBoolChange={onBoolChange}
          onRichTextChange={onRichTextChange}
          onSelectChange={onTextChange}
          onFileChange={onFileChange}
          onImageChange={onImageChange}
          enableOnClick={handleClick}
          setReloadCustomFields={setReloadCustomFields}
          noAutoFocus
          margin
        />

        {/* <InputField
          id={fieldKey}
          // placeholder='Field Name'
          // label={fieldKey}
          value={fieldValue}
          onChange={onChange}
          // readOnly={!active}
          enableOnClick={handleClick}
          // width="calc(100% - 50px)"
          noAutoFocus
          margin
        /> */}
      </Grid>

      {
        // active
        //   ?
        //     <>
        //       <InputField
        //         // placeholder='Field Name'
        //         // label={fieldKey}
        //         value={fieldValue}
        //         onChange={onChange}
        //         // width="calc(100% - 50px)"
        //         margin
        //       />
        //     </>
        //   :
        //     <>
        //       <Typography>{fieldValue}</Typography>
        //     </>
      }
    </Grid>

  )
}

const MiddleCard = () => {

  return (
    <>
      <Grid>
        <DjangoComponents card padding>
          {/* Unit 2: Activity */}
          <Grid
            overflowY="scroll"
            style={{
              height: 'calc((100vh - (92px + 2.5rem)) / 2 - 1.75rem)'
            }}
          >
            <Typography variant='h6'>Activity</Typography>
            <Typography>Activity Endpoints</Typography>
          </Grid>
        </DjangoComponents>
      </Grid>

    </>
  )
}

const ActivityTab = (props) => {
  const { activities } = props;

  return (
    <Grid
      id="activity container"
      // container
      style={{
        // height: 500, //'calc(100% - 150px)',
        padding: '1.25rem',
        marginBottom:0
      }}
    >
        {
          activities
            ?
              activities.map((activity, index) => (
                <Grid
                  container
                  style={{
                    padding:'12px 0',
                    borderBottom:`1px solid #ccc`
                  }}
                >
                  <Grid
                    item
                    sx={{
                      display:'flex'
                    }}
                    xs={8}
                  >
                    {
                      activity.id === 0 &&
                      <>
                      <SubscribeListSVG width={30} color={activitiesData[activity.id].color} style={{marginRight:8}} />
                      <Typography><span style={{fontWeight:700, color:activitiesData[activity.id].color}}>Subscribed</span> to the <span style={{fontWeight:700, color:activitiesData[activity.id].color}}>{activity.mailing_list_name}</span> list.</Typography>
                      </>
                    }
                    {
                      activity.id === 1 &&
                      <>
                      <UnsubscribeListSVG width={30} style={{marginRight:8}} />
                      <Typography><span style={{fontWeight:700}}>{activity.event}</span></Typography>
                      </>
                    }
                    {
                      activity.id === 2 &&
                      <Typography><span style={{fontWeight:700}}>Unsubscribed</span> from the <span style={{fontWeight:700}}>{activity.campaign_name}</span> campaign.</Typography>
                    }
                    {
                      activity.id === 3 &&
                      <>
                      <UnsubscribeListSVG width={30} color={activitiesData[activity.id].color} style={{marginRight:8}} />
                      <Typography><span style={{fontWeight:700, color:activitiesData[activity.id].color}}>Unsubscribed</span> from the <span style={{fontWeight:700, color:activitiesData[activity.id].color}}>{activity.mailing_list_name}</span> list.</Typography>
                      </>
                    }
                    {
                      activity.id === 4 &&
                      <Typography><span style={{fontWeight:700}}>Opened email</span> from the <span style={{fontWeight:700}}>{activity.campaign_name}</span> campaign.</Typography>
                    }
                    {
                      activity.id === 5 &&
                      <>
                      <EmailSentSVG width={30} color={activitiesData[activity.id].color} style={{marginRight:8}} />
                      <Typography><span style={{fontWeight:700, color:activitiesData[activity.id].color}}>Email sent</span> from the <span style={{fontWeight:700, color:activitiesData[activity.id].color}}>{activity.campaign_name}</span> campaign.</Typography>
                      </>
                    }
                    {
                      activity.id === 6 &&
                      <Typography><span style={{fontWeight:700}}>Link clicked</span> from the <span style={{fontWeight:700}}>{activity.campaign_name}</span> campaign.</Typography>
                    }
                    {
                      activity.id === 7 &&
                      <Typography><span style={{fontWeight:700}}>{activity.event}</span></Typography>
                    }
                    {/* {activity.mailing_list_name && <Typography>{activity.mailing_list_name}</Typography>}
                    {activity.campaign_name && <Typography>{activity.campaign_name}</Typography>}
                    <Typography>{activity.event}</Typography> */}
                  </Grid>
                  
                  {
                    // TODO: Localize the activity.date so that it is an accurate time for the end user.
                    // It may be default UTC time now?
                  }
                  <Grid
                    item
                    xs={4}
                  >
                    <Typography>{new Date(activity.date).toLocaleString([], { day:'2-digit', month:'2-digit', year:'2-digit', hour: '2-digit', minute: '2-digit', hour12: true })}</Typography>
                    </Grid>
                </Grid>
              ))
            :
              null
        }
    </Grid>
  )
}

const FieldsTab = (props) => {
  const { contact, setContact, customFields, setReloadCustomFields, userDataBagContext, onSubmit } = props;
  const [updatedContact, setUpdatedContact] = useState(contact);
  const [isAddingField, setIsAddingField] = useState(false);
  const [fieldToRemove, setFieldToRemove] = useState(null);
  const [editing, setEditing] = useState(null);
  const [openWarning, setOpenWarning] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [profileFields, setProfileFields] = useState(contact.custom_fields);
  const [profile, setProfile] = useState(null);

  const theme = useTheme();
  const screenSize = useScreenSize();
  // console.log('customFields', customFields)
  // console.log('profile', profile)
  // console.log('profileFields', profileFields)

  // Update global contact state when updatedContact is updated in this FieldsTab component
  useEffect(() => {
    if (updatedContact && contact) {
      setContact(prev => ({
        ...prev,
        custom_fields: updatedContact.custom_fields
      }))
    }
  }, [updatedContact])

  const handleProfileFields = (incomingProfile) => {
    if (profile?.id === incomingProfile.id) {
      setProfile(null);
      return;
    }
    const newProfileFields = [ ...incomingProfile.order ];
    const copiedCustomFields = { ...contact.custom_fields };
    let updatedFields = {};

    const visibleProfileFields = newProfileFields.filter(x => x.visible);
    //   const filteredItems = copiedCustomFields.filter(item =>
    //     visibleProfileFields.some(x => x.id === item.id)
    //   );
    visibleProfileFields.forEach(field => {
      if (copiedCustomFields[field.id]) {
        updatedFields = { ...updatedFields, [field.id]: copiedCustomFields[field.id] }
      } else {
        updatedFields = { ...updatedFields, [field.id]: '' }
      }
    })

    console.log('updatedFields', updatedFields)
    setProfile(incomingProfile);
    setProfileFields(prev => ({ ...prev, custom_fields: updatedFields }));
  }

  const handleIsAddingField = () => {
    if (isAddingField) {
      setIsAddingField(false)
    } else {
      setIsAddingField(true)
    }
  }

  const handleTextCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    console.log('value', value)
    setUpdatedContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }));
    setProfileFields(updatedCustomFields);
  }

  const handleListCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const copiedFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...copiedFields, [name]: value }
    const currentCustomField = customFields.find(x => x.id == name);
    
    setProfileFields(updatedCustomFields);
    
    if (currentCustomField.enum_values.includes(value)) {
      setUpdatedContact(prev => ({
        ...prev,
        custom_fields: updatedCustomFields
      }))
    } else {
      if (value) {
        // setReloadCustomFields(true);
        setUpdatedContact(prev => ({
          ...prev,
          custom_fields: updatedCustomFields
        }))
      }
    }
  }

  const handleNumberCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: Number(value) }
    setUpdatedContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }));
    setProfileFields(updatedCustomFields);
  }

  const handleDateCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: new Date(value).toLocaleDateString() }
    setUpdatedContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }));
    setProfileFields(updatedCustomFields);
  }

  const handleBoolCustomField = (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    setUpdatedContact(prev => ({
      ...prev,
      custom_fields: updatedCustomFields
    }));
    setProfileFields(updatedCustomFields);
  }

  const handleImageCustomField = async (value, name) => {
    console.log('value', value);
    const fileExists = contact.custom_fields[name];
    console.log('fileExists', fileExists);

    if (!value) {
      const copiedContact = { ...contact };
      const customFields = { ...copiedContact.custom_fields };
      const updatedCustomFields = { ...customFields, [name]: '' };
      setUpdatedContact(prev => ({
        ...prev,
        custom_fields: updatedCustomFields
      }))
      setProfileFields(updatedCustomFields);
      return;
    }

    try {
      // Save file to storage
      const storageFolder = await getCustomFieldFolder(); // Change to 'upload folder' when Gaurav adds that api
      const storageFolderId = storageFolder.folderId;
      const fileName = value.file.name;
      const formData = new FormData();
      formData.append('file', value.file);
      formData.append('name', fileName);
      formData.append('folder', storageFolderId);

      const storageFileObj = {
        folderId: storageFolderId,
        storageFilePayload: formData
      }

      if (fileExists) {
        // Replace the file, keeping the same file id
        // This PUT api call happens in the ImageField component so that the component
        // knows whent the image has been updated and can modify the ui accordingly.
        return;

      } else {
        // Create a new file with a new file id
        const storedImage = await createStorageFile(storageFileObj);
        if (storedImage.status === "success") {
          console.log('storedImage', storedImage.data);

          // Add storage file id to contact image custom field 
          const copiedContact = { ...contact };
          const customFields = { ...copiedContact.custom_fields };
          const updatedCustomFields = { ...customFields, [name]: storedImage.data.id }
          setUpdatedContact(prev => ({
            ...prev,
            custom_fields: updatedCustomFields
          }))
          setProfileFields(updatedCustomFields);
    
          // const updatedContact = { ...copiedContact, custom_fields: updatedCustomFields };
          // const savedContact = await onSubmit(updatedContact);
        }
      }

    }

    catch (error) {

    }
  }

  const handleFileCustomField = async (value, name) => {
    // console.log('value', value);
    const fileExists = contact.custom_fields[name];
    // console.log('fileExists', fileExists);

    try {
      // Save file to storage
      const storageFolder = await getCustomFieldFolder(); // Change to 'upload folder' when Gaurav adds that api
      const storageFolderId = storageFolder.folderId;
      const fileName = value.file.name;
      const formData = new FormData();
      formData.append('file', value.file);
      formData.append('name', fileName);
      formData.append('folder', storageFolderId);

      const storageFileObj = {
        folderId: storageFolderId,
        storageFilePayload: formData
      }

      if (fileExists) {
        // Replace the file, keeping the same file id
        // This PUT api call happens in the ImageField component so that the component
        // knows whent the image has been updated and can modify the ui accordingly.
        return;

      } else {
        // Create a new file with a new file id
        const storedImage = await createStorageFile(storageFileObj);
        if (storedImage.status === "success") {
          // console.log('storedImage', storedImage.data);

          // Add storage file id to contact image custom field 
          const copiedContact = { ...contact };
          const customFields = { ...copiedContact.custom_fields };
          const updatedCustomFields = { ...customFields, [name]: storedImage.data.id }
          const updatedContact = { ...copiedContact, custom_fields: updatedCustomFields };
          setUpdatedContact(prev => ({
            ...prev,
            custom_fields: updatedCustomFields
          }))
          setProfileFields(updatedCustomFields);

          // const savedContact = await onSubmit(updatedContact);
        }
      }
    }

    catch (error) {

    }
  }

  const handleRichTextCustomField = async (value, name) => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };
    const updatedCustomFields = { ...customFields, [name]: value }
    const savedContact = await onSubmit({ ...copiedContact, custom_fields: updatedCustomFields });
    if (savedContact) {
      console.log('success', savedContact);
    }

  }

  const handleImportAllFields = (customFields) => {
    const copiedContact = { ...contact };
    const existingFields = { ...copiedContact.custom_fields };
    // console.log('existingFields', existingFields);
    // console.log('object keys existingFields', Object.keys(existingFields));
    let newFields = { ...existingFields };
    // console.log('newFields', newFields);
    // console.log('customFields', customFields);

    customFields.forEach(field => {
      if (Object.keys(existingFields).includes(String(field.id))) {
        console.log('THAT FIELD ALREADY EXISTS');
      } else {
        newFields = { ...newFields, [field.id]: '' }
      }
    });


    // console.log('NEW newFields', newFields);

    setUpdatedContact(prev => ({
      ...prev,
      custom_fields: newFields
    }))
    setProfileFields(newFields);
  }

  const handleFieldImport = (field) => {
    const newItem = { [field.id]: '' }
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };

    if (Object.keys(customFields).includes(field.id)) {
      console.log('THAT FIELD ALREADY EXISTS');
    } else {
      const updatedCustomFields = { ...customFields, ...newItem }
      setUpdatedContact(prev => ({
        ...prev,
        custom_fields: updatedCustomFields
      }))
      setProfileFields(updatedCustomFields);
    }
  }

  const confirmFieldRemove = (field) => {
    setFieldToRemove(field);
    setOpenWarning(true);
  }

  const handleFieldRemove = () => {
    const copiedContact = { ...contact };
    const customFields = { ...copiedContact.custom_fields };

    // Create a copy of customFields without the specified key
    delete customFields[fieldToRemove.key];

    setUpdatedContact((prev) => ({
      ...prev,
      custom_fields: customFields,
    }));
    setProfileFields(customFields);
    setFieldToRemove(null);
    setOpenWarning(false);
  };

  const handleSave = (updatedCustomFields) => {
    onSubmit({ ...contact, custom_fields: updatedCustomFields });
    // setIsSaved(true);
    // setIsSaving(false);
  }

  // useEffect(() => {
  //   if (loaded) {
  //     let timeoutId;
  //     let savingId;
  
  //     clearTimeout(timeoutId);
  //     clearTimeout(savingId);
  //     setIsSaved(false);
  //     timeoutId = setTimeout(() => {
  //       handleSave(contact.custom_fields);
  //     }, 2000);
  
  //     savingId = setTimeout(() => {
  //       setIsSaving(true);
  //     }, 1000);
  
  //     return () => {
  //       clearTimeout(timeoutId);
  //       clearTimeout(savingId);
  //     };
  //   } else {
  //     setLoaded(true);
  //   }

  // }, [contact.custom_fields])



  return (
    customFields.length
      ?
        <Grid
          style={{
            height: 'calc(100% - 50px)',
            overflowY:'auto',
            padding: '1.25rem'
          }}
        >
          {/* <Typography variant='h6'>Fields</Typography> */}
          {
            // CUSTOM FIELD ADDING
            <Grid
              container
              style={{
                columnGap:8,
              }}
            >
              {
                userDataBagContext?.data?.contactProfiles &&
                  userDataBagContext.data.contactProfiles.map(profileItem => (
                    <TagButton
                      bgColor={profileItem.id === profile?.id
                        ? `${theme.palette.primary.main}99`
                        : `${theme.palette.primary.main}20`
                      }
                      outlinedColor={profileItem.id === profile?.id
                        ? theme.palette.primary.main
                        : theme.palette.primary.main
                      }
                      fontColor={profileItem.id === profile?.id
                        ? '#fff'
                        : theme.palette.primary.main
                      }
                      outlined
                      onClick={() => handleProfileFields(profileItem)}
                      // iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition: '.5s' }} />}
                      label={profileItem.label}
                      active={profileItem.id === profile?.id}
                    />

                  ))
              }
              <TagButton
                bgColor={`${theme.palette.primary.main}20`}
                fontColor={theme.palette.primary.main}
                outlined
                onClick={handleIsAddingField}
                iconStart={<AddSVG width={12} color1={theme.palette.primary.main} style={{ transform: isAddingField ? 'rotate(45deg)' : 'rotate(0deg)', transition: '.5s' }} />}
                label={isAddingField ? 'Close' : (screenSize.sm ? 'Field' : "Add Field")}
              />
              <Divider style={{width:'100%', margin:'1.25rem 0'}}>
                {
                  isAddingField
                    ?
                      <Typography
                        style={{
                          fontSize:'.85rem',
                          fontWeight:500,
                          color:"#aaa",
                          display:'flex',
                          alignItems:'center'
                        }}
                      >
                        ADD CUSTOM FIELDS
                        <IconButton
                          onClick={() => setIsAddingField(false)}
                        >
                          <CloseFilledSVG width={14} color1="#aaa" />
                        </IconButton>
                      </Typography>
                      
                    :
                      null
                }
              </Divider>
            </Grid>
          }
          {
            // TODO: Update the search/add all/close button UI.
            // The current ui is very clunky. The new ui needs to work in all components that import and use the ImportCustomFields component
          }
          <Collapse
            in={isAddingField}
            style={{
              width: '100%',
              // marginTop: isAddingField ? 16 : 0,
              transition: '.75s',
            }}
          >
            <div
              style={{
                boxShadow:'0 0 10px #00000030',
                borderRadius:16,
                overflow:'clip'  
              }}
            >
              <ImportCustomFields
                setIsOpen={setIsAddingField}
                existingCustomFields={Object.keys(contact.custom_fields)}
                handleFieldImport={handleFieldImport}
                handleImportAllFields={handleImportAllFields}
                setEditing={setEditing}
                handleOpen={setIsAddingField}
                padding="1rem 1.25rem 0 1.25rem"
              />
            </div>
            <Divider style={{width:'100%', margin:'1.25rem 0'}} />
          </Collapse>
          <TableWrapper>
            {
              contact
                ?
                  profile
                    ?
                      <>
                        {
                          // Object.entries(profileFields.custom_fields).map(([key, value], index) => {
                            Object.entries(profile.order.filter(x => x.visible).reduce((acc, field) => ({
                                ...acc,
                                [field.id]: contact.custom_fields[field.id] || ''
                            }), {})
                          ).map(([key, value], index) => {
                              // const fieldIdArr = customFields.map(x => x.id);
                            const fieldId = customFields?.filter(x => x.id == key)[0]?.id;
                            if (fieldId) {
                              return (
                                <TableRow
                                  contactId={contact.id}
                                  customFields={customFields}
                                  customField={{ key: fieldId, value: value }}
                                  index={index}
                                  handleTextCustomField={handleTextCustomField}
                                  handleListCustomField={handleListCustomField}
                                  handleNumberCustomField={handleNumberCustomField}
                                  handleDateCustomField={handleDateCustomField}
                                  handleBoolCustomField={handleBoolCustomField}
                                  handleImageCustomField={handleImageCustomField}
                                  handleFileCustomField={handleFileCustomField}
                                  handleRichTextCustomField={handleRichTextCustomField}
                                  confirmFieldRemove={confirmFieldRemove}
                                  setReloadCustomFields={setReloadCustomFields}
                                  showDelete
                                  setEditing={setEditing}
                                  active={editing === key}
                                />
                              )
                            }
                          })
                        }
                      </>
                    :
                      <>
                        {
                          Object.entries(profileFields).map(([key, value], index) => {
                              // const fieldIdArr = customFields.map(x => x.id);
                            const fieldId = customFields?.filter(x => x.id == key)[0]?.id;
                            if (fieldId) {
                              return (
                                <TableRow
                                  contactId={contact.id}
                                  customFields={customFields}
                                  customField={{ key: fieldId, value: value }}
                                  index={index}
                                  handleTextCustomField={handleTextCustomField}
                                  handleListCustomField={handleListCustomField}
                                  handleNumberCustomField={handleNumberCustomField}
                                  handleDateCustomField={handleDateCustomField}
                                  handleBoolCustomField={handleBoolCustomField}
                                  handleImageCustomField={handleImageCustomField}
                                  handleFileCustomField={handleFileCustomField}
                                  handleRichTextCustomField={handleRichTextCustomField}
                                  confirmFieldRemove={confirmFieldRemove}
                                  setReloadCustomFields={setReloadCustomFields}
                                  showDelete
                                  setEditing={setEditing}
                                  active={editing === key}
                                />
                              )
                            }
                          })
                        }
                      </>
                :
                <Typography>No custom fields have been created yet.</Typography>
            }
          </TableWrapper>
          <Dialog
            open={openWarning}
            onClose={() => setOpenWarning(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Confirm Field Removal"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove this field? This action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenWarning(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleFieldRemove} color="primary" autoFocus>
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

        </Grid>
      :
        <Grid
          style={{
            height: 'calc(100% - 50px)',
            overflowY:'auto',
            padding: '1.25rem'
          }}
        >
          <Typography>
            No Custom Fields have been created yet. Click the link below to add your first custom field now.
          </Typography>
          <Grid
            container
            variant="center"
          >
            <Button
              variant="contained"
              href="/contacts/custom_fields/"
              target="_blank"
              sx={{
                mt:3
              }}
            >
              Add Custom Field
            </Button>
          </Grid>
        </Grid>
          
  )
}

const NotesTab = (props) => {
  const { contact, setContact, onSubmit } = props;
  const [inputValue, setInputValue] = useState(contact.notes);
  const [isSaved, setIsSaved] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [loaded, setLoaded] = useState(false);

  // console.log('contact notes', contact.notes);
  // console.log('isSaving', isSaving);
  
  const theme = useTheme();
  const screenSize = useScreenSize();

  const handleNotes = (value) => {
    setContact(prev => ({
      ...prev,
      notes: value
    }))
    setInputValue(value);
  }

  useEffect(() => {
    if (loaded) {
      let timeoutId;
      let savingId;
  
      clearTimeout(timeoutId);
      clearTimeout(savingId);
      setIsSaved(false);
      timeoutId = setTimeout(() => {
        handleSave();
      }, 2000);
  
      savingId = setTimeout(() => {
        setIsSaving(true);
      }, 1000);
  
      return () => {
        clearTimeout(timeoutId);
        clearTimeout(savingId);
      };
    } else {
      setLoaded(true);
    }

  }, [inputValue])

  const handleSave = () => {
    // onSubmit({ ...contact, notes: inputValue });
    setIsSaved(true);
    setIsSaving(false);
  }
  

  return (
    <Grid
      style={{
        height: 'calc(100% - 50px)',
        overflowY:'auto',
        padding: '1.25rem'
      }}
    >
      <Grid
        container
        style={{
          justifyContent: 'flex-end'
        }}
      >
        <Button
          disabled={isSaving || isSaved}
          onClick={() => onSubmit({ ...contact, notes: inputValue })}
        >
          {
            isSaved ? 'Saved'
              : isSaving
                ? 'Saving...'
                : 'Save'
          }
        </Button>
      </Grid>
      <InputField
        value={inputValue}
        onChange={(e) => handleNotes(e.target.value)}
        multiline
        margin
      />

    </Grid>
  )
}

const MailingListAccordion = (props) => {
  const { title, map } = props;
  const theme = useTheme();

  return (
    <Accordion
      style={{
        borderLeft: 'none',
      }}
    >
      <AccordionSummary 
        expandIcon={<UpCaretSVG width={20} color1="#ccc" style={{transform:'rotate(180deg)'}} />} 
        id="panel-header" 
        aria-controls="panel-content"
        style={{
          borderLeft: 'none',
        }}>
        <Typography style={{ fontWeight: 700 }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          padding:0
        }}
      >
        {/* <Grid
          container
          style={{
            justifyContent:'space-between',
            alignItems:'center',
            padding:'12px 16px',
            background: '#00000008'
          }}
        >
          <ReusableModalTooltip>
            <div style={{display:'flex', alignItems:'center', gap:8, cursor:'pointer'}}>
              <AddRoundedSVG width={14} color1={theme.palette.primary.dark} />
              <Typography style={{fontWeight:700, color:theme.palette.primary.dark}}>Add To Another Mailing List</Typography>
            </div>
            <Grid
              container
            >
              <Typography>Add Contact To Mailing List</Typography>
            </Grid>
          </ReusableModalTooltip>
        </Grid> */}


        {
          map?.map((item, index) => (
            <Grid
              container
              style={{
                justifyContent:'space-between',
                alignItems:'center',
                padding:'12px 16px',
                background: index % 2 ? '#00000008' : 'transparent'
              }}
            >
              <Grid
                item
                style={{
                  maxWidth:'calc(100% - 35px)'
                }}
              >
                <Typography
                  style={{
                    overflow:'hidden',
                    whiteSpace:'nowrap',
                    textOverflow:'ellipsis'
                  }}
                >
                  {item.name}
                </Typography>
              </Grid>
              <Grid
                item
                style={{
                  width:35
                }}
              >
                {/* <Button
                  href={`/${hrefLocation}/${item.id}/`}
                >
                  View
                </Button> */}
                <IconButton
                  href={`/lists/${item.id}/`}
                  target="_blank"
                >
                  <OpenInNewSVG width={15} color1={theme.palette.primary.light} />
                </IconButton>
              </Grid>
            </Grid>
          ))
        }
      </AccordionDetails>
    </Accordion>

  )
}

const CampaignAccordion = (props) => {
  const { title, map } = props;
  const theme = useTheme();

  return (
    <Accordion
      style={{
        borderLeft: 'none',
      }}
    >
      <AccordionSummary 
        expandIcon={<UpCaretSVG width={20} color1="#ccc" style={{transform:'rotate(180deg)'}} />} 
        id="panel-header" 
        aria-controls="panel-content"
        style={{
          borderLeft: 'none',
        }}>
        <Typography style={{ fontWeight: 700 }}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          padding:0
        }}
      >

        {
          map?.map((item, index) => (
            <Grid
              container
              style={{
                justifyContent:'space-between',
                alignItems:'center',
                padding:'12px 16px',
                background: index % 2 ? '#00000008' : 'transparent'
              }}
            >
              <Grid
                item
                style={{
                  maxWidth:'calc(100% - 35px)'
                }}
              >
                <Typography
                  style={{
                    overflow:'hidden',
                    whiteSpace:'nowrap',
                    textOverflow:'ellipsis'
                  }}
                >
                  {item.name}
                </Typography>
                <Grid container>

                <Tag
                  tag={{
                    name: status.campaign[item.status],
                    color: theme.palette.primary.main
                  }}
                />
                </Grid>

              </Grid>
              <Grid
                item
                style={{
                  width:35
                }}
              >
                {/* <Button
                  href={`/${hrefLocation}/${item.id}/`}
                >
                  View
                </Button> */}
                <IconButton
                  href={`/campaigns/${item.id}/`}
                  target="_blank"
                >
                  <OpenInNewSVG width={15} color1={theme.palette.primary.light} />
                </IconButton>
              </Grid>
            </Grid>
          ))
        }
      </AccordionDetails>
    </Accordion>

  )
}