import React, { useState, useEffect } from 'react';

// Define the initial text styles
const TEXT_STYLES = ['normal', 'italic', 'oblique'];
const TEXT_ALIGN = ['left', 'center', 'right', 'justify'];
const TEXT_DECORATION = ['none', 'underline', 'line-through', 'overline'];
const FONT_WEIGHTS = ['normal', 'bold', 'bolder', 'lighter'];

const ElementEditorDivider = ({ selectedElement, onTextStyleChange }) => {
  // Combine all text-related styles into a single `style` object
  const [style, setStyle] = useState({
    borderTop: selectedElement.style?.borderTop || '1px solid #cccccc',
    width: selectedElement.style?.width || '100%',
    marginLeft: selectedElement.style?.marginLeft || 'auto',
    marginRight: selectedElement.style?.marginRight || 'auto',  
  });

  if (selectedElement.type === "Divider") {
    console.log('style', style)
  }
  const [borderWidth, setBorderWidth] = useState(selectedElement.style.borderTop?.split(' ')[0] || 1);
  const [borderStyle, setBorderStyle] = useState(selectedElement.style.borderTop?.split(' ')[1] || 'solid');
  const [borderColor, setBorderColor] = useState(selectedElement.style.borderTop?.split(' ')[2] || '#cccccc');
  const [width, setWidth] = useState(selectedElement?.style?.width?.replace(/[^0-9]/g, '') || '100%');
  const [widthFormat, setWidthFormat] = useState(selectedElement.style?.width?.replace(/[0-9]/g, '') || 'px');
  const [alignment, setAlignment] = useState('center');


  // Update width when value changes
  useEffect(() => {
    if (!width) {
      setWidth('auto');
      const reducedStyle = JSON.parse(JSON.stringify(selectedElement));
      onTextStyleChange({...reducedStyle.style, width: `auto` });
      return;
    }
    if (width && width !== 'auto' && widthFormat) {
      setStyle(prev => ({ ...prev, width: `${width}${widthFormat}` }))
      if (onTextStyleChange) {
        const reducedStyle = JSON.parse(JSON.stringify(selectedElement));
        onTextStyleChange({...reducedStyle.style, width: `${width}${widthFormat}` });
      }
    }
  }, [width, widthFormat])

  // Set border states based on the existing borderTop style when component loads
  useEffect(() => {
    if (selectedElement) {
      if (selectedElement.style.borderTop) {
        setBorderWidth(selectedElement.style.borderTop.split(' ')[0].replace(/[^0-9]/g, ''));
        setBorderStyle(selectedElement.style.borderTop.split(' ')[1]);
        setBorderColor(selectedElement.style.borderTop.split(' ')[2]);
        setWidthFormat(selectedElement.style?.width?.replace(/[0-9]/g, ''));
        setWidth(selectedElement.style?.width?.replace(/[^0-9]/g, ''));
        setInitialAlignment()
      };
    }
  }, []);

  // Update top border when value changes
  useEffect(() => {
    if (borderWidth || borderStyle || borderColor) {
      setStyle(prev => ({ ...prev, borderTop: `${borderWidth ?? 4}px ${borderStyle ?? 'solid'} ${borderColor ?? '#cccccc'}` }))
      if (onTextStyleChange) {
        onTextStyleChange({...selectedElement.style, borderTop: `${borderWidth ?? 4}px ${borderStyle ?? 'solid'} ${borderColor ?? '#cccccc'}` });
      }
    }
  }, [borderWidth, borderStyle, borderColor]);

  const handleAlignment = (value) => {
    let updatedAlignment = {};
    if (value === 'left') {
      updatedAlignment = {marginLeft:'0', marginRight:'auto'};
    } else
    if (value === 'center') {
      updatedAlignment = {marginLeft:'auto', marginRight:'auto'};
    } else
    if (value === 'right') {
      updatedAlignment = {marginLeft:'auto', marginRight:'0'};
    }
    setAlignment(value);
    setStyle(prev => ({ ...prev, ...updatedAlignment }));
    if (onTextStyleChange) {
      onTextStyleChange({ ...selectedElement.style, ...updatedAlignment });
    }
  }

  const setInitialAlignment = () => {
    let value = '';
    if (selectedElement.marginLeft == '0' && selectedElement.marginRight == 'auto') {
      value = 'left';
    } else
    if (selectedElement.marginLeft == 'auto' && selectedElement.marginRight == 'auto') {
      value = 'center';
    } else
    if (selectedElement.marginLeft == 'auto' && selectedElement.marginRight == '0') {
      value = 'right';
    }
    console.log('value', value)
    setAlignment(value);
  }


  return (
    <div className="EmailTemplate-text-editor">
      <h4>Edit Style</h4>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Color</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <input
            className="EmailTemplate-editor-field-input"
            type="color"
            value={borderColor}
            onChange={(e) => setBorderColor(e.target.value)}
          />
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Size</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <input
            className="EmailTemplate-editor-field-input input-no-padding-block"
            type="number"
            value={borderWidth}
            onChange={(e) => setBorderWidth(e.target.value)}
            min={1}
          />
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Style</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <select className="EmailTemplate-editor-field-input" value={style.fontFamily} onChange={(e) => setBorderStyle(e.target.value)}>
            <option value="solid">Solid</option>
            <option value="dashed">Dashed</option>
            <option value="dotted">Dotted</option>
          </select>
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Alignment</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <select className="EmailTemplate-editor-field-input" value={alignment} onChange={(e) => handleAlignment(e.target.value)}>
            <option value="left">Left</option>
          <option value="center">Center</option>
            <option value="right">Right</option>
          </select>
        </div>
      </div>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Width</label>
        </div>
        <div className="EmailTemplate-editor-field-value-col-2">
          <input
            className="EmailTemplate-editor-field-input input-no-padding-block"
            type="number"
            value={width}
            onChange={(e) => setWidth(e.target.value)}
            min="20"
            max="1000"
          />
        </div>
        <div className="EmailTemplate-editor-field-value-col-3">
          <div
            className={`EmailTemplate-editor-field-value-col-3 EmailTemplate-item ${widthFormat === "px" ? 'EmailTemplate-selected' : ''}`}
            onClick={() => setWidthFormat('px')}
          >
            px
          </div>
          <div
            className={`EmailTemplate-editor-field-value-col-3 EmailTemplate-item ${widthFormat === "%" ? 'EmailTemplate-selected' : ''}`}
            onClick={() => setWidthFormat('%')}
          >
            %
          </div>
        </div>
      </div>

    </div>
  );
};

export default ElementEditorDivider;
