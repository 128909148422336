import React, { useEffect, useState } from 'react'
import { DjangoComponents } from '../../templates/DjangoStyles';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { CircularProgress, Grid, LinearProgress, Typography } from '@mui/material';
import { TagButton } from '../ui/Tags';

const CampaignReport = (props) => {
  const { campaignData } = props;
  const [campaign, setCampaign] = useState({});

  console.log('campaignData', campaignData);

  return (
    <Grid container p={2}>
      <ReportHeader campaignData={campaignData} />
      <Grid container spacing={2}>
        <LinearProgressCard
          title="Open Rate"
          value={Math.round(campaignData.open_rate)}
          valueEnd="%"
          details={{
            "List average": `${Math.round(campaignData.mailing_list_open_rate)}%`,
            "Unique opens": campaignData.unique_opens_count,
            "Total opens": campaignData.total_opens_count,
          }}
        />
        <LinearProgressCard
          title="Click Rate"
          value={Math.round(campaignData.click_rate)}
          valueEnd="%"
          details={{
            "List average": `${Math.round(campaignData.mailing_list_click_rate)}%`,
            "Unique clicks": campaignData.unique_clicks_count,
            "Total clicks": campaignData.total_clicks_count,
          }}
        />
        {/* <CircleProgressCard
          title="Open Rate"
          value={Math.round(campaignData.open_rate)}
          valueEnd="%"
          details={{
            "List average": `${Math.round(campaignData.mailing_list_open_rate)}%`,
            "Unique opens": campaignData.unique_opens_count,
            "Total opens": campaignData.total_opens_count,
          }}
        />
        <CircleProgressCard
          title="Click Rate"
          value={Math.round(campaignData.click_rate)}
          valueEnd="%"
          details={{
            "List average": `${Math.round(campaignData.mailing_list_click_rate)}%`,
            "Unique clicks": campaignData.unique_clicks_count,
            "Total clicks": campaignData.total_clicks_count,
          }}
        /> */}
      </Grid>
    </Grid>
  )
}

export default CampaignReport

const ReportHeader = (props) => {
  const { campaignData } = props;

  return (
    <Grid
      container
      sx={{
        justifyContent:'space-between',
        mb:2
      }}
    >
      <Typography
        variant="h2"
        sx={{
          // fontSize:'2rem'
        }}
      >
        {campaignData.name}
      </Typography>
    </Grid>

  )
}

const LinearProgressCard = (props) => {
  const { title, value, valueEnd, details } = props;
  const theme = useTheme();
  const [determinedValue, setDeterminedValue] = useState(0);
  
  useEffect(() => {
    if (value) {
      setDeterminedValue(value);
    }
  }, [value])  
  
  return (
    <Grid item xs={12} sm>
      <DjangoComponents card padding>
        <Grid
          container
          sx={{
            justifyContent:'space-between',
            alignItems:'flex-end`',
            mb:1
          }}
        >
          <Typography
              sx={{
                fontSize:'1.3rem',
                lineHeight:1
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                fontSize:'1.1rem',
                lineHeight:1
              }}
            >
              {value}{valueEnd}
            </Typography>
        </Grid>
        <LinearProgress
          variant="determinate"
          value={determinedValue}
          style={{
            width:'100%',
            // maxWidth:400,
            height:20,
            borderRadius:6
          }}
        />
        {
          details &&
            <Grid
              container
              sx={{
                mt:1
              }}
            >
              {Object.entries(details).map(([key, value]) => (
                <Grid container sx={{justifyContent:'space-between'}}>
                  <Typography
                    sx={{
                      fontSize:'.9rem',
                      color:'#333'
                    }}
                  >
                    {key}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize:'.9rem',
                      color:'#333'
                    }}
                  >
                    {value}
                  </Typography>
                  </Grid>
              ))}
            </Grid>
        }
          
      </DjangoComponents>
    </Grid>
  )
}

const CircleProgressCard = (props) => {
  const { title, value, valueEnd, details } = props;
  const theme = useTheme();
  const [determinedValue, setDeterminedValue] = useState(0);
  
  useEffect(() => {
    if (value) {
      setDeterminedValue(value);
    }
  }, [value])

  return (
    <Grid item xs={12} sm>
      <DjangoComponents card padding>
        <Grid
          container
          sx={{
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            mb:1
          }}
        >
          <Typography
            sx={{
              fontSize:'1.3rem',
              lineHeight:1
            }}
          >
            {title}
          </Typography>
          <Grid
            sx={{
              position:'relative',
              width:200,
              height:200,
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              mt:1
            }}
          >
            <CircularProgress
              variant="determinate"
              value={100}
              size={200}
              thickness={10}
              sx={{
                position:'absolute',
                top:0,
                left:0,
                '&&.MuiCircularProgress-root':{
                  color:theme.palette.primary.light
                }
              }}
            />
            <CircularProgress
              variant="determinate"
              color="secondary"
              value={determinedValue}
              size={200}
              thickness={10}
              sx={{
                position:'absolute',
                top:0,
                left:0,
                '&&.MuiCircularProgress-root':{
                  color:theme.palette.primary.dark
                }
              }}
            />
            <Typography
              sx={{
                fontSize:'1.6rem',
                fontWeight:600,
                lineHeight:1
              }}
            >
              {value}{valueEnd}
            </Typography>

          </Grid>
        </Grid>
        {
          details &&
            <Grid
              container
              sx={{
                mt:1
              }}
            >
              {Object.entries(details).map(([key, value]) => (
                <Grid container sx={{justifyContent:'space-between'}}>
                  <Typography
                    sx={{
                      fontSize:'.9rem',
                      color:'#333'
                    }}
                  >
                    {key}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize:'.9rem',
                      color:'#333'
                    }}
                  >
                    {value}
                  </Typography>
                  </Grid>
              ))}
            </Grid>
        }
          
      </DjangoComponents>
    </Grid>
  )
}