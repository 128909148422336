import React, { useState, useEffect } from 'react';

// Define the initial text styles
const TEXT_STYLES = ['normal', 'italic', 'oblique'];
const TEXT_ALIGN = ['left', 'center', 'right', 'justify'];
const TEXT_DECORATION = ['none', 'underline', 'line-through', 'overline'];
const FONT_WEIGHTS = ['normal', 'bold', 'bolder', 'lighter'];

const ElementEditorSpacer = ({ selectedElement, onTextStyleChange }) => {
  // Combine all text-related styles into a single `style` object

  const handleChange = (value) => {
    onTextStyleChange({ height: Number(value) })
  }

  return (
    <div className="EmailTemplate-text-editor">
      <h4>Edit Style</h4>

      <div className="EmailTemplate-editor-field">
        <div className="EmailTemplate-editor-field-key">
          <label>Spacing</label>
        </div>
        <div className="EmailTemplate-editor-field-value">
          <input
            className="EmailTemplate-editor-field-input EmailTemplate-input-no-padding-block"
            type="number"
            value={selectedElement?.style?.height || 0}
            onChange={(e) => handleChange(e.target.value)}
            min={0}
          />
        </div>
      </div>

    </div>
  );
};

export default ElementEditorSpacer;
