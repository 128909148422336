import { createTheme, responsiveFontSizes } from '@mui/material/styles';

export const theme = createTheme({
  palette:{
    primary:{
      main: '#00A6A6',
      dark: '#066E7C',
      light: '#27D8D3'
    },
    secondary:{
      main: '#F78B1E',
      dark: '#F46221',
      light: '#F2B666',
      contrastText: '#FFFFFF'
    },
    tertiary:{
      main: '#27ADE7',
      dark: '#004E70',
      light: '#AEFAFF',
      contrastText: '#FFFFFF'
    },
    quaternary:{
      main: '#EFC899',
      dark: '#CC7300',
      light: '#FFE0B1'
    },
    alt:{
      main: '#7AE5CA',
      dark: '',
      light: '#C7FFF0'
    },
    verify:{
      main: '#159B78',
      dark: '#057A69',
      light: '#40C69F'
    },
    blank:{
      main:'#FFFFFF',
      dark:'#EEEEEE',
      contrastText: '#00A6A6'
    },
    light:{
      main: '#CCC',
      dark: '#AAA',
      light: '#DDD'
    },
    mid:{
      main: '#AAA',
      dark: '#777',
      light: '#CCC'
    },
    background: {
      main: '#FFFFFF',
      secondary: '#FFFBF8',
      dark: '#03032E'
    },
    primaryBackground: {
      light: '#EDFCFB',
      main: '#D8F1F0'
    },
    status: {
      campaign: {
        sent: {
          light: '#00A6A640',
          main: '#00A6A6',
          dark: '#066E7C',
          accent: '#00A6A6'
        },
        scheduled: {
          light: '#27ADE740',
          main: '#27ADE7',
          dark: '#004E70',
          accent: '#27ADE75C'    
        },
        draft: {
          light: '#EFC89940',
          main: '#EFC899',
          dark: '#CC7300',
          accent: '#EFC899'
        },
        queued: {
          light: '#EFC89940',
          main: '#EFC899',
          dark: '#CC7300',
          accent: '#EFC899'
        },
        delivering: {
          light: '#8b00cc40',
          main: '#8b00cc',
          dark: '#4e00a6',
          accent: '#8b00cc'
        },
        paused: {
          light: '#d2214a40',
          main: '#d2214a',
          dark: '#9d092b',
          accent: '#d2214a'
        },
      },
    }
  },
  typography: {
    fontFamily:'Inter',
    fontSize: 14,
    landing: {
      fontFamily:'Inter',
      fontSize:'1.2rem',
      lineHeight:'1.8rem'
    },
    landing_title: {
      fontFamily:'Poppins',
      fontSize:'3.75rem',
      lineHeight:1,
      fontWeight:300,
      "@media (max-width:640px)": {
        fontSize: "2.375rem"
      },
      "@media (min-width:640px)": {
        fontSize: "3.125rem"
      },
      "@media (min-width:1024px)": {
        fontSize: "3.33rem"
      },
      "@media (min-width:1400px)": {
        fontSize: "3.75rem"
      },
    },
    default: {
      fontFamily:'Inter',
      fontSize:14,
      margin:0,
    },
    h1:{
      fontSize:'2rem'
    },
    h2:{
      fontSize:'2rem',
      fontWeight:600
    },
  },
  
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          width: 'calc(100%-64px)',
          background: '#ffffffee',
          padding: 32
        },
      },
    },

    MuiBox: {
      variants: [
        {
          props: { variant: 'fixed' },
          style: {
            position:'fixed',
            top:0,
          },
        },
      ]
    },

    MuiGrid: {
      variants: [
        {
          props: { variant: 'fixed' },
          style: {
            position: 'fixed',
            top: 0,
          },
        },
        {
          props: { variant: 'centerWidth' },
          style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems:'flex-start'
          },
        },
        {
          props: { variant: 'centerHeight' }, 
          style: {
            display: 'flex',
            alignItems: 'center',
          },
        },
        {
          props: { variant: 'center' },
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        },
        {
          props: { variant: 'space-between' },
          style: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems:'center'
          },
        },
      ],
    },

    MuiButton: {
    },
    
    MuiDrawerHeader: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '16px',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        title: {
          fontWeight: 'bold',
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          margin: '16px 0',
          '& .MuiInputBase-input': {
            backgroundColor: 'white',
            display:'flex',
            alignItems:'center'
          },
        },
      },
    },

    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth:32
        }
      }
    },

    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.severity === "error" && {
              backgroundColor: "#FF3333",
              // color: "#751A0C",
            }),
          ...(ownerState.severity === "warning" &&
            ownerState.variant === "standard" && {
              backgroundColor: "#FFF6E5",
              // color: "#93541B",
            }),
          ...(ownerState.severity === "info" &&
            ownerState.variant === "standard" && {
              backgroundColor: "#E5F3FB",
              // color: "#004B6F",
            }),
          ...(ownerState.severity === "success" && {
              backgroundColor: "#00A6A6",
              // color: "#006730",
            }),
        }),
        icon: ({ ownerState }) => ({
          ...(ownerState.severity === "error" &&
            ownerState.variant === "standard" && {
              color: "grey",
            }),
          ...(ownerState.severity === "warning" &&
            ownerState.variant === "standard" && {
              color: "#FFAD00",
            }),
          ...(ownerState.severity === "info" &&
            ownerState.variant === "standard" && {
              color: "#008EDB",
            }),
          ...(ownerState.severity === "success" &&
            ownerState.variant === "standard" && {
              color: "#0EC54F",
            }),
        }),
      },
    },
  },

  zIndex: {
    mobileStepper: 1000,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
    footer: 200,
    nav: 100,
    navSticky:50
  },

  breakpoints:{
    values:{
      ts:0,
      xs:380,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    }
  }
})



export const responsiveFontTheme = responsiveFontSizes(theme);


// ALL MUI COMPONENTS
// MuiAppBar
// MuiAvatar
// MuiBackdrop
// MuiBadge
// MuiBottomNavigation
// MuiButton
// MuiButtonBase
// MuiCard
// MuiCardActions
// MuiCardContent
// MuiCardHeader
// MuiCardMedia
// MuiCheckbox
// MuiChip
// MuiCircularProgress
// MuiCollapse
// MuiContainer
// MuiCssBaseline
// MuiDialog
// MuiDialogActions
// MuiDialogContent
// MuiDialogContentText
// MuiDialogTitle
// MuiDivider
// MuiDrawer
// MuiFab
// MuiFilledInput
// MuiFormControl
// MuiFormControlLabel
// MuiFormGroup
// MuiFormHelperText
// MuiFormLabel
// MuiGrid
// MuiIconButton
// MuiInput
// MuiInputAdornment
// MuiInputBase
// MuiInputLabel
// MuiLinearProgress
// MuiLink
// MuiList
// MuiListItem
// MuiListItemAvatar
// MuiListItemIcon
// MuiListItemSecondaryAction
// MuiListItemText
// MuiMenu
// MuiMenuItem
// MuiMobileStepper
// MuiModal
// MuiNativeSelect
// MuiOutlinedInput
// MuiPaper
// MuiPopover
// MuiRadio
// MuiScopedCssBaseline
// MuiSelect
// MuiSkeleton
// MuiSlider
// MuiSnackbar
// MuiSnackbarContent
// MuiSpeedDial
// MuiSpeedDialAction
// MuiSpeedDialIcon
// MuiStep
// MuiStepButton
// MuiStepConnector
// MuiStepContent
// MuiStepIcon
// MuiStepLabel
// MuiStepper
// MuiSvgIcon
// MuiSwitch
// MuiTab
// MuiTable
// MuiTableBody
// MuiTableCell
// MuiTableContainer
// MuiTableFooter
// MuiTableHead
// MuiTablePagination
// MuiTableRow
// MuiTableSortLabel
// MuiTabs
// MuiTextareaAutosize
// MuiTextField
// MuiToggleButton
// MuiToggleButtonGroup
// MuiToolbar
// MuiTooltip
// MuiTouchRipple
// MuiTypography
// MuiUseMediaQuery
// MuiZoom