import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import { ContactSVG, DeleteSVG, FilterSVG } from '../../assets/icons';
import { Grid, Stack } from '@mui/material';
import CustomTooltip from './CustomTooltip';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, columns, checkboxes, actionCell } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {
          checkboxes &&
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{
                  'aria-label': 'select all desserts',
                }}
              />
            </TableCell>
        }
        {columns.map((column) => (
          <TableCell
            key={column.field}
            align={column.numeric ? 'right' : 'left'}
            padding={column.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === column.field ? order : false}
          >
            <TableSortLabel
              active={orderBy === column.field}
              direction={orderBy === column.field ? order : 'asc'}
              onClick={createSortHandler(column.field)}
            >
              {column.label}
              {orderBy === column.field ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {
          // This cell is for the added actions column
          actionCell &&
            <TableCell width={80} /> 
        }
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { title, numSelected, searchButton, toolbarButtons, toolbarActions } = props;
  return (
    <Toolbar
      sx={{
        justifyContent:'space-between',
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {
        // Show when rows are NOT selected
      }
      <Grid
        container
        style={{
          visibility: numSelected > 0 ? 'hidden' : 'visible',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: numSelected > 0 ? 'none' : 'flex'
        }}
      >
        <Typography
          sx={{}}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>

        <div style={{ display: numSelected > 0 ? 'none' : 'block' }}>
          {searchButton}
        </div>

        <Grid
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: 12,
            gap: 8
          }}
        >
          {toolbarButtons?.map((item, index) => (item))}
        </Grid>
      </Grid>

      {
        // Show when rows ARE selected
      }
      <Grid
        container
        style={{
          visibility: numSelected > 0 ? 'visible' : 'hidden',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: numSelected > 0 ? 'flex' : 'none'
        }}
      >
        <Typography>
          {numSelected} selected
        </Typography>


        <Grid
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: 12,
            gap: 8
          }}
        >
          {toolbarActions}
        </Grid>
      </Grid>
    </Toolbar>
  );
}

export const FillTable = (props) => {
  const { title, rows, columns, onRowClick, setFilterBy, pages, showPaginate=true, checkboxes=true, dense=false, handleRowSelection, addEmptyRows, actionCell, renderCell, searchButton, toolbarButtons, toolbarActions } = props;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [selected, setSelected] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const [pageName, setPageName] = React.useState("")

  // console.log('page', page);
  // console.log('selectedRows', selectedRows);

  // Update rows per page filter
  React.useEffect(() => {
    if (setFilterBy) {
      setFilterBy(prev => {
        
        return({
          ...prev,
          page_size: rowsPerPage
        })
      })
    }
  }, [rowsPerPage])

  // Update current page
  React.useEffect(() => {
    if (setFilterBy) {
      setFilterBy(prev => ({ ...prev, page: page+1 }));
    }
  }, [page])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      handleRowSelection(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleRowClick = (e, row) => {
    // Check if there is a function to run on row click event
    if (!onRowClick) {
      return;
    }

    // Check if the target is an interactive element
    const interactiveElements = ['TD']; // Add more tags as needed

    if (interactiveElements.includes(e.target.tagName)) {
      onRowClick(row); // Call the callback function with params
      return;
    }

    return;
  };


  const handleCheckboxClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    handleRowSelection(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // setDestinationPageId(newPage+1);
    setFilterBy(prev => ({ ...prev, page: newPage+1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    // setDestinationPageId(1);
    setFilterBy(prev => ({ ...prev, page: 1 }));
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    rowsPerPage - rows.length;

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
      ),
    [order, orderBy, page, rowsPerPage, rows, pages],
  );

  return (
    <Box sx={{ width: '100%' }}>
        <EnhancedTableToolbar title={title} numSelected={selected.length} searchButton={searchButton} toolbarButtons={toolbarButtons} toolbarActions={toolbarActions} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              checkboxes={checkboxes}
              columns={columns}
              actionCell={actionCell}
            />
            <TableBody>
              {
                visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(e) => handleRowClick(e, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      sx={{ cursor: onRowClick ? 'pointer' : 'initial', position:'relative' }}
                    >
                      {
                        checkboxes &&
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              onChange={(event) => handleCheckboxClick(event, row.id)}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                      }
                      {
                        columns.map(item => (
                          <TableCell key={`${row.id}-${item.field}`}>
                              {
                                renderCell(row, item)
                                // React.cloneElement(renderCell, {
                                //   contactId: row.id,
                                //   key: item.field,
                                //   field: item.field,
                                //   type: item.type,
                                //   input: item.columnType === 'custom'
                                //     ? row.custom_fields?.[item.id] // We use the id in custom fields because the format is { [id]: value }
                                //     : typeof row[item.field] === 'object' && Array.isArray(row[item.field])
                                //       ? item.type === 'tags'
                                //         ? row[item.field]
                                //         : row[item.field].map(item => item.name)
                                //       : row[item.field]            
                                // })
                              }
                          </TableCell>
                        ))
                      }
                      {
                        actionCell &&
                        <TableCell width={80}>
                          { actionCell(row) }
                        </TableCell>
                      }
                    </TableRow>
                  );
                })
              }
              {(addEmptyRows && emptyRows > 0) && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 67) * emptyRows,
                  }}
                >
                  <TableCell colSpan={columns.length+2} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {
          !showPaginate || !pages
            ? 
              null
            :
              <TablePagination
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                component="div"
                // count={rows.length}
                count={pages.count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
        }
    </Box>
  );
}
