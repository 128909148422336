import { useEffect, useState } from "react";
import { useAuth } from "../context/authContext";
import { addDoc, collection, deleteDoc, doc, limit, onSnapshot, orderBy, query, runTransaction, serverTimestamp, setDoc, where } from "firebase/firestore";
import { db } from "../firebase";
import { api_endpoint, auth_request_headers } from "../lib/Backend";
import axios from "axios";
import { mockData } from "../lib/mockData";



// ==========================================================================================================================
// ==========================================================================================================================
//      ERROR MESSAGE FUNCTIONS (NOT APIs)
// ==========================================================================================================================
// ==========================================================================================================================
// const errorMessageString = (responseData) => { // This was the original error message function but it didn't handle nested messages well
//   // Skip running function if there is no response data
//   console.log('responseData', responseData);
//   if (!responseData) return null;

//   const processEntry = ([key, value]) => {
//     if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
//       // If the value is an object, process its entries recursively
//       const nestedEntries = Object.entries(value).map(processEntry);
//       console.log('IF key: ', key, 'value: ', value);
//       return <div key={key}>{nestedEntries}</div>;
//     } else {
//       // Otherwise, return the key-value pair as a string
//       console.log('ELSE key: ', key, 'value: ', value);
//       return <div key={key}>{`${key}: ${value}`}</div>;
//     }
//   };
  
//   // Process each entry in responseData
//   const dataElements = Object.entries(responseData).map(processEntry);

//   return (
//     dataElements.length > 5 // This is to prevent 500 errors with tons of items. Eventually need to separate 500 errors
//       ? ['There was an error with this request']
//       : dataElements.length > 0 ? dataElements : null
//   );
// };


function errorMessageString(data) {
  const deepestItems = [];

  function traverse(obj) {
      for (const key in obj) {
          if (Array.isArray(obj[key])) {
              // If the current property is an array, check if its elements are objects
              obj[key].forEach(element => {
                  if (typeof element === 'object' && element !== null) {
                      // If an element is an object, continue traversal
                      traverse(element);
                  } else {
                      // If the element is not an object, it's at the deepest level
                      deepestItems.push(<div>{`${key}: ${element}`}</div>);
                  }
              });
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
              // If the current property is an object, continue traversal
              traverse(obj[key]);
          } else {
              deepestItems.push(<div>{`${key}: ${obj[key]}`}</div>);
          }
      }
  }

  traverse(data);

  return (
    deepestItems.length > 5 // This is to prevent 500 errors with tons of items. Eventually need to separate 500 errors
      ? [`There were ${deepestItems.length < 20 ? deepestItems.length : 'too many'} errors with this request`]
      : deepestItems.length > 0 ? deepestItems : null
  );

}

// POST === createXXX
// PATCH === updateXXX
// DELETE === deleteXXX
// GET === getXXX


// ==========================================================================================================================
// ==========================================================================================================================
//      ACCOUNT AND ACTIONS
// ==========================================================================================================================
// ==========================================================================================================================



// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for registering a user
export const postRegister = async (registerObj) => {
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/actions/api/register/', {...registerObj}, headers);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error registering user', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}

// Function for registering a user
export const postResendEmail = async (resendObj) => {
  const { payload } = resendObj;
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/actions/api/resend-email/', {...payload},
      headers);
      return {status:'success', data: response.data};
    } catch (error) {
      console.log('Error sending email', error);
      return {status:'error', data: error};
    }
}

// Function for subscribing to a form
export const postFormSubscribe = async (submissionObj) => {
  const { payload, token } = submissionObj;
  
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
  

    const response = await axios.post(
      api_endpoint() + `/actions/user/subscribe/create/${token}/`, {...payload},
      headers);
    return {status: 'success', data: response.data};
  } catch (error) {
    return {status: 'error', error: error};
  }

}

// Function for submitting a user survey
export const postSurveySubmission = async (submissionObj) => {
  const { payload, token } = submissionObj;
  
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/actions/api/take_survey/${token}/`, {...payload},
      headers);

      return {status: 'success', data: response.data};

    } catch (error) {
      console.log('Error creating survey', error);
      return {status: 'error', error: error};
  }

}


// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------


// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------


// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------


// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting account
// reload state allows updating by making another api resquest
export const useGetAccount = () => {
  const [account, setAccount] = useState(); // State to set and return
  const getAccount = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/accounts/api/detail/`, headers);
      setAccount(response.data);

    } catch (error) {
      console.log('Error getting account', error);
    }
  }

  useEffect(() => {
    getAccount();
  }, [])

  return account ? account : null

}


// Function for getting account
export const getAccount = async () => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/accounts/api/detail/`, headers);
    return {status: 'success', data: response.data};
    
  } catch (error) {
    console.log('Error getting account', error);
    return {status: 'error'};
  }
}

// GET FORM SUBSCRIPTION HOOK
export const useGetFormSubscribe = (token) => {
  const [form, setForm] = useState();

  const getFormSubscription = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }

      const response = await axios.get(api_endpoint() + `/actions/user/subscribe/${token}/`, headers);

      setForm(response.data);
    } catch (error) {
      console.log('Error getting forms', error);
    }
  }

  useEffect(() => {
    if (token) {
      getFormSubscription()
    }
    
  }, [token])


  return form ? form : null

}

export const useGetTakeSurvey = (token) => {
  const [survey, setSurvey] = useState();

  const getSurveyShareLink = async () => {
    try{
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      
      }
      const response = await axios.get(api_endpoint() + `/actions/api/take_survey/${token}`, headers);
      setSurvey(response.data);

    } catch (error) {
      console.log('Error getting survey', error);
    }
  }
  useEffect(() => {
    if(token){
      getSurveyShareLink()
    }
  }, [token])
  return survey ? survey : null
}



























// ==========================================================================================================================
// ==========================================================================================================================
//      TENANTS
// ==========================================================================================================================
// ==========================================================================================================================



// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for registering a tenant
export const postRegisterTenant = async (registerObj) => {
  const { payload } = registerObj;
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/tenants/api/register/', {...payload},
      headers);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error registering tenant', error);
    return {status:'error', data: error};
  }
}


// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating a tenant
export const putTenant = async (registerObj) => {
  const { payload } = registerObj;
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + '/tenants/api/details/', {...payload},
      headers);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error registering tenant', error);
    return {status:'error', data: error};
  }
}



// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------


// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------


// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting account
// reload state allows updating by making another api resquest
export const useGetTenantDetails = () => {
  const [tenantDetails, setTenantDetails] = useState(); // State to set and return
  const getTenantDetails = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/tenants/api/details/`, headers);
      setTenantDetails(response.data);

    } catch (error) {
      console.log('Error getting tenant details', error);
    }
  }

  useEffect(() => {
    getTenantDetails();
  }, [])

  return tenantDetails ? tenantDetails : null

}

export const getTenantDetails = async () => {
  try {
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/tenants/api/details/`, headers);

    return {status: 'success', data: response.data};

  } catch (error) {
    console.log('Error getting tenant details', error);
    return {status: 'error', error: error};
  }
}

// Hook for getting account
// reload state allows updating by making another api resquest
export const useGetUserDetails = () => {
  const [userDetails, setUserDetails] = useState(); // State to set and return
  const getUserDetails = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/accounts/api/detail/`, headers);
      setUserDetails(response.data);

    } catch (error) {
      console.log('Error getting user details', error);
    }
  }

  useEffect(() => {
    getUserDetails();
  }, [])

  return userDetails ? userDetails : null

}

export const getUserDetails = async () => {
  try {
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/accounts/api/detail/`, headers);

    return {status: 'success', data: response.data};

  } catch (error) {
    console.log('Error getting user details', error);
    return {status: 'error', error: error};
  }
}






























// ==========================================================================================================================
// ==========================================================================================================================
//      SETTINGS
// ==========================================================================================================================
// ==========================================================================================================================



// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for posting sender details
export const postSenderDetails = async (senderDetailObj) => {

  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(api_endpoint() + '/settings/api/sender_details/', senderDetailObj, headers);
    return {status:'success', data: response.data};

  } catch (error) {
    console.log('Error registering tenant', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}

// Function for validating sender domain details
export const postSenderVerifyDomain = async (detailId) => {

  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(api_endpoint() + `/settings/api/sender_details/validate_domain/${detailId}/`, headers);
    return {status:'success', data: response.data};

  } catch (error) {
    console.log('Error registering tenant', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}


// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------


// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------
// Function for posting tenant details
export const putAdminDetails = async (adminDetailsObj) => {
  const { payload } = adminDetailsObj;

  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(api_endpoint() + '/settings/api/admin_details/', payload, headers);
    return {status:'success', data: response.data};

  } catch (error) {
    console.log('Error adding tenant details', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}

// Function for updating a tenant data bag
export const putTenantDataBag = async (dataObj) => {
  const { id, data } = dataObj;

  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(api_endpoint() + `/settings/api/data_bag/`, data, headers);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error updating data bag', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}

// Function for updating a tenant data bag
export const putUserDataBag = async (dataObj) => {
  const { id, data } = dataObj;

  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(api_endpoint() + `/settings/api/user_data_bag/`, data, headers);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error updating data bag', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}

// Function for updating sender details
export const putSenderDetails = async (dataObj) => {
  const { id, data } = dataObj;

  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(api_endpoint() + `/settings/api/sender_details/${id}/`, data, headers);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error updating data bag', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}



// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating sender details
export const deleteSenderDetails = async (id) => {
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.delete(api_endpoint() + `/settings/api/sender_details/${id}/`, headers);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error deleting data bag', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting tenant data bag
export const useGetUsage = () => {
  const [usage, setUsage] = useState(); // State to set and return
  const getUsage = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/settings/api/usage/`, headers);
      setUsage(response.data);

    } catch (error) {
      console.log('Error getting tenant details', error.response);
      return {status: 'error', error: errorMessageString(error.response?.data ? error.response.data : null)};
    }
  }

  useEffect(() => {
    getUsage();
  }, [])

  return usage ? usage : null

}

// Hook for getting tenant data bag
export const useGetTenantDataBag = () => {
  const [tenantDataBag, setTenantDataBag] = useState(); // State to set and return
  const getTenantDataBag = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/settings/api/data_bag/`, headers);
      setTenantDataBag(response.data);

    } catch (error) {
      console.log('Error getting tenant details', error.response);
      return {status: 'error', error: errorMessageString(error.response?.data ? error.response.data : null)};
    }
  }

  useEffect(() => {
    getTenantDataBag();
  }, [])

  return tenantDataBag ? tenantDataBag : null

}

// Function for getting tenant data bag
export const getTenantDataBag = async () => {
  try {
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/settings/api/data_bag/`, headers);
    console.log('databag response', response);
    return {status: 'success', data: response.data};

  } catch (error) {
    console.log('Error getting tenant data bag details', error);
    return {status: 'error', error: error};
  }
}

// Hook for getting tenant data bag
export const useGetUserDataBag = () => {
  const [userDataBag, setUserDataBag] = useState(); // State to set and return
  const getUserDataBag = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/settings/api/user_data_bag/`, headers);
      setUserDataBag(response.data);

    } catch (error) {
      console.log('Error getting user details', error);
      return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
    }
  }

  useEffect(() => {
    getUserDataBag();
  }, [])

  return userDataBag ? userDataBag : null

}

// Function for getting tenant data bag
export const getUserDataBag = async () => {
  try {
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/settings/api/user_data_bag/`, headers);
    console.log('databag response', response);
    return {status: 'success', data: response.data};

  } catch (error) {
    console.log('Error getting user data bag details', error);
    return {status: 'error', error: error};
  }
}

// Hook for getting all sender details
export const useGetAllSenderDetails = (reload) => {
  const [senderDetails, setSenderDetails] = useState(); // State to set and return
  const getAllSenderDetails = async () => {
    try {
      let allSenderDetails = [];
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
  
      // Function to fetch custom fields recursively
      const fetchSenderDetails = async (url) => {
        const response = await axios.get(url);
        const { data } = response;

        if (data.results) {
          allSenderDetails = [...allSenderDetails, ...data.results];
        }

        if (data.next) {
          await fetchSenderDetails(data.next); // Fetch next page
        } else {
          setSenderDetails(allSenderDetails); // Set the accumulated sender details
        }
      };

      await fetchSenderDetails(api_endpoint() + '/settings/api/sender_details/', headers);
    } catch (error) {
      console.log('Error getting sender details', error);
      return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
    }
  };

  useEffect(() => {
    if (reload || reload === undefined) {
      getAllSenderDetails();
    }
  }, [reload])

  return senderDetails ? senderDetails : null

}































// ==========================================================================================================================
// ==========================================================================================================================
//      CONTACTS AND CONTACT UPLOAD API
// ==========================================================================================================================
// ==========================================================================================================================



// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a contact
export const createContact = async (contactObj) => {
  const { payload } = contactObj;
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/contacts/api/contacts/', {...payload},
      headers);

    return {status: 'success', data: response.data};

  } catch (error) {
    console.log('Error creating contact', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}

// POST CSV FILE
export const createUploadCsv = async (file) => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const headers = {
      'Content-Type': 'multipart/form-data',
      'accept': 'application/json',
    };

    // const response = await axios.post(
    //   api_endpoint() + `/contacts/api/import/csv/`,
    //   formData,
    //   {headers}
    // );

    const response = await axios.request({
      method: 'POST',
      url: api_endpoint() + `/contacts/api/import/csv/`,
      data: formData,
      headers: headers,
      maxRedirects: '0'
    });
    // const url = api_endpoint() + `/contacts/api/import/csv/`;

    // const response = await fetch(url, {
    //   method: 'POST',
    //   // headers: headers,
    //   body: formData,
    //   // redirect: 'manual'
    // })

    console.log('csv response: ', response);
    return { status: 'success', data: response.data, responseURL: response.request.responseURL };
  } catch (error) {
    console.log('Error uploading CSV file', error);
    return { status: 'error', error: error };
  }
};


// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------

// Function for updating a contact
export const patchContact = async (contactObj) => {
  const { contactId, payload } = contactObj;
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.patch(
      api_endpoint() + `/contacts/api/contacts/${contactId}/`, {...payload},
      headers);
    return { status: 'success', data: response.data };
  } catch (error) {
    console.log('Error updating a contact', error);
  }
}


// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------

// Function for updating a contact
export const putContact = async (contactObj) => {
  const { contactId, payload } = contactObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/contacts/api/contacts/${contactId}/`, {...payload},
      headers);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error updating a contact', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}


// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

// Function for updating a contact
export const deleteContact = async (contactId) => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.delete(
      api_endpoint() + `/contacts/api/contacts/${contactId}/`, headers);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error deleting a contact', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting contacts
// reload state allows updating by making another api resquest
export const useGetContacts = (contactsObj) => {
  const { reload, pageId } = contactsObj;
  const [contacts, setContacts] = useState(); // State to set and return
  const getContacts = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/contacts/api/contacts/?page=${pageId ? pageId : 1}`, headers);
      // const response = await axios.get(apiURL,
      //   {'withCredentials': true });

      setContacts(response.data);
    } catch (error) {
      console.log('Error getting contacts', error);
    }
  }

  useEffect(() => {
    getContacts();
  }, [reload, pageId])

  return contacts ? contacts : null

}


// Function for getting contacts
export const getContacts = async () => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const response = await axios.get(
      // 'http://localhost:7000/api',
      api_endpoint() + '/contacts/api/contacts/',
      headers);
    return response;
  } catch (error) {
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}

// Hook for getting contacts
export const useGetContact = (contactId, reload) => {
  const [contact, setContact] = useState(); // State to set and return
  const getContact = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/contacts/api/contacts/${contactId}/`, headers);
      // const response = await axios.get(apiURL,
      //   {'withCredentials': true });

      setContact(response.data);
    } catch (error) {
      console.log('Error getting contacts', error);
    }
  }

  useEffect(() => {
    if (contactId) {
      getContact();
    }
  }, [contactId, reload])

  return contact ? contact : null

}

export const getContact = async (contactId) => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/contacts/api/contacts/${contactId}/`, headers);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error getting contacts', error);
    return {status: 'error'};
  }
}

// Hook for getting filtered / queried contacts
// reload state when the filterBy state updates
export const useGetFilteredContacts = (contactsObj) => {
  const { reload, pageId, filterBy } = contactsObj;
  const page = filterBy.page ? filterBy.page : pageId;
  const name = filterBy.name ? `&name=${filterBy.name}` : '';
  const page_size = filterBy.page_size ? `&page_size=${filterBy.page_size}` : '';
  const status = filterBy.status ? `&status=${filterBy.status}` : '';
  const tags = filterBy.tags ? `&tags=${filterBy.tags}` : '';
  const mailing_lists = filterBy.mailing_lists ? `&mailing_lists=${filterBy.mailing_lists}` : '';
  const search = filterBy.search ? `&q=${filterBy.search}` : '';
  const email = filterBy.email ? `&primary_email=${filterBy.email}` : '';
  const fields = filterBy.fields?.length ? filterBy.fields.map(item => item.id && item.value ? `&q=${item.id}:${item.value}` : '') : '';

  const [contacts, setContacts] = useState(); // State to set and return
  const getContacts = async () => {
    try {
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + 
      `/contacts/api/contacts/?page=${page ? page : 1}${page_size && page_size}${name && name}${status && status}${tags && tags}${mailing_lists && mailing_lists}${search && search}${email && email}${fields && fields}
      `,
      headers);

      setContacts(response.data);
    } catch (error) {
      console.log('Error getting contacts', error);
    }
  }

  useEffect(() => {
    getContacts();
  }, [reload, filterBy, pageId])

  return contacts ? contacts : null

}


export const useGetContactActivity = (contactId, reload) => {
  const [activity, setActivity] = useState(); // State to set and return
  const getContact = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/activity/api/activity/contact/${contactId}/`, headers);
      // const response = await axios.get(apiURL,
      //   {'withCredentials': true });
      setActivity(response.data);

    } catch (error) {
      console.log('Error getting contacts', error);
    }
  }

  useEffect(() => {
    if (contactId) {
      getContact();
    }
  }, [contactId, reload])

  return activity ? activity : null

}

// Hook for getting specific csv import
export const useGetCsvImport = (uploadId, reload) => {
  const [upload, setUpload] = useState(); // State to set and return
  const getUpload = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/contacts/api/imports/${uploadId}/`, headers);
      // const response = await axios.get(apiURL,
      //   {'withCredentials': true });

      setUpload(response.data);
    } catch (error) {
      console.log('Error getting upload', error);
    }
  }

  useEffect(() => {
    if (uploadId) {
      getUpload();
    }
  }, [uploadId, reload])

  return upload ? upload : null

}

// Hook for getting specific csv import
export const useGetCsvImports = (pageId, reload) => {
  const [upload, setUpload] = useState(); // State to set and return
  const getUpload = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/contacts/api/imports/?page=${pageId ? pageId : 1}`, headers);
      // const response = await axios.get(apiURL,
      //   {'withCredentials': true });

      setUpload(response.data);
    } catch (error) {
      console.log('Error getting imports upload', error);
    }
  }

  useEffect(() => {
    getUpload();
  }, [pageId, reload])

  return upload ? upload : null

}






























// ==========================================================================================================================
// ==========================================================================================================================
//      CONTACTS MAILING LISTS API
// ==========================================================================================================================
// ==========================================================================================================================



// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a contact
export const postContactToMailingList = async (mailingListObj) => {
  const { contactId, payload } = mailingListObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/contacts/api/contact_mailing_list_membership/${contactId}/`, payload,
      headers);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error adding a mailing list to contact', error);
    return {status:'error', error:'error'}
  }
}


// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------



// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------



// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------
// Function for deleting a contact
export const deleteContactFromMailingList = async (contactId, listId) => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.delete(
      api_endpoint() + `/contacts/api/contact_mailing_list_membership/${contactId}/${listId}/`,
      headers);
    return {status:'success'};
  } catch (error) {
    console.log('Error deleting contact from mailing list', error);
    return {status:'error', error:'error'}
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------
export const useGetContactMailingLists = (contactId, reload) => {
  const [mailingLists, setMailingLists] = useState(); // State to set and return
  const getMailingLists = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/contacts/api/contact_mailing_list_membership/${contactId}/`, headers);
      // const response = await axios.get(apiURL,
      //   {'withCredentials': true });
      setMailingLists(response.data);

    } catch (error) {
      console.log('Error getting contacts', error);
    }
  }

  useEffect(() => {
    if (contactId) {
      getMailingLists();
    }
  }, [contactId, reload])

  return mailingLists ? mailingLists : null

}































// ==========================================================================================================================
// ==========================================================================================================================
//      CONTACTS CUSTOM FIELDS API
// ==========================================================================================================================
// ==========================================================================================================================



// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a contact
export const createCustomField = async (customFieldObj) => {
  const { payload } = customFieldObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/contacts/api/custom-fields/', {...payload},
      headers);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error creating a custom field', error);
    return {status:'error', error:'error'}
  }
}


// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------



// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------

// Function for updating a contact
export const putCustomField = async (customFieldObj) => {
  const { payload, customFieldId } = customFieldObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/contacts/api/custom-fields/${customFieldId}/`, {...payload},
      headers);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error creating a custom field', error);
    return {status:'error', error:'error'}
  }
}



// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------
// Function for deleting a contact
export const deleteCustomField = async (fieldId) => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.delete(
      api_endpoint() + `/contacts/api/custom-fields/${fieldId}/`,
      headers);
    return {status:'success'};
  } catch (error) {
    console.log('Error creating a custom field', error);
    return {status:'error', error:'error'}
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting paginated custom fields
export const useGetAllCustomFields = (reload) => {
  const [customFields, setCustomFields] = useState(); // State to set and return
  const getAllCustomFields = async () => {
    try {
      let allCustomFields = [];
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
  
      // Function to fetch custom fields recursively
      const fetchCustomFields = async (url) => {
        const response = await axios.get(url);
        const { data } = response;

        if (data.results) {
          allCustomFields = [...allCustomFields, ...data.results];
        }

        if (data.next) {
          await fetchCustomFields(data.next); // Fetch next page
        } else {
          setCustomFields(allCustomFields); // Set the accumulated custom fields
        }
      };

      await fetchCustomFields(api_endpoint() + '/contacts/api/custom-fields/', headers);
    } catch (error) {
      console.log('Error getting contacts', error);
    }
  };

  useEffect(() => {
    if (reload || reload === undefined) {
      getAllCustomFields();
    }
  }, [reload])

  return customFields ? customFields : null

}

// Hook for getting paginated custom fields
export const useGetCustomFields = (reload, pageId) => {
  const [customFields, setCustomFields] = useState(); // State to set and return
  const getCustomFields = async () => {
    try {
      var headers = {
        'accept': 'application/json',
        'withCredentials': true,
      }
      const response = await axios.get(api_endpoint() + `/contacts/api/custom-fields/?page=${pageId ? pageId : 1}`, headers);
      // const response = await axios.get(apiURL,
      //   {'withCredentials': true });
      setCustomFields(response.data);
    } catch (error) {
      console.log('Error getting contacts', error);
    }
  }

  useEffect(() => {
    if (reload || reload === undefined || pageId) {
      getCustomFields();
    }
  }, [reload, pageId])

  return customFields ? customFields : null

}

export const getAllCustomFields = async () => {
  try {
    let allCustomFields = [];
    var headers = {
      'accept': 'application/json',
      'withCredentials': true,
    }

    // Function to fetch custom fields recursively
    const fetchCustomFields = async (url) => {
      const response = await axios.get(url);
      const { data } = response;

      if (data.results) {
        allCustomFields = [...allCustomFields, ...data.results];
      }

      if (data.next) {
        await fetchCustomFields(data.next); // Fetch next page
      } else {
        console.log('finished collecting fields') // Set the accumulated custom fields
      }
    };

    await fetchCustomFields(api_endpoint() + '/contacts/api/custom-fields/', headers);
    return {status: 'success', data: allCustomFields};
    
  } catch (error) {
    console.log('Error getting contacts', error);
    return {status: 'error', error: error}
  }
};


export const getCustomFields = async () => {
  try {
    var headers = {
      'accept': 'application/json',
      'withCredentials': true,
      // 'Authorization': 'Basic '+ encodedToken
    }
    const response = await axios.get(api_endpoint() + '/contacts/api/custom-fields/', headers);

    return {status: 'success', data: response.data.results};
  } catch (error) {
    console.log('Error getting contacts', error);
    return {status: 'error'}
  }
}







































// ==========================================================================================================================
// ==========================================================================================================================
//      TAGS API
// ==========================================================================================================================
// ==========================================================================================================================



// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a contact
export const postTag = async (tagObj) => {
  const { payload } = tagObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/contacts/api/tags/', {...payload},
      headers);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error creating a custom field', error);
    return {status: 'error'}
  }
}


// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------













// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------

// Function for updating a contact
export const putTag = async (tagObj) => {
  const { tagId, payload } = tagObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/contacts/api/tags/${tagId}/`, {...payload},
      headers);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error updating a tag', error);
    return {status: 'error'}
  }
}


// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

// Deletes tag from database -- DO NOT USE TO REMOVE A TAG FROM A USER!!
export const deleteTag = async (tagId) => {
  try {
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const deletedTag = await axios.delete(api_endpoint() + `/contacts/api/tags/${tagId}/`, headers);
    return {status:'success'};
  }

  catch (error) {
      console.log('Error deleting tag', error);
      return {status:'error'};
  }
}


// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting custom fields
export const useGetTags = (tagObj) => {
  const { pageId = 1, reload=null } = tagObj;
  const [tags, setTags] = useState(); // State to set and return
  const getTags = async () => {
    try {
      // var username = 'user_woo@example.com';
      // var password = 'string';

      // const token = `${username}:${password}`;
      // const encodedToken = Buffer.from(token).toString('base64');
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true,
        // 'Authorization': 'Basic '+ encodedToken
      }
      const response = await axios.get(api_endpoint() + `/contacts/api/tags/`, headers); //?page=${pageId ? pageId : 1}`, headers);
      // const response = await axios.get(apiURL,
      //   {'withCredentials': true });

      setTags(response.data.results);
    } catch (error) {
      console.log('Error getting contacts', error);
    }
  }

  useEffect(() => {
    getTags();
  }, [reload])

  return tags ? tags : null

}

// GET FILTERED SURVEYS
export const useGetFilteredTags = (tagObj) => {
  const { reload, filterBy } = tagObj;
  const [tags, setTags] = useState(); // State to set and return
  const page = filterBy.page || 1;
  const page_size = filterBy.page_size ? `&page_size=${filterBy.page_size}` : '';
  const search = filterBy.search ? `&q=${filterBy.search}` : '';

  const getTags = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/contacts/api/tags/?page=${page ? page : 1}${page_size && page_size}${search && search}`, headers);

      setTags(response.data);
    } catch (error) {
      console.log('Error getting tags', error);
    }
  }

  useEffect(() => {
      getTags();
  }, [reload, filterBy])

  return tags ? tags : null
}


// Hook for getting paginated custom fields
export const useGetAllTags = (reload) => {
  const [tags, setTags] = useState(); // State to set and return
  const getAllTags = async () => {
    try {
      let allTags = [];
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
  
      // Function to fetch custom fields recursively
      const fetchTags = async (url) => {
        const response = await axios.get(url);
        const { data } = response;

        if (data.results) {
          allTags = [...allTags, ...data.results];
        }

        if (data.next) {
          await fetchTags(data.next); // Fetch next page
        } else {
          setTags(allTags); // Set the accumulated custom fields
        }
      };

      await fetchTags(api_endpoint() + '/contacts/api/tags/', headers);
    } catch (error) {
      console.log('Error getting all tags', error);
    }
  };

  useEffect(() => {
    if (reload || reload === undefined) {
      getAllTags();
    }
  }, [reload])

  return tags ? tags : []

}







































// ==========================================================================================================================
// ==========================================================================================================================
//      FUNNELS API
// ==========================================================================================================================
// ==========================================================================================================================



// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a funnel
export const createFunnel = async (funnelObj) => {
  const { payload } = funnelObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/cms/api/funnels/', {...payload},
      headers);
    return response.data;
  } catch (error) {
    console.log('Error creating funnel', error);
  }
}

// Function for creating a stage
export const createStage = async (stageObj) => {
  const { funnelId, payload } = stageObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/cms/api/funnels/${funnelId}/stages/`, {...payload},
      headers);
    return response.data;
  } catch (error) {
    console.log('Error creating stage', error);
  }
}

// Function for creating a step
export const createStep = async (stepObj) => {
  const { funnelId, payload } = stepObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/cms/api/funnels/${funnelId}/steps/`, {...payload},
      headers);
    return response.data;
  } catch (error) {
    console.log('Error creating step', error);
  }
}


// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------
// Function for patching a funnel
export const patchFunnel = async (funnelObj) => {
  const { funnelId, payload } = funnelObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.patch(
      api_endpoint() + `/cms/api/funnels/${funnelId}/`, {...payload},
      headers);
    return response.data;
  } catch (error) {
    console.log('Error creating funnel', error);
  }
}


// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------
// Function for replacing/updating a funnel
export const putFunnel = async (funnelObj) => {
  const { funnelId, payload } = funnelObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/cms/api/funnels/${funnelId}/`, {...payload},
      headers);
    return response.data;
  } catch (error) {
    console.log('Error creating funnel', error);
  }
}

// Function for replacing/updating a stage
export const putStage = async (stageObj) => {
  const { funnelId, stageId, payload } = stageObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/cms/api/funnels/${funnelId}/stages/${stageId}/`, {...payload},
      headers);
    return response.data;
  } catch (error) {
    console.log('Error creating stage', error);
  }
}

// Function for replacing/updating a step
export const putStep = async (stepObj) => {
  const { funnelId, stepId, payload } = stepObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/cms/api/funnels/${funnelId}/steps/${stepId}/`, {...payload},
      headers);
    return response.data;
  } catch (error) {
    console.log('Error creating step', error);
  }
}


// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------


// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting all funnels
export const useGetFunnels = () => {
  const [funnels, setFunnels] = useState(); // State to set and return
  const getFunnels = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + '/cms/api/funnels/', headers);

      setFunnels(response.data.results);
    } catch (error) {
      console.log('Error getting funnels', error);
    }
  }

  useEffect(() => {
      getFunnels();
  }, [])

  return funnels ? funnels : null

}

// Hook for getting a specific funnel by id
export const useGetFunnel = (funnelId) => {
  const [funnel, setFunnel] = useState(); // State to set and return
  const getFunnel = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/cms/api/funnels/${funnelId}/`, headers);

      setFunnel(response.data);
    } catch (error) {
      console.log('Error getting funnels', error);
    }
  }

  useEffect(() => {
    if (funnelId) {
      getFunnel();
    }
    
  }, [funnelId])

  return funnel ? funnel : null

}

// Hook for getting all stages
export const useGetStages = (stagesObj) => {
  const { funnelId } = stagesObj;
  const [stages, setStages] = useState(); // State to set and return
  const getStages = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/cms/api/funnels/${funnelId}/stages/`, headers);

      getStages(response.data.results);
    } catch (error) {
      console.log('Error getting stages', error);
    }
  }

  useEffect(() => {
    if (funnelId) {
      setStages();
    }
      
  }, [funnelId])

  return stages ? stages : null

}

// Hook for getting a specific stage by id
export const useGetStage = (stageObj) => {
  const { funnelId, stageId } = stageObj;
  const [stage, setStage] = useState(); // State to set and return
  const getStage = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/cms/api/funnels/${funnelId}/stages/${stageId}/`, headers);

      getStage(response.data);
    } catch (error) {
      console.log('Error getting stages', error);
    }
  }

  useEffect(() => {
    if (funnelId, stageId) {
      setStage();
    }
      
  }, [funnelId, stageId])

  return stage ? stage : null

}

// Hook for getting all steps
export const useGetSteps = (stepsObj) => {
  const { funnelId } = stepsObj;
  const [steps, setSteps] = useState(); // State to set and return
  const getSteps = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/cms/api/funnels/${funnelId}/steps/`, headers);

      getSteps(response.data.results);
    } catch (error) {
      console.log('Error getting steps', error);
    }
  }

  useEffect(() => {
    if (funnelId) {
      setSteps();
    }
      
  }, [funnelId])

  return steps ? steps : null

}

// Hook for getting a specific step by id
export const useGetStep = (stepObj) => {
  const { funnelId, stepId } = stepObj;
  const [step, setStep] = useState(); // State to set and return
  const getStep = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/cms/api/funnels/${funnelId}/steps/${stepId}/`, headers);

      getStep(response.data);
    } catch (error) {
      console.log('Error getting steps', error);
    }
  }

  useEffect(() => {
    if (funnelId, stepId) {
      setStep();
    }
      
  }, [funnelId, stepId])

  return step ? step : null

}






































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// SURVEYS
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a survey
export const postSurvey = async (surveyObj) => {
  const { payload } = surveyObj;
  
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/surveys/api/surveys/`, {...payload},
      headers);
      return {status: 'success', data: response.data };

    } catch (error) {
    console.log('Error creating survey', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }

}

export const postSurveyShareLink = async (surveyObj) => {
  const { payload } = surveyObj;
  
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/surveys/api/surveys/share_link/create/`, {...payload},
      headers);
      return {status: 'success', data: response.data};

    } catch (error) {
    console.log('Error creating form', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }

}
// Function for creating a survey (SEE postSurveySubmission in ACTIONS)



// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating a survey with put api
export const putSurvey = async (surveyObj) => {
  const { payload, surveyId } = surveyObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(api_endpoint() + `/surveys/api/surveys/${surveyId}/`, {...payload}, headers);
    return {status: 'success', response: response.data};

  } catch (error) {
    console.log('Error updating survey', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }

}




// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteSurvey = async (surveyId) => {
  try {
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const deleted = await axios.delete(api_endpoint() + `/surveys/api/surveys/${surveyId}/`, headers);
    return {status:'success'};
  }

  catch (error) {
      console.log('Error deleting survey', error);
      return {status:'error'};
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// GET ALL SURVEYS
export const useGetAllSurveys = (reload) => {
  const [surveys, setSurveys] = useState(); // State to set and return
  const getAllSurveys = async () => {
    try {
      let allSurveys = [];
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
  
      // Function to fetch custom fields recursively
      const fetchSurveys = async (url) => {
        const response = await axios.get(url);
        const { data } = response;

        if (data.results) {
          allSurveys = [...allSurveys, ...data.results];
        }

        if (data.next) {
          await fetchSurveys(data.next); // Fetch next page
        } else {
          setSurveys(allSurveys); // Set the accumulated custom fields
        }
      };

      await fetchSurveys(api_endpoint() + '/surveys/api/surveys/', headers);

    } catch (error) {
      console.log('Error getting all surveys', error);
    }
  };

  useEffect(() => {
    if (reload || reload === undefined) {
      getAllSurveys();
    }
  }, [reload])

  return surveys ? surveys : null

}



// GET SURVEYS
export const useGetSurveys = (reload) => {
  const [surveys, setSurveys] = useState(); // State to set and return
  const getSurveys = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/surveys/api/surveys/`, headers);

      setSurveys(response.data.results);
    } catch (error) {
      console.log('Error getting surveys', error);
    }
  }

  useEffect(() => {
    if (reload) {
      getSurveys();
    }
  }, [reload])

  return surveys ? surveys : null

}

// GET FILTERED SURVEYS
export const useGetFilteredSurveys = (surveyObj) => {
  const { reload, filterBy } = surveyObj;
  const [surveys, setSurveys] = useState(); // State to set and return
  const page = filterBy.page || 1;
  const page_size = filterBy.page_size ? `&page_size=${filterBy.page_size}` : '';
  const search = filterBy.search ? `&q=${filterBy.search}` : '';

  const getSurveys = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/surveys/api/surveys/?page=${page ? page : 1}${page_size && page_size}${search && search}`, headers);

      setSurveys(response.data);
    } catch (error) {
      console.log('Error getting surveys', error);
    }
  }

  useEffect(() => {
    getSurveys();
  }, [reload, filterBy])

  return surveys ? surveys : null
}

// GET SINGLE SURVEY
export const useGetSurvey = (surveyId) => {
  const [survey, setSurvey] = useState();

  // GET SINGLE SURVEY
  const getSurvey = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/surveys/api/surveys/${surveyId}/`, headers);

      setSurvey(response.data);
    } catch (error) {
      console.log('Error getting surveys', error);
    }
  }

  useEffect(() => {
    if (surveyId) {
      getSurvey()
    }
    
  }, [surveyId])

  return survey ? survey : null

}

// GET ALL RESPONSES FOR A SPECIFIC SURVEY
export const useGetAllSurveyResponses = (surveyId, reload) => {
  const [responses, setResponses] = useState(); // State to set and return
  const getAllResponses = async () => {
    try {
      let allResponses = [];
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
  
      // Function to fetch custom fields recursively
      const fetchResponses = async (url) => {
        const response = await axios.get(url);
        const { data } = response;

        if (data.results) {
          allResponses = [...allResponses, ...data.results];
        }

        if (data.next) {
          await fetchResponses(data.next); // Fetch next page
        } else {
          setResponses(allResponses); // Set the accumulated custom fields
        }
      };

      await fetchResponses(api_endpoint() + `/surveys/api/surveys/${surveyId}/responses/`, headers);

    } catch (error) {
      console.log('Error getting responses', error);
    }
  };

  useEffect(() => {
    if (reload || reload === undefined) {
      getAllResponses();
    }
  }, [reload])

  return responses ? responses : null

}

// GET PAGINATED RESPONSES FOR A SPECIFIC SURVEY
export const useGetSurveyResponses = (surveyObj, reload) => {
  const { surveyId, pageId, pageSize } = surveyObj;
  const [responses, setResponses] = useState(); // State to set and return
  const getResponses = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/surveys/api/surveys/${surveyId}/responses/?page=${pageId}&page_size=${pageSize}`, headers);

      setResponses(response.data.results);
    } catch (error) {
      console.log('Error getting responses', error);
    }
  }

  useEffect(() => {
    if (surveyId && pageId && reload) {
      getResponses();
    }
    
  }, [reload, surveyId, pageId])

  return responses ? responses : null

}

// GET SINGLE RESPONSE FOR A SPECIFIC SURVEY
export const useGetSurveyResponse = (surveyId, responseId) => {
  const [response, setResponse] = useState();

  // GET SINGLE SURVEY
  const getResponse = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/surveys/api/surveys/${surveyId}/responses/${responseId}/`, headers);

      setResponse(response.data);
    } catch (error) {
      console.log('Error getting responsess', error);
    }
  }

  useEffect(() => {
    if (surveyId) {
      getResponse()
    }
    
  }, [surveyId])

  return response ? response : null

}


export const useGetSurveyAnalytics = (surveyId, reload) => {
  const [analytics, setAnalytics] = useState(); 
  const getAnalytics = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/surveys/api/surveys/analytics/${surveyId}/`, headers);

      setAnalytics(response.data);
    } catch (error) {
      console.log('Error getting analytics', error);
    }
  }

  useEffect(() => {
    getAnalytics();
  }, [reload])

  return analytics ? analytics : null
}







































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// FORMS
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a form
export const postForm = async (formObj) => {
  const { payload } = formObj;
  
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/forms/api/forms/`, {...payload},
      headers);
      return {status: 'success', data: response.data};

    } catch (error) {
    console.log('Error creating form', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }

}

// Function for creating a form
export const postFormShareLink = async (formObj) => {
  const { payload } = formObj;
  
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/forms/api/forms/create_share_link/`, {...payload},
      headers);
      return {status: 'success', data: response.data};

    } catch (error) {
    console.log('Error creating form', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }

}




// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating a form with put api
export const putForm = async (formObj) => {
  const { payload, formId } = formObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(api_endpoint() + `/forms/api/forms/${formId}/`, {...payload}, headers);
    return {status: 'success', data: response.data};

  } catch (error) {
    console.log('Error updating form', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}




// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteForm = async (formId) => {
  try {
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const deleted = await axios.delete(api_endpoint() + `/forms/api/forms/${formId}/`, headers);
    return {status:'success', data: deleted.data};
  }

  catch (error) {
    console.log('Error deleting form', error);
    return {status:'error'};
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// GET ATTACHMENTS SHARED OR OWNED BY CURRENT USER
export const useGetForms = ({reset: reset}) => {
  const [forms, setForms] = useState(); // State to set and return
  const getForms = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/forms/api/forms/`, headers);

      setForms(response.data.results);
    } catch (error) {
      console.log('Error getting forms', error);
    }
  }

  useEffect(() => {
    if (reset) {
      getForms();
    }
  }, [reset])

  return forms ? forms : null

}

// GET FILTERED EMAIL TEMPLATES
export const useGetFilteredForms = (formObj) => {
  const { reload, filterBy } = formObj;
  const [forms, setForms] = useState(); // State to set and return
  const page = filterBy.page || 1;
  const page_size = filterBy.page_size ? `&page_size=${filterBy.page_size}` : '';
  const search = filterBy.search ? `&q=${filterBy.search}` : '';

  const getForms = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/forms/api/forms/?page=${page ? page : 1}${page_size && page_size}${search && search}`, headers);

      setForms(response.data);
    } catch (error) {
      console.log('Error getting forms', error);
    }
  }

  useEffect(() => {
    getForms();
  }, [reload, filterBy])

  return forms ? forms : null
}



// GET FORM HOOK
export const useGetForm = (formId, reload) => {
  const [form, setForm] = useState();

  const getForm = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
        const response = await axios.get(api_endpoint() + `/forms/api/forms/${formId}/`, headers);

      setForm(response.data);
    } catch (error) {
      console.log('Error getting forms', error);
    }
  }

  useEffect(() => {
    if (formId || reload) {
      getForm()
    }
    
  }, [formId, reload])


  return form ? form : null

}











































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// LISTS (MAILING LISTS)
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------




// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------




// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------



// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteList = async (listId) => {
  try {
    var headers = {
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const deleted = await axios.delete(api_endpoint() + `/lists/api/lists/${listId}/`, headers); // This api doesn't exist yet
    return {status:'success'};
  }

  catch (error) {
      console.log('Error deleting list', error);
      return {status:'error'};
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// GET PAGINATED LISTS
export const useGetLists = (pageId, reload) => {
  const [lists, setLists] = useState(); // State to set and return
  const getLists = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/lists/api/all_lists/?page=${pageId ? pageId : 1}`, headers);
      setLists(response.data.results);
    } catch (error) {
      console.log('Error getting lists', error);
    }
  }

  useEffect(() => {
    if (reload || reload === undefined || pageId) {
      getLists();
    }
  }, [reload, pageId])

  return lists ? lists : null

}

// GET ALL LISTS
export const useGetAllLists = (reload) => {
  const [lists, setLists] = useState(); // State to set and return
  const getAllLists = async () => {
    try {
      let allLists = [];
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
  
      // Function to fetch custom fields recursively
      const fetchLists = async (url) => {
        const response = await axios.get(url);
        const { data } = response;

        if (data.results) {
          allLists = [...allLists, ...data.results];
        }

        if (data.next) {
          await fetchLists(data.next); // Fetch next page
        } else {
          setLists(allLists); // Set the accumulated custom fields
        }
      };

      await fetchLists(api_endpoint() + '/lists/api/all_lists/', headers);

    } catch (error) {
      console.log('Error getting lists', error);
    }
  };

  useEffect(() => {
    if (reload || reload === undefined) {
      getAllLists();
    }
  }, [reload])

  return lists ? lists : null

}

// GET FILTERED LISTS
export const useGetFilteredLists = (listsObj) => {
  const { reload, pageId, filterBy } = listsObj;
  const name = filterBy.name ? `&name=${filterBy.name}` : '';

  const [lists, setLists] = useState(); // State to set and return
  const getLists = async () => {
    try {
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + 
      `/lists/api/all_lists/?page=${pageId ? pageId : 1}${name && name}
      `,
      headers);

      setLists(response.data);
    } catch (error) {
      console.log('Error getting lists', error);
    }
  }

  useEffect(() => {
    getLists();
  }, [reload, filterBy, pageId])

  return lists ? lists : null

}



export const getLists = async () => {
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/lists/api/all_lists/`, headers);
    return(response.data.results);
  } catch (error) {
    console.log('Error getting lists', error);
  }
}


















































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// EMAIL TEMPLATES
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating an email template
export const createEmailTemplate = async (emailTemplateObj) => {
  const { storageFolderId, storagePayload, templatePayload } = emailTemplateObj;

  // STEP 1: Upload file to storage
  const uploadToStorage = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.post(
        api_endpoint() + `/storage/api/folders/${storageFolderId}/files/`, 
        // "https://webhook.site/5b2c3bcd-3356-4ee0-832d-3266443aa2cc",
        storagePayload,
        headers);
        return {status: "success", data: response};
  
      } catch (error) {
        console.log('Error uploading email template to storage', error);
        return {status: "error", error: error};
    }  
  }

  // STEP 2: Save link to database
  const saveEmailTemplate = async (payload) => {
    try {  
      // var headers = await auth_request_headers();
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.post(
        api_endpoint() + `/templates/api/email_templates/`, {...payload},
        headers);
        return {status: 'success', data: response.data};
  
      } catch (error) {
      console.log('Error creating emailTemplate', error);
      return {status: 'error', error: error};
    }  
  }

  try {
    const fileUpload = await uploadToStorage();
    if (fileUpload.status === "success") {
      const newTemplate = await saveEmailTemplate({...templatePayload, content_file: fileUpload.data.data.id})
      if (newTemplate.status === "success") {
        return {status: 'success', fileUploadData: fileUpload.data, newTemplateData: newTemplate.data};
      }
    }
  }

  catch (error) {
    console.log('Error uploading template', error);
    return {status: 'error', error: error};
  }



}



// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating a emailTemplate with put api
export const putEmailTemplate = async (emailTemplateObj) => {
  const { payload, emailTemplateId } = emailTemplateObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, {...payload}, headers);
    return {status: 'success', response: response.data};

  } catch (error) {
    console.log('Error updating emailTemplate', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }

}



// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating a emailTemplate with put api
export const patchEmailTemplate = async (emailTemplateObj) => {
  const { payload, emailTemplateId } = emailTemplateObj;
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, {...payload}, headers);
    return {status: 'success', response: response.data};

  } catch (error) {
    console.log('Error updating emailTemplate', error);
    return {status: 'error'};
  }

}



// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteEmailTemplate = async (emailTemplateId) => {
  try {
    var headers = {
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const deleted = await axios.delete(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, headers);
    return {status:'success'};
  }

  catch (error) {
      console.log('Error deleting emailTemplate', error);
      return {status:'error'};
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// GET ALL EMAIL TEMPLATE FILES
export const useGetAllEmailTemplates = (reload) => {
  const [emailTemplates, setEmailTemplates] = useState(); // State to set and return
  const getAllEmailTemplates = async () => {
    try {
      let allEmailTemplates = [];
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
  
      // Function to fetch custom fields recursively
      const fetchEmailTemplates = async (url) => {
        const response = await axios.get(url);
        const { data } = response;

        if (data.results) {
          allEmailTemplates = [...allEmailTemplates, ...data.results];
        }

        if (data.next) {
          await fetchEmailTemplates(data.next); // Fetch next page
        } else {
          setEmailTemplates(allEmailTemplates); // Set the accumulated custom fields
        }
      };

      await fetchEmailTemplates(api_endpoint() + '/templates/api/email_templates/', headers);

    } catch (error) {
      console.log('Error getting email templates', error);
    }
  };

  useEffect(() => {
    if (reload || reload === undefined) {
      getAllEmailTemplates();
    }
  }, [reload])

  return emailTemplates ? emailTemplates : null

}

// GET PAGINATED EMAIL TEMPLATE FILES
export const useGetEmailTemplates = ({reset}) => {
  const [emailTemplates, setEmailTemplates] = useState(); // State to set and return
  const getEmailTemplates = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/templates/api/email_templates/`, headers);
      setEmailTemplates(response.data.results);
    } catch (error) {
      console.log('Error getting emailTemplates', error);
    }
  }

  useEffect(() => {
    if (reset || reset === undefined) {
      getEmailTemplates();
    }
  }, [reset])

  return emailTemplates ? emailTemplates : null

}

// GET FILTERED EMAIL TEMPLATES
export const useGetFilteredEmailTemplates = (templateObj) => {
  const { reload, filterBy } = templateObj;
  const [emailTemplates, setEmailTemplates] = useState(); // State to set and return
  const page = filterBy.page || 1;
  const page_size = filterBy.page_size ? `&page_size=${filterBy.page_size}` : '';
  const search = filterBy.search ? `&name__icontains=${filterBy.search}` : '';

  const getEmailTemplates = async () => {
    try {
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/templates/api/email_templates/?page=${page ? page : 1}${page_size && page_size}${search && search}`, headers);

      setEmailTemplates(response.data);
    } catch (error) {
      console.log('Error getting emailTemplates', error);
    }
  }

  useEffect(() => {
    getEmailTemplates();
  }, [reload, filterBy])

  return emailTemplates ? emailTemplates : null
}



// GET SINGLE ATTACHMENT
export const useGetEmailTemplate = (emailTemplateId) => {
  const [emailTemplate, setEmailTemplate] = useState();

  // GET SINGLE ATTACHMENT
  const getEmailTemplate = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, headers);

      setEmailTemplate(response.data);
    } catch (error) {
      console.log('Error getting emailTemplates', error);
    }
  }

  useEffect(() => {
    if (emailTemplateId) {
      getEmailTemplate()
    }
    
  }, [emailTemplateId])


  return emailTemplate ? emailTemplate : null

}

export const getEmailTemplate = async (emailTemplateId) => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }

    const response = await axios.get(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, headers);

    return ({status: 'success', data: response.data})
  } catch (error) {
    console.log('Error getting emailTemplates', error);
  }
}

export const useGetEmailTemplateStorageFile = (emailTemplateId, reload) => {
  const [emailTemplate, setEmailTemplate] = useState();

  // GET SINGLE TEMPLATE
  const getEmailTemplate = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const template = await axios.get(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, headers);
      if (template.status === 200) {
        const storageFolder = await getEmailTemplateFolder();
        const storageFolderId = storageFolder.folderId;
        const contentFileId = template.data.content_file;
        const storageFile = await getStorageFile({folderId:storageFolderId, fileId: contentFileId});
        if (storageFile.status == "success") {
          try {
            const templateData = storageFile.data.file
            const response = await fetch(templateData, {
              // mode: 'no-cors',
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            });

            const getData = await response.text();

            setEmailTemplate({template: template.data, storageFile: storageFile.data, data: JSON.parse(getData)});

          } catch (error) {
            console.log('Error fetching data:', error);
          }

        }
      }
    } catch (error) {
      console.log('Error getting emailTemplates', error);
    }
  }

  useEffect(() => {
    if (emailTemplateId || reload) {
      getEmailTemplate()
    }
    
  }, [emailTemplateId, reload])


  return emailTemplate ? emailTemplate : null

}













































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// CAMPAIGNS
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------



// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------



// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------



// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteCampaign = async (campaignId) => {
  try {
    var headers = {
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const deleted = await axios.delete(api_endpoint() + `/campaigns/api/campaigns/${campaignId}/`, headers); // This api doesn't exist yet
    return {status:'success'};
  }

  catch (error) {
      console.log('Error deleting campaign', error);
      return {status:'error'};
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting all campaigns
export const useGetAllCampaigns = (reload) => {
  const [campaigns, setCampaigns] = useState(); // State to set and return
  const getAllCampaigns = async () => {
    try {
      let allCampaigns = [];
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
  
      // Function to fetch custom fields recursively
      const fetchCampaigns = async (url) => {
        const response = await axios.get(url);
        const { data } = response;

        if (data.results) {
          allCampaigns = [...allCampaigns, ...data.results];
        }

        if (data.next) {
          await fetchCampaigns(data.next); // Fetch next page
        } else {
          setCampaigns(allCampaigns); // Set the accumulated custom fields
        }
      };

      await fetchCampaigns(api_endpoint() + '/campaigns/api/campaigns/', headers);

    } catch (error) {
      console.log('Error getting campaigns', error);
    }
  };

  useEffect(() => {
    if (reload || reload === undefined) {
      getAllCampaigns();
    }
  }, [reload])

  return campaigns ? campaigns : null

}

// Hook for getting all campaigns
export const useGetAllCampaignsByStatus = (status, reload) => {
  const [campaigns, setCampaigns] = useState(); // State to set and return
  const getAllCampaigns = async () => {
    try {
      let allCampaigns = [];
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
  
      // Function to fetch custom fields recursively
      const fetchCampaigns = async (url) => {
        const response = await axios.get(url);
        const { data } = response;

        if (data.results) {
          allCampaigns = [...allCampaigns, ...data.results];
        }

        if (data.next) {
          await fetchCampaigns(data.next); // Fetch next page
        } else {
          setCampaigns(allCampaigns); // Set the accumulated custom fields
        }
      };

      await fetchCampaigns(api_endpoint() + `/campaigns/api/campaigns/?status=${status}`, headers);

    } catch (error) {
      console.log('Error getting campaigns by status', error);
    }
  };

  useEffect(() => {
    if (reload || reload === undefined) {
      getAllCampaigns();
    }
  }, [reload])

  return campaigns ? campaigns : null

}

// Hook for getting paginated campaigns
export const useGetCampaigns = (pageId, reload ) => {
  const [campaigns, setCampaigns] = useState(); // State to set and return
  const getCampaigns = async () => {
    try {
      var headers = {
        'accept': 'application/json',
        'withCredentials': true,
      }
      const response = await axios.get(api_endpoint() + `/campaigns/api/campaigns/?page=${pageId ? pageId : 1}`, headers);
      setCampaigns(response.data);

    } catch (error) {
      console.log('Error getting campaigns', error);
    }
  }

  useEffect(() => {
    if (reload || reload === undefined || pageId) {
      getCampaigns();
    }
  }, [reload, pageId])

  return campaigns ? campaigns : null

}

// Hook for getting filtered / queried campaigns
// reload state when the filterBy state updates
export const useGetFilteredCampaigns = (campaignsObj) => {
  const { reload, pageId, filterBy } = campaignsObj;
  const name = filterBy.name ? `&name=${filterBy.name}` : '';
  const status = filterBy.status ? `&status=${filterBy.status}` : '';
  const mailing_lists = filterBy.mailing_lists ? filterBy.mailing_lists.map(x => `&mailing_list=${x}`).toString().replaceAll(',', '') : '';  // filterBy.mailing_lists must be an array 

  const [campaigns, setCampaigns] = useState(); // State to set and return
  const getCampaigns = async () => {
    try {
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + 
      `/campaigns/api/campaigns/?page=${pageId ? pageId : 1}${name && name}${status && status}${mailing_lists && mailing_lists}
      `,
      headers);

      setCampaigns(response.data);
    } catch (error) {
      console.log('Error getting campaigns', error);
    }
  }

  useEffect(() => {
    getCampaigns();
  }, [reload, filterBy, pageId])

  return campaigns ? campaigns : null

}


// GET SINGLE CAMPAIGN
export const useGetCampaign = (campaignId) => {
  const [campaign, setCampaign] = useState();

  // GET SINGLE CAMPAIGN
  const getCampaign = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/campaigns/api/campaigns/${campaignId}/`, headers);

      setCampaign(response.data);
    } catch (error) {
      console.log('Error getting campaigns', error);
    }
  }

  useEffect(() => {
    if (campaignId) {
      getCampaign()
    }
    
  }, [campaignId])


  return campaign ? campaign : null

}

export const getFilteredCampaigns = async (campaignsObj) => {
  const { pageId, filterBy } = campaignsObj;
  const name = filterBy.name ? `&name=${filterBy.name}` : '';
  const status = filterBy.status ? `&status=${filterBy.status}` : '';
  const mailing_lists = filterBy.mailing_lists ? filterBy.mailing_lists.map(x => `&mailing_list=${x}`).toString().replaceAll(',', '') : '';  // filterBy.mailing_lists must be an array 

  try {
    var headers = {
      'accept': 'application/json',
      'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + 
    `/campaigns/api/campaigns/?page=${pageId ? pageId : 1}${name && name}${status && status}${mailing_lists && mailing_lists}
    `,
    headers);
    return {status:'success', data: response.data};
  } catch (error) {
    console.log('Error getting campaigns', error);
  }
}

export const getCampaign = async (campaignId) => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/campaigns/api/campaigns/${campaignId}/`, headers);
    return response.data;

  } catch (error) {
    console.log('Error getting campaigns', error);
  }
}












































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// STORAGE FILES
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating a private folder (can also be public if set in folderPayload)
export const postFolder = async (folderObj) => {
  const { folderPayload } = folderObj;

  try {
    // var headers = await auth_request_headers();
    var headers = {
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/storage/api/folders/`, 
      folderPayload,
      headers);
      return {status: "success", data: response.data};

    } catch (error) {
      console.log('Error creating folder', error);
      return {status: "error"};
  }
}

// Function for creating a public folder
export const postPublicFolder = async (folderObj) => {
  const { folderPayload } = folderObj;

  try {
    // var headers = await auth_request_headers();
    var headers = {
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/storage/api/folders/`, 
      folderPayload,
      headers);
      return {status: "success", data: response.data};

    } catch (error) {
      console.log('Error creating folder', error);
      return {status: "error"};
  }
}

// Function for creating an email template
export const createStorageFile = async (storageFileObj) => {
  const { storageFilePayload, folderId } = storageFileObj;

  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'multipart/form-data; boundary=---WebKitFormBoundary7MA4YWxkTrZu0gW',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/storage/api/folders/${folderId}/files/`, 
      storageFilePayload,
      headers);
      return {status: "success", data: response.data};

    } catch (error) {
      console.log('Error uploading file to storage', error);
      return {status: "error"};
  }
}



// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating a emailTemplate with put api
export const putStorageFile = async (storageFileObj) => {
  const { storageFilePayload, folderId, fileId } = storageFileObj;

  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'multipart/form-data; boundary=---WebKitFormBoundary7MA4YWxkTrZu0gW',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/storage/api/folders/${folderId}/files/${fileId}/`, 
      storageFilePayload,
      headers);
      return {status: "success", data: response.data};

    } catch (error) {
      console.log('Error uploading file to storage', error);
      return {status: "error"};
  }
}



// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------
// Function for updating a emailTemplate with put api
export const patchStorageFile = async (storageFileObj) => {
  const { storageFilePayload, folderId, fileId } = storageFileObj;

  try {
    // var headers = await auth_request_headers();
    var headers = {
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.patch(
      api_endpoint() + `/storage/api/folders/${folderId}/files/${fileId}/`, 
      // "https://webhook.site/5b2c3bcd-3356-4ee0-832d-3266443aa2cc",
      storageFilePayload,
      headers);
      return {status: "success", data: response};

    } catch (error) {
      console.log('Error uploading file to storage', error);
  }
}



// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteStorageFile = async ({ folderId, fileId }) => {
  try {
    var headers = {
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const deleted = await axios.delete(api_endpoint() + `/storage/api/folders/${folderId}/files/${fileId}/`)
    return {status:'success'};
  }

  catch (error) {
      console.log('Error deleting file', error);
      return {status:'error'};
  }
}



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// GET FILE FROM STORAGE WHEN YOU KNOW THE FOLDER ID
export const useGetPublicFolder = (obj) => {
  const { reload } = obj;
  const [publicFolder, setPublicFolder] = useState(); // State to set and return
  const getPublicFolder = async () => {
    try {
      var headers = {
        // 'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/storage/api/folders/name/Public/`, headers);
      setPublicFolder(response.data);
    } catch (error) {
      console.log('Error getting folder', error);
      if (error.response?.status === 404) {
        const publicFolder = await postPublicFolder({folderPayload: { name:'Public', is_public:true }});
        if (publicFolder.status === "success") {
          getPublicFolder();
        }
      }
    }
  }

  useEffect(() => {
      getPublicFolder();
  }, [reload])

  return publicFolder ? publicFolder : null

}

export const getPublicFolder = async () => {
  try {
    var headers = {
      // 'Content-Type': 'multipart/form-data',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/storage/api/folders/name/Public/`, headers);
    return {status: "success", data: response.data};
  } catch (error) {
    console.log('Error getting folder', error);
    if (error.response?.status === 404) {
      const publicFolder = await postPublicFolder({folderPayload: { name:'Public', is_public:true }});
      if (publicFolder.status === "success") {
        getPublicFolder();
      }
    } else {
      return {status: 'Error', error: errorMessageString(error.response.data)}
    }
  }
}



// GET ATTACHMENTS SHARED OR OWNED BY CURRENT USER
export const useGetStorageFiles = ({reload, folderId}) => {
  const [storageFiles, setStorageFiles] = useState(); // State to set and return
  const getStorageFiles = async () => {
    try {
      var headers = {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/storage/api/folders/${folderId}/files/`, headers);
      setStorageFiles(response.data.results);
    } catch (error) {
      console.log('Error getting emailTemplates', error);
    }
  }

  useEffect(() => {
    if (reload || reload === undefined) {
      getStorageFiles();
    }
  }, [reload])

  return storageFiles ? storageFiles : null

}

// GET FILE FROM STORAGE WHEN YOU KNOW THE FOLDER ID
export const useGetStorageFile = (obj) => {
  const { reload, folderId, fileId } = obj;
  const [storageFile, setStorageFile] = useState(); // State to set and return
  const getStorageFile = async () => {
    try {
      var headers = {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/storage/api/folders/${folderId}/files/${fileId}/`, headers);
      setStorageFile(response.data);
    } catch (error) {
      console.log('Error getting file', error);
    }
  }

  useEffect(() => {
    if ( folderId && fileId ) {
      getStorageFile();
    }
  }, [reload, folderId, fileId])

  return storageFile ? storageFile : null

}

// GET ALL CUSTOM FIELD FILES FROM STORAGE WHEN YOU DON'T KNOW THE FOLDER ID
export const useGetAllStorageFileUploads = (obj) => {
  const { reload, fileId } = obj;
  const [storageFile, setStorageFile] = useState(); // State to set and return
  const getStorageFile = async () => {
    try {
      var headers = {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const storageFolder = await getCustomFieldFolder();
      const storageFolderId = storageFolder.folderId;
      const response = await axios.get(api_endpoint() + `/storage/api/folders/${storageFolderId}/files/`, headers);
      setStorageFile(response.data);
    } catch (error) {
      console.log('Error getting file', error);
    }
  }

  useEffect(() => {
    if (fileId) {
      getStorageFile();
    }
  }, [reload, fileId])

  return storageFile ? storageFile : null

}

export const getAllStorageFileUploads = async () => {
  try {
    var headers = {
      'Content-Type': 'multipart/form-data',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const storageFolder = await getCustomFieldFolder();
    const storageFolderId = storageFolder.folderId;
    const response = await axios.get(api_endpoint() + `/storage/api/folders/${storageFolderId}/files/`, headers);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error getting file', error);
    return {status: 'error', error: error}
  }
}

export const getAllPublicStorageFileUploads = async (storageFolderId) => {
  try {
    var headers = {
      'Content-Type': 'multipart/form-data',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/storage/api/folders/${storageFolderId}/files/`, headers);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error getting public storage file(s)', error);
    return {status: 'error', error: error}
  }
}


// GET FILE FROM STORAGE WHEN YOU DON'T KNOW THE FOLDER ID
export const useGetStorageFileUpload = (obj) => {
  const { reload, fileId } = obj;
  const [storageFile, setStorageFile] = useState(); // State to set and return
  const getStorageFile = async () => {
    try {
      var headers = {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const storageFolder = await getCustomFieldFolder();
      const storageFolderId = storageFolder.folderId;
      const response = await axios.get(api_endpoint() + `/storage/api/folders/${storageFolderId}/files/${fileId}/`, headers);
      setStorageFile(response.data);
    } catch (error) {
      console.log('Error getting file', error);
    }
  }

  useEffect(() => {
    if (fileId) {
      getStorageFile();
    }
  }, [reload, fileId])

  return storageFile ? storageFile : null

}

// GET RICH TEXT FILE FROM STORAGE
export const useGetStorageFileRichText = (obj) => {
  const { reload, fileId } = obj;
  const [storageFile, setStorageFile] = useState(); // State to set and return
  const getStorageFile = async () => {
    try {
      var headers = {
        'Content-Type': 'multipart/form-data',
        'accept': 'application/json',
        // 'withCredentials': true
      }
      const storageFolder = await getEmailTemplateFolder();
      const folderId = storageFolder.folderId;
      const storageResponse = await axios.get(api_endpoint() + `/storage/api/folders/${folderId}/files/${fileId}/`, headers);
      if (storageResponse.status === 200) {
        try {
          const response = await fetch(storageResponse.data.file, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          const getHtml = await response.text();
          setStorageFile(getHtml);

        } catch (error) {
          console.log('Error fetching data:', error);
        }        
        
      }
    } catch (error) {
      console.log('Error getting file', error);
    }
  }

  useEffect(() => {
    if ( fileId ) {
      getStorageFile();
    }
  }, [reload, fileId])

  return storageFile ? storageFile : null

}

export const getStorageFile = async (obj) => {
  const { folderId, fileId } = obj;
  try {
    var headers = {
      'Content-Type': 'multipart/form-data',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.get(api_endpoint() + `/storage/api/folders/${folderId}/files/${fileId}/`, headers);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error getting file', error);
    return {status: 'error', error: error};
  }
}


export const getStorage = async (emailTemplateId) => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }

    const response = await axios.get(api_endpoint() + `/templates/api/email_templates/${emailTemplateId}/`, headers);

    return ({status: 'success', data: response.data})
  } catch (error) {
    console.log('Error getting file', error);
  }
}

// USE THIS TO GET THE APPROPRIATE STORAGE FILE FOR ALL EMAIL TEMPLATES AND RICH TEXT CUSTOM FIELD FILES
// IMPORTANT NOTE: Rich Text custom fields use the email template folder instead of the custom field folder
export const getEmailTemplateFolder = async () => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }

    const response = await axios.get(api_endpoint() + `/templates/api/template_folder/`, headers);
    const folderId = response.data.folder;
    return ({status: 'success', folderId: folderId, data: response.data})
  } catch (error) {
    console.log('Error getting emailTemplates', error);
  }
}

// USE THIS TO GET THE APPROPRIATE STORAGE FILE FOR ALL OTHER CUSTOM FIELD FILES SUCH AS IMAGES
// IMPORTANT NOTE: Rich Text custom fields use the email template folder instead of the custom field folder
export const getCustomFieldFolder = async () => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }

    const response = await axios.get(api_endpoint() + `/contacts/api/custom_fields_folder/`, headers);
    const folderId = response.data.folder;
    return ({status: 'success', folderId: folderId, data: response.data})
  } catch (error) {
    console.log('Error getting emailTemplates', error);
  }
}












































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// PAYMENT
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------
// Function for creating an email template
export const postPayment = async (productObj) => {
  const { productId, productPayload } = productObj;

  try {
    // var headers = await auth_request_headers();
    var headers = {
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/payment/api/checkout/${productId}/`, productPayload, headers);
      return {status: "success", data: response.data};

    } catch (error) {
      console.log('Error with checkout', error);
      return {status: "error"};
  }
}

// Function for creating an email template
export const postManageSubscription = async () => {

  try {
    // var headers = await auth_request_headers();
    var headers = {
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + `/payment/api/manage_subscriptions/`, headers);
      return {status: "success", data: response.data};

    } catch (error) {
      console.log('Error with checkout', error);
      return {status: "error"};
  }
}



// ------------------------------------------------------------------------------------
// PUT OPERATIONS
// ------------------------------------------------------------------------------------




// ------------------------------------------------------------------------------------
// PATCH OPERATIONS
// ------------------------------------------------------------------------------------



// ------------------------------------------------------------------------------------
// DELETE OPERATIONS
// ------------------------------------------------------------------------------------



// ------------------------------------------------------------------------------------
// GET OPERATIONS
// ------------------------------------------------------------------------------------

// Hook for getting all subscriptions
export const useGetAllSubscriptions = (reload) => {
  const [subscriptions, setSubscriptions] = useState(); // State to set and return
  const getAllSubscriptions = async () => {
    try {
      let allSubscriptions = [];
      var headers = {
        'Content-Type': 'application/json',
        'accept': 'application/json',
        // 'withCredentials': true
      }
  
      // Function to fetch custom fields recursively
      const fetchSubscriptions = async (url) => {
        const response = await axios.get(url);
        const { data } = response;

        if (data.results) {
          allSubscriptions = [...allSubscriptions, ...data.results];
        }

        if (data.next) {
          await fetchSubscriptions(data.next); // Fetch next page
        } else {
          setSubscriptions(allSubscriptions); // Set the accumulated custom fields
        }
      };

      await fetchSubscriptions(api_endpoint() + '/payment/api/subscriptions/', headers);

    } catch (error) {
      console.log('Error getting subscriptions', error);
    }
  };

  useEffect(() => {
    if (reload || reload === undefined) {
      getAllSubscriptions();
    }
  }, [reload])

  return subscriptions ? subscriptions : null

}

















































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// GIPHY
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// POST OPERATIONS
// ------------------------------------------------------------------------------------

// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------
// Function for searching for Giphy gifs
export const getGiphyGifSearch = async (term) => {
  try {
    const response = await fetch(
      `https://api.giphy.com/v1/gifs/search?api_key=YGSbqyZ66CmxQ6Wrx4KKc7JfY2BsWxIe&limit=12&q=${term}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data.data); // Array of GIF objects
    return data.data; // Return the array of GIFs
  } catch (error) {
    console.error("Error fetching Giphy API:", error);
    return { status: "error", message: error.message };
  }
};

// Function for searching for Giphy stickers
export const getGiphyStickerSearch = async (term) => {
  try {
    const response = await fetch(
      `https://api.giphy.com/v1/stickers/search?api_key=YGSbqyZ66CmxQ6Wrx4KKc7JfY2BsWxIe&limit=12&q=${term}`
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    console.log(data.data); // Array of sticker objects
    return data.data; // Return the array of stickers
  } catch (error) {
    console.error("Error fetching Giphy API:", error);
    return { status: "error", message: error.message };
  }
};













































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// MESSAGES (FIREBASE)
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// =============================================================
// COLLECTION: messages
// =============================================================


// Get all messages
export const useGetMessages = () => {
  const [messages, setFeedback] = useState();

  const getMessages = async () => {
    try {
      const collectionRef = collection(db, 'messages');

      onSnapshot(collectionRef, (snapshot) => {
        setFeedback(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting messages', error);
    }
  }

  useEffect(() => {
    getMessages();
  }, [])

  return messages ? messages : null
}


export const postMessage = async (message) => {
  try {
    const messageRef = collection(db, 'messages');
    const messagePayload = {
      ...message,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    }

    const sendMessage = await addDoc(messageRef, messagePayload, {merge:true});
    return {status: 'success'}

  } catch (error) {
    console.log('Error sending message', error);
    return {status: 'error', error: error};
  }

}
















































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// FEEDBACK (FIREBASE)  TEMPORARY!!
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// =============================================================
// COLLECTION: feedback
// =============================================================


// Get all feedback
export const useGetFeedback = () => {
  const [feedback, setFeedback] = useState();

  const getFeedback = async () => {
    try {
      const collectionRef = collection(db, 'feedback');

      onSnapshot(collectionRef, (snapshot) => {
        setFeedback(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting feedback', error);
    }
  }

  useEffect(() => {
      getFeedback();
  }, [])

  return feedback ? feedback : null
}






// =============================================================
// COLLECTION: featureRequests
// =============================================================


// Get all reported feature requests
export const useGetFeatureRequests = () => {
  const [featureRequests, setFeatureRequests] = useState();

  const getFeatureRequests = async () => {
    try {
      const collectionRef = collection(db, 'featureRequests');

      onSnapshot(collectionRef, (snapshot) => {
        setFeatureRequests(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting feature requests', error);
    }
  }

  useEffect(() => {
      getFeatureRequests();
  }, [])

  return featureRequests ? featureRequests : null
}






// =============================================================
// COLLECTION: bugs
// =============================================================


// Get all reported feature requests
export const useGetBugs = () => {
  const [bugItems, setBugItems] = useState();

  const getBugs = async () => {
    try {
      const collectionRef = collection(db, 'bugs');
      onSnapshot(collectionRef, (snapshot) => {
        setBugItems(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting feature requests', error);
    }
  }

  useEffect(() => {
      getBugs();
  }, [])

  return bugItems ? bugItems : null
}





















































//==================================================================================================================
//==================================================================================================================
//==================================================================================================================
//
// SUBMISSIONS
//
//==================================================================================================================
//==================================================================================================================
//==================================================================================================================

// ------------------------------------------------------------------------------------
// CREATE / UPDATE OPERATIONS
// ------------------------------------------------------------------------------------

export const createSubmission = async (folder, obj) => {

  if (obj.id) {
    try {
        const submissionRef = doc(db, `submissions-${folder}`, obj.id);
        const submissionPayload = {
          ...obj,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
        }

        const newSubmission = await setDoc(submissionRef, submissionPayload, {merge:true});
        return "success"

    }

    catch (error) {
        console.log('Error adding prospect', error);
    }
  } else {
    try {
      const submissionRef = collection(db, `submissions-${folder}`);
      const submissionPayload = {
        ...obj,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      }

      const newSubmission = await addDoc(submissionRef, submissionPayload, {merge:true});
      return "success"

    }

    catch (error) {
        console.log('Error adding prospect', error);
    }

  }
}

export const updateSubmission = async (folder, obj) => {
  try {
      const submissionRef = doc(db, `submissions-${folder}`, obj.id);
      const submissionPayload = {
        ...obj,
        updatedAt: serverTimestamp(),
      }

      const updatedSubmission = await setDoc(submissionRef, submissionPayload, {merge:true});
      return "success"

  }

  catch (error) {
      console.log('Error adding prospect', error);
  }
}




// ------------------------------------------------------------------------------------
// DELETE / DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteSubmission = async (folder, obj) => {
  try {
    const submissionRef = doc(db, 'deletedSubmissions', obj.id);
    const submissionPayload = {
      ...obj,
      deletedAt: serverTimestamp(),
    }

    const newSubmission = await setDoc(submissionRef, submissionPayload);
    await deleteDoc(doc(db, `submissions-${folder}`, obj.id));


  }

  catch (error) {
      console.log('Error deleting prospect', error);
  }
}

export const archiveSubmissionForConsidering = async (folder, obj) => {
  try {
    const consideringRef = doc(db, 'considering', obj.id);
    const consideringPayload = {
      ...obj,
      voteUp: [obj.email],
      voteDown: [],
      consideredAt: serverTimestamp(),
    }

    const newConsidering = await setDoc(consideringRef, consideringPayload);
    await deleteDoc(doc(db, `submissions-${folder}`, obj.id));

  }

  catch (error) {
      console.log('Error archiving submission', error);
  }
}




// ------------------------------------------------------------------------------------
// READ OPERATIONS
// ------------------------------------------------------------------------------------

// GET SUBMISSIONS OF A SPECIFIC FORM (FOLDER)
export const useGetSubmissions = (folder) => {
  const [submissions, setSubmissions] = useState();
  const authContext = useAuth();

  const getSubmissions = async () => {
    try {
      const collectionRef = collection(db, `submissions-${folder}`);
      const q = query(collectionRef, where('shared', 'array-contains', authContext.user.uid))
      onSnapshot(collectionRef, (snapshot) => {
          setSubmissions(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))        
      })
    } catch (error) {
      console.log('Error getting submissions', error);
    }
  }

  useEffect(() => {
      getSubmissions()
  }, [])

  return submissions ? submissions : null

}

// GET SINGLE ATTACHMENT
export const useGetSubmission = (folder, submissionId) => {
  const [submission, setSubmission] = useState();
  const authContext = useAuth();

  // GET SINGLE ATTACHMENT
  const getSubmission = async () => {
    try {
      const docRef = doc(db, `submissions-${folder}`, submissionId);
      onSnapshot(docRef, (doc) => {
        setSubmission({...doc.data(), id: doc.id})
      })

      // const docSnap = await getDoc(docRef);
      // if (docSnap.exists()) {
      //   setSubmission({ ...docSnap.data(), id: docSnap.id })
      // } else {
      //   setSubmission(null)
      // }
      
    } catch (error) {
      console.log('Error getting single submission', error);
    }
  }

  useEffect(() => {
    if (submissionId) {
      getSubmission()
    }
    
  }, [submissionId])


  return submission ? submission : null

}


















































// ==========================================================================================================================
// ==========================================================================================================================
//      USER API
// ==========================================================================================================================
// ==========================================================================================================================

// ------------------------------------------------------------------------------------
// READ / GET OPERATIONS
// ------------------------------------------------------------------------------------

// GET CLIENTS FROM CLIENTS COLLECTION
// BASED ON SPECIFIC QUERIES FROM OBJ MAP
export const useGetClients = (obj) => {
  const [clients, setClients] = useState();

  const getAllClients = async () => {
    try {
      const collectionRef = collection(db, 'clients');
      const q = query(collectionRef, orderBy('updatedAt', 'desc'), limit(20));
      onSnapshot(q, (snapshot) => {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting clients', error);
    }
  }

  const getClientsByStatus = async () => {
    try {
      const collectionRef = collection(db, 'clients');
      const q = query(collectionRef, where('status', '==', obj.status), orderBy('updatedAt', 'desc'), limit(20));
      onSnapshot(q, (snapshot) => {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting clients', error);
    }
  }

  // Get clients based on a certain field and field value
  // obj({field, value, limit})
  const getClientsByField = async (obj) => {
    try {
      const collectionRef = collection(db, 'clients');
      const q = query(collectionRef, where(obj.field, '==', obj.value), orderBy('updatedAt', 'desc'), limit(obj.limit ? obj.limit : 20));
      onSnapshot(q, (snapshot) => {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting clients', error);
    }
  }

  const getClientsByCampaign = async () => {
    try {
      const collectionRef = collection(db, 'clients');
      const q = query(collectionRef, where('campaign', '==', obj.campaign), orderBy('updatedAt', 'desc'), limit(20));
      onSnapshot(q, (snapshot) => {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting clients', error);
    }
  }

  useEffect(() => {
    if (obj.type === 'all') {
      getAllClients();
    } else
    if (obj.type === 'status') {
      getClientsByStatus();
    } else
    if (obj.type === 'campaign') {
      getClientsByCampaign();
    }

  }, [obj.type])

  return clients ? clients : null

}

// GET CLIENTS FROM CLIENTS COLLECTION
// BASED ON SPECIFIC FIELDS AND FIELD VALUES
// obj({field, value, limit})
export const useGetClientsByField = (obj) => {
  const [clients, setClients] = useState();
  const [noClients, setNoClients] = useState(false);

  // Get clients based on a certain field and field value
  // obj({field, value, limit})
  const getClientsByField = async (obj) => {
    try {
      const collectionRef = collection(db, 'clients');
      const q = query(collectionRef, where(obj.field, '==', obj.value), orderBy('updatedAt', 'desc'), limit(obj.limit ? obj.limit : 20));
      onSnapshot(q, (snapshot) => {
        if (snapshot.size > 0) {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
        } else {
          setNoClients(true);
        }
        
      })
    } catch (error) {
      console.log('Error getting clients', error);
      setNoClients(true);
    }
  }

  const getAllClients = async () => {
    try {
      const collectionRef = collection(db, 'clients');
      const q = query(collectionRef, orderBy('updatedAt', 'desc'), limit(obj.limit ? obj.limit : 20));
      onSnapshot(q, (snapshot) => {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
    } catch (error) {
      console.log('Error getting clients', error);
    }
  }


  useEffect(() => {
    if (obj) {
      // getClientsByField()
      setClients(mockData.contacts.contacts)
    }
  }, [obj])

  useEffect(() => {
    if (noClients && obj) {
      getAllClients()
    }
  }, [obj, noClients])

  return clients ? clients : null

}

// GET ALL CLIENTS FROM CLIENTS COLLECTION
export const useGetAllClients = (id) => {
  const [clients, setClients] = useState();
  const getAllClients = async () => {
    try {
      const collectionRef = collection(db, 'clients');
      // const q = query(collectionRef, where('teacherId', '==', authContext.account.id), orderBy('updatedAt', 'desc'));
      onSnapshot(collectionRef, (snapshot) => {
          setClients(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}) ))
      })
      } catch (error) {
      console.log('Error getting clients', error);
      }
    }

  useEffect(() => {
    if (id) {
      getAllClients();
    }
  }, [id])

  return clients ? clients : null

}

  
// ------------------------------------------------------------------------------------
// CREATE / POST OPERATIONS
// ------------------------------------------------------------------------------------

export const addProspect = async (obj) => {
  try {
      const clientRef = collection(db, 'clients');
      const clientPayload = {
        ...obj,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      }

      const newClient = await addDoc(clientRef, clientPayload);

  }

  catch (error) {
    console.log('Error adding prospect', error);
  }
}

export const updateProspect = async (obj) => {
  try {
      const clientRef = doc(db, 'clients', obj.id);
      const clientPayload = {
        ...obj,
        updatedAt: serverTimestamp()
      }

      const updatedClient = await setDoc(clientRef, clientPayload, {merge:true});

  }

  catch (error) {
    console.log('Error adding prospect', error);
  }
}

// Hook
export const useFetchSubs = () => {
  const [subs, setSubs] = useState(); // State to set and return
  const authContext = useAuth();
  const fetchSubs = async () => {
    try {
      var headers = await auth_request_headers();
      const response = await axios.get(api_endpoint() + '/api/auth/subusers/', {headers});
      setSubs(response.data.results);
      authContext.setSubs(response.data.results);
    } catch (error) {
      console.log('Error fetching subs', error);
    }
  }

  useEffect(() => {
    if (authContext.user) {
      fetchSubs();
    }
  }, [authContext.user])

  return subs ? subs : null

}



// ------------------------------------------------------------------------------------
// DELETE / DELETE OPERATIONS
// ------------------------------------------------------------------------------------

export const deleteProspect = async (obj) => {
  try {
    const clientRef = doc(db, 'deleted', obj.id);
    const clientPayload = {
      ...obj,
      deletedAt: serverTimestamp(),
    }

    const newClient = await setDoc(clientRef, clientPayload);
    await deleteDoc(doc(db, 'clients', obj.id));


  }

  catch (error) {
      console.log('Error deleting prospect', error);
  }
}

// ------------------------------------------------------------------------------------
// USERS MUTATIONS
// ------------------------------------------------------------------------------------

export const useGetUsers = (usersObj) => {
  const { reload, pageId } = usersObj;
  const [users, setUsers] = useState(); // State to set and return
  const getUsers = async () => {
    try {
      // var headers = await auth_request_headers();
      var headers = {
        'accept': 'application/json',
        'withCredentials': true
      }
      const response = await axios.get(api_endpoint() + `/accounts/api/subusers/?page=${pageId ? pageId : 1}`, headers);
      // const response = await axios.get(apiURL,
      //   {'withCredentials': true });

      setUsers(response.data);
    } catch (error) {
      console.log('Error getting users', error);
    }
  }

  useEffect(() => {
    getUsers();
  }, [reload, pageId])

  return users ? users : null

}

export const createUser = async (userObj) => {
  const { payload } = userObj;
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.post(
      api_endpoint() + '/accounts/api/subusers/', {...payload},
      headers);

    return {status: 'success', data: response.data};

  } catch (error) {
    console.log('Error creating user', error);
    return {status: 'error', error: errorMessageString(error.response.data ? error.response.data : null)};
  }
}

export const deleteUser = async (userId) => {
  try {
    // var headers = await auth_request_headers();
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.delete(
      api_endpoint() + `/accounts/api/subusers/${userId}/`, headers);
    return {status: 'success', data: response.data};
  } catch (error) {
    console.log('Error deleting a user', error);
    return {status: 'error'};
  }
}

export const putUser = async (userObj) => {
  const { userId, payload } = userObj;
  try {
    var headers = {
      'Content-Type': 'application/json',
      'accept': 'application/json',
      // 'withCredentials': true
    }
    const response = await axios.put(
      api_endpoint() + `/accounts/api/subusers/${userId}/`, {...payload} ,
      headers);
    return {status: 'success', data: response.data };
  } catch (error) {
    console.log('Error updating a user', error);
    return { status: 'error', error: error.message }
  }
}
