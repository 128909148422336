import React, { useState, useEffect, useRef } from 'react';
import { roundToNearest } from '../../lib/Functions';
import { Slider } from '@mui/material';

function ColorPicker({ value = '#ffffffff', onChange }) {
  const [hex, setHex] = useState(null);
  const [hue, setHue] = useState(0);
  const [saturation, setSaturation] = useState(0);
  const [brightness, setBrightness] = useState(100);
  const [opacity, setOpacity] = useState(1);
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [currentColor, setCurrentColor] = useState({ hex: '', hsla: '', rgba: '', rgb: '' });
  const [circlePosition, setCirclePosition] = useState({ x: 0, y: 0 });
  const [initialized, setInitialized] = useState(false);
  const popupRef = useRef(null);

  // console.log('hue: ', hue)
  // console.log('saturation: ', saturation)
  // console.log('brightness: ', brightness)
  // console.log('opacity: ', opacity)
  // console.log('current color: ', currentColor)

  useEffect(() => {
    // Initialize the color based on the value prop
    // if (!initialized) {
      const { r, g, b, alpha } = hexToRgba(value);
      const hsb = rgbToHsb(r, g, b);
      setHex(value);
      setHue(hsb.h);
      setSaturation(hsb.s);
      setBrightness(hsb.b);
      setOpacity(alpha); // Reset opacity to 1 for new selection
      // Set initial circle position based on saturation and brightness
      setCirclePosition({
        x: (hsb.s / 100) * 220 -1, // Assuming the width of the color square is 150px
        y: 150 - (hsb.b / 100) * 150 -1, // Assuming the height of the color square is 150px
      });

      // setInitialized(true);
    // }
  }, []);

  // useEffect(() => {
  //   // Update the current color whenever the color or opacity changes
  //   const newRgb = hsbToRgb(hue, saturation, brightness);
  //   const newHex = rgbToHex(newRgb, opacity);
  //   const newRgba = rgbaColor();

  //   // Only update state if the new values are different
  //   if (newHex !== currentColor.hex || newRgba !== currentColor.rgba) {
  //     setCurrentColor({ hex: newHex, rgba: newRgba });
  //     if (onChange) {
  //       onChange({ hex: newHex, rgba: newRgba });
  //     }
  //   }
  // }, [hue, saturation, brightness, opacity, currentColor]);

  useEffect(() => {
    // Update the current color whenever the color or opacity changes
    const newRgb = hsbToRgb(hue, saturation, brightness);
    const newHex = rgbToHex(newRgb, opacity);
    const newRgba = rgbaColor();

    // // Only update state if the new values are different
    if (newHex !== currentColor.hex || newRgba !== currentColor.rgba) {
      setCurrentColor({ hex: newHex, hsla:`hsla(${hue}, ${saturation}%, ${brightness}%, ${opacity})`, rgba: newRgba , rgb: `rgba(${newRgb.r}, ${newRgb.g}, ${newRgb.b}, 1)`});
      setHex(newHex);
      if (onChange) {
        onChange({ hex: newHex, rgba: newRgba });
      }
    }
  }, [hue, saturation, brightness, opacity]);

  const hsbToRgb = (h, s, b) => {
    s /= 100;
    b /= 100;
    const k = (n) => (n + h / 60) % 6;
    const f = (n) => b * (1 - s * Math.max(0, Math.min(k(n), 4 - k(n), 1)));
    return {
      r: Math.round(255 * f(5)),
      g: Math.round(255 * f(3)),
      b: Math.round(255 * f(1)),
    };
  };

  const rgbToHex = ({ r, g, b }, alpha) => {
    const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0'); // Convert alpha to hex
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}${alphaHex}`;
  };

  const rgbaColor = () => {
    const { r, g, b } = hsbToRgb(hue, saturation, brightness);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  };

  const rgbToHsb = (r, g, b) => {
    r /= 255; g /= 255; b /= 255;
    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    const h = max === min ? 0 : max === r ? (60 * ((g - b) / (max - min)) + 360) : max === g ? (60 * ((b - r) / (max - min)) + 120) : (60 * ((r - g) / (max - min)) + 240);
    const s = max === 0 ? 0 : ((max - min) / max) * 100;
    const v = max * 100;
    // console.log('h: ', h, 's: ', s, 'b: ', v);
    return { h: Math.round(h), s: Math.round(s), b: Math.round(v) };
  };

  const hexToRgba = (hex) => {
    // Remove the '#' if present
    hex = hex.replace(/^#/, '');

    // Parse red, green, and blue values
    const bigint = parseInt(hex.slice(0, 6), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Check if alpha is provided (8-character hex code)
    let alpha = 1; // Default to fully opaque
    if (hex.length === 8) {
        const alphaInt = parseInt(hex.slice(6, 8), 16); // Get last two characters for alpha
        alpha = Math.round((alphaInt / 255) * 100) / 100; // Convert to 0-1 range, rounded to two decimals
    }

    // console.log('hex', hex);
    // console.log('r', r, 'g', g, 'b', b, 'alpha', alpha);
    
    return {
        r,
        g,
        b,
        alpha
    };
};

  const togglePicker = () => {
    setIsPickerOpen(!isPickerOpen)
  };

  const handleBlur = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPickerOpen(false);
    }
  };

  useEffect(() => {
    // Add event listener when the popup is open
    if (isPickerOpen) {
      document.addEventListener('mousedown', handleBlur);
    } else {
      document.removeEventListener('mousedown', handleBlur);
    }
    
    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('mousedown', handleBlur);
    };
  }, [isPickerOpen]);

  const handleColorSquareClick = (e) => {
    const { offsetX, offsetY, target } = e.nativeEvent;
    const rect = target.getBoundingClientRect();
    const sat = Math.round((offsetX / rect.width) * 100);
    const bright = Math.round(100 - (offsetY / rect.height) * 100);
    setSaturation(sat);
    setBrightness(bright);
    setCirclePosition({ x: offsetX, y: offsetY });
  };

  const handleHexChange = (e) => {
    const inputHex = e.target.value;
    setHex(inputHex);
  };

  const handleHexComplete = (e) => {
    const inputHex = e.target.value;
    // if (/^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{8})$/.test(inputHex)) {
      const { r, g, b } = hexToRgba(inputHex);
      const hsb = rgbToHsb(r, g, b);
      setHue(hsb.h);
      setSaturation(hsb.s);
      setBrightness(hsb.b);
      setOpacity(inputHex.length === 9 ? parseInt(inputHex.slice(-2), 16) / 255 : 1);
      setCurrentColor(prev => ({ ...prev, hex: inputHex }))
    // }
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <button
        onClick={togglePicker}
        style={{
          backgroundColor: value ? value : rgbaColor(),
          border: '1px solid #ccc',
          width: '80px',
          height: '20px',
          cursor: 'pointer',
          borderRadius: '4px',
        }}
      />
      {isPickerOpen && (
        <div
          ref={popupRef}  // Attach the ref to the popup
          style={{
            position: 'absolute',
            top: '100%',
            right: '100%',
            marginTop: '10px',
            padding: '10px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            zIndex: 10,
            width: '220px',
          }}
        >
          <div
            onMouseDown={handleColorSquareClick}
            onMouseMove={(e) => e.buttons === 1 && handleColorSquareClick(e)}
            style={{
              width: '100%',
              height: '150px',
              background: `hsl(${hue}, 100%, 50%)`,
              position: 'relative',
              cursor: 'crosshair',
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                background: 'linear-gradient(to right, #fff, rgba(255, 255, 255, 0))',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            ></div>
            <div
              style={{
                width: '100%',
                height: '100%',
                background: 'linear-gradient(to top, #000, rgba(0, 0, 0, 0))',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            ></div>
            <div
              style={{
                position: 'absolute',
                top: circlePosition.y - 0,
                left: circlePosition.x - 0,
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                backgroundColor: currentColor.rgba,
                border: '2px solid #fff',
                pointerEvents: 'none',
                transform: 'translate(-50%, -50%)',
              }}
            ></div>
          </div>

          {/* <label style={{ display: 'block', marginBottom: '8px' }}>
            Hue:
            <input
              type="range"
              min="0"
              max="360"
              value={hue}
              onChange={(e) => setHue(parseInt(e.target.value))}
              style={{ width: '100%' }}
            /> */}
            <div style={{display:'flex', justifyContent:'center'}}>
              <Slider
                min={0}
                max={360}
                value={hue}
                onChange={(e) => setHue(parseInt(e.target.value))}
                sx={{
                  '&& .MuiSlider-thumb':{
                    background:currentColor.rgb,
                    border:'2px solid #fff',
                    width:20,
                    height:20,
                  },
                  '&& .MuiSlider-thumb.Mui-active':{
                    boxShadow:`0 0 0 5px ${currentColor.rgb.replace('1)', '.2)')}`
                  },
                  '&& .MuiSlider-rail':{
                    // background:currentColor.rgb,
                    background: `
                      linear-gradient(to right, 
                        hsl(0, 100%, 50%), 
                        hsl(60, 100%, 50%), 
                        hsl(120, 100%, 50%), 
                        hsl(180, 100%, 50%), 
                        hsl(240, 100%, 50%), 
                        hsl(300, 100%, 50%), 
                        hsl(360, 100%, 50%)
                      );
                    `,
                    opacity:1,
                    height:12
                  },
                  '&& .MuiSlider-track':{
                    // background:currentColor.rgb,
                    background:'transparent',
                  //   background: `
                  //   linear-gradient(to right, 
                  //     hsl(0, 100%, 50%), 
                  //     hsl(60, 100%, 50%), 
                  //     hsl(120, 100%, 50%), 
                  //     hsl(180, 100%, 50%), 
                  //     hsl(240, 100%, 50%), 
                  //     hsl(300, 100%, 50%), 
                  //     hsl(360, 100%, 50%)
                  //   );
                  // `,
                    border:'none',
                    height:12
                  },
                  width:'92%'
                }}
              />
            </div>

            <div style={{display:'flex', justifyContent:'center'}}>
              <Slider
                min={0}
                max={1}
                step={.01}
                value={opacity}
                onChange={(e) => setOpacity(parseFloat(e.target.value))}
                sx={{
                  '&& .MuiSlider-thumb':{
                    background:'#fff',
                    // backgroundColor:currentColor.rgba,
                    border:'2px solid #fff',
                    width:20,
                    height:20,
                    ':hover':{
                      boxShadow:`0 0 0 8px ${currentColor.rgb.replace('1)', '.2)')}`
                    }
                  },
                  '&& .MuiSlider-thumb.Mui-active':{
                    boxShadow:`0 0 0 5px ${currentColor.rgb.replace('1)', '.2)')}`
                  },
                  '&& .MuiSlider-rail':{
                    // display:'none',
                    background:`linear-gradient(90deg, ${currentColor.rgb.replace('1)', '0)')}, ${currentColor.rgb})`,
                    opacity:1,
                    height:12
                  },
                  '&& .MuiSlider-track':{
                    display:'none',
                    background:currentColor.rgb,
                    border:'none',
                    height:12
                  },
                  width:'92%'
                }}
              />
            </div>
          {/* </label> */}

          {/* <label style={{ display: 'block', marginBottom: '8px' }}>
            Opacity:
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={opacity}
              onChange={(e) => setOpacity(parseFloat(e.target.value))}
              style={{ width: '100%' }}
            />
          </label> */}


            <input
              type="text"
              value={hex}
              onChange={handleHexChange}
              onBlur={handleHexComplete}
              style={{ width: 'calc(100% - 10px)', marginTop:8, padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }}
            />
          {/* <button
            onClick={togglePicker}
            style={{
              marginTop: '10px',
              padding: '5px 10px',
              border: 'none',
              borderRadius: '4px',
              backgroundColor: '#007bff',
              color: '#fff',
              cursor: 'pointer',
            }}
          >
            Close
          </button> */}
          {/* <button
            onClick={() => onChange(currentColor)}
            style={{
              marginTop: '10px',
              padding: '5px 10px',
              border: 'none',
              borderRadius: '4px',
              backgroundColor: '#007bff',
              color: '#fff',
              cursor: 'pointer',
            }}
          >
            Save
          </button> */}
        </div>
      )}
    </div>
  );
}

export default ColorPicker;
