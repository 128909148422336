import { Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { status, statusColors } from '../../lib/Data';
import Tag, { TagSimple } from '../ui/Tags'
import { DownloadSVG } from '../../assets/icons';
import Paginate from '../ui/Paginate';
import CustomTooltip from '../ui/CustomTooltip';

const CsvImportList = (props) => {
  const { imports, column } = props;

  return (
    <Grid
      container
      style={{
        padding:'1.25rem'
      }}
    >
      {
        imports?.map(importItem => (
          <ImportedItem key={importItem.id} importItem={importItem} column={column} />
        ))
      }
    </Grid>
  )
}

export default CsvImportList

const ImportedItem = (props) => {
  const { importItem, column } = props;

  return (
    <Grid
      container
      spacing={1}
      style={{
        alignItems:'center',
        padding:'8px 0',
        flexDirection: column ? 'column' : 'row'
      }}
    >
      <GridItemName item={importItem.file_name} link={importItem.status === 1 ? `/contacts/import/csv/${importItem.id}/` : `/contacts/import/csv/${importItem.id}/queued/`} />
      <GridItemDate item={new Date(importItem.created).toLocaleDateString('en', {dateStyle:'medium'})} tooltip={new Date(importItem.created).toLocaleString('en', {dateStyle:'medium', timeStyle:'short'})} />
      <GridItemTime item={new Date(importItem.created).toLocaleTimeString('en', {timeStyle:'short'})} />
      <GridItemText item={`${importItem.total_rows} contacts`} />
      <GridItemDone item={importItem.total_done} />
      <GridItemErrored item={importItem.total_errored} />
      <GridItemStatus item={importItem.status} />
      <GridItemDownload item={importItem.file} size="auto" />
    </Grid>
  )

}

const GridItemName = ({ item, link, size }) => {

  return (
    <Grid
      item
      sx={{
        display:'flex',
        justifyContent:'center'
      }}
      xs={size ? size : true}
    >
      <CustomTooltip title={item.split('csv_uploads/')[1]}>
        <a href={link} style={{textDecoration:'none', color:'inherit'}}>
          <Typography
            style={{
              // fontSize:'.85rem'
            }}
          >
            {item.split('csv_uploads/')[1]}
          </Typography>
        </a>
      </CustomTooltip>
    </Grid>
  )
}

const GridItemText = ({ item, size }) => {

  return (
    <Grid
      item
      sx={{
        display:{xs:'none', md:'flex'},
        justifyContent:'center'
      }}
      xs={size ? size : true}
    >
      <CustomTooltip title={item}>
        <Typography
          style={{
            fontSize:'.85rem'
          }}
        >
          {item}
        </Typography>
      </CustomTooltip>
    </Grid>
  )
}

const GridItemDate = ({ item, size, tooltip }) => {

  return (
    <Grid
      item
      sx={{
        display:{xs:'none', sm:'flex'},
        justifyContent:'center'
      }}
      xs={size ? size : true}
    >
      <CustomTooltip title={tooltip ? tooltip : item}>
        <Typography
          style={{
            fontSize:'.85rem'
          }}
        >
          {item}
        </Typography>
      </CustomTooltip>
    </Grid>
  )
}

const GridItemTime = ({ item, size, tooltip }) => {

  return (
    <Grid
      item
      sx={{
        display:{xs:'none', lg:'flex'},
        justifyContent:'center'
      }}
      xs={size ? size : true}
    >
      <CustomTooltip title={tooltip ? tooltip : item}>
        <Typography
          style={{
            fontSize:'.85rem'
          }}
        >
          {item}
        </Typography>
      </CustomTooltip>
    </Grid>
  )
}

// const GridItemDate = ({ item, size }) => {

//   return (
//     <Grid
//       item
//       sx={{
//         display:{xs:'none', sm:'flex'},
//         flexDirection:'column',
//         alignItems:'center'
//       }}
//       xs={size ? size : true}
//     >
//       <Typography
//         style={{
//           fontSize:'.85rem',
//           lineHeight:1
//         }}
//       >
//         {new Date(item).toLocaleDateString('en', {dateStyle:'medium'})}
//       </Typography>
//       <Typography
//         style={{
//           fontSize:'.85rem',
//           lineHeight:1
//         }}
//       >
//         {new Date(item).toLocaleTimeString('en', {timeStyle:'short'})}
//       </Typography>
//     </Grid>
//   )
// }

const GridItemStatus = ({ item, size }) => {

  return (
    <Grid
      item
      style={{
        display:'flex',
        justifyContent:'center'
      }}
      xs={size ? size : true}
    >
      <TagSimple
        name={status.imports[item].toUpperCase()}
        color={{
          background: statusColors.imports[item]
        }}
      />
    </Grid>
  )
}

const GridItemErrored = ({ item, size }) => {

  return (
    <Grid
      item
      sx={{
        display:{xs:'none', lg:'flex'},
        justifyContent:'center'
      }}
      xs={size ? size : true}
    >
      <TagSimple
        name={`${item} errors`}
        color={{
          background: '#FFFFFF',
          outline: item > 0 ? '#FF0000' : '#CCCCCC',
          text: item > 0 ? '#FF0000' : '#CCCCCC'
        }}
      />
    </Grid>
  )
}

const GridItemDone = ({ item, size }) => {

  return (
    <Grid
      item
      sx={{
        display:{xs:'none', lg:'flex'},
        justifyContent:'center'
      }}
      xs={size ? size : true}
    >
      <TagSimple
        name={`${item} done`}
        color={{
          background: '#FFFFFF',
          outline: '#00A6A6',
          text: '#00A6A6'
        }}
      />
    </Grid>
  )
}

const GridItemDownload = ({ item, size }) => {

  return (
    <Grid
      item
      sx={{
        display:{xs:'flex'},
        justifyContent:'center',
        padding:'0 8px'
      }}
      xs={size ? size : true}
    >
      <CustomTooltip
        title="Download csv file"
      >
        <IconButton
          href={item}
        >
          <DownloadSVG width={14} />
        </IconButton>
      </CustomTooltip>
    </Grid>
  )
}