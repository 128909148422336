import React, { useEffect, useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import ListSettings from '../components/lists/ListSettings'
import ListCreate from '../components/lists/ListCreate'

const mockList = { "id": "kae9aeDE", "name": "Penguins", "subscribed_count": "2", "unsubscribed_count|intcomma": "0", "open_rate": "20.0", "last_campaign_open_rate": "0.0", "click_rate": "20.0", "last_campaign_click_rate": "0.0" }


const ListCreatePage = () => {
  const isLive = process.env.REACT_APP_CURRENT_ENV !== 'STAGING';

  return (

    // <MainPageTemplate title="Welcome">
      <ListCreate />
    // </MainPageTemplate>    
  )
}

export default ListCreatePage