import React, { useState, useEffect } from 'react'
import {  Button, Grid, Table, TableCell, TableRow, Typography } from '@mui/material';
import { InputField } from '../ui/Fields';
import { initSenderDetail } from '../../lib/Data';
import { postSenderDetails, postSenderVerifyDomain, putSenderDetails } from '../../hooks/mutations';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import { CheckSVG, CheckmarkFilledSVG, RemoveFillSVG, WarningSVG } from '../../assets/icons';
import { useTheme } from '@emotion/react';

const EditSenderDetail = (props) => {
  const { existingSenderDetail, handleCancel, setReload } = props;
  const [senderDetail, setSenderDetail] = useState(initSenderDetail);
  const [snackMessage, setSnackMessage] = useState(null);

  const theme = useTheme();

  console.log('existingSenderDetail', existingSenderDetail);
  console.log('sender detail', senderDetail);

  // Update senderDetail if an existing detail already exists
  useEffect(() => {
    if (existingSenderDetail) {
      setSenderDetail(existingSenderDetail);
    }
  }, [existingSenderDetail])

  const submitNewDetails = async () => {
    console.log('POST')
    const submitted = await postSenderDetails(senderDetail);
    if (submitted.status === "success") {
      setSnackMessage({ open: true, message: 'Domain details submitted', severity: 'success' });
      handleCancel();
      setReload(true);
    } else if ( submitted.status === "error") {
      setSnackMessage({open: true, message: submitted.error, severity:'error'});
    }
  }

  const submitUpdatedDetails = async () => {
    console.log('PUT')
    const submitted = await putSenderDetails({ id: existingSenderDetail.id, data: { ...existingSenderDetail, ...senderDetail } });
    if (submitted.status === "success") {
      setSnackMessage({ open: true, message: 'Domain details updated', severity: 'success' });
      handleCancel();
      setReload(true);
    } else if ( submitted.status === "error") {
      setSnackMessage({open: true, message: submitted.error, severity:'error'});
    }
  }

  const submitVerifyDomain = async () => {
    if (senderDetail.id) {
      const verifiedDomain = await postSenderVerifyDomain(senderDetail.id);
      console.log('verifiedDomain', verifiedDomain);
      if (verifiedDomain.status === "success") {
        if (verifiedDomain.data.is_validated) {
          setSnackMessage({open: true, message: 'Domain has been verified', severity:'success'});
        } else {
          setSnackMessage({open: true, message: 'Domain was not verified', severity:'error'});
        }
      } else if (verifiedDomain.status === "error") {
        setSnackMessage({open: true, message: verifiedDomain.error, severity:'error'});
      }
    }
  }
  
  return (
    <Grid
      container
    >
      <InputField
        label="Domain Name"
        placeholder="Enter the domain name"
        fullWidth
        value={senderDetail.domain_name}
        onChange={(e) => setSenderDetail({ ...senderDetail, domain_name: e.target.value }) }
        margin
      />

      <InputField
        label="From Email Address"
        placeholder="Enter the default From email address"
        fullWidth
        value={senderDetail.default_from_email}
        onChange={(e) => setSenderDetail({ ...senderDetail, default_from_email: e.target.value }) }
        margin
      />

      <InputField
        label="From Email Name"
        placeholder="Enter the default name for your emails"
        fullWidth
        value={senderDetail.default_from_name}
        onChange={(e) => setSenderDetail({ ...senderDetail, default_from_name: e.target.value }) }
        margin
      />

      <InputField
        label="Website"
        placeholder="Enter the website url"
        fullWidth
        value={senderDetail.website_address}
        onChange={(e) => setSenderDetail({ ...senderDetail, website_address: e.target.value }) }
        margin
      />

      <Grid
        container
        sx={{
          justifyContent:'space-between'
        }}
      >
        <Grid
          container
          variant="center"
          sx={{
            marginBottom:'24px'
          }}
        >

          <Button
            onClick={existingSenderDetail ? submitUpdatedDetails : submitNewDetails}
            variant="contained"
          >
            Update Domain Details
          </Button>
        </Grid>
      </Grid>

      {
        !senderDetail.is_validated &&
          <Grid
            container
            style={{
              boxShadow:'0 0 5px #00000030',
              borderRadius:16,
              padding:16,
              marginBottom:24,
            }}
          >
            <Grid container variant="center" style={{marginBottom:16}}>
              <WarningSVG width={20} color1={theme.palette.warning.main} />
              <Typography
                style={{
                  marginLeft:8,
                  fontWeight:600
                }}
              >
                Your domain has not been verified
              </Typography>
            </Grid>
            <Typography
              sx={{
                fontSize:'1.2rem',
                fontWeight:500
              }}
            >
              Instructions for updating your DNS records
            </Typography>
            <Typography>
              The DNS records page is a little different for every domain hosting provider. The following directions are general steps for locating the DNS records for most domain hosting providers. If you need additional help finding your DNS records, please contact your domain host for steps to locating their DNS records page.
            </Typography>
            {
              updateDNS.map((item, index) => (
                <Grid
                  container
                  style={{
                    flexDirection:'column',
                    marginBottom:16,
                    marginTop: index === 0 ? 16 : 0
                  }}
                >
                  <Typography
                    sx={{
                      fontSize:'1.2rem',
                      fontWeight:500,
                      lineHeight:1
                    }}
                  >
                    {item.label}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight:600
                    }}
                  >
                    {item.instruction}
                  </Typography>
                </Grid>
              ))
            }

          </Grid>
      }

      {
        senderDetail.is_validated 
          ?
            null
          :
          <Grid
            container
            variant="center"
            style={{
              margin:'24px 0'
            }}
          >
            <Button
              variant="contained"
              onClick={submitVerifyDomain}
            >
              Verify Domain
            </Button>
          </Grid>
      }

      <Grid
        container
        style={{
          boxShadow:'0 0 5px #00000030',
          borderRadius:16,
          padding:16
        }}
      >
        {
          !senderDetail.is_validated &&
            <Grid container variant="center" style={{marginBottom:16}}>
              <WarningSVG width={20} color1={theme.palette.warning.main} />
              <Typography
                style={{
                  marginLeft:8,
                  fontWeight:600
                }}
              >
                Your domain has not been verified
              </Typography>
            </Grid>
        }

        <Grid
          container
          style={{
            boxShadow:'0 0 5px #00000015',
            paddingTop:8,
            borderRadius:8,  
          }}
        >
          {
            senderDetail?.dns_details &&Object.keys(senderDetail.dns_details).length &&
              <DNSRecordItems
                dkim_item={senderDetail.dns_details.dkim}
                mx_item={senderDetail.dns_details.mail_server}
                spf_item={senderDetail.dns_details.subdomain_spf}
              />
          }
        </Grid>

        {
          // senderDetail?.dns_details &&Object.keys(senderDetail.dns_details).length &&
          //     Object.entries(senderDetail.dns_details).map(([key, value]) => (
          //       <Grid
          //         container
          //         style={{
          //           marginBottom:8
          //         }}
          //       >
          //         <Typography
          //           style={{
          //             fontSize:'1.2rem',
          //             fontWeight:600,
          //             marginLeft:16
          //           }}
          //         >
          //           {key}
          //         </Typography>
          //         <Grid
          //           container
          //           style={{
          //             boxShadow:'0 0 5px #00000015',
          //             paddingTop:8,
          //             borderRadius:8,  
          //           }}
          //         >
                    
          //           {
          //             // Object.keys(value).length &&
          //             //   Object.entries(value).map(([key, value]) => (
          //             //     <Grid
          //             //       item
          //             //       style={{
          //             //         marginBottom:16,
          //             //         paddingRight:8
          //             //       }}
          //             //       xs={12}
          //             //       sm={3}
          //             //     >
          //             //       <Typography
          //             //         style={{
          //             //           fontWeight:600
          //             //         }}
          //             //       >
          //             //         {key == 'data' ? 'value' : key}
          //             //       </Typography>
          //             //       {
          //             //         typeof value == "boolean"
          //             //           ?
          //             //             <Grid container>
          //             //               {
          //             //                 value
          //             //                   ? <CheckmarkFilledSVG width={18} color1={theme.palette.success.main} />
          //             //                   : <RemoveFillSVG width={18} color1={theme.palette.error.main} />
          //             //               }
          //             //             </Grid>
          //             //           :
          //             //             <Typography
          //             //               style={{
          //             //                 wordWrap:'break-word'
          //             //               }}
          //             //             >
          //             //               {String(value)}
          //             //             </Typography>
          //             //       }
          //             //     </Grid>
          //             //   ))
          //           }
          //         </Grid>
          //       </Grid>
          //     ))
        }
      </Grid>

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />

    </Grid>
  )
}

export default EditSenderDetail

const updateDNS = [
  {
    label: 'Step 1',
    instruction: 'Login to your domain hosting provider'
  },
  {
    label: 'Step 2',
    instruction: 'Find the domain you want to edit'
  },
  {
    label: 'Step 3',
    instruction: 'Navigate to the DNS records for the domain'
  },
  {
    label: 'Step 4',
    instruction: 'Add the following items to your DNS records',
  },
  {
    label: 'Step 5',
    instruction: 'Click the Verify Domain button'
  }
]

const DNSRecordItems = (props) => {
  const { mx_item, spf_item, dkim_item } = props;

  return (
    <Table style={{width:'100%'}}>
      <TableRow>
        <TableCell>valid</TableCell>
        <TableCell>type</TableCell>
        <TableCell>host</TableCell>
        <TableCell>value</TableCell>
      </TableRow>
      <CustomRow item={mx_item} />
      <CustomRow item={spf_item} />
      <CustomRow item={dkim_item} />
    </Table>
  )
}

const CustomRow = ({ item }) => {
  const { valid, type, host, data } = item;
  const theme = useTheme();

  return (
    <TableRow style={{overflow:'hidden', overflowWrap:'break-word'}}>
      <CustomCell>
        <Grid container>
          {
            valid
              ? <CheckmarkFilledSVG width={18} color1={theme.palette.success.main} />
              : <RemoveFillSVG width={18} color1={theme.palette.error.main} />
          }
        </Grid>
      </CustomCell>
      <CustomCell>{type}</CustomCell>
      <CustomCell>{host}</CustomCell>
      <CustomCell>{data}</CustomCell>
    </TableRow>

  )
}

const CustomCell = ({ children }) => {

  return (
    <TableCell
      style={{
        whiteSpace: 'normal',
        wordWrap: 'break-word',
        overflowWrap: 'break-word',
        wordBreak:'break-word'
      }}        
    >
        {children}
    </TableCell>
)}