import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import { DjangoComponents } from '../../templates/DjangoStyles';
import { TagButton } from '../ui/Tags';
import { campaignStatusEnum } from '../../lib/Data';
import { useParams } from 'react-router-dom';
import { getCampaign, getFilteredCampaigns, useGetAllLists, useGetAllEmailTemplates, useGetAllSurveys } from '../../hooks/mutations';
import { CheckmarkCheckSVG, CheckmarkSVG, CheckSVG, DeleteSVG, SendSVG } from '../../assets/icons';
import { useTheme } from '@emotion/react';
import { InputField } from '../ui/Fields';

const CampaignEdit = () => {
  const { campaignId } = useParams();
  const [campaign, setCampaign] = useState({});
  const [current, setCurrent] = useState(null);
  const lists = useGetAllLists();
  const templates = useGetAllEmailTemplates();
  const surveys = useGetAllSurveys();

  console.log('campaign', campaign)

  useEffect(() => {
    if (campaignId) {
      callCampaign(campaignId);
    } else {
      callCampaign('LJpm81DV');
    }
  }, [campaignId]);

  const callCampaign = async (campaignId) => {
    const incomingCampaign = await getCampaign(campaignId);
    setCampaign(incomingCampaign);
  }

  const handleCampaignChange = (field, value) => {
    setCampaign(prev => ({
      ...prev,
      [field]: value
    }))
  }

  return (
    <DjangoComponents inner card>
      <Grid
        container
      >
        <CampaignEditHeader campaign={campaign} />
        <CampaignEditAccordion
          title="To"
          info="Which list are you sending this campaign to?"
          button="Add recipients"
          value={lists?.find(x => x.id === campaign.mailing_list)?.name}
          current={current}
          setCurrent={setCurrent}
          campaign={campaign}
        >
          <TextField
            select
            fullWidth
            variant='standard'
            InputProps={{
              disableUnderline: true,
              style: {
                display: 'flex',
                alignItems: 'center',
                lineHeight:1,
                height:55
              }
            }}
            style={{
              margin: 0,
              borderRadius: 4,
              padding:'0 16px',
              boxShadow: '0 0 5px #00000030',
              display: 'flex',
              alignItems: 'center',
              width:'calc(100% - 32px)'
            }}
            value={campaign.mailing_list ? campaign.mailing_list : 'Select A List'}
            onChange={(e) => handleCampaignChange('mailing_list', e.target.value)}
          >
            {
              lists?.map(list => (
                <MenuItem
                  key={list.id}
                  value={list.id}
                >
                  {list.name}
                </MenuItem>
              ))
            }
          </TextField>
        </CampaignEditAccordion>
        <CampaignEditAccordion
          title="From"
          info="Who is sending this campaign?"
          button="Add from"
          value={campaign.from?.name && campaign.from?.email ? `${campaign.from.name} (${campaign.from.email})` : null}
          current={current}
          setCurrent={setCurrent}
          campaign={campaign}
        >
          <InputField
            label="Name"
            value={campaign.from?.name}
            onChange={(e) => handleCampaignChange('from', { ...campaign.from, name: e.target.value} )}
            singleLine
            margin
          />
          <InputField
            label="Email address*"
            value={campaign.from?.email}
            onChange={(e) => handleCampaignChange('from', { ...campaign.from, email: e.target.value} )}
            singleLine
          />
        </CampaignEditAccordion>
        <CampaignEditAccordion
          title="Subject"
          info="What's the subject line for this campaign?"
          button="Add subject"
          value={campaign.subject}
          current={current}
          setCurrent={setCurrent}
          campaign={campaign}
        >
          <InputField
            label="Subject*"
            value={campaign.subject}
            onChange={(e) => handleCampaignChange('subject', e.target.value)}
            singleLine
          />
        </CampaignEditAccordion>
        <CampaignEditAccordion
          title="Content"
          info="Which email template are you using?"
          button="Choose template"
          value={templates?.find(x => x.id === campaign.email_template)?.name}
          current={current}
          setCurrent={setCurrent}
          campaign={campaign}
        >
          {
            templates?.length
              ?
                <TextField
                  select
                  fullWidth
                  variant='standard'
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      display: 'flex',
                      alignItems: 'center',
                      lineHeight:1,
                      height:55
                    }
                  }}
                  style={{
                    margin: 0,
                    borderRadius: 4,
                    padding:'0 16px',
                    boxShadow: '0 0 5px #00000030',
                    display: 'flex',
                    alignItems: 'center',
                    width:'calc(100% - 32px)'
                  }}
                  value={campaign.email_template ? campaign.email_template : 'Select A Template'}
                  onChange={(e) => handleCampaignChange('email_template', e.target.value)}
                >
                  {
                    templates?.map(template => (
                      <MenuItem
                        key={template.id}
                        value={template.id}
                      >
                        {template.name}
                      </MenuItem>
                    ))
                  }
                </TextField>
              :
                <Grid
                  container
                  sx={{
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center'
                  }}
                >
                  <Typography>You have not created any surveys yet.</Typography>
                  <Button
                    variant="contained"
                    href="/surveys/add"
                    target="_blank"
                  >
                    Create a survey
                  </Button>
                </Grid>
          }
        </CampaignEditAccordion>
        <CampaignEditAccordion
          title="Survey (optional)"
          info="Is there a survey needed for this campaign?"
          button="Add survey"
          current={current}
          setCurrent={setCurrent}
          campaign={campaign}
        >
          {
            surveys?.length
              ?
                <TextField
                  select
                  fullWidth
                  variant='standard'
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      display: 'flex',
                      alignItems: 'center',
                      lineHeight:1,
                      height:55
                    }
                  }}
                  style={{
                    margin: 0,
                    borderRadius: 4,
                    padding:'0 16px',
                    boxShadow: '0 0 5px #00000030',
                    display: 'flex',
                    alignItems: 'center',
                    width:'calc(100% - 32px)'
                  }}
                  value={campaign.survey ? campaign.survey : 'Select A Survey'}
                  onChange={(e) => handleCampaignChange('survey', e.target.value)}
                >
                  {
                    campaign.survey
                      ?
                        surveys?.map(survey => (
                          <MenuItem
                            key={survey.id}
                            value={survey.id}
                          >
                            {survey.name}
                          </MenuItem>
                        ))
                      :
                        [ {id: 'none', name: 'Select A Survey'}, ...surveys ]?.map(survey => (
                          <MenuItem
                            key={survey.id}
                            value={survey.id}
                          >
                            {survey.name}
                          </MenuItem>
                        ))
                  }
                </TextField>
              :
                <Grid
                  container
                  sx={{
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center'
                  }}
                >
                  <Typography>You have not created any surveys yet.</Typography>
                  <Button
                    variant="contained"
                    href="/surveys/add"
                    target="_blank"
                  >
                    Create a survey
                  </Button>
                </Grid>
          }

        </CampaignEditAccordion>
        <CampaignActions />
      </Grid>
    </DjangoComponents>
  )
}

export default CampaignEdit

const CampaignEditHeader = ({ campaign }) => {
  return (
    <Grid
      container
      sx={{
        p:2,
        justifyContent:'space-between',
        alignItems:'center'
      }}
    >
      <Grid>
        <Typography
          variant="h2"
          sx={{
            fontSize:'1.6rem !important',
            fontWeight:500
          }}
        >
          {campaign?.name ? campaign.name : 'Untitled Campaign'}
        </Typography>
        <Typography
          sx={{
            color:'#999'
          }}
        >
          Campaign {campaign?.campaign_number}
        </Typography>
      </Grid>
      <TagButton
        bgColor={campaignStatusEnum.find(x => x.status === campaign?.status)?.color}
        fontColor={campaignStatusEnum.find(x => x.status === campaign?.status)?.text}
        outlined
        outlinedColor={campaignStatusEnum.find(x => x.status === campaign?.status)?.color}
        label={campaignStatusEnum.find(x => x.status === campaign?.status)?.label}
        noPointer
      />
    </Grid>
  )
}

const CampaignActions = ({ campaign }) => {
  return (
    <Grid
      container
      sx={{
        p:2,
        justifyContent:'space-between',
        alignItems:'center'
      }}
    >
      <Grid
        sx={{
          display:'flex',
          gap:2
        }}
      >
        <Button
          variant="contained"
          endIcon={<SendSVG width={14} color1="#fff" />}
        >
          Send campaign
        </Button>
        <Button
          variant="outlined"
          // endIcon={<CalendarSVG width={14} color1="#fff" />}
        >
          Schedule
        </Button>
        <Button
          variant="outlined"
          color="mid"
          // endIcon={<CalendarSVG width={14} color1="#fff" />}
        >
          Finish later
        </Button>
      </Grid>
      <Button
        variant="outlined"
        color="error"
        startIcon={<DeleteSVG width={14} color1="#d32f2f" />}
      >
        Discard
      </Button>
    </Grid>
  )
}

const CampaignEditAccordion = (props) => {
  const { children, current, setCurrent, title, info, value, button } = props;
  const theme = useTheme();

  return (
    <Accordion
      expanded={current === title}
      onChange={() => { if (current === title) setCurrent(null); else setCurrent(title); }}
      disableGutters
      sx={{
        width:'100%',
        background: current === title || current === null ? '#fff' : '#fdfdfd',
        transition:'background .3s',
        borderTop: current === title ? '1px solid grey' : '1px solid transparent',
        // my: current === title ? 1 : 0,
        // zIndex: current === title ? 100 : 10
      }}
    >
      <AccordionSummary
        sx={{
        }}
      >
        <Grid
          container
          sx={{
            justifyContent:'space-between',
            alignItems:'center',
          }}
        >
          <Grid
            sx={{
              opacity: current === title || current === null ? 1 : .6
            }}
          >
            <Typography
              sx={{
                fontSize:'1.2rem',
                fontWeight:600
              }}
            >
              {title}
            </Typography>
            {
              !value || current === title
                ?
                  <Typography>
                    {info}
                  </Typography>
                :
                  <Typography>
                    {value}
                  </Typography>
            }
          </Grid>
          {
            current !== title
              ?
                value
                  ?
                    <CheckmarkSVG width={30} color1={theme.palette.primary.main} style={{marginRight:16}} />
                  :
                    <Button>
                      {button}
                    </Button>
              : null
          }
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  )
}