import React from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import Campaigns from '../components/campaigns/Campaigns'

const CampaignsPage = () => {
  return (

    // <MainPageTemplate title="Welcome">
      <Campaigns />
    // </MainPageTemplate>

    
  )
}

export default CampaignsPage