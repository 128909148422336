import React, { useEffect, useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import ListSubscribers from '../components/lists/ListSubscribers'

const mockSubscribers = [
  { "id": "1", "name": "Lee Grambush", "primary_email": "lgrambush@gmail.com", "status_badge": "<span class='badge badge- primary badge - pill'>Subscribed</span>", "status": "2", "open_rate": "20.0", "click_rate": "20.0", "date_joined": "Nov. 13, 2024, 2:24 a.m.", "mailing_list_name": "Penguins", "mailing_list_id": "3" },
  { "id": "2", "name": "ACTIVATE", "primary_email": "lee@activateparty.com", "status_badge": "<span class='badge badge-primary badge-pill'>Subscribed</span>", "status": "2", "open_rate": "25.0", "click_rate": "25.0", "date_joined": "Nov. 13, 2024, 12:13 p.m.", "mailing_list_name": "Penguins", "mailing_list_id": "3" }
]

const mockList = { "id": "3", "name": "Penguins" }

const ListSubscribersPage = () => {
  const isLive = process.env.REACT_APP_CURRENT_ENV !== 'STAGING';
  const [subscribers, setSubscribers] = useState(isLive ? null : mockSubscribers);
  const [list, setList] = useState(isLive ? null : mockList);
  console.log('list', list);
  console.log('subscribers', subscribers);

  // Get the subscribers
  const scriptElements = document.querySelectorAll('.django-list-subscriber');
  // console.log('scriptElements', scriptElements);

  useEffect(() => {
    if (scriptElements && !subscribers) {
      const scriptElementsArray = Array.from(scriptElements).map(x => JSON.parse(x.innerHTML));
      console.log('scriptElementsArray', scriptElementsArray);
      // const parsedElementsArray = JSON.parse(scriptElementsArray);
      // console.log('parsedElementsArray', parsedElementsArray);
      setSubscribers(scriptElementsArray)
    }
    
  }, [scriptElements])

  // Get the mailing list
  const listElement = document.getElementById('django-subscribers-list');

  if (listElement && !list) {
    // Access the content of the script element
    const djangoContactString = listElement?.textContent;
    // console.log('djangoContactString', djangoContactString)

    // Parse the JSON data
    const djangoContact = JSON.parse(djangoContactString);
    // console.log('djangoContact', djangoContact)

    // Set the contact id state from the JSON data
    setList(djangoContact)
  }

  return (

    // <MainPageTemplate title="Welcome">
    (subscribers && list) &&
      <ListSubscribers incomingSubscribers={subscribers} list={list} />
    // </MainPageTemplate>    
  )
}

export default ListSubscribersPage