import React, { cloneElement, useEffect, useState } from 'react';
import './EmailTemplate.css';
import { Column1SVG, Column2SVG, Column3SVG, CopySVG, DeleteSVG, DesktopSVG, EditSVG, MobileSVG, MoveAllDirectionsSVG, MoveSVG, TabletSVG, UploadSVG, WarningSVG } from '../../assets/icons';
import ElementEditorText from './ElementEditorText';
import ElementEditorButton from './ElementEditorButton';
import ElementRenderer from './ElementRenderer';
import { Button, CircularProgress, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { constantTemplateVariables, initEmailTemplate } from '../../lib/Data';
import { createEmailTemplate, getEmailTemplateFolder, putEmailTemplate, putStorageFile, useGetAllCustomFields } from '../../hooks/mutations';
import { copyString, getRandomString } from '../../lib/Functions';
import ElementEditorImage from './ElementEditorImage';
import ElementEditorDivider from './ElementEditorDivider';
import SectionEditor from './SectionEditor';
import { useTheme } from '@emotion/react';
import EmailHtml from './EmailHtml';
import { renderToStaticMarkup } from 'react-dom/server';
import SnackbarWrapper from '../ui/SnackbarWrapper';
import BodyEditor from './BodyEditor';
import DialogWarning from '../ui/DialogWarning';
import ElementEditorSpacer from './ElementEditorSpacer';
import Loading from '../ui/Loading';
import CustomTooltip from '../ui/CustomTooltip';
import FileDropZone from '../ui/FileDropZone';
import DialogAction from '../ui/DialogAction';
import ElementEditorGif from './ElementEditorGif';
import ElementEditorStickers from './ElementEditorSticker';

// Element types for the email builder
const ELEMENT_TYPES = [
  { type: 'Text', label: 'Text', id: 'text', base: 'element', style:{}, wrapStyle:{} },
  { type: 'Button', label: 'Button', id: 'button', base: 'element', style:{}, wrapStyle:{} },
  { type: 'Image', label: 'Image', id: 'image', base: 'element', style:{}, wrapStyle:{} },
  // { type: 'Video', label: 'Video', id: 'video', base: 'element', style:{}, wrapStyle:{} },
  { type: 'Divider', label: 'Divider', id: 'divider', base: 'element', style:{}, wrapStyle:{} },
  { type: 'Spacer', label: 'Spacer', id: 'spacer', base: 'element', style:{}, wrapStyle:{} },
  { type: 'GIF', label: 'GIF', id: 'gif', base: 'element', style:{}, wrapStyle:{} },
  { type: 'Sticker', label: 'Sticker', id: 'sticker', base: 'element', style:{}, wrapStyle:{} },
  // { type: 'Quote', label: 'Quote', id: 'quote', base: 'element', style:{}, wrapStyle:{} },
  // { type: 'Link', label: 'Link', id: 'link', base: 'element', style:{}, wrapStyle:{} },
  // { type: 'List', label: 'List', id: 'list', base: 'element', style:{}, wrapStyle:{} },
  // { type: 'Grid Items', label: 'Grid Items', id: 'grid-items', base: 'element', style:{}, wrapStyle:{} },
];

const BUTTON_TYPES = [
  { type: 'Variables', label: 'Variables', id: 'variables', base: 'button', style:{} },
]

const SECTION_TYPES = [
  { type: 'column-1', label: '1 Column', id: 'column-1', base: 'section', style:{}, icon:<Column1SVG height={16} color="#ccc" /> },
  { type: 'column-2', label: '2 Column', id: 'column-2', base: 'section', style:{}, icon:<Column2SVG height={16} color="#ccc" /> },
  { type: 'column-3', label: '3 Column', id: 'column-3', base: 'section', style:{}, icon:<Column3SVG height={16} color="#ccc" /> },
];

const EmailTemplate = (props) => {
  const byDesign = process.env.REACT_APP_by_design === "true" ? true : false;
  const { emailTemplate, template, storageFile, setReload } = props; // Get incoming template parts if it exists
  const [sections, setSections] = useState(byDesign ? {body: {}, root:{}, sections:[]} : initEmailTemplate); // Track the email sections
  const [tempSections, setTempSections] = useState(null) // State to temporarily store sections when updated from new import
  // const [body, setBody] = useState({}); // Track the email body
  const [dragging, setDragging] = useState(null); // Track the dragged element or section
  const [dragOverSection, setDragOverSection] = useState(null); // Track the section being dragged over
  const [dragOverElement, setDragOverElement] = useState(null); // Track the section being dragged over
  const [selectedItem, setSelectedItem] = useState(null); // Track the selected section or element
  const [snackMessage, setSnackMessage] = useState(null); // Track the snackbar messages
  const [templateName, setTemplateName] = useState(template?.name || 'Untitled Template');
  const [tempTemplateName, setTempTemplateName] = useState(null); // State to temporarily store name when updated from new import
  const [openName, setOpenName] = useState(false);
  const [preview, setPreview] = useState(false);
  const [previewSize, setPreviewSize] = useState(800);
  const [openVariables, setOpenVariables] = useState(false); // Track the variables dialog open state
  const [openImport, setOpenImport] = useState(false); // Track the import dialog open state
  const [openWarning, setOpenWarning] = useState(null); // This value becomes a string, NOT a boolean; 
  const [openExportHtml, setOpenExportHtml] = useState(null); // This value becomes a string, NOT a boolean; 
  const [importedJson, setImportedJson] = useState(null); // This is the value if a json file is imported
  const [isChanged, setIsChanged] = useState(false); // This checks whether the local data is different from db data
  const [unsubExists, setUnsubExists] = useState(false); // This checks whether the {-{unsub}-} variable string is in the sections array
  const [saving, setSaving] = useState(false);
  const customFields = useGetAllCustomFields();
  const theme = useTheme();
  const urlPath = window.location.pathname;
  const urlPathItems = urlPath.split('/');
  const isNew = urlPathItems[urlPathItems.length-1] === 'new' || urlPathItems[urlPathItems.length-2] === 'new'

  // console.log('emailTemplate', emailTemplate)
  // console.log('template', template)
  // console.log('tempSections', tempSections)
  // console.log('unsubExists', unsubExists)
  console.log('sections', sections)

  // Update sections if there is an incomingTemplate
  useEffect(() => {
    if (emailTemplate) {
      if (JSON.stringify(emailTemplate.data) !== JSON.stringify(sections)) {
        console.log('setting sections')
        setSections(JSON.parse(JSON.stringify(emailTemplate.data)));
      } else {console.log('no sections to update')}
    }
  }, [emailTemplate])

  // Update templateName state from template data
  useEffect(() => {
    if (template?.name) {
      setTemplateName(template.name);
    }
  }, [template?.name])

  // Check if the local sections and templateName is same as db content
  useEffect(() => {
    if (template && emailTemplate) {
      const nameChanged = template.name !== templateName;
      const sectionsChanged = JSON.stringify(emailTemplate.data) !== JSON.stringify(sections);
      console.log('nameChanged', nameChanged);
      console.log('sectionsChanged', sectionsChanged);
      if (nameChanged || sectionsChanged) {
        setIsChanged(true);
      } else {
        setIsChanged(false);
      }
    }
  }, [template, emailTemplate, templateName, sections])

  // Check whether the {-{unsub}-} variable string is in the sections array
  useEffect(() => {
    if (sections.sections) {
      const sectionsString = JSON.stringify(sections.sections);
      // Check if the string includes '{-{unsub}-}'
      setUnsubExists(sectionsString.includes("{-{unsub}-}"));
    }
  }, [sections])

  // Close warning dialog
  const handleCloseWarning = () => {
    setOpenWarning(null);
  }

  // Handle updates to the body div element
  const handleBodyStyleChange = (newStyle) => {
    const copiedBody = { ...sections.body };
    const updatedBody = { ...copiedBody, style: { ...copiedBody.style, ...newStyle } };

    setSections(prev => ({ ...prev, body: updatedBody }));
  }

  // Handle updates to the body div element
  const handleRootStyleChange = (newStyle) => {
    const copiedRoot = { ...sections.root };
    const updatedRoot = { ...copiedRoot, style: { ...copiedRoot.style, ...newStyle } };

    setSections(prev => ({ ...prev, root: updatedRoot }));
  }

  // Handle updates to the root of a section
  const handleSectionChange = (newSection) => {
    if (selectedItem && selectedItem.base === 'section') {
      const copiedSections = [ ...sections.sections ];
      const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.uid);
      let updatedSection = copiedSections[sectionIndex];
      updatedSection = { ...updatedSection, ...newSection };

      copiedSections[sectionIndex] = updatedSection;

      setSections(prev => ({ ...prev, sections: copiedSections }));
      setSelectedItem(updatedSection);
    }
  }

  // Handle style updates when a text element is selected
  const handleSectionStyleChange = (newStyle) => {
    if (selectedItem && selectedItem.base === 'section') {
      const copiedSections = [ ...sections.sections ];
      const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.uid);
      let updatedSection = copiedSections[sectionIndex];
      updatedSection.style={ ...updatedSection.style, ...newStyle };

      copiedSections[sectionIndex] = updatedSection;

      setSections(prev => ({ ...prev, sections: copiedSections }));
    }
  }

  // Handle general updates to element root fields
  const onElementChange = (field, newContent) => {
    const copiedSections = [ ...sections.sections ];
    const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.sectionId);
    const elementIndex = copiedSections[sectionIndex]?.elements.findIndex(x => x.uid === selectedItem.uid && x.columnIndex === selectedItem.columnIndex);
    let updatedElement = copiedSections[sectionIndex]?.elements[elementIndex];
    updatedElement[field] = newContent;
    copiedSections[sectionIndex].elements[elementIndex] = updatedElement;

    setSections(prev => ({ ...prev, sections: copiedSections }));
  }

  // Handle style updates when a text element is selected
  const handleTextStyleChange = (newStyle) => {
    if (selectedItem && (selectedItem.type === 'Text' || selectedItem.type === "Button" || selectedItem.type === "Image" || selectedItem.type === "GIF" || selectedItem.type === "Divider" || selectedItem.type === "Spacer")) {
      const copiedSections = [ ...sections.sections ];
      const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.sectionId);
      const elementIndex = copiedSections[sectionIndex]?.elements.findIndex(x => x.uid === selectedItem.uid && x.columnIndex === selectedItem.columnIndex);
      let updatedElement = copiedSections[sectionIndex]?.elements[elementIndex];
      updatedElement.style={ ...updatedElement.style, ...newStyle };

      copiedSections[sectionIndex].elements[elementIndex] = updatedElement;

      setSections(prev => ({ ...prev, sections: copiedSections }));
    }
  }

  // Handle style updates when a text element is selected
  const handleWrapStyleChange = (newStyle) => {
    const copiedSections = [ ...sections.sections ];
    const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.sectionId);
    const elementIndex = copiedSections[sectionIndex]?.elements.findIndex(x => x.uid === selectedItem.uid && x.columnIndex === selectedItem.columnIndex);
    let updatedElement = copiedSections[sectionIndex]?.elements[elementIndex];
    updatedElement.wrapStyle={ ...updatedElement.wrapStyle, ...newStyle };

    copiedSections[sectionIndex].elements[elementIndex] = updatedElement;

    setSections(prev => ({ ...prev, sections: copiedSections }));
  }

  // Handle updates to the content field
  const onTextContentChange = (newContent) => {
    if (selectedItem && (selectedItem.type === 'Button')) {
      const copiedSections = [ ...sections.sections ];
      const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.sectionId);
      const elementIndex = copiedSections[sectionIndex]?.elements.findIndex(x => x.uid === selectedItem.uid && x.columnIndex === selectedItem.columnIndex);
      let updatedElement = copiedSections[sectionIndex]?.elements[elementIndex];
      updatedElement.content=newContent;
      copiedSections[sectionIndex].elements[elementIndex] = updatedElement;

      setSections(prev => ({ ...prev, sections: copiedSections }));
    }
  }
  
  // Handle updates to the url field
  const onTextUrlChange = (newUrl) => {
    if (selectedItem && (selectedItem.type === 'Button' || selectedItem.type === "Image" || selectedItem.type === "GIF" || selectedItem.type === "Sticker")) {
      console.log('newUrl', newUrl);
      const copiedSections = [ ...sections.sections ];
      const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.sectionId);
      const elementIndex = copiedSections[sectionIndex]?.elements.findIndex(x => x.uid === selectedItem.uid && x.columnIndex === selectedItem.columnIndex);
      let updatedElement = copiedSections[sectionIndex]?.elements[elementIndex];
      updatedElement.url=newUrl;
      copiedSections[sectionIndex].elements[elementIndex] = updatedElement;

      setSections(prev => ({ ...prev, sections: copiedSections }));
    }
  }

  // Handle updates to the alt field
  const onTextAltChange = (newAlt) => {
    if (selectedItem && selectedItem.type === "Image") {
      const copiedSections = [ ...sections.sections ];
      const sectionIndex = copiedSections.findIndex(x => x.uid === selectedItem.sectionId);
      const elementIndex = copiedSections[sectionIndex]?.elements.findIndex(x => x.uid === selectedItem.uid && x.columnIndex === selectedItem.columnIndex);
      let updatedElement = copiedSections[sectionIndex]?.elements[elementIndex];
      updatedElement.alt=newAlt;
      copiedSections[sectionIndex].elements[elementIndex] = updatedElement;

      setSections(prev => ({ ...prev, sections: copiedSections }));
    }
  }
  
  // Handle general updates to an element
  const handleElementUpdate = (updatedElement) => {
    const updatedSections = sections.sections.map((section) => {
      if (section.uid === updatedElement.sectionId) {
        return {
          ...section,
          elements: section.elements.map((el) =>
            el.uid === updatedElement.uid ? updatedElement : el
          ),
        };
      }
      return section;
    });
    setSections(prev => ({ ...prev, sections: updatedSections }));
  };

  // Handle deleting an element
  const handleElementDelete = (deletedElement) => {
    const updatedSections = sections.sections.map((section) => {
      if (section.uid === deletedElement.sectionId) {
        return {
          ...section,
          elements: section.elements.filter(x => x.uid != deletedElement.uid)
        };
      }
      return section;
    });
    setSelectedItem(null);
    setSections(prev => ({ ...prev, sections: updatedSections }));

  }

  // Handle duplicating an element and putting it below the original
  const handleElementCopy = (copiedElement) => {
    console.log('copiedElement', copiedElement)
    const newSections = [ ...sections.sections ]
    // Find the section and the element
    for (const section of newSections) {
        const index = section.elements.findIndex(el => el.uid === copiedElement.uid);
        console.log('copiedElement', copiedElement)
        if (index !== -1) {
            // Copy the element
            const newElement = { ...section.elements[index] };
            console.log('copiedElement', copiedElement)
  
            // Generate a new uid
            newElement.uid = getRandomString(16);
  
            // Insert the new element after the original one
            section.elements.splice(index + 1, 0, newElement);
        }
    }

    setSections(prev => ({ ...prev, sections: newSections }));
  }
  
  // Handle the drag start event when dragging sections (using the icon)
  const handleSectionDragStart = (e, element, sectionId) => {
    e.stopPropagation();
    setDragging({ element, sectionId });
  };

  // Handle drag over for sections to visualize where the section is being dragged
  const handleDragOverSection = (e, sectionId) => {
    e.preventDefault();
    setDragOverSection(sectionId); // Highlight the section being dragged over
  };

  // Handle the drop event for sections to move them to a new position
  const handleSectionDrop = (e, targetSectionId) => {
    e.preventDefault();
    if (dragging && dragging.sectionId !== targetSectionId) {
      const draggedSectionId = dragging.sectionId;
      const newSections = [...sections.sections];

      const draggedIndex = newSections.findIndex(s => s.uid === draggedSectionId);
      const targetIndex = newSections.findIndex(s => s.uid === targetSectionId);

      const [movedSection] = newSections.splice(draggedIndex, 1);
      newSections.splice(targetIndex, 0, movedSection);

      setSections(prev => ({ ...prev, sections: newSections }) );
      setDragging(null);
      setDragOverSection(null);
    }
  };

  // Handle the drag end event for sections and elements
  const handleDragEnd = () => {
    setDragging(null);
    setDragOverSection(null); // Reset when the drag ends
  };

  // Add a new section (column-1, column-2, column-3) when the user drops the respective column element
  const addSection = (element) => {
    setSections(prev => ({
      ...prev,
      sections: [
        ...sections.sections,
        {
          uid: `${element.base}-${getRandomString(16)}`,
          id: element.id,
          type: element.type,
          label: element.label,
          base: element.base,
          class: 'fill-eb-section',
          style: {},
          elements: [],
        },
      ]
    }));
  };

  // Remove a section
  const removeSection = () => {
    const sectionId = openWarning;
    const updatedSections = sections.sections.filter(section => section.uid !== sectionId);
    if (selectedItem && selectedItem.uid === sectionId) {
      setOpenWarning(null);
      setSelectedItem(null); // Clear selection if the section is removed
      setSections(prev => ({ ...prev, sections:  updatedSections}) );
    }
  };
  
  // Handle drag start for elements within sections
  const handleElementDragStart = (e, element, sectionId) => {
    e.stopPropagation(); // Prevent triggering section drag while dragging elements
    setDragging({ element, sectionId });
  };

  // Handle drag start for elements within sections
  const handleElementButton = (button) => {
    // e.stopPropagation(); // Prevent triggering section drag while dragging elements
    if (button.id === 'variables') {
      setOpenVariables(true);
    }
  };

  // Handle the drag over event to visualize where to drop elements
  const handleElementDragOver = (e, sectionId, targetElementIndex) => {
    e.preventDefault();
    setDragOverSection(sectionId);
    setDragOverElement(targetElementIndex); // Store the target index
};

  // Handle the drop event for elements within the same section
// Handle the drop event for elements within the same section
  const handleElementDrop = (e, sectionId, columnIndex) => {
    e.preventDefault();
    e.stopPropagation();

    // Stop is the element is a column 'section' base type
    if (dragging.element.base === 'section') {
      setDragging(null);  // Clear the dragging state
      return;
    }

    // If there's an element being dragged
    if (dragging && dragging.element) {
        const newSections = [...sections.sections];
        const draggedElement = dragging.element;

        // Check if the dragged element is new (not previously in a section)
        if (!draggedElement.sectionId) {
            // Add the element to the target section's column
            const targetSectionIndex = newSections.findIndex(s => s.uid === sectionId);
            if (targetSectionIndex === -1) {
                return; // Target section not found
            }

            const updatedElement = {
                ...draggedElement,
                uid: draggedElement.uid || getRandomString(16), // Generate a new UID if it doesn't exist
                class: `fill-eb-${draggedElement.id}`,
                columnIndex,
                sectionId,
            };

            // Insert the element at the target index, or push to the end if null
            if (dragOverElement !== null) {
                newSections[targetSectionIndex].elements.splice(dragOverElement, 0, updatedElement);
                newSections[targetSectionIndex].elements.sort((a,b) => a.columnIndex - b.columnIndex);
            } else {
                newSections[targetSectionIndex].elements.push(updatedElement);
                newSections[targetSectionIndex].elements.sort((a,b) => a.columnIndex - b.columnIndex);
            }

        } else {
            // If the element was previously in a section, move it to the new section
            const originalSectionIndex = newSections.findIndex(s => s.uid === draggedElement.sectionId);
            if (originalSectionIndex === -1) {
                return; // Original section not found
            }

            // Remove the element from its original section
            newSections[originalSectionIndex].elements = newSections[originalSectionIndex].elements.filter(
                (el) => el.uid !== draggedElement.uid
            );

            // Prepare the updated element
            const updatedElement = {
                ...draggedElement,
                columnIndex,
                sectionId,
            };

            // Insert the element at the target index in the new section
            const targetSectionIndex = newSections.findIndex(s => s.uid === sectionId);
            if (targetSectionIndex === -1) {
                return; // Target section not found
            }

            if (dragOverElement !== null) {
                newSections[targetSectionIndex].elements.splice(dragOverElement, 0, updatedElement);
                newSections[targetSectionIndex].elements.sort((a,b) => a.columnIndex - b.columnIndex);
            } else {
                newSections[targetSectionIndex].elements.push(updatedElement);
                newSections[targetSectionIndex].elements.sort((a,b) => a.columnIndex - b.columnIndex);
            }
        }

        // Update the sections state and clear dragging
        setSections(prev => ({ ...prev, sections:newSections }) );
        setDragging(null);  // Clear the dragging state

    } else {
        console.log('No element being dragged.');
    }
  };

  // Function to export and download sections as JSON
  const exportJSON = () => {
    // Use sections
    const jsonData = { ...sections };

    // Set json filename
    const fileName = `${templateName}.json` || 'template_data.json';
    // Convert JSON data to a string
    const jsonString = JSON.stringify(jsonData, null, 2);

    // Create a blob from the JSON string
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Append the anchor to the document, click it, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // Function to export and download sections as JSON
  const importJSON = () => {
    // Use sections
    const jsonData = { ...sections };

    // Set json filename
    const fileName = `${template?.name}.json` || 'template_data.json';
    // Convert JSON data to a string
    const jsonString = JSON.stringify(jsonData, null, 2);

    // Create a blob from the JSON string
    const blob = new Blob([jsonString], { type: "application/json" });

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Append the anchor to the document, click it, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // Function to export sections as HTML
  const exportHTML = () => {
    // Render the EmailHtml component to a string
    const html = renderToStaticMarkup(<EmailHtml json={sections} />);
    setOpenExportHtml(html);
  };

  // Function to return sections as HTML for saving functions
  const returnHTML = () => {
    // Render the EmailHtml component to a string
    const html = renderToStaticMarkup(<EmailHtml json={sections} />);
    console.log("Exported HTML:");
    console.log(html);
    return html;
  };

  // Copy html as pasteable blob
  const copyHtmlAsBlob = async () => {
    if (navigator.clipboard && navigator.clipboard.write) {
      try {
        // Ensure openExportHtml is a string or convert it
        const htmlString = typeof openExportHtml === "string"
          ? openExportHtml
          : JSON.stringify(openExportHtml);

        // Create a Blob from the HTML string
        const blob = new Blob([htmlString], { type: "text/html" });
        console.log("Blob created:", blob);

        // Create a ClipboardItem
        const clipboardItem = new ClipboardItem({ "text/html": blob });
        console.log("ClipboardItem created:", clipboardItem);

        // Write the ClipboardItem to the clipboard (wrap it in an array)
        await navigator.clipboard.write([clipboardItem]);

        console.log("HTML content copied to clipboard as a Blob!");
      } catch (err) {
        console.error("Failed to copy HTML to clipboard:", err);
      }
    } else {
      console.log("Clipboard API not supported. Using fallback...");
      fallbackCopyHtml(openExportHtml);
    }
  };

  // Copy html as pasteable blob
  const copyHtmlAsText = () => {
    if (navigator.clipboard && navigator.clipboard.write) {
      try {
        copyString(openExportHtml);
        console.log("HTML content copied to clipboard as text!", openExportHtml);
      } catch (err) {
        console.error("Failed to copy HTML to clipboard: ", err);
      }
    } else {
      console.log("Clipboard API not supported. Using fallback...");
      fallbackCopyHtml(openExportHtml);
    }
  }

  function fallbackCopyHtml(htmlContent) {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlContent;
    document.body.appendChild(tempElement);

    const range = document.createRange();
    range.selectNodeContents(tempElement);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand("copy");
      console.log("HTML content copied using fallback method!");
    } catch (err) {
      console.error("Fallback failed:", err);
    }

    document.body.removeChild(tempElement);
  }

  // Select an item (either a section or element)
  const selectItem = (e, item) => {
    e.stopPropagation();
    setSelectedItem(item); // Toggle selection
    // setSelectedItem(item === selectedItem ? null : item); // Toggle selection
  };

  const handleVariableCopy = (field) => {
    copyString(`{-{${field.field_name}}-}`);
    setSnackMessage({open: true, message: `${field.user_visible_name} variable copied`, severity: 'success'});
    setOpenVariables(false);
  }

  const updateTemplate = async () => {
    setSaving(true);
    try {
      // Check if name has been changed and update the template if it has
      if (templateName !== template.name) {
        const emailTemplateObj = {
          emailTemplateId: template.id,
          payload: { ...template, name: templateName },
        }
        const savedTemplate = await putEmailTemplate(emailTemplateObj);
        console.log('savedTemplate', savedTemplate)
        if (savedTemplate.status === "success") {
          setOpenName(false);
          setSnackMessage({open: true, message: 'Name updated successfully', severity: 'success'})
      
        } else if (savedTemplate.status === "error") {
          setSnackMessage({open: true, message: savedTemplate.error, severity: 'error'})
        }    
      }
      const storageFolder = await getEmailTemplateFolder();
      const storageFolderId = storageFolder.folderId;

      // Place the sections json and html into a json
      const rawJson = { content: returnHTML(), data: sections, existing: true };
      console.log('data for json', rawJson);
      const jsonBlob = new Blob([JSON.stringify(rawJson, null, 2)], { type: 'application/json' });
      console.log('jsonBlob', jsonBlob);
      const fileName = `${template.name.replace(' ', '_')}-${storageFile.id}-${getRandomString(16)}`;
      console.log('fileName', fileName);
      const jsonFileObj = new File([jsonBlob], fileName, { type: 'text/plain' });
      console.log('jsonFileObj', jsonFileObj);
      const formData = new FormData();
      formData.append('file', jsonFileObj);
      formData.append('name', fileName); // <-- use state variable to save and edit the name
      formData.append('folder', storageFolderId);
      console.log('formData', formData);

      // setSnackMessage({open: true, message: <LinearProgress color='primary' style={{width:'200px', height:'15px', borderRadius:30}} />})
      const storageObj = {
        storageFilePayload: formData,
        folderId: storageFolderId,
        fileId: template.content_file
      }
      console.log('storageObj', storageObj);

      const updatedFile = await putStorageFile(storageObj);
      if (updatedFile.status === "success") {
        setSnackMessage({open: true, message: 'Template Saved', severity: 'success'})

      } else {
        setSnackMessage({open: true, message: 'Error saving template', severity: 'error'})

      }
      setReload(true);
      setSaving(false);

    } catch (error) {
      console.log(`Error updating template ${template.id}`, error)
      setSaving(false);
    }
  };

  const createTemplate = async () => {
    setSaving(true);
    try {
      const storageFolder = await getEmailTemplateFolder();
      const storageFolderId = storageFolder.folderId;
      
      // Place the sections json and html into a json
      const rawJson = { content: returnHTML(), data: sections, existing: true };
      console.log('data for json', rawJson);
      const jsonBlob = new Blob([JSON.stringify(rawJson, null, 2)], { type: 'application/json' });
      const fileName = `${templateName.replace(' ', '_')}-${getRandomString(16)}`;
      console.log('fileName', fileName);
      const jsonFileObj = new File([jsonBlob], fileName, { type: 'text/plain' });
      const formData = new FormData();
      formData.append('file', jsonFileObj);
      formData.append('name', fileName);
      formData.append('folder', storageFolderId);

      const emailTemplateObj = {
        storageFolderId: storageFolderId,
        storagePayload: formData,
        templatePayload: {
          'name': templateName,
          'content': '',
          'update_date': new Date(),
          'editor_json': null,
          'last_used_campaign': null 
        }
      }

      const savedTemplate = await createEmailTemplate(emailTemplateObj);
      if (savedTemplate.status === "success") {
        setSnackMessage({open: true, message: 'New email template created', severity:'success'})
        setTimeout(() => {
          window.location.href = `/templates/${savedTemplate.newTemplateData.id}`
        }, 500);
      } else if (savedTemplate.status === "error") {
        setSnackMessage({open: true, message: 'Error creating email template', severity:'error'})
      }
      setSaving(false);
    } catch (error) {
      console.log('Error creating new email template', error)
      setSnackMessage({open: true, message: 'Error creating email template', severity:'error'})
      setSaving(false);
    }
  }



  return (
    <div className="EmailTemplate-email-builder">
      {/* Elements Panel */}
      <div className={`EmailTemplate-elements-panel`}>
        <h3>Content</h3>
        <h4>Sections</h4>
        {SECTION_TYPES.map((section) => (
          <div
            key={section.uid}
            draggable
            onDragStart={(e) => handleElementDragStart(e, section)}
            className="EmailTemplate-element EmailTemplate-flex EmailTemplate-space-between"
          >
            {section.label} {cloneElement(section.icon, {width:'100%'})}
          </div>
        ))}
        <h4>Elements</h4>
        {ELEMENT_TYPES.map((element) => (
          <div
            key={element.uid}
            draggable
            onDragStart={(e) => handleElementDragStart(e, element)}
            className="EmailTemplate-element"
          >
            {element.type}
          </div>
        ))}
        {BUTTON_TYPES.map((button) => (
          <div
            key={button.id}
            onClick={() => handleElementButton(button)}
            // draggable
            // onDragStart={(e) => handleElementButton(e, button)}
            className="EmailTemplate-element-clickable"
          >
            <div>
            {button.type}
            </div>
            <div>
            {`>`}
            </div>
          </div>
        ))}
        {/* Export Buttons */}
        <div className="EmailTemplate-section-header EmailTemplate-gap-8">
          {/* <button onClick={exportJSON}>Export JSON</button> */}
          <button className="EmailTemplate" onClick={exportHTML} style={{width:'100%', marginBottom:8, padding:'12px 8px'}}>
            Export HTML
          </button>
        </div>
        <div className="EmailTemplate-section-header EmailTemplate-gap-8">
          <button className="EmailTemplate" onClick={exportJSON} style={{width:'100%', marginBottom:8, padding:'12px 8px'}}>
            Download
          </button>
        </div>
        <div className="EmailTemplate-section-header EmailTemplate-gap-8">
          <button className="EmailTemplate" onClick={() => setOpenImport(true)} style={{width:'100%', marginBottom:8, padding:'12px 8px'}}>
            Import
          </button>
        </div>
          {
            !byDesign && // Only show the Save button when byDesign is false
            <div className="EmailTemplate-section-header-column EmailTemplate-gap-8">
                <button className="EmailTemplate" onClick={emailTemplate ? updateTemplate : createTemplate} style={{width:'100%', padding:'12px 8px'}}>
                  {
                    saving
                      ? <><CircularProgress sx={{color:'#fff'}} thickness={8} size={12} /> <span style={{marginLeft:8}}>Saving</span></>
                      : 'Save Template'
                  }
                </button>
                {isChanged && <><WarningSVG width={20} color1={theme.palette.warning.main} /><Typography>Unsaved changes</Typography></>}
              </div>
          }
      </div>

      {/* Email Builder */}
      {
        emailTemplate !== undefined || isNew
          ?
            <div
              className={`EmailTemplate-builder-panel`}
              onClick={() => setSelectedItem(null)}
              style={{
                backgroundColor: sections.root.style?.backgroundColor || 'transparent'
              }}
            >
              <div
                className={`EmailTemplate-builder-display`}
                onClick={() => setSelectedItem(null)}
              >
                <div className="EmailTemplate-flex EmailTemplate-space-between">
                  <div className="EmailTemplate-flex" onClick={() => setOpenName(true)}>
                    <h3>
                      {openName 
                        ? <input
                          value={templateName}
                          onChange={(e) => setTemplateName(e.target.value)}
                          onBlur={() => setOpenName(false)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              setOpenName(false);
                            }
                          }}          
                          autoFocus
                          style={{
                            outline:'none',
                            border:'none'
                          }}
                        />
                        : templateName}
                    </h3>
                    {openName ? null : <EditSVG onClick={() => setOpenName(true)} width={16} style={{marginLeft:8}} />}
                  </div>
                  <button  className="EmailTemplate" onClick={() => setPreview(prev => !prev)}>{preview ? "Edit" : "Preview"}</button>
                  <div className="EmailTemplate-flex ">
                    <button className={`EmailTemplate ${previewSize === 'mobile' ? 'EmailTemplate-active-icon' : 'EmailTemplate-invisible'}`} onClick={() => setPreviewSize('mobile')}><MobileSVG height={22} color={previewSize === 'mobile' ? '#FFF' : 'inherit'} /></button>
                    <button className={`EmailTemplate ${previewSize === 'tablet' ? 'EmailTemplate-active-icon' : 'EmailTemplate-invisible'}`} onClick={() => setPreviewSize('tablet')}><TabletSVG height={22} color={previewSize === 'tablet' ? '#FFF' : 'inherit'} /></button>
                    <button className={`EmailTemplate ${previewSize === 'desktop' ? 'EmailTemplate-active-icon' : 'EmailTemplate-invisible'}`} onClick={() => setPreviewSize('desktop')}><DesktopSVG height={22} color={previewSize === 'desktop' ? '#FFF' : 'inherit'} /></button>
                  </div>
                </div>
              </div>
              <div
                className={`EmailTemplate-builder-display ${previewSize}`}
                onClick={() => setSelectedItem(null)}
              >

                {
                  !preview
                    ?
                      // Edit Sections
                      <div className="EmailTemplate-builder-layout">
                        {sections?.sections.map((section, sectionIndex) => (
                          <div
                            key={section.uid}
                            className={`EmailTemplate-section ${dragOverSection === section.uid ? 'EmailTemplate-drag-over' : ''} ${selectedItem === section ? 'EmailTemplate-selected' : ''}`}
                            onDragOver={(e) => handleDragOverSection(e, section.uid)}
                            onDrop={(e) => handleSectionDrop(e, section.uid)}
                            onClick={(e) => selectItem(e, section)} // Handle click for section selection
                            style={
                              section.style
                            }
                          >
                            <div
                              className="EmailTemplate-section-header EmailTemplate-drag-handle EmailTemplate-remove"
                              draggable
                              onDragStart={(e) => handleSectionDragStart(e, section, section.uid)}
                              onDragEnd={handleDragEnd}
                            >
                              <MoveAllDirectionsSVG height={20} />
                              <div className="EmailTemplate-flex EmailTemplate-gap-8">Section {SECTION_TYPES.find(x => x.type === section.type).icon}</div>
                              <div onClick={() => setOpenWarning(section.uid)}><DeleteSVG height={18} style={{marginRight:10}} /></div>
                            </div>

                            <table className={`EmailTemplate-section-columns`}>
                              <tbody>
                                {section.type === 'column-1' && (
                                  <tr>
                                    <td
                                      className={`EmailTemplate-column EmailTemplate-${section.type}`}
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e) => handleElementDrop(e, section.uid, 0)}
                                    >
                                      {section.elements.map((element, elementIndex) => (
                                        element.columnIndex === 0 ? (
                                          <div
                                            key={elementIndex}
                                            className={`EmailTemplate-element ${selectedItem === element ? 'EmailTemplate-selected' : ''}`}  // Highlight selected elements
                                            onClick={(e) => selectItem(e, element)} // Handle click for element selection
                                            draggable
                                            onDragStart={(e) => handleElementDragStart(e, element, section.uid)}
                                            onDragOver={(e) => handleElementDragOver(e, section.uid, elementIndex)} // Pass the index here
                                            onDragEnd={handleDragEnd}
                                          >
                                            <ElementRenderer
                                              key={element.uid}
                                              element={element}
                                              elementStyle={element.style}
                                              focused={selectedItem?.uid === element.uid}
                                              onElementUpdate={handleElementUpdate}
                                              onElementDelete={handleElementDelete}
                                              onElementCopy={handleElementCopy}
                                              preview={preview}
                                            />
                                          </div>
                                        ) : null
                                      ))}
                                    </td>
                                  </tr>
                                )}

                                {/* Other column layouts (column-2) */}
                                {section.type === 'column-2' && (
                                  <tr>
                                    <td
                                      className={`EmailTemplate-column EmailTemplate-${section.type}`}
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e) => handleElementDrop(e, section.uid, 0)}
                                    >
                                      {section.elements.map((element, elementIndex) =>
                                        element.columnIndex === 0 ? (
                                          <div
                                            key={elementIndex}
                                            className={`EmailTemplate-element ${selectedItem === element ? 'EmailTemplate-selected' : ''}`}
                                            onClick={(e) => selectItem(e, element)}
                                            draggable
                                            onDragStart={(e) => handleElementDragStart(e, element, section.uid)}
                                            onDragOver={(e) => handleElementDragOver(e, section.uid, elementIndex)} // Pass the index here
                                            onDragEnd={handleDragEnd}
                                          >
                                            <ElementRenderer
                                              key={element.uid}
                                              element={element}
                                              focused={selectedItem?.uid === element.uid}
                                              onElementUpdate={handleElementUpdate}
                                              onElementDelete={handleElementDelete}
                                              onElementCopy={handleElementCopy}
                                              preview={preview}
                                            />
                                          </div>
                                        ) : null
                                      )}
                                    </td>
                                    <td
                                      className={`EmailTemplate-column EmailTemplate-${section.type}`}
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e) => handleElementDrop(e, section.uid, 1)}
                                    >
                                      {section.elements.map((element, elementIndex) =>
                                        element.columnIndex === 1 ? (
                                          <div
                                            key={elementIndex}
                                            className={`EmailTemplate-element ${selectedItem === element ? 'EmailTemplate-selected' : ''}`}
                                            onClick={(e) => selectItem(e, element)}
                                            draggable
                                            onDragStart={(e) => handleElementDragStart(e, element, section.uid)}
                                            onDragOver={(e) => handleElementDragOver(e, section.uid, elementIndex)} // Pass the index here
                                            onDragEnd={handleDragEnd}
                                          >
                                            <ElementRenderer
                                              key={element.uid}
                                              element={element}
                                              focused={selectedItem?.uid === element.uid}
                                              onElementUpdate={handleElementUpdate}
                                              onElementDelete={handleElementDelete}
                                              onElementCopy={handleElementCopy}
                                              preview={preview}
                                            />
                                          </div>
                                        ) : null
                                      )}
                                    </td>
                                  </tr>
                                )}

                                {/* Other column layouts (column-3) */}
                                {section.type === 'column-3' && (
                                  <tr>
                                    <td
                                      className={`EmailTemplate-column EmailTemplate-${section.type}`}
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e) => handleElementDrop(e, section.uid, 0)}
                                    >
                                      {section.elements.map((element, elementIndex) =>
                                        element.columnIndex === 0 ? (
                                          <div
                                            key={elementIndex}
                                            className={`EmailTemplate-element ${selectedItem === element ? 'EmailTemplate-selected' : ''}`}
                                            onClick={(e) => selectItem(e, element)}
                                            draggable
                                            onDragStart={(e) => handleElementDragStart(e, element, section.uid)}
                                            onDragOver={(e) => handleElementDragOver(e, section.uid, elementIndex)} // Pass the index here
                                            onDragEnd={handleDragEnd}
                                          >
                                            <ElementRenderer
                                              key={element.uid}
                                              element={element}
                                              focused={selectedItem?.uid === element.uid}
                                              onElementUpdate={handleElementUpdate}
                                              onElementDelete={handleElementDelete}
                                              onElementCopy={handleElementCopy}
                                              preview={preview}
                                            />
                                          </div>
                                        ) : null
                                      )}
                                    </td>
                                    <td
                                      className={`EmailTemplate-column EmailTemplate-${section.type}`}
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e) => handleElementDrop(e, section.uid, 1)}
                                    >
                                      {section.elements.map((element, elementIndex) =>
                                        element.columnIndex === 1 ? (
                                          <div
                                            key={elementIndex}
                                            className={`EmailTemplate-element ${selectedItem === element ? 'EmailTemplate-selected' : ''}`}
                                            onClick={(e) => selectItem(e, element)}
                                            draggable
                                            onDragStart={(e) => handleElementDragStart(e, element, section.uid)}
                                            onDragOver={(e) => handleElementDragOver(e, section.uid, elementIndex)} // Pass the index here
                                            onDragEnd={handleDragEnd}
                                          >
                                            <ElementRenderer
                                              key={element.uid}
                                              element={element}
                                              focused={selectedItem?.uid === element.uid}
                                              onElementUpdate={handleElementUpdate}
                                              onElementDelete={handleElementDelete}
                                              onElementCopy={handleElementCopy}
                                              preview={preview}
                                            />
                                          </div>
                                        ) : null
                                      )}
                                    </td>
                                    <td
                                      className={`EmailTemplate-column EmailTemplate-${section.type}`}
                                      onDragOver={(e) => e.preventDefault()}
                                      onDrop={(e) => handleElementDrop(e, section.uid, 2)}
                                    >
                                      {section.elements.map((element, elementIndex) =>
                                        element.columnIndex === 2 ? (
                                          <div
                                            key={elementIndex}
                                            className={`EmailTemplate-element ${selectedItem === element ? 'EmailTemplate-selected' : ''}`}
                                            onClick={(e) => selectItem(e, element)}
                                            draggable
                                            onDragStart={(e) => handleElementDragStart(e, element, section.uid)}
                                            onDragOver={(e) => handleElementDragOver(e, section.uid, elementIndex)} // Pass the index here
                                            onDragEnd={handleDragEnd}
                                          >
                                            <ElementRenderer
                                              key={element.uid}
                                              element={element}
                                              focused={selectedItem?.uid === element.uid}
                                              onElementUpdate={handleElementUpdate}
                                              onElementDelete={handleElementDelete}
                                              onElementCopy={handleElementCopy}
                                              preview={preview}
                                            />
                                          </div>
                                        ) : null
                                      )}
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        ))}

                        {/* Drop Root Section */}
                        <div
                          className="EmailTemplate-drop-root"
                          onDragOver={(e) => e.preventDefault()}
                          onDrop={(e) => {
                            const sectionType = dragging?.element?.type;
                            const isSection = dragging?.element?.base === 'section';
                            if (sectionType && isSection) {
                              addSection(dragging.element);
                            }
                            setDragging(null);
                          }}
                        >
                          <span>Drop new column sections here</span>
                          {
                            (!unsubExists && !byDesign) &&
                              <div
                                className="EmailTemplate-builder-layout-unsub-warning"
                              >
                                <WarningSVG width={20} color1={theme.palette.secondary.dark} />
                                <span style={{color:theme.palette.secondary.dark}}>No usubscribe link found</span>
                              </div>
                          }
                        </div>
                      </div>
                    :
                      <EmailHtml json={sections} />
                }


              </div>
            </div>
          : <Loading />
      }

      {/* Editor Panel */}
      <div className={`EmailTemplate-editor-panel ${preview && 'EmailTemplate-disabled'}`}>
        <h3>Editor</h3>
        {
          selectedItem
            ?
              <>
                <h4>{selectedItem.base === "section" ? `${selectedItem.label} Section` : selectedItem.type}</h4>
                {selectedItem && selectedItem.base === 'section' && (
                  <SectionEditor selectedElement={selectedItem} onSectionChange={handleSectionChange} onSectionStyleChange={handleSectionStyleChange} />
                )}
                {selectedItem && selectedItem.type === 'Text' && (
                  <ElementEditorText selectedElement={selectedItem} onTextStyleChange={handleTextStyleChange} onWrapStyleChange={handleWrapStyleChange}  />
                )}
                {selectedItem && selectedItem.type === 'Button' && (
                  <ElementEditorButton selectedElement={selectedItem} onTextStyleChange={handleTextStyleChange} onWrapStyleChange={handleWrapStyleChange} onTextContentChange={onTextContentChange} onTextUrlChange={onTextUrlChange} />
                )}
                {selectedItem && selectedItem.type === 'Image' && (
                  <ElementEditorImage selectedElement={selectedItem} onTextStyleChange={handleTextStyleChange} onWrapStyleChange={handleWrapStyleChange}  onTextAltChange={onTextAltChange} onTextUrlChange={onTextUrlChange} />
                )}
                {selectedItem && selectedItem.type === 'Divider' && (
                  <ElementEditorDivider selectedElement={selectedItem} onTextStyleChange={handleTextStyleChange} />
                )}
                {selectedItem && selectedItem.type === 'Spacer' && (
                  <ElementEditorSpacer selectedElement={selectedItem} onTextStyleChange={handleTextStyleChange} />
                )}
                {selectedItem && selectedItem.type === 'GIF' && (
                  <ElementEditorGif selectedElement={selectedItem} onTextStyleChange={handleTextStyleChange} onWrapStyleChange={handleWrapStyleChange}  onTextUrlChange={onTextUrlChange} />
                )}
                {selectedItem && selectedItem.type === 'Sticker' && (
                  <ElementEditorStickers selectedElement={selectedItem} onTextStyleChange={handleTextStyleChange} onWrapStyleChange={handleWrapStyleChange}  onTextUrlChange={onTextUrlChange} />
                )}
              </>
      
            :
                <BodyEditor onBodyStyleChange={handleBodyStyleChange} body={sections.body} onRootStyleChange={handleRootStyleChange} root={sections.root} />
        }
      </div>

      <Dialog
        open={openVariables}
        onClose={() => setOpenVariables(false)}
      >
        {
          byDesign
            ?
              <Grid
                container
                style={{
                  padding:24,
                  justifyContent:'center'
                }}
              >
                <Typography>
                  Variables allow you to send emails to an entire mailing list of contacts, dynamically putting in variables such as <span style={{fontWeight:700}}>first_name</span> or <span style={{fontWeight:700}}>email</span> to personalize the email. We can't add variables to the email template unless you have a Fill CRM subscription because we don't have access to your mailing lists and available variables.
                </Typography>
                <Button href="https://fillmarketing.com" target="_blank" sx={{mt:2}}>Explore the Fill CRM</Button>
              </Grid>
            :
              constantTemplateVariables && customFields
                ?
                  <Grid
                    container
                    style={{
                      padding:24
                    }}
                  >
                    <Typography
                      style={{
                        fontSize:'1.3rem',
                        textAlign:'center',
                        width:'100%'
                      }}
                    >
                      Field Variables
                    </Typography>
                    <Typography
                      style={{
                        textAlign:'center',
                        width:'100%',
                        marginBottom:16
                      }}
                    >
                      Copy and paste into a text element
                    </Typography>
                    <Typography
                      style={{
                        fontSize:'.9rem',
                        fontWeight: 700,
                        textAlign:'center',
                        width:'100%',
                        marginBottom:16,
                        textTransform:'uppercase'
                      }}
                    >
                      Click to copy
                    </Typography>
                    {
                      [ ...constantTemplateVariables, ...customFields ]?.map(field => (
                        <Grid
                          container
                          onClick={() => handleVariableCopy(field)}
                          sx={{
                            alignItems:'center',
                            padding:'4px 16px',
                            background:'#eee',
                            '&&:hover':{
                              background:'#ddd'
                            },
                            borderRadius:2,
                            marginBottom:1,
                            cursor:'pointer'
                          }}
                        >
                          <Grid
                            item
                            // xs
                          >
                            <Typography
                              style={{
                                fontSize:'.9rem',
                              }}
                            >
                              {field.user_visible_name}
                            </Typography>
                            <Typography
                              style={{
                                fontSize:'.9rem',
                                fontWeight: 600
                              }}
                            >
                              {`{-{${field.field_name}}-}`}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display:'flex',
                              justifyContent:'flex-end'
                            }}
                            xs
                          >
                            <IconButton

                            >
                              <CopySVG width={15} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))
                    }
                  </Grid>
                : null
        }
      </Dialog>

      <DialogAction
        open={openImport}
        onClose={() => setOpenImport(false)}
        header="Import"
        title="Import .json file"
        icon={<UploadSVG width={16} color1={theme.palette.primary.main} />}
        hideButtons
        messages={{
          one: `Import any ${byDesign ? 'ByDesign' : 'Fill'} email template file that you have previously downloaded. You will have the option to update the template name, content, or both.`
        }}
      >
        <Grid
          container
          sx={{
            // minHeight:300
          }}
        >
          {
            importedJson
              ?
                <Grid
                  container
                  sx={{
                    alignContent:'flex-start'
                  }}
                >
                  <Typography sx={{width:'100%', textAlign:'center', fontSize:'1.1rem'}}>Importing <span style={{fontWeight:700}}>{importedJson.file.name}</span></Typography>
                  <Grid container sx={{flexDirection:'column', alignItems:'center', gap:1, mt:4, border:'1px solid #ccc', borderRadius:2, p:2}}>
                  <Typography sx={{mt:-3.75, backgroundColor:'#fff', px:1, fontWeight:600}}>Update template name</Typography>
                  {
                    templateName !== importedJson.file.name.replace('.json', '')
                      ?
                        <>
                          <Typography>{templateName} <span style={{fontSize:'1.2rem', margin:'0 4px'}}>→</span> {importedJson.file.name.replace('.json', '')}</Typography>
                          <Button
                            onClick={() => {
                              setTempTemplateName(templateName);
                              setTemplateName(importedJson.file.name.replace('.json', ''));
                            }}
                            variant="contained"
                          >
                            Update Name
                          </Button>
                        </>
                      : 
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setTemplateName(tempTemplateName);
                            setTempTemplateName(null);
                          }}
                        >
                          Reverse Name Update
                        </Button>
                  }
                  </Grid>

                  <Grid container sx={{flexDirection:'column', alignItems:'center', gap:1, mt:4, border:'1px solid #ccc', borderRadius:2, p:2}}>
                    <Typography sx={{mt:-3.75, backgroundColor:'#fff', px:1, fontWeight:600}}>Update template content</Typography>
                  {
                    tempSections
                      ?
                        <Button
                            onClick={() => {
                              setSections(tempSections);
                              setTempSections(null);
                            }}
                            variant="outlined"
                          >
                          Reverse Update
                        </Button>
                      :
                        <>
                          <Typography>This will completely replace your existing email template with the imported template.</Typography>
                          <Button
                            onClick={() => {
                              setTempSections(sections);
                              setSections(importedJson.text);
                            }}
                            variant="contained"
                          >
                            Update Content
                          </Button>
                        </>
                  }
                  </Grid>
                  <Grid
                    container
                    sx={{
                      justifyContent:'space-between',
                      mt:2
                    }}
                  >
                    <Button
                      variant="outlined"
                      color="tertiary"
                      onClick={() => {
                        if (tempSections) setSections(tempSections);
                        if (tempTemplateName) setTemplateName(tempTemplateName);
                        setTempSections(null);
                        setTempTemplateName(null);
                        setImportedJson(null);
                        setOpenImport(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      color="tertiary"
                      onClick={() => {
                        setImportedJson(null);
                        setOpenImport(false);
                      }}
                    >
                      Done
                    </Button>
                  </Grid>
                </Grid>
              :
                <Grid
                  container
                >
                  {/* <Typography
                    sx={{
                      width:'100%',
                      textAlign:'center',
                      fontSize:'1.1rem',
                      fontWeight:700
                    }}
                  >
                    Import .json file
                  </Typography>
                  <Typography
                    sx={{
                      mb:2
                    }}
                  >
                    Import any {byDesign ? 'ByDesign' : 'Fill'} email template file that you have previously downloaded. You will have the option to update the template name, content, or both.
                  </Typography> */}
                  <FileDropZone
                    setFile={setImportedJson}
                    readAsText
                    message="Upload .json file"
                    style={{minWidth:350}}
                  />
                </Grid>
          }
        </Grid>
      </DialogAction>

      <Dialog
        open={openExportHtml}
        onClose={() => setOpenExportHtml(null)}
      >
        <Grid
          container
          sx={{
            background:'#333',
            p:2,
            lineBreak:'anywhere',
            maxHeight:600,
            overflowY:'auto'
          }}
        >
          <samp style={{color:'#fff', fontSize:'1rem'}}>
            {openExportHtml}
          </samp>
        </Grid>
        <Grid
          container
          sx={{
            justifyContent:'space-between',
            background:'#ddd',
            p:2
          }}
        >
          <CustomTooltip
            title="Copy and paste the html text"
          >
            <Button
              variant="contained"
              onClick={copyHtmlAsText}
              startIcon={<CopySVG width={18} color1="#fff" color2="#ffffffaa" color3="#ffffffaa" />}
            >
              Raw HTML
            </Button>
          </CustomTooltip>
          <CustomTooltip
            title="Copy and paste the graphic preview"
          >
            <Button
              variant="contained"
              onClick={copyHtmlAsBlob}
              startIcon={<CopySVG width={18} color1="#fff" color2="#ffffffaa" color3="#ffffffaa" />}
            >
              Styled HTML
            </Button>
          </CustomTooltip>
        </Grid>
      </Dialog>

      <DialogWarning
        open={openWarning}
        onClose={handleCloseWarning}
        header="Delete Section"
        title={`You are about to delete the selected Section`}
        messages={{
          one:'Are you sure you want to delete this section? All of the elements in the section will be deleted as well.',
          confirm:'Yes'
        }}
        // deleting
        cancel={handleCloseWarning}
        confirm={removeSection}
        // icons
      />

      <SnackbarWrapper
        onClose={() => setSnackMessage(prev => ({ ...prev, open:false }))}
        notice={snackMessage}
      />
    </div>
  );
};

export default EmailTemplate;
