import React from 'react';
import './EmailTemplate.css';

// Function to get styles from classes
// Make sure these styles match the corresponding style in EmailTemplate.css
const getStylesFromClass = (className) => {
  const styles = {}; // You can define your styles based on classes here
  switch (className) {
    case 'fill-eb-root':
      styles.width = '100%';
      // styles.backgroundColor = '#ddd';
      break;
    case 'fill-eb-body':
      styles.maxWidth = '600px';
      styles.width = '100%';
      styles.padding = '0 0 0 0';
      styles.margin = '0 auto';
      // styles.backgroundColor = '#ddd';
      break;
    case 'fill-eb-section':
      styles.padding = '10px 0 0 0';
      // styles.marginBottom = '5px';
      // styles.backgroundColor = '#fff';
      break;
    case 'fill-eb-column':
      styles.width = '100%';
      styles.display = 'inline-block';
      styles.verticalAlign = 'top';
      styles.padding = '10px';
      styles.boxSizing = 'border-box';
      break;
    case 'fill-eb-column-1':
      styles.width = '100%';
      break;
    case 'fill-eb-column-2':
      styles.width = '50%';
      break;
    case 'fill-eb-column-3':
      styles.width = '33%';
      break;
    case 'fill-eb-element-wrapper':
      styles.marginBottom = '4px';
      break;
    case 'fill-eb-text':
      styles["font-family"] = 'Arial, Helvetica, sans-serif';
      styles["font-size"] = '16px';
      styles["font-weight"] = 400;
      styles.textAlign = 'left';
      styles.color = '#333';
      break;
    case 'fill-eb-button':
      styles["font-family"] = 'Arial, Helvetica, sans-serif';
      styles["font-size"] = '16px';
      styles["font-weight"] = 400;
      styles["border-radius"] = "5px";
      styles.color = '#fff';
      styles.backgroundColor = '#27ADE7';
      styles.width = 'auto';
      styles.height = 'auto';
      styles.border = 'none';
      styles.padding = '5px 10px';
      break;
    case 'fill-eb-divider':
      styles["border-top"] = '1px solid #ccc';
      break;
    case 'fill-eb-spacer':
      styles["margin-top"] = 0;
      break;
    case 'fill-eb-image':
      styles.maxWidth = '100%';
      styles.height = 'auto';
      styles.objectFit = 'contain';
      break;
    case 'fill-eb-gif':
      styles.maxWidth = '100%';
      styles.height = 'auto';
      styles.objectFit = 'contain';
      break;
    case 'fill-eb-sticker':
      styles.maxWidth = '100%';
      styles.height = 'auto';
      styles.objectFit = 'contain';
      break;
    // Add other classes and styles as needed
    default:
      break;
  }
  return styles;
};

const EmailHtml = ({ json }) => {
  // Pull body and sections from incoming json object
  const { body, root, sections } = json;

  // Function to render individual elements based on their type
  const renderElement = (element) => {
    const { id, url, class: elementClass, content, style, wrapStyle } = element;

    if (element.id === 'button') { console.log('element', element) }
// console.log(elementClass);
    // Combine incoming styles with any additional styles from classes
    const combinedStyle = {
      ...getStylesFromClass(elementClass),
      ...style,
    };

    const combinedWrapStyle = {
      ...getStylesFromClass('fill-eb-element-wrapper'),
      ...wrapStyle,
    }

    switch (id) {
      case 'text':
        return (
          <div className={`fill-eb-element-wrapper`}>
            <div key={element.uid} className={elementClass} style={combinedWrapStyle} dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        );
      case 'divider':
        return (
          <div className={`fill-eb-element-wrapper`} style={combinedWrapStyle}>
            <hr key={element.uid} className={elementClass} style={combinedStyle} />
          </div>
        );
      case 'spacer':
        return (
          <div className={`fill-eb-element-wrapper`} style={combinedWrapStyle}>
            <div key={element.uid} className={elementClass} style={combinedStyle} />
          </div>
        );
      case 'button':
        return (
          <div className={`fill-eb-element-wrapper`} style={combinedWrapStyle}>
            <a href={element.url} target="_blank" key={element.uid}>
              <button className={elementClass} style={combinedStyle}>
                {content}
              </button>
            </a>
          </div>
        );
      case 'image':
        return (
          <div className={`fill-eb-element-wrapper`} style={combinedWrapStyle}>
            <img key={element.uid} src={url} alt="Image" className={elementClass} style={combinedStyle} />
          </div>
        );
      case 'gif':
        return (
          <div className={`fill-eb-element-wrapper`} style={combinedWrapStyle}>
            <img key={element.uid} src={url} alt="GIF" className={elementClass} style={combinedStyle} />
          </div>
        );
      case 'sticker':
        return (
          <div className={`fill-eb-element-wrapper`} style={combinedWrapStyle}>
            <img key={element.uid} src={url} alt="Sticker" className={elementClass} style={combinedStyle} />
          </div>
        );
      default:
        return null;
    }
  };

  // Function to render sections based on their configuration
  const renderSection = (section) => {
    const { id, class: sectionClass, elements, style } = section;

    // Combine incoming styles with any additional default styles
    const combinedSectionStyle = {
      ...style,
      // Optionally, you can add default styles here
    };

    // Determine the number of columns based on the section id
    const columnCount = parseInt(id.split('-')[1], 10); // column-1 -> 1, column-2 -> 2, etc.

    return (
      <table key={section.uid} className={`${sectionClass} ${id}`} style={{ width: '100%', borderCollapse: 'collapse', ...combinedSectionStyle }}>
        <tbody>
          <tr style={{width:'100%'}}>
            {Array.from({ length: columnCount }).map((_, columnIndex) => (
              <td
                key={columnIndex}
                className={`fill-eb-column fill-eb-${section.type}`}
                style={{ 
                  ...getStylesFromClass('fill-eb-column'),
                  ...getStylesFromClass(`fill-eb-${section.type}`)
                 }}
              >
                {elements.filter((element) => element.columnIndex === columnIndex).map(renderElement)}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    );
  };

  return (
    <div
      style={{
        ...getStylesFromClass('fill-eb-root'),
        ...root.style
      }}
    >
      <div
        className={`fill-eb-body`}
        style={{
          ...getStylesFromClass('fill-eb-body'),
          ...body.style
        }}
      >
        {sections.map(renderSection)}
      </div>
    </div>
  );
};

export default EmailHtml;