import React, { cloneElement, useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Typography,
  ListItemIcon,
  Switch,
  FormControlLabel
} from "@mui/material";
import { useTheme } from "@mui/system";
import { AddSVG } from '../../assets/icons';
import { useScreenSize } from '../../lib/Interface';
import { selectedIcons } from '../custom_fields/CustomFields';
import { createCustomField, putCustomField } from '../../hooks/mutations';
import { InputField } from '../ui/Fields';
import { getRandomInts, translateFieldType } from '../../lib/Functions';
import { DjangoComponents } from '../../templates/DjangoStyles';
import { TagButton } from '../ui/Tags';

const Step3CreateCustomFields = (props) => {
  const { trigger, setIsAddingField, setIsConnectingFields, incomingNewCustomFields, existingOptionsCustomFields, headers, preview, setReload } = props;
  const theme = useTheme();
  const [newCustomFields, setNewCustomFields] = useState(incomingNewCustomFields ? incomingNewCustomFields : []);
  const [newUpdatingOptionsCustomFields, setUpdatingOptionsCustomFields] = useState(existingOptionsCustomFields ? existingOptionsCustomFields : []);
  const [fieldsToUpdate, setFieldsToUpdate] = useState(existingOptionsCustomFields.filter(x => x.enum_values_new));

  const screenSize = useScreenSize();

  // console.log('existingOptionsCustomFields', existingOptionsCustomFields);
  console.log('fieldsToUpdate', fieldsToUpdate);
  // console.log('newCustomFields', newCustomFields);
  // console.log('newUpdatingOptionsCustomFields', newUpdatingOptionsCustomFields);
  // console.log('headers', headers);

  useEffect(() => {
    if (incomingNewCustomFields) {
      setNewCustomFields(incomingNewCustomFields);
    }
  }, [incomingNewCustomFields])

  useEffect(() => {
    if (existingOptionsCustomFields) {
      // testExistingOptions();
      // setUpdatingOptionsCustomFields(existingOptionsCustomFields);
    }
  }, [existingOptionsCustomFields])

  const customFieldData = {
    "id": getRandomInts(16), // crypto.randomUUID(),
    "data_type": "text",
    "field_name": ""
  };

  const handleAddCustomField = () => {
    setNewCustomFields(prev => [
      ...prev,
      customFieldData
    ]);
  };

  const handleFieldType = (value, id) => {
    const newFields = [...newCustomFields];
    const updated = newFields.map(x => {
      if (x.id === id) {
        return {
          ...x,
          data_type: value
        };
      } else {
        return x;
      }
    });

    setNewCustomFields(updated);
  };

  const handleFieldName = (value, id) => {
    const newFields = [...newCustomFields];
    const updated = newFields.map(x => {
      if (x.id === id) {
        return {
          ...x,
          field_name: value
        };
      } else {
        return x;
      }
    });
    setNewCustomFields(updated);
  };

  const cancelNewFields = () => {
    if (setIsAddingField) {
      setIsAddingField(false);
    }
    setNewCustomFields([]);
  };

  const handleFieldsToUpdate = (field) => {
    if (!!fieldsToUpdate.find(x => x.id === field.id)) {
      console.log('removing field');
      const updatedFields = fieldsToUpdate.filter(x => x.id !== field.id);
      setFieldsToUpdate(updatedFields);
    } else {
      console.log('adding field');
      const updatedFields = [ ...fieldsToUpdate, field ];
      setFieldsToUpdate(updatedFields);
    }
  }

  // Save new custom fields
  const postCustomField = async (customField) => {
    try {
      const customFieldObj = {
          payload: {
          'field_name': customField.field_name,
          'data_type': customField.data_type,
          'validation_rule': customField.data_type === 'list' ? 'enum_values' : null,
          'enum_values': customField.data_type === 'list' || customField.data_type === 'enum' ? (customField.enum_values ? customField.enum_values : ['placeholder']) : null
      }
      }
      const posted = await createCustomField(customFieldObj);
      if (posted.status === 'success') {
        console.log(`posted ${customField.field_name}:`, posted);
      } else {
        throw new Error('Failed')
      }
    }

    catch (error) {
      console.log('Error posting custom field', error);
    }
  }

  // Update existing custom field options
  const updateCustomField = async (updatedField) => {
    const newEnumValues = [ ...updatedField.enum_values, ...updatedField.enum_values_new ];
    console.log('newEnumValues', newEnumValues);
    delete updatedField.enum_values_new;
    delete updatedField.headerIndex;
    delete updatedField.values;
    try {
      const updatedFieldObj = {
        customFieldId: updatedField.id,
        payload:
        {
          ...updatedField,
          enum_values: newEnumValues
        }
    }
    console.log('updatedFieldObj', updatedFieldObj)
    const posted = await putCustomField(updatedFieldObj);
      if (posted.status === 'success') {
        console.log(`posted ${updatedField.field_name}:`, posted);
      } else {
        throw new Error('Failed')
      }
    }

    catch (error) {
      console.log('Error posting custom field', error);
    }
  }

  
  useEffect(() => {
    if (trigger) {
      handleSubmit();
    }
  }, [trigger])

  const handleSubmit = async () => {
    try {
        // Use Promise.all() to wait for all postCustomField promises to resolve
        if (newCustomFields.length) {
          await Promise.all(newCustomFields.map(customField => postCustomField(customField)));
        }
        if (fieldsToUpdate.length) {
          await Promise.all(fieldsToUpdate.map(customField => updateCustomField(customField)));
        }
        setIsConnectingFields(true); // This line will only execute if all postCustomField calls succeed
        setReload(true);
      } catch (error) {
        console.log('error', error);
    }
};

  return (
    <Grid
      container
      // style={{
      //   border: `2px solid ${theme.palette.primary.main}`,
      //   borderRadius: 8,
      //   padding: 24
      // }}
    >
      {
        !!newCustomFields.length &&
          <Grid
            container
            display={screenSize.xs ? 'none' : 'inherit'}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
            >
              <Typography

              >
                Field Name
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={8}
            >
              <Typography

              >
                Field Type
              </Typography>
            </Grid>
          </Grid>
      }
      {newCustomFields.map((field, index) => (
        <CustomFieldItem
          key={field.field_name}
          index={index}
          headers={headers}
          field={field}
          screenSize={screenSize}
          newCustomFields={newCustomFields}
          handleFieldName={handleFieldName}
          handleFieldType={handleFieldType}
          theme={theme}
          preview={preview}
        />
      ))}

      {
        existingOptionsCustomFields.filter(x => x.enum_values_new).length 
          ?
            <Grid
              container
              sx={{
                py:2,
                px:4,
                pb:4,
                borderRadius:4,
                boxShadow:'0 0 5px #00000030',
                background:'#ffffff'
              }}
            >
              <Grid container sx={{flexDirection:'column', alignItems:'center'}}>
                <Typography>FIELDS TO UPDATE</Typography>
                <Typography sx={{ maxWidth:800, fontWeight:600, color:'#777', fontStyle:'italic' }}>These fields already exist, but the newly imported contacts have selection options that are not setup for these fields. These options are set to import by default. You may choose not to add these options if you like, but they will be removed from the contacts on import.</Typography>
              </Grid>

            {newUpdatingOptionsCustomFields.filter(x => x.enum_values_new)?.map((field, index) => (
                <Grid
                  container
                  key={field.field_name}
                  sx={{
                    marginTop: index === 0 ? 2 : null,
                    alignItems: 'center',
                    border: `2px solid ${!!fieldsToUpdate.find(x => x.id === field.id) ? theme.palette.primary.main : '#ccc'}`,
                    p:1,
                    borderRadius:2
                    // background: index % 2 ? `${theme.palette.primary.light}30` : 'transparent'
                  }}
                >
                  {/* <Grid
                    item
                    xs={12}
                  >
                    <Typography
                      color="mid.main"
                      style={{
                        fontStyle:'italic'
                      }}
                    >
                      {field.field_name}
                    </Typography>
                  </Grid> */}

                  <Grid
                    item
                    paddingRight={screenSize.xs ? 0 : 2}
                    marginBottom={screenSize.xs ? 1 : 0}
                    xs={12}
                    sm={6}
                    md={4}
                  >
                    <Grid
                      container
                      sx={{
                        height:55,
                        // background:'#fff',
                        // boxShadow:'0 0 5px #00000030',
                        gap:4,
                        alignItems:'center',
                        p:2,
                        borderRadius:1
                      }}
                    >
                      {field.field_name}
                      <Grid sx={{display:'flex', gap:1}}>
                        {cloneElement(selectedIcons[newUpdatingOptionsCustomFields[index]["data_type"]].icon, { width: 20, color1: theme.palette.primary.main, style:{height:20} })}
                        {translateFieldType(newUpdatingOptionsCustomFields[index]["data_type"])}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    px={0.5}
                    xs={12}
                    sm={6}
                    md={8}
                  >
                    <Grid
                      container
                      style={{
                        gap: 8,
                      }}
                    >
                      <Grid
                        container
                        sx={{
                          height:55,
                          // background:'#fff',
                          // boxShadow:'0 0 5px #00000030',
                          gap:2,
                          alignItems:'center',
                          p:2,
                          borderRadius:1,
                          justifyContent:'flex-end'
                        }}
                      >
                        <FormControlLabel
                          label="Import Options"
                          control={
                            <Switch
                              checked={!!fieldsToUpdate.find(x => x.id === field.id)} // Convert result to boolean with !!
                              onClick={() => handleFieldsToUpdate(field)
                                // {
                                // fieldsToUpdate.find(x => x.id === field.id)
                                //   ? handleAddFieldToUpdate(field)
                                //   : handleRemoveFieldToUpdate(field)
                                // }
                              }
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {
                    field.data_type === 'list' || field.data_type === 'enum'
                      ? 
                        <Grid
                          item
                          xs={12}
                        >
                          <Grid container>
                            <Typography
                              color="mid.main"
                              style={{
                                fontStyle:'italic',
                                marginTop:16
                              }}
                            >
                              Existing Field Options
                            </Typography>

                            <Grid
                              container
                            >
                              {
                                field.enum_values
                                  ?
                                    field.enum_values.map(item => (
                                      <Grid
                                        item
                                        style={{
                                          padding:'2px 8px',
                                          margin:'4px',
                                          background:'#ddd',
                                          borderRadius:'4px'
                                        }}
                                      >
                                        <Typography

                                        >
                                          {item}
                                        </Typography>
                                      </Grid>
                                    ))
                                  :
                                    <Typography>No options were found. You can add options for this list after it is created.</Typography>
                              }
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Typography
                              color="mid.main"
                              style={{
                                fontStyle:'italic',
                                marginTop:16
                              }}
                            >
                              New Field Options from Import
                            </Typography>

                            <Grid
                              container
                            >
                              {
                                field.enum_values_new
                                  ?
                                    field.enum_values_new.map(item => (
                                      <Grid
                                        item
                                        style={{
                                          padding:'2px 8px',
                                          margin:'4px',
                                          background: !!fieldsToUpdate.find(x => x.id === field.id) ? `${theme.palette.primary.light}77` : '#ddd',
                                          borderRadius:'4px'
                                        }}
                                      >
                                        <Typography

                                        >
                                          {item}
                                        </Typography>
                                      </Grid>
                                    ))
                                  :
                                    <Typography>No new options were found. You can add options for this list after it is created.</Typography>
                              }
                            </Grid>
                          </Grid>
                        </Grid>

                      : null
                  }
                  {/* <Divider style={{width:'100%', margin:'24px 0'}} /> */}

                </Grid>
              ))}
            </Grid>
          : null
      }



      {/* <Button
        onClick={handleAddCustomField}
        disabled={newCustomFields.filter(x => x.field_name.length < 1).length > 0}
        fullWidth
        style={{
          outline: `2px dashed ${newCustomFields.filter(x => x.field_name.length < 1).length > 0 ? "#ccc" : theme.palette.primary.main}`,
          height: 50,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 8,
          gap: 8,
          margin: '8px 0',
          textTransform: 'initial'
        }}
      >
        <AddSVG width={16} color1={newCustomFields.filter(x => x.field_name.length < 1).length > 0 ? '#ccc' : theme.palette.primary.main} />
        <Typography style={{ fontWeight: 600 }}>New Field</Typography>
      </Button> */}

      {/* <Grid
        container
        sx={{
          justifyContent: 'space-between',
          marginTop: '8px'
        }}
      >
        <Button
          onClick={cancelNewFields}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!newCustomFields.filter(x => x.field_name.length > 0).length}
        >
          Save
        </Button>
      </Grid> */}
    </Grid>
  );
};

export default Step3CreateCustomFields

export const InputButtons = (props) => {
  const { fieldType, type, value, onClick } = props;
  const theme = useTheme();
  const screenSize = useScreenSize();

  return (
    <Button
      onClick={onClick}
      fullWidth
      style={{
        outline: fieldType === type ? `2px solid ${theme.palette.primary.main}` : 'none',
        textTransform:'lowercase',
        lineHeight:1,
        height:'100%',
        boxShadow:'0 0 5px #00000030',
        alignItems:'center',
        // padding:'16px 16px',
        maxHeight:27,
        gap:8,
        borderRadius:8,
        display:'flex',
        color: fieldType ? (fieldType === type ? theme.palette.primary.main : '#aaa') : 'inherit',
        background: fieldType === type ? '#ffffff' : '#ffffff77'
      }}
    >
      <Grid
        sx={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          width:25
        }}
      >
        {cloneElement(value.icon, {
          color1:fieldType === type ? theme.palette.primary.main : "#aaa"
        })}
      </Grid>
      {screenSize.lg ? null : value.label}

    </Button>
  )
}

const CustomFieldItem = (props) => {
  const { index, headers, field, screenSize, newCustomFields, handleFieldName, handleFieldType, theme, preview } = props;
  const [showAllValues, setShowAllValues] = useState(false);
  const [displayedValues, setDisplayedValues] = useState(field.enum_values && field.enum_values.slice(0,20));

  const handleDisplayedValues = () => {
    if (!field.enum_values) return;

    setShowAllValues(prev => !prev);

    if (!showAllValues) {
      setDisplayedValues(field.enum_values);
    } else {
      const truncatedValues = field.enum_values.slice(0,20);
      setDisplayedValues(truncatedValues);
    }
  }

  // useEffect(() => {
  //   if (showAllValues) {
  //     setDisplayedValues(field.enum_values);
  //   } else {
  //     const truncatedValues = field.enum_values.slice(0,20);
  //     setDisplayedValues(truncatedValues);
  //   }
  // }, [showAllValues])

  return (
    <Grid
      container
      style={{
        marginTop: index === 0 ? '16px' : null,
        alignItems: 'center',
        // background: index % 2 ? `${theme.palette.primary.light}30` : 'transparent'
      }}
    >
      <Grid
        item
        xs={12}
      >
        <Typography
          color="mid.main"
          style={{
            fontStyle: 'italic'
          }}
        >
          From {headers[field.headerIndex]}
        </Typography>
      </Grid>

      <Grid
        item
        paddingRight={screenSize.xs ? 0 : 2}
        marginBottom={screenSize.xs ? 1 : 0}
        xs={12}
        sm={6}
        md={4}
      >
        <InputField
          placeholder='Field Name'
          value={newCustomFields[index]?.["field_name"]}
          onChange={(e) => handleFieldName(e.target.value, field.id)} />
      </Grid>

      <Grid
        item
        px={0.5}
        xs={12}
        sm={6}
        md={8}
      >
        <Grid
          container
          style={{
            gap: 8,
          }}
        >
          {screenSize.lg
            ?
            <TextField
              select
              fullWidth
              variant='standard'
              InputProps={{
                disableUnderline: true,
                style: {
                  display: 'flex',
                  alignItems: 'center',
                  lineHeight: 1,
                  height: 55,
                }
              }}
              style={{
                margin: 0,
                borderRadius: 4,
                padding: '0 16px',
                boxShadow: '0 0 5px #00000030',
                display: 'flex',
                alignItems: 'center',
                background: 'white'
              }}
              value={newCustomFields[index]["data_type"]}
              onChange={(e) => handleFieldType(e.target.value, field.id)}
            >
              {Object.entries(selectedIcons).map(([key, value]) => (
                <MenuItem
                  key={key}
                  value={key}
                >
                  <ListItemIcon>
                    {cloneElement(value.icon, { width: key === 'number' || key === 'large_text_field' ? 24 : 20, color1: theme.palette.primary.main, style: { height: 20 } })}
                  </ListItemIcon>

                  {value.label}

                </MenuItem>
              ))}
            </TextField>
            :
            Object.entries(selectedIcons).map(([key, value], iconIndex) => (
              <Grid
                item
                xs
                sm={2.32}
              >
                <InputButtons
                  type={key}
                  value={value}
                  fieldType={newCustomFields[index]["data_type"]}
                  onClick={() => handleFieldType(key, newCustomFields[index].id)} />
              </Grid>
            ))}
        </Grid>
      </Grid>

      {field.data_type === 'list' || field.data_type === 'enum'
        ?
        <Grid
          item
          xs={12}
        >
          <Grid
            container
            sx={{
              alignItems:'center',
              mt:2
            }}
          >
            <Typography
              color="mid.main"
              style={{
                fontStyle: 'italic',
              }}
            >
              Field Options {field.enum_values.length && `(${field.enum_values.length} options)` }
            </Typography>
            <TagButton
              label={showAllValues ? "show less" : "show all"}
              fontColor={theme.palette.tertiary.dark}
              // iconStart={<AddSVG width={8} color={theme.palette.tertiary.dark} />}
              onClick={handleDisplayedValues}
            />
          </Grid>

          <Grid
            container
          >
            {field.enum_values
              ?
              displayedValues.map(item => (
                <Grid
                  item
                  style={{
                    padding: '2px 8px',
                    margin: '4px',
                    background: '#ddd',
                    borderRadius: '4px'
                  }}
                >
                  <Typography

                  >
                    {item}
                  </Typography>
                </Grid>
              ))
              :
              <Typography>No options were found. You can add options for this list after it is created.</Typography>}
          </Grid>
        </Grid>

        : null}

      <Grid
        item
        xs={12}
      >
        <Typography
          color="mid.main"
          style={{
            fontStyle: 'italic',
            marginTop: 16
          }}
        >
          {preview[field.headerIndex].length
            ? `Examples from ${headers[field.headerIndex]}`
            : 'No examples found in uploaded data'}
        </Typography>

        <Grid
          container
        >
          {preview[field.headerIndex].map(item => (
            <Grid
              item
              style={{
                padding: '2px 8px',
                margin: '4px',
                background: '#ddd',
                borderRadius: '4px'
              }}
            >
              <Typography

              >
                {item}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Divider style={{ width: '100%', margin: '24px 0' }} />

    </Grid>
  )
}

