import React, { useState } from 'react'
import MainPageTemplate from '../templates/MainPageTemplate'
import CampaignReport from '../components/campaigns/CampaignReport';

const mockData = `{"id": "4", "name": "Test Campaign 3", "open_rate": "100.0", "mailing_list_open_rate": "20.0", "unique_opens_count": "2", "total_opens_count": "2", "click_rate": "100.0", "mailing_list_click_rate": "20.0", "unique_clicks_count": "2", "total_clicks_count": "200", "links": "[{&quot;model&quot;: &quot;col_campaigns.link&quot;, &quot;pk&quot;: 1, &quot;fields&quot;: {&quot;created&quot;: &quot;2024-11-13T22:30:52.481Z&quot;, &quot;modified&quot;: &quot;2024-11-13T22:30:52.481Z&quot;, &quot;tenant_id&quot;: 1, &quot;uuid&quot;: &quot;56b649fd-712a-464f-bb4a-c845963dfe06&quot;, &quot;email&quot;: 4, &quot;url&quot;: &quot;http://google.com&quot;, &quot;unique_clicks_count&quot;: 2, &quot;total_clicks_count&quot;: 2, &quot;index&quot;: 0}}]", "subscriber_open_activities": "&lt;QuerySet [{&#x27;contact__id&#x27;: 1, &#x27;contact__primary_email&#x27;: &#x27;lgrambush@gmail.com&#x27;, &#x27;total_opens&#x27;: 1}, {&#x27;contact__id&#x27;: 2, &#x27;contact__primary_email&#x27;: &#x27;lee@activateparty.com&#x27;, &#x27;total_opens&#x27;: 1}]&gt;", "location_open_activities": "&lt;QuerySet []&gt;" }`

const CampaignReportPage = () => {
  const isLive = process.env.REACT_APP_CURRENT_ENV !== 'STAGING';
  const [campaignData, setCampaignData] = useState(isLive
    ? null
    : JSON.parse(mockData.replaceAll('&quot;', '"').replaceAll('&#x27;', '"').replaceAll('&lt;QuerySet', '').replaceAll('&gt;', '').replaceAll('"[', '[').replaceAll(']"', ']').replaceAll('" [', '['))
  );
  // const [campaignData, setCampaignData] = useState(null);
  // Get the script element by its id
  const scriptElement = document.getElementById('django-campaign-report');

  if (scriptElement && !campaignData) {
    // Access the content of the script element
    const djangoReportString = scriptElement?.textContent;
    // console.log('djangoReportString', djangoReportString)
  
    // Parse the JSON data
    const djangoReport = JSON.parse(djangoReportString);
    // console.log('djangoReport', djangoReport)

    // Set the contact id state from the JSON data
    setCampaignData(djangoReport)
  }

  // console.log('campaignData', campaignData)

  return (

    // <MainPageTemplate title="Welcome">
    campaignData &&
      <CampaignReport campaignData={campaignData} />
    // </MainPageTemplate>

    
  )
}

export default CampaignReportPage